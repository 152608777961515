import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { deletePrenda } from "../functions";
const PrendaDelete = ({openDelete,setOpenDelete, id}) => {
    const deleteFnc = async () =>{
    await deletePrenda(id);
    setOpenDelete(false);
}
    return (
        <>
            <Dialog
            open={openDelete}
            onClose={()=>setOpenDelete(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación de la Prenda"}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={()=>setOpenDelete(false)}color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc}color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default PrendaDelete;