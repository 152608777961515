import { getAllConsumptionByPrendaV2 } from "components-base/prendas/functions";
import { client } from "utils/axios";

export async function getAllClothsByProduct(prendas) {
  let cloths = [];

  for (let j = 0; j < prendas.length; j++) {
    const pp = prendas[j];
    const prendaId = pp.id;
    const cloth = await getAllConsumptionByPrendaV2(prendaId);
    cloth.forEach((c) => {
      let _cloth = c.cloth;
      if (cloths.length === 0) {
        cloths.push(_cloth);
      } else {
        let _clothCheck = cloths.find((item) => item.id === _cloth.id);
        if (!_clothCheck) cloths.push(_cloth);
      }
    });
  }
  return cloths;
}

export async function dataTransform(detail) {
  let products = [];
  let cloths = [];
  detail.detallePedido.forEach((item) => {
    item.producto.tallas = item.details.tallas;
    products = [...products, item.producto];
  });

  let obj = {
    cloths,
    products,
    percentage: detail.percentage,
  };
  return obj;
}
export function transformSizes(sizes, percentage) {
  let data = [];
  sizes.forEach((s) => {
    let _percentage = Math.floor(Number((s.cantidad * percentage) / 100));
    let obj = {
      name: s.talla.nombre,
      pieces: s.cantidad,
      percentage: _percentage,
      total: Number(s.cantidad) + _percentage,
      cut: "",
    };
    data = [...data, obj];
  });

  return data;
}

export async function getAllEmbroideryOrderById(id) {
  const { data } = await client.get(`embroideryOrder/filter/${id}`);
  return data;
}
export const createEmbroideryOrder = async (embroideryInput) => {
  try {
    await client.post("embroideryOrder", embroideryInput);
  } catch (error) {}
};
export const modifyEmbroideryOrder = async (embroideryInput) => {
  try {
    await client.put("embroideryOrder", embroideryInput);
  } catch (error) {}
};
export const deleteEmbroideryOrder = async (id) => {
  await client.delete(`embroideryOrder/${id}`);
};

export function DataEmboideryConvert(data) {
  const sizes = data.sizes;
  let totalPercentage = 0;
  let totalPieces = 0;
  let grandTotal = 0;
  sizes.forEach((item) => {
    totalPercentage += Number(item.percentage);
    totalPieces += Number(item.pieces);
    grandTotal += Number(item.total);
  });
  const totals = {
    totalPercentage,
    totalPieces,
    grandTotal,
  };
  let obj = {
    embroideryManager: data.embroideryManager.name,
    model: `${data.product.modelo} (${data.product.nombre})`,
    defects: data.defects || "",
    garment: data.garment,
    embroideryTime: data.embroideryTime,
    deliveryDate: data.deliveryDate.split(" ")[0],
    image: data.product.imagen,
    sizes: data.sizes,
    totals: totals,
  };
  console.log(obj);
  return obj;
}
