// import InsumoTable from "components-base/insumos/components/InsumoTable";
// import TelasTable from "components-base/telas/components/TelaTable";
import MachinaryTable from "components-base/machinary/components/MachinaryTable";
import FurnitureTable from "components-base/furniture/components/FurnitureTable";
import OutputsTable from "components-base/Outputs/components/OutputsTable";
import InputsTable from "components-base/Inputs/components/InputsTable";
import StackTelasTable from "components-base/StackCloth/components/StackTelasTable";

export const tabsElement = [
  // {
  //   label: "Telas",
  //   component: <TelasTable />,
  // },
  // {
  //   label: "Insumos",
  //   component: <InsumoTable />,
  // },
  {
    label: "Maquinarias",
    component: <MachinaryTable />,
  },
  {
    label: "Muebles",
    component: <FurnitureTable />,
  },
  {
    label: "Entradas",
    component: <InputsTable />,
  },
  // {
  //   label: "Salidas",
  //   component: <OutputsTable />,
  // },
  {
    label: "Stack Rollos",
    component: <StackTelasTable />,
  },
];
