import React from "react";

// Admin Imports
import { MdOutlineInventory } from "react-icons/md";
import MainDashboard from "pages/admin/inicio";
import NFTMarketplace from "pages/admin/pedidos";
import Profile from "pages/admin/profile";
import Productos from "pages/admin/productos/productos";
import Inventario from "pages/admin/inventory/inventory";
import Usuarios from "pages/admin/usuarios";
import Rutas from "pages/admin/rutas";

// Auth Imports
import SignIn from "pages/auth/SignIn";

// Icon Imports
import {
  MdSupervisedUserCircle,
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdCardTravel, // eslint-disable-next-line
} from "react-icons/md";
import Salidas from "pages/admin/salidas/salidas";
import EngineeringIcon from '@mui/icons-material/Engineering';
import ComputerIcon from '@mui/icons-material/Computer';
import WorkerManagement from "pages/admin/workers/workers";
import SystemManagement from "pages/admin/system-management";
import UserManagement from "pages/admin/user-management";

const routes = [
  {
    name: "Panel Principal",
    layout: "/admin",
    path: "inicio",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Administracion del Personal",
    layout: "/admin",
    path: "workers",
    icon: <EngineeringIcon className="h-6 w-6" />,
    component: <WorkerManagement />,
  },
  {
    name: "Usuarios del Sistema",
    layout: "/admin",
    path: "user-management",
    icon: <MdSupervisedUserCircle className="h-6 w-6" />,
    component: <UserManagement />,
  },
  {
    name: "Administracion del Sistema",
    layout: "/admin",
    path: "system-management",
    icon: <ComputerIcon className="h-6 w-6" />,
    component: <SystemManagement />,
  },
  {
    name: "Pedidos",
    layout: "/admin",
    path: "pedidos",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
  },
  {
    name: "Salidas",
    layout: "/admin",
    path: "salidas",
    icon: <MdCardTravel className="h-6 w-6" />,
    component: <Salidas />,
  },
  {
    name: "Usuarios",
    layout: "/admin",
    path: "usuarios",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Usuarios />,
  },
  {
    name: "Rutas",
    layout: "/admin",
    path: "rutas",
    icon: <MdCardTravel className="h-6 w-6" />,
    component: <Rutas />,
  },

  {
    name: "Productos",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "productos",
    component: <Productos />,
  },
  {
    name: "Inventario",
    layout: "/admin",
    icon: <MdOutlineInventory className="h-6 w-6" />,
    path: "inventario",
    component: <Inventario />,
  },

  {
    name: "Mi Perfil",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  // {
  //   name: "Acceso Users",
  //   layout: "/users",
  //   path: "default",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Salida",
    layout: "/auth",
    path: "sign-in",
    icon: <MdPerson className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
