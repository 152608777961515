import { client } from "../../../utils/axios";
import { showMessage } from "../Message";

export const initialState = {
  error: null,
  loading: false,
  success: false,
};

// Actions Types
const ERROR = "[USERS] ERROR";
//CHOFER
const CREATE_CHOFER_START = "[USERS] CREATE_CHOFERES_START";
const CREATE_CHOFER_SUCCESS = "[USERS] CREATE_CHOFERES_SUCCESS";
const MODIFY_CHOFER_START = "[USERS] MODIFY_CHOFER_STAR";
const MODIFY_CHOFER_SUCCESS = "[USERS] MODIFY_CHOFER_SUCCESS";
const DELETE_CHOFER_START = "[USERS] DELETE_CHOFER_START";
const DELETE_CHOFER_SUCCESS = "[USERS] DELETE_CHOFER_SUCCESS";
//MAQUILERO
const CREATE_MAQUILERO_START = "[USERS] CREATE_MAQUILERO_START";
const CREATE_MAQUILERO_SUCCESS = "[USERS] CREATE_MAQUILERO_SUCCESS";
const MODIFY_MAQUILERO_START = "[USERS] MODIFY_MAQUILERO_START";
const MODIFY_MAQUILERO_SUCCESS = "[USERS] MODIFY_MAQUILERO_SUCCESS";
const DELETE_MAQUILERO_START = "[USERS] DELETE_MAQUILERO_START";
const DELETE_MAQUILERO_SUCCESS = "[USERS] DELETE_MAQUILERO_SUCCESS";
const RESET_SUCCESS = "[USERS] RESET_SUCCESS";
/*
 Arrow function for change state
 */
const process = (type) => ({
  type,
});
const setError = (payload) => ({
  type: ERROR,
  payload,
});

export default function Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_CHOFER_START:
      return { ...state, loading: true };
    case CREATE_CHOFER_SUCCESS:
      return { ...state, success: true, loading: false };
    case MODIFY_CHOFER_START:
      return { ...state, loading: true };
    case MODIFY_CHOFER_SUCCESS:
      return { ...state, success: true, loading: false };
    case DELETE_CHOFER_START:
      return { ...state, loading: true };
    case DELETE_CHOFER_SUCCESS:
      return { ...state, success: true, loading: false };
    case CREATE_MAQUILERO_START:
      return { ...state, loading: true };
    case CREATE_MAQUILERO_SUCCESS:
      return { ...state, success: true, loading: false };
    case MODIFY_MAQUILERO_START:
      return { ...state, loading: true };
    case MODIFY_MAQUILERO_SUCCESS:
      return { ...state, success: true, loading: false };
    case DELETE_MAQUILERO_START:
      return { ...state, loading: true };
    case DELETE_MAQUILERO_SUCCESS:
      return { ...state, success: true, loading: false };
    case RESET_SUCCESS:
      return { ...state, success: false };
    case ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
}

export const createDriver = (dataDriver) => async (dispatch) => {
  dispatch(process(CREATE_CHOFER_START));
  client
    .post("choferes", dataDriver)
    .then((response) => {
      // console.log(response);
      dispatch(process(CREATE_CHOFER_SUCCESS));
      dispatch(
        showMessage({
          message: "Nuevo chofer agregado con éxito",
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(process(RESET_SUCCESS));
    })
    .catch(function (error) {
      dispatch(
        showMessage({
          message:
            "Ocurrió un error al agregar un nuevo chofer: " + " " + error,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(setError(ERROR));
      console.log(error);
    });
};

export const modifyDriver = (dataDriver) => async (dispatch) => {
  dispatch(process(MODIFY_CHOFER_START));
  client
    .put("choferes", dataDriver)
    .then((response) => {
      dispatch(process(MODIFY_CHOFER_SUCCESS));
      dispatch(
        showMessage({
          message: "Se editaron los datos del chofer con éxito",
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(process(RESET_SUCCESS));
    })
    .catch(function (error) {
      dispatch(
        showMessage({
          message: "Ocurrió un error al editar el chofer: " + " " + error,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(setError(ERROR));
      console.log(error);
    });
};

export const deleteDriver = (idDriver) => async (dispatch) => {
  dispatch(process(DELETE_CHOFER_START));
  client
    .delete("choferes/" + idDriver)
    .then((response) => {
      dispatch(process(DELETE_CHOFER_SUCCESS));
      dispatch(
        showMessage({
          message: "Se eliminaron los datos del chofer con éxito",
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(process(RESET_SUCCESS));
    })
    .catch(function (error) {
      dispatch(
        showMessage({
          message:
            "Ocurrió un error al eliminar los datos del chofer: " + " " + error,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(setError(ERROR));
      console.log(error);
    });
};

export const createMaquilero = (dataMaquilero) => async (dispatch) => {
  dispatch(process(CREATE_MAQUILERO_START));
  client
    .post("maquileros", dataMaquilero)
    .then((response) => {
      dispatch(process(CREATE_MAQUILERO_SUCCESS));
      dispatch(
        showMessage({
          message: "Nuevo maquilero agregado con éxito",
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(process(RESET_SUCCESS));
    })
    .catch(function (error) {
      dispatch(
        showMessage({
          message:
            "Ocurrió un error al agregar un nuevo maquilero: " + " " + error,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(setError(ERROR));
      console.log(error);
    });
};

export const modifyMaquilero = (dataMaquilero) => async (dispatch) => {
  dispatch(process(MODIFY_MAQUILERO_START));
  client
    .put("maquileros", dataMaquilero)
    .then((response) => {
      dispatch(process(MODIFY_MAQUILERO_SUCCESS));
      dispatch(
        showMessage({
          message: "Se editaron los datos del maquilero con éxito",
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(process(RESET_SUCCESS));
    })
    .catch(function (error) {
      dispatch(
        showMessage({
          message: "Ocurrió un error al editar el maquilero: " + " " + error,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(setError(ERROR));
      console.log(error);
    });
};

export const deleteMaquilero = (idMaquilero) => async (dispatch) => {
  dispatch(process(DELETE_MAQUILERO_START));
  client
    .delete("maquileros/" + idMaquilero)
    .then((response) => {
      dispatch(process(DELETE_MAQUILERO_SUCCESS));
      dispatch(
        showMessage({
          message: "Se eliminaron los datos del maquilero con éxito",
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(process(RESET_SUCCESS));
    })
    .catch(function (error) {
      dispatch(
        showMessage({
          message:
            "Ocurrió un error al eliminar los datos del maquilero: " +
            " " +
            error,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      dispatch(setError(ERROR));
      console.log(error);
    });
};
