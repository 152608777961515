import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import MaquileroFormFields from "./MaquileroFormFields";
import TabComponent from "components-base/tab/Tab";
import { createMaquilero, modifyMaquilero, validationSchemaMaquilero } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";
import { useEffect, useState } from "react";

const MaquileroForm = ({ mode, handleClose }) => {
    const { defaultDataMaquilero } = useSelector(({ maquilero }) => maquilero);
    const [maquinas, setMaquinas] = useState(null);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (mode === "POST") {
            setMaquinas([])
        } else {
            if (defaultDataMaquilero.details)
                setMaquinas(defaultDataMaquilero.details.maquinas);
            else
                setMaquinas([]);
        }
    }, [mode, defaultDataMaquilero]);

    const messages = (mensaje,res) => {
        dispatch(
            showMessage({
                message: res ? `Maquilero ${mensaje} con éxito` : `Hubo un error!`,
                variant: res ? "success" : "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    codigo: "",
                    nombre: "",
                    apellidoPaterno: "",
                    apellidoMaterno: "",
                    direccion: "",
                    rfc: "",
                    capacidad: 0,
                    foraneo: false,
                    ruta:""
                }
                : {
                    id: defaultDataMaquilero.id,
                    codigo: defaultDataMaquilero.codigo,
                    capacidad: defaultDataMaquilero.capacidad,
                    foraneo: defaultDataMaquilero.foraneo,
                    nombre: defaultDataMaquilero.persona.nombre,
                    apellidoPaterno: defaultDataMaquilero.persona.apellidoPaterno,
                    apellidoMaterno: defaultDataMaquilero.persona.apellidoMaterno,
                    direccion: defaultDataMaquilero.persona.direccion,
                    rfc: defaultDataMaquilero.persona.rfc,
                    persona: { id: defaultDataMaquilero.persona.id },
                    ruta:defaultDataMaquilero.ruta

                },
        validationSchema: validationSchemaMaquilero,
        onSubmit: async (values) => {
            switch (mode) {
                case "POST":
                    values.details= {maquinas:maquinas}
                    const res = await createMaquilero(values);
                    messages(res ? "creado" : "fallo",res)
                    handleClose();
                    break;
                case "PUT":
                    values.details= {maquinas:maquinas}
                    await modifyMaquilero(values);
                    messages("modificado");
                    handleClose();
                    break;
                default: handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información Maquilero",
                    component: <MaquileroFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} maquinas={maquinas} setMaquinas={setMaquinas} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item  >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item >
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default MaquileroForm;