import { DialogActions, Grid, TextField, Autocomplete, RadioGroup, FormControl, FormControlLabel, Radio, FormLabel, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AddConsumption from "./AddConsumption";
import { useDispatch, useSelector } from "react-redux";
import { getAllTelas } from "components-base/telas/functions";
import { createClothConsumption, getProductTallas } from "components-base/prendas/functions";
import { showMessage } from "store/modules/Message";
import { modifyClothConsumption } from "components-base/prendas/functions";

const ClothConsumption = ({ handleClose, defaultData, mode, productoId }) => {
    const { defaultData: prenda } = useSelector(({ prenda }) => prenda);
    const dispatch = useDispatch();
    const [cloth, setCloth] = useState(null);
    const [clothSelected, setClothSelected] = useState(null);
    const [type, setType] = useState("average");
    const [details, setDetails] = useState(null);
    const [notes, setNotes] = useState("");

    useEffect(() => {
        const fetchCloth = async () => {
            let cloths = await getAllTelas();
            setCloth(cloths)
        }
        fetchCloth();
    }, [])

    useEffect(() => {
        if (mode === "PUT") {
            setDetails(defaultData.details);
            setNotes(defaultData.notes);
            setClothSelected(defaultData.cloth);
        } else {
            const fetchTallas = async () => {
                const tallasByProducto = await getProductTallas(productoId || 0);
                let tallas = tallasByProducto.map(item => 
                   {
                    item.talla.quantity = 0;
                    item.talla.unit={name:"Selecciona una medida"}
                    return item.talla
                }
                    )
                setDetails(tallas);
            }
            fetchTallas();
        }
    }, [mode]);


    const handleClothChange = (e, option) => {
        setClothSelected(option);
    }
    const submitAction = async () => {

        if (!clothSelected) {
            dispatch(showMessage({
                variant: "error",
                message: "Ingrese toda la informacion requerida.",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            }));
            return;
        }
        let _average = false;
        let quantity = 0;
        let unit = ""
        if (type === "average") {
            _average = true;
            quantity = details[0].quantity;
            unit = details[0].unit;
        }

        let obj = {
            cloth: { id: clothSelected.id },
            prenda: { id: prenda.id },
            quantity: quantity,
            unit: unit.name,
            details: details,
            isAverage: _average,
            notes: notes
        }
        let res = false;
        if (mode === "POST")
            res = await createClothConsumption(obj);
        if (mode === "PUT") {
            obj.id = defaultData.id
            res = await modifyClothConsumption(obj);
        }

        dispatch(showMessage({
            variant: res ? "success" : "error",
            message: res ? `El consumo se ${mode === "POST" ? "agrego" : "modifico"} con exito.` : `Hubo un error al ${mode === "POST" ? "crear" : "modificar"} el consumo.`,
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
        }));
        handleClose();
    }

    if (!details) return "Cargando...."
    return (
        < >

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="title" >
                        {"Puedes agregar el consumo promedio para todas las tallas de la prenda o indicar individualmente."}
                    </Typography>

                </Grid>
                <Grid item xs={3}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="radio-buttons-Type"
                            defaultValue="average"
                            name="type"
                            onChange={(e) => setType(e.target.value)}
                        >
                            <FormControlLabel value="average" control={<Radio />} label="Indicar el promedio" />
                            <FormControlLabel value="individually" control={<Radio />} label="Indicar individualmente" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        fullWidth
                        getOptionLabel={(cloth) => `${cloth.name}, ${cloth.color}, ${cloth.supplier.persona.nombre} ${cloth.supplier.persona.apellidoPaterno}`}
                        onChange={handleClothChange}
                        disablePortal
                        id="combo-box-cloth"
                        options={cloth || []}
                        renderInput={(params) => <TextField {...params} label="Tela" />}
                        defaultValue={mode === "PUT" ? defaultData.cloth : null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AddConsumption details={details} setDetails={setDetails} notes={notes} setNotes={setNotes} />
                </Grid>
            </Grid>

            <DialogActions>
                <Button onClick={handleClose} variant="contained" color={"secondary"}>
                    {"Cancelar"}
                </Button>
                <Button onClick={submitAction} variant="contained" color={"secondary"}>
                    {"Guardar"}
                </Button>
            </DialogActions>
        </>
    )
}
export default ClothConsumption;