import { Dialog, DialogContent, Button } from "@mui/material";

const ImageModal = ({ imageUrl, open, handleClose }) => {
    return (
        <Dialog open={open}
            onClose={handleClose}
            fullScreen
        >
            <Button onClick={handleClose}>
                Cerrar
            </Button>
            <DialogContent>
                <img src={imageUrl} alt="Sin Imagen" />
            </DialogContent>
        </Dialog>
    )
}
export default ImageModal;