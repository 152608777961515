import Card from "components/card";
import { DataGrid } from "@mui/x-data-grid";
import MaquileroDrawer from "./MaquileroDrawer";
import React, { useState, useEffect } from "react";
import { getAllMaquilero } from "../functions";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import MaquileroDelete from "./MaquileroDelete";
import { columns } from "../columns";
import ImpresionQrPdf from "./ImpresionQrPdf";
import ImpresionQRModal from "./ImpresionQRModal";

const MaquileroTable = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [maquileroData, setMaquileroData] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const [openPDF, setOpenPDF] = useState(false);
  const dispatch = useDispatch();

  const handleOpenPDF = () => setOpenPDF(true);
  const handleClosePDF = () => setOpenPDF(false);

  useEffect(() => {
    const fetchData = async () => {
      let maquileros = await getAllMaquilero();
      setMaquileroData(maquileros);
      let col = columns(
        setOpen,
        setMode,
        setOpenDelete,
        dispatch,
        handleOpenPDF
      );
      setDataColumns(col);
    };
    fetchData();
  }, [open, openDelete, dispatch]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <ImpresionQRModal handleClosePDF={handleClosePDF} open={openPDF} />
      <MaquileroDelete openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <MaquileroDrawer open={open} handleClose={handleClose} mode={mode} />
      <Card extra={"w-full h-full p-5"}>
        <div className="relative flex items-center justify-end">
          <button
            onClick={() => {
              setOpen(true);
              setMode("POST");
            }}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Agregar Maquilero
          </button>
        </div>
        {maquileroData ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={maquileroData || []}
            columns={dataColumns || []}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};

export default MaquileroTable;
