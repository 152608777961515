import { client } from "utils/axios"
import * as yup from "yup";

export async function deleteDriverLogs(OperadorId) {
  try {
    await client.delete(`driverLogs/${OperadorId}`);
  } catch (error) {
    console.log(error)
  }
     
  };
export async function getAllDriverLogs(date){
    const {data} = await client.get(`/driverLogs/date/${date}`);
    return data.sort((a,b)=>{return b.id - a.id});
}

export const validationSchemaDriverLogs = yup.object({
    nombre: yup
        .string("Ingresa nombre")
        .required("Campo Requerido"),
    apellidoPaterno: yup
        .string("Ingresa el Apellido Paterno")
        .required("Campo Requerido"),
        apellidoMaterno: yup
        .string("Ingresa el Apellido Materno"),
        direccion: yup
        .string("Ingresa la Dirección")
        .required("Campo Requerido"),
        rfc: yup
        .string("Ingresa el RFC"),
    codigo: yup
        .string("Ingresa el Código")
        .required("Campo Requerido"),
        numeroLicencia: yup
        .string("Ingresa el número de la Licencia")
        .required("Campo Requerido"),
        vigenciaLicencia: yup
        .string("Ingresa la vigencia de la Licencia")
        .required("Campo Requerido"),
        tipoLicencia: yup
        .string("Ingresa el tipo de la Licencia")
        .required("Campo Requerido"),

});
export async function createDriverLogs(dataInput){
  try {
    let persona = {
      nombre:dataInput.nombre,
      apellidoPaterno:dataInput.apellidoPaterno,
      apellidoMaterno: dataInput.apellidoMaterno,
      direccion: dataInput.direccion,
      rfc:dataInput.rfc,
      curp:dataInput.rfc
    }
    const {data} = await  client.post("personas", persona);

    let chofer ={
      persona:{id:data.id},
      codigo:dataInput.codigo,
      numeroLicencia:dataInput.numeroLicencia,
      tipoLicencia:dataInput.tipoLicencia,
      vigenciaLicencia: dataInput.vigenciaLicencia
    }
  await  client.post("driverLogs", chofer);

    return true;

  } catch (error) {
    console.log(error)
    return false;
  }      
 
  };
  export async function modifyDriverLogs(operador) {
    let persona = {
      apellidoPaterno: operador.apellidoPaterno,
      apellidoMaterno: operador.apellidoMaterno,
      nombre: operador.nombre,
      rfc: operador.rfc,
      direccion: operador.direccion,
      id: operador.persona.id
    }
    try {
      await client.put("personas", persona);
     await client.put("driverLogs", operador);
    } catch (error) {

    }
  
  };