import {
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { boxSize } from "utils/apis";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
const OperadoresFormFields = ({ formik, setFieldValue }) => {

  const handleChangeDate = (value, name) => {
    setFieldValue(name, moment(value).format("YYYY-MM-DD"))
  };

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="codigo"
            name="codigo"
            label="Código"
            value={formik.values.codigo}
            onChange={formik.handleChange}
            error={
              formik.touched.codigo && Boolean(formik.errors.codigo)
            }
            helperText={formik.touched.codigo && formik.errors.codigo}
            color="primary"
          />
          <TextField
            fullWidth
            id="curp"
            name="curp"
            label="CURP"
            value={formik.values.curp}
            onChange={formik.handleChange}
            error={
              formik.touched.curp && Boolean(formik.errors.curp)
            }
            helperText={formik.touched.curp && formik.errors.curp}
            color="primary"
          />
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={
              formik.touched.nombre && Boolean(formik.errors.nombre)
            }
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />
          <TextField
            fullWidth
            id="apellidoPaterno"
            name="apellidoPaterno"
            label="Apellido Paterno"
            value={formik.values.apellidoPaterno}
            onChange={formik.handleChange}
            error={
              formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)
            }
            helperText={formik.touched.apellidoPaterno && formik.errors.apellidoPaterno}
            color="primary"
          />
          <TextField
            fullWidth
            id="apellidoMaterno"
            name="apellidoMaterno"
            label="Apellido Materno"
            value={formik.values.apellidoMaterno}
            onChange={formik.handleChange}
            error={
              formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)
            }
            helperText={formik.touched.apellidoMaterno && formik.errors.apellidoMaterno}
            color="primary"
          />
          <TextField
            fullWidth
            id="direccion"
            name="direccion"
            label="Dirección"
            value={formik.values.direccion}
            onChange={formik.handleChange}
            error={
              formik.touched.direccion &&
              Boolean(formik.errors.direccion)
            }
            helperText={
              formik.touched.direccion &&
              formik.errors.direccion
            }
            color="primary"
          />

        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="rfc"
            name="rfc"
            label="RFC"
            value={formik.values.rfc}
            onChange={formik.handleChange}
            error={
              formik.touched.rfc && Boolean(formik.errors.rfc)
            }
            helperText={formik.touched.rfc && formik.errors.rfc}
            color="primary"
          />
          <TextField
            fullWidth
            id="tipoLicencia"
            name="tipoLicencia"
            label="Tipo de Licencia"
            value={formik.values.tipoLicencia}
            onChange={formik.handleChange}
            error={
              formik.touched.tipoLicencia &&
              Boolean(formik.errors.tipoLicencia)
            }
            helperText={
              formik.touched.tipoLicencia &&
              formik.errors.tipoLicencia
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="numeroLicencia"
            name="numeroLicencia"
            label="Número de Licencia"
            value={formik.values.numeroLicencia}
            onChange={formik.handleChange}
            error={
              formik.touched.numeroLicencia &&
              Boolean(formik.errors.numeroLicencia)
            }
            helperText={
              formik.touched.numeroLicencia &&
              formik.errors.numeroLicencia
            }
            color="primary"
          />
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
            <DatePicker
              name={"vigenciaLicencia"}
              label={"Vigencia de la Licencia"}
              value={moment(formik.values.vigenciaLicencia) || moment()}
              closeOnSelect
              sx={{ width: "100%" }}
              slotProps={{ textField: { variant: 'outlined', } }}
              showDaysOutsideCurrentMonth
              disableHighlightToday
              onChange={(value) => handleChangeDate(value, "vigenciaLicencia")}

            />
          </LocalizationProvider>

        </Grid>
      </Grid>
    </Box>

  )
}
export default OperadoresFormFields;