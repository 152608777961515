import {
  Autocomplete,
  Grid,
  Typography,
  TextField,
  Skeleton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const EmbroideryOrderForm = ({
  mode,
  image,
  data,
  setEmbroiderer,
  handleChangeDate,
  setDefects,
  setCutTime,
  handleChangeProduct,
  setGarment,
  defaultDataEmbroidery,
  deliveryDate,
}) => {
  return (
    <Grid container spacing={2} flexDirection={"row"} sx={{ paddingTop: 5 }}>
      <Grid item xs={4}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {image ? (
            <img
              src={image}
              alt="Selcciona un modelo"
              style={{ height: 400, width: 400 }}
            />
          ) : (
            <Skeleton
              animation={false}
              variant="rectangular"
              height={400}
              width={400}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid container flexDirection={"row"} spacing={2}>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Encargado del Bordado:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(cutter) => `${cutter.name}`}
              onChange={(e, o) => setEmbroiderer(o)}
              id="_embroidery"
              options={[{ id: 1, name: "David Perez Serrano" }]}
              maxWidth
              renderInput={(params) => <TextField {...params} label="" />}
              defaultValue={
                mode === "POST"
                  ? { id: 1, name: "David Perez Serrano" }
                  : defaultDataEmbroidery?.embroideryManager
              }
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Modelo:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              isOptionEqualToValue={(option, value) =>
                Number(option.id) === Number(value.id)
              }
              getOptionLabel={(product) =>
                `${product.modelo} (${product.nombre})`
              }
              onChange={handleChangeProduct}
              id="_product"
              options={data.products || []}
              maxWidth
              renderInput={(params) => <TextField {...params} label="" />}
              defaultValue={defaultDataEmbroidery?.product}
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Prenda:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="_garment"
              name="garment"
              label=""
              onChange={(e) => setGarment(e.target.value)}
              defaultValue={mode === "PUT" ? defaultDataEmbroidery.garment : ""}
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Defectos:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              rows={2}
              multiline
              id="defectos"
              name="defectos"
              label=""
              onChange={(e) => setDefects(e.target.value)}
              defaultValue={mode === "PUT" ? defaultDataEmbroidery.defects : ""}
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">
              {"Tiempo aproximado de Bordado:"}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="time"
              name="time"
              label=""
              type="number"
              onChange={(e) => setCutTime(Number(e.target.value))}
              defaultValue={
                mode === "PUT" ? defaultDataEmbroidery.embroideryTime : ""
              }
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Fecha de entrega:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="es-mx"
            >
              <DatePicker
                name={"fechaEntrega"}
                label={""}
                defaultValue={moment(deliveryDate)}
                closeOnSelect
                sx={{ width: "100%" }}
                slotProps={{ textField: { variant: "outlined" } }}
                showDaysOutsideCurrentMonth
                disableHighlightToday
                onChange={handleChangeDate}
                disabled
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EmbroideryOrderForm;
