import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setMachinaryId, setDefaultData } from "store/modules/Machinary";
import { v4 as uuidv4 } from "uuid";
import { PrintSharp } from "@mui/icons-material";

export const columns = (setOpen, setMode, setOpenDelete, dispatch) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 100,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar Maquinaria" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultData(data.row));
                dispatch(setMachinaryId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir Etiqueta" placement="top">
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                // dispatch(setDefaultDataDetail({ detalleSalida: [data.row] }));
                // handleOpenPDF();
              }}
            >
              <PrintSharp />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Eliminar Maquinaria" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setMachinaryId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip> */}
        </React.Fragment>
      ),
    },
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
    },
    {
      field: "description",
      headerName: "Descripción",
      width: 300,
    },
    {
      field: "code",
      headerName: "Código",
      width: 100,
    },
    {
      field: "responsible",
      headerName: "Responsable",
      width: 170,
    },
    // {
    //   field: "details.presentacion",
    //   headerName: "Presentacion",
    //   width: 200,
    //   renderCell: (data) => <>{data.row.details?.presentacion}</>,
    // },
    {
      field: "category.name",
      headerName: "Categoria",
      width: 100,
      renderCell: (data) => <>{data.row.category?.name}</>,
    },
  ];
};
