import { Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { TotalTable } from "./TotalTable";

const TallaCantidad = ({ tallasByProduct, setTallasByProduct, setFieldValue, prendas, mode }) => {

    const [value, setValue] = useState(null);
    const [newValue, setNewValue] = useState("");
    const [item, setItem] = useState(null);
    const [prenda, setPrenda] = useState(null);
    const [tallaId, setTallaId] = useState(null);

    useEffect(() => {
        if (value === 0) {
            setValue(null)
        }
    }, [value])

    useEffect(() => {
        if (newValue) {
            try {

                let _tallasByProduct = tallasByProduct;
                let newItem = Object.assign({}, item);
                newItem.cantidadAsignada = 0;
                _tallasByProduct = _tallasByProduct.filter(t => t.talla.id !== item.talla.id)

                if (!newItem.prendas) {
                    newItem.prendas = [];
                    let _prenda = Object.assign({}, prenda);
                    _prenda.cantidadAsignada = Number(newValue);
                    _prenda.tallaId = tallaId;
                    newItem.prendas.push(_prenda)
                }
                else {
                    let _prenda = Object.assign({}, prenda);
                    let objectIndex = newItem.prendas.findIndex(i => i.id === _prenda.id)
                    if (objectIndex >= 0) {
                        newItem.prendas[objectIndex].cantidadAsignada = Number(newValue)
                    } else {
                        _prenda.cantidadAsignada = Number(newValue);
                        _prenda.tallaId = tallaId;
                        newItem.prendas.push(_prenda)
                    }
                }
                newItem.prendas.forEach(i => {
                    newItem.cantidadAsignada += Number(i.cantidadAsignada)
                })
                _tallasByProduct = [..._tallasByProduct, newItem]
                _tallasByProduct = _tallasByProduct.sort((a, b) => { return a.talla.id - b.talla.id });
                setFieldValue("details", { data: _tallasByProduct, prendas: prendas.map(p => { return { id: p.id, productoId: p.productoId, nombre: p.nombre } }) })
                setTallasByProduct(_tallasByProduct)

            } catch ({ message }) {
                console.log(message)
            }
            return () => {
                setItem(null);
                setPrenda(null);
                setTallaId(null);
            }
        }
    }, [newValue]);

    const changeCantidad = (e, item, p, tallaId) => {
        setItem(item);
        setPrenda(p);
        setTallaId(tallaId)
        setNewValue(e.target.value)

    }
    const getQuantity = (item, p) => {
        const obj = item?.prendas?.find(i => i.id === p.id);
        if (obj)
            return Number(obj.cantidadAsignada || 0)
        else
         return 0
    }

    return (
        <>
            <Grid container spacing={2} flexDirection={"row"}>
                <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                            {"Disponibilidad"}
                        </h4>
                    </div>
                    {!tallasByProduct && (
                        <div style={{ textAlign: "center" }}>
                            <h2 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                                {"Seleciona un modelo"}
                            </h2>
                        </div>
                    )}
                </Grid>
                {tallasByProduct && tallasByProduct.length > 0 && tallasByProduct.map((item, index) => (
                    <Grid item xs={12} key={index} >
                        <Grid container spacing={2} flexDirection={"row"}
                            style={{ margin: 2 }}
                        >
                            <Grid item xs={2}
                                className=" items-start justify-center rounded-2xl bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <p className="text-sm text-gray-600">Tallas:</p>
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    {item.talla.nombre}
                                </p>
                            </Grid>
                            <Grid item xs={5} className=" justify-center rounded-2xl bg-white bg-clip-border  shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <TotalTable item={item} />
                            </Grid>
                            <Grid item xs={5} className="justify-center rounded-2xl bg-white bg-clip-border  shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <Grid container spacing={2} flexDirection={"row"}>
                                    {mode === "PUT" ? (
                                        <>
                                            {
                                                prendas && prendas.length > 0 && prendas.map((p, i) => (
                                                    <Grid key={`${i}${item.talla.id}`} item xs={3} >
                                                        <TextField
                                                            type="number"
                                                            label={<Typography variant="caption">{p.nombre}</Typography>}
                                                            onChange={(e) => changeCantidad(e, item, p, item.talla.id)}
                                                            defaultValue={getQuantity(item, p)}
                                                            sx={{ width: 120 }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </>) : (
                                        <>
                                            {
                                                prendas && prendas.length > 0 && prendas.map((p, i) => (
                                                    <Grid key={`${i}${item.talla.id}`} item xs={3} >
                                                        <TextField
                                                            label={<Typography variant="caption">{p.nombre}</Typography>}
                                                            type="number"
                                                            onChange={(e) => changeCantidad(e, item, p, item.talla.id)}
                                                            defaultValue={p.cantidadAsignada || 0}
                                                            sx={{ width: 120 }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </>
                                    )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
export default TallaCantidad;
