import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import SpecificationSheetTable from "./SpecificationSheetTable";
import { useSelector } from "react-redux";
import { background_marifer } from "utils/apis";

const SpecificationSheetMainModal = ({ open, handleClose }) => {
    const styles = {
        '& .MuiDialog-paper': {
            boxSizing: 'border-box',
            backgroundImage: `url(${background_marifer})`,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            '&:before': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                content: '""',
                display: 'block',
                background: '#fff',
                opacity: '0.6',
                zIndex: -999
            }
        }
    }
    const { defaultData } = useSelector(({ producto }) => producto)
    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            sx={styles}
        >
            <DialogContent>
                <SpecificationSheetTable producto={defaultData} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose} color={"secondary"} variant="contained"
                >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default SpecificationSheetMainModal;