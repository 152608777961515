import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import OrderCutTable from "./OrderCutTable";
import { useSelector } from "react-redux";

const OrderCutMainModal = ({ open, handleClose }) => {
  const { defaultDataPedido } = useSelector(({ pedido }) => pedido);
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogContent>
        <OrderCutTable defaultDataPedido={defaultDataPedido} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OrderCutMainModal;
