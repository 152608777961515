import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Box, CircularProgress, Typography } from "@mui/material";
import { boxSize } from "utils/apis";
import PrendaModal from "./PrendaModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllByProductId } from "../functions";
import PrendaDelete from "./PrendaDelete";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../columns";
import ClothConsumption from "./cloth/ClothConsumption";
import ClothListModal from "./cloth/ClothConsumptionListModal";
const PrendasTable = () => {

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [mode, setMode] = useState("POST")
  const [prendas, setPrendas] = useState(null);
  const { productIdSelected } = useSelector(({ producto }) => producto);
  const [_columns, setColumns] = useState(null);
  const dispatch = useDispatch();
  const [openModalConsumption, setOpenModalConsumption] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      let prendas = await getAllByProductId(productIdSelected);
      setPrendas(prendas);
      let col = columns(setOpen, setMode, setOpenDelete, dispatch, setRowId, setOpenModalConsumption);
      setColumns(col);

    }
    fetchData();
  }, [open, openDelete, productIdSelected]);
  const handleCloseConsumption = () => {
    setOpenModalConsumption(false);
  }

  const handleClose = () => {
    setOpen(false)
  }
  const setModeCreate = (event) => {
    event.preventDefault();
    setMode("POST");
    setOpen(true);

  }

  return (
    <Box sx={{ height: boxSize, justifyContent: "center" }}>
      <ClothListModal open={openModalConsumption} handleClose={handleCloseConsumption} />
      <PrendaDelete openDelete={openDelete} setOpenDelete={setOpenDelete} id={rowId} />
      <PrendaModal open={open} handleClose={handleClose} mode={mode} />
      <Card
        extra={"w-full h-full p-5"}
      >
        <div className="flex items-center justify-between rounded-t-3xl p-3">
          <div className="text-lg font-bold text-navy-700 dark:text-white">
            Prendas del Producto
          </div>
          <button
            onClick={setModeCreate}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            Agregar nueva prenda
          </button>
        </div>
        {prendas ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={prendas || []}
            columns={_columns || []}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            getRowHeight={() => 'auto'}

          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}

      </Card>

    </Box>

  );
};

export default PrendasTable;
