import {  Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ClothConsumption from "./ClothConsumption";

const ClothConsumptionListModal = ({ open, handleClose, mode }) => {

    const { defaultDataCC, defaultData } = useSelector(({ prenda }) => prenda);

    return (
    <>
            <Dialog
            open={open}
            onClose={handleClose}
            style={{ padding: 10 }}
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}>
                <IconButton onClick={handleClose} sx={{ color: "purple" }}>
                    <Cancel />
                </IconButton>
            </DialogTitle>
            <DialogTitle>
            </DialogTitle>
            <DialogContent>
               <ClothConsumption defaultData={defaultDataCC} handleClose={handleClose} mode={mode} productoId={defaultData.productoId} />
            </DialogContent>
        </Dialog>

    </>


    )
}
export default ClothConsumptionListModal;