
import { createTheme } from '@mui/material/styles';

const primaryLightColor = '#ff147a'; // Hot Pink
const secondaryLightColor = '#BA55D3'; // Medium Orchid
const backgroundLightColor = '#FFF0F5'; // Lavender Blush
const paperLightColor = '#FFF0F5'; // White
const errorLightColor = '#FF6F61'; // Soft Red
const warningLightColor = '#FFD700'; // Gold
const infoLightColor = '#87CEFA'; // Light Sky Blue
const successLightColor = '#98FB98'; // Pale Green
const primaryDarkColor = '#ff69b4'; // Hot Pink
const secondaryDarkColor = '#ba55d3'; // Medium Orchid
const backgroundDarkColor = '#111c44'; // Dark Gray
const paperDarkColor = '#111c44'; // Slightly Lighter Dark Gray
const errorDarkColor = '#ff6f61'; // Soft Red
const warningDarkColor = '#ffc107'; // Amber
const infoDarkColor = '#00bcd4'; // Cyan
const successDarkColor = '#66bb6a'; // Light Green

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryLightColor,
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryLightColor,
      contrastText: '#fff',
    },
    background: {
      default: backgroundLightColor,
      paper: paperLightColor,
    },
    text: {
      primary: '#000',
      secondary: '#555',
    },
    error: {
      main: errorLightColor,
    },
    warning: {
      main: warningLightColor,
    },
    info: {
      main: infoLightColor,
    },
    success: {
      main: successLightColor,
    },
  },
  typography: {
    fontFamily: 'Comic Sans MS, cursive, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: primaryLightColor,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: secondaryLightColor,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: primaryLightColor,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#333',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 300,
      color: '#555',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          textTransform: 'none',
          boxShadow: '0 3px 5px 2px rgba(255, 105, 180, .3)',
        },
        containedPrimary: {
          backgroundColor: primaryLightColor,
          color: '#fff',
          '&:hover': {
            backgroundColor: '#ff1493',
          },
        },
        containedSecondary: {
          backgroundColor: secondaryLightColor,
          color: '#fff',
          '&:hover': {
            backgroundColor: '#9932cc',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          backgroundColor: paperLightColor,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: primaryLightColor,
          color: '#fff',
        },
      },
    },
  },
});


export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: primaryDarkColor,
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryDarkColor,
      contrastText: '#fff',
    },
    background: {
      default: backgroundDarkColor,
      paper: paperDarkColor,
    },
    text: {
      primary: '#fff',
      secondary: '#bbb',
    },
    error: {
      main: errorDarkColor,
    },
    warning: {
      main: warningDarkColor,
    },
    info: {
      main: infoDarkColor,
    },
    success: {
      main: successDarkColor,
    },
  },
  typography: {
    fontFamily: 'Comic Sans MS, cursive, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: primaryDarkColor,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: secondaryDarkColor,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: primaryDarkColor,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#eee',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 300,
      color: '#ccc',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          textTransform: 'none',
          boxShadow: '0 3px 5px 2px rgba(255, 105, 180, .3)',
        },
        containedPrimary: {
          backgroundColor: primaryDarkColor,
          color: '#fff',
          '&:hover': {
            backgroundColor: '#ff1493',
          },
        },
        containedSecondary: {
          backgroundColor: secondaryDarkColor,
          color: '#fff',
          '&:hover': {
            backgroundColor: '#9932cc',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          backgroundColor: paperDarkColor,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#333',
          color: '#fff',
        },
      },
    },
  },
});