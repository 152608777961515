import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import image from "../../../../assets/img/marifer.png";
import moment from "moment";
import { getPickupsData, getProductData } from "./functions";
import { totalSumByPrenda } from "components-base/salidas/functions";

// Create styles
const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 15,
    height: 15,
    marginRight: 4,
    border: "1 solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  checked: {
    backgroundColor: "pink",
  },
  cross: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
  },
  image2: {
    position: "absolute",
    objectPosition: "0",
    zIndex: -999,
    width: "320px",
    height: "320px",
    opacity: 0.2,
  },
  image: {
    position: "relative",
    objectFit: "cover",
    zIndex: -999,
    width: "120px",
    height: "120px",
    paddingLeft: 0,
    opacity: 0.9,
  },
  header: {
    color: "#ff0080",
    textTransform: "uppercase",
    marginTop: 25,
    paddingLeft: 40,
    fontSize: "18pt",
    fontWeight: "extrabold",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
    height: "7.975cm",
  },
  section1: {
    padding: 8,
    textAlign: "center",
    width: "7cm",
    height: "100%",
    backgroundColor: "white",
    border: 1,
    borderColor: "#0A83F5",
    lineHeight: 1.5,
    fontSize: "10pt",
  },
  section2: {
    padding: 8,
    lineHeight: 4,
    border: 1,
    borderColor: "#0A83F5",
    width: "14.6cm",
    height: "100%",
    backgroundColor: "white",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
// Create styles
const HeaderColumns = () => (
  <View
    style={{
      flexDirection: "row",
      textAlign: "center",
      fontSize: "9pt",
      color: "#111132",
    }}
  >
    <View style={{ width: "5%", border: 1 }}>
      <Text>{"Folio"}</Text>
    </View>
    <View style={{ width: "10%", border: 1 }}>
      <Text>{"Fecha Salida"}</Text>
    </View>
    <View style={{ width: "25%", border: 1 }}>
      <Text>{"Chofer"}</Text>
    </View>
    <View style={{ width: "25%", border: 1 }}>
      <Text>{"Maquilero"}</Text>
    </View>
    <View style={{ width: "35%", border: 1 }}>
      <Text>{"Comentarios"}</Text>
    </View>

  </View>
)

const ResumePDF = ({ data }) => {
  if (data.length === 0) return "Sin datos";
  const product = getProductData(data);
  const [pickups, setPickups] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const id = data[0].pedido.id;
      let _data = await getPickupsData(id);
      setPickups(_data);
    }
    getData();
  }, [])
  if (!pickups) return "Obteniendo la informacion....."
  return (
    <PDFViewer style={styles.viewer}>
      {
        <Document>
          <Page size="LETTER" style={styles.page}>
            <View style={{
              flexDirection: "column",
            }}>
              <View style={{ textAlign: "center", width: "100%" }}>
                <Text style={styles.header}>
                  {`Resumen Pedido`}
                </Text>
              </View>
              <View style={{ width: "100%", fontSize: 7, flexDirection: "row", textAlign: "center", wordWrap: "word-break", paddingLeft: 100, paddingBottom: 20 }} >
                <View>
                  <Text style={{ border: 1, padding: 1, width: 75, textAlign: "center", }}
                  >
                    {"Codigo"}
                  </Text>
                  <Text
                    style={{ fontSize: 7, border: 1, padding: 1, height: 25, width: 75, textAlign: "center", }}
                  > {data[0].pedido.codigo}</Text>
                </View>
                <View>
                  <View>
                    <Text
                      style={{
                        fontSize: 7,
                        border: 1,
                        padding: 1,
                        width: 120,
                        textAlign: "center",
                      }}
                    >
                      {"Modelo"}
                    </Text>
                  </View>

                  <Text
                    style={{
                      fontSize: 7,
                      border: 1,
                      width: 120,
                      padding: 1,
                      height: 25,
                      textAlign: "center",
                    }}
                  >
                    {product.modelo}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 7,
                      wordWrap: "break-word",
                      border: 1,
                      padding: 1,
                      width: 60,
                      textAlign: "center",
                    }}
                  >
                    {"Total"}
                  </Text>

                  <Text
                    style={{
                      width: 60,
                      textAlign: "center",
                      wordWrap: "word-break",
                      border: 1,
                      fontSize: 7,
                      paddingTop: 2,
                      height: 25,
                    }}
                  >
                    {product.totalAmount}
                  </Text>
                </View>
                <View style={{ textAlign: "left", width: 150, color: "#111" }}>
                  <Text
                    style={{ border: 1, fontSize: 7, padding: 1, height: 19 }}
                  >
                    {`Fecha de Creacion: ${moment(data[0].pedido.fechaCreacion.split(" ")[0]).format("DD-MM-YYYY")}`}
                  </Text>

                  <Text
                    style={{ border: 1, fontSize: 7, padding: 1, height: 19 }}
                  >
                    {`Fecha de Entrega: ${moment(data[0].pedido.fechaEntrega.split(" ")[0]).format("DD-MM-YYYY")}`}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                padding: 1,
                backgroundColor: "pink",
              }}
            >
              <View style={{ width: "100%", marginRight: "10px" }}>
                <Text style={{ fontSize: 10 }}>
                  {"Salidas"}
                </Text>
              </View>

            </View>
            <View style={{ height: "auto", border: 1 }}>
              <View style={{ alignItems: "center" }}>
                <Image src={image} style={styles.image2} />
              </View>

              {data.map((s, i) => (<>
                <HeaderColumns />
                <View
                  key={i}
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    fontSize: "7pt",
                    wordWrap: "word-break",
                    height: "auto"
                  }}
                >
                  <View style={{ width: "5%", border: 1 }}>
                    <Text>{s.folio}</Text>
                  </View>
                  <View style={{ width: "10%", border: 1 }}>
                    <Text>{moment(s.fechaSalida.split(" ")[0]).format("DD-MM-YYYY")}</Text>
                  </View>
                  <View style={{ width: "25%", border: 1 }}>
                    <Text>{`${s.chofer.persona.nombre} ${s.chofer.persona.apellidoPaterno} ${s.chofer.persona.apellidoMaterno}`}</Text>
                  </View>
                  <View style={{ width: "25%", border: 1 }}>
                    <Text>{`${s.maquilero.persona.nombre} ${s.maquilero.persona.apellidoPaterno} ${s.maquilero.persona.apellidoMaterno}`}</Text>
                  </View>
                  <View style={{ width: "35%", border: 1, fontSize: 7 }}>
                    <Text>{s.comments}</Text>
                  </View>

                </View>
                <View
                  style={{
                    flexDirection: "column",
                    backgroundColor: "pink",
                    marginBottom:5
                  }}
                >
                  <View style={{ width: "100%", marginRight: "5px", textAlign: "center", }}>
                    <Text style={{ fontSize: 7 }}>
                      {"Detalle de la entrega"}
                    </Text>
                  </View>
                  <View style={{ width: "100%", marginRight: "5px", marginTop: "5px" }}>
                    {s.details.prendas.map((p, i) => (
                      <View
                        key={i}
                        style={{
                          height:
                            s.details.data.filter(
                              (f) =>
                                f.producto.id === p.productoId && f.cantidadAsignada
                            ).length * 12,
                          flexDirection: "row",
                          textAlign: "center",
                          fontSize: 7,
                          wordWrap: "word-break",
                        }}
                      >
                        <View style={{ width: "20%" }}>
                          <Text style={{ fontSize: 7 }}>{`${p.nombre}`}</Text>
                        </View>

                        <View style={{ width: "30%", fontSize: 7 }}>
                          {s.details.data
                            .filter(
                              (f) =>
                                f.producto.id === p.productoId && f.cantidadAsignada
                            )
                            .filter((f) => f.cantidadAsignada > 0)
                            .map((m) => (
                              <Text
                                key={m.talla.nombre}
                                style={{ fontWeight: "heavy" }}
                              >
                                {m.talla.nombre}
                              </Text>
                            ))}
                        </View>
                        <View style={{ width: "15%", fontSize: 7 }}>
                          {s.details.data
                            .filter(
                              (f) =>
                                f.producto.id === p.productoId && f.cantidadAsignada
                            )
                            .filter((f) => f.cantidadAsignada > 0)
                            .map((m) => (
                              <Text>{totalSumByPrenda(m, p.id)}</Text>
                            ))}
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              </>
              ))}
            </View>
            <View
              style={{
                flexDirection: "row",
                padding: 1,
                backgroundColor: "pink",
                marginTop: 5
              }}
            >
              <View style={{ width: "100%", marginRight: "10px" }}>
                <Text style={{ fontSize: 10 }}>
                  {"Recolecciones"}
                </Text>
              </View>
            </View>

            <View style={{ height: "auto", border: 1, marginTop: 10 }}>
              <View style={{ alignItems: "center" }}>
                <Image src={image} style={styles.image2} />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: 7,
                  color: "#000111",
                  height: 20
                }}
              >
                <View style={{ width: "7%", border: 1 }}>
                  <Text>{"Folio"}</Text>
                </View>
                <View style={{ width: "8%", border: 1 }}>
                  <Text>{"Fecha de recoleccion"}</Text>
                </View>
                <View style={{ width: "7%", border: 1 }}>
                  <Text>{"Cantidad"}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{"Prendas"}</Text>
                </View>
                <View style={{ width: "18%", border: 1 }}>
                  <Text>{"Talla"}</Text>
                </View>
                <View style={{ width: "13%", border: 1 }}>
                  <Text>{"Chofer"}</Text>
                </View>
                <View style={{ width: "13%", border: 1 }}>
                  <Text>{"Maquilero"}</Text>
                </View>
                <View style={{ width: "27%", border: 1 }}>
                  <Text>{"Observaciones"}</Text>
                </View>
              </View>

              {pickups.map((i, index) => (
               i.details && (
                <View
                  key={index} style={{ height: 38, flexDirection: "row", textAlign: "center", fontSize: 7 }}
                >
                  <View style={{ width: "7%", border: 1 }}>
                    {i.salida ? (<Text>{i.salida.folio}</Text>): (<Text>{i.folio}</Text>)}

                  </View>
                  <View style={{ width: "8%", border: 1 }}>
                    <Text>{moment(i.fechaEntrada.replace("T", " ")).format("YYYY-MM-DD HH:MM")}</Text>
                  </View>
                  <View style={{ width: "7%", border: 1, fontSize: 9 }} >
                    <Text>{i.cantidad}</Text>
                  </View>
                  <View style={{ width: "20%", border: 1 }}>
                    {i.prenda ? <Text>{i.prenda} </Text> : i.details.prendas.map((p, index) => (
                      <View key={index}>
                        <Text>{`${index + 1}.- ${p.nombre}`} </Text>
                      </View>
                    ))}
                  </View>
                  <View style={{ width: "18%", border: 1 }}>
                    {i.talla ? <Text>{i.talla}</Text> : i.details.prendas.map((p, index) => (
                      <View key={index}>
                        {i.salida ?
                        <Text>{`${index + 1}.-${i.salida.details.data.find(item => item.talla.id === p.tallaId).talla.nombre}`} </Text>
                        : <Text>{"Sin Datos"} </Text>
                      }
                      </View>
                    ))}
                  </View>
                  <View style={{ width: "13%", border: 1 }}>
                    {i.driver && (
                      <Text>{`${i.driver.persona.nombre} ${i.driver.persona.apellidoPaterno} ${i.driver.persona.apellidoMaterno}`}</Text>
                    )
                    }
                  </View>
                  <View style={{ width: "13%", border: 1 }}>
                    {i.maquilero && (
                      <Text>{`${i.maquilero.persona.nombre} ${i.maquilero.persona.apellidoPaterno} ${i.maquilero.persona.apellidoMaterno}`}</Text>
                    )
                    }
                  </View>
                  <View style={{ width: "27%", border: 1 }}>
                    {<Text>{i.comments}</Text>}
                  </View>
                </View>)
              ))}

            </View>

          </Page>
        </Document>
      }
    </PDFViewer>
  );
};
export default ResumePDF;
