export const TotalTable = ({ item }) => {
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 rounded-s-lg">
              Item
            </th>
            <th scope="col" className="px-6 py-3 rounded-s-lg">
              Cantidad
            </th>
            <th scope="col" className="px-6 py-3">
              + 2%
            </th>
            <th scope="col" className="px-6 py-3 rounded-e-lg">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white dark:bg-gray-800">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              Productos
            </th>
            <td className="px-6 py-4">{item.cantidad}</td>
            <td className="px-6 py-4">{Math.ceil(item.cantidad * 0.02)}</td>
            <td className="px-6 py-4">{Math.ceil(item.cantidad * 1.02)}</td>
          </tr>
          <tr className="bg-white dark:bg-gray-800">
            <th
              scope="row"
              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              Prendas
            </th>
            <td className="px-6 py-4">
              {item.cantidad * item.producto.prenda.length}
            </td>
            <td className="px-6 py-4">
              {Math.ceil(item.cantidad * item.producto.prenda.length * 0.02)}
            </td>
            <td className="px-6 py-4">
              {Math.ceil(item.cantidad * item.producto.prenda.length * 1.02)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
