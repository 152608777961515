import SalidaTable from "./components/SalidaTable";
import { useEffect, useState } from "react";
import { convertMonth, getAllSalidas } from "./functions";
import { months } from "utils/apis";

import TabComponent from "components-base/tab/Tab";
import { useApplicationContext } from "components-base/application-context/AppContext";
const Salidas = () => {
  const { globalFilter, setGlobalFilter } = useApplicationContext();
  const [salidas, setSalidas] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    if (globalFilter) {
      setSearchValue( globalFilter)
    }
    return () => {setSearchValue(null);setGlobalFilter(null)}
  }, [globalFilter])
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }
  const clearSearch = () => {
    setSearchValue(null);
  }
  useEffect(() => {
    const fetchData = async () => {
      let salidas = await getAllSalidas();
      let _salidas = convertMonth(salidas);
      setSalidas(_salidas);
    };
    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (salidas) {
      let tabsArray = months.map((month) => {
        return {
          label: month,
          component: (
            <SalidaTable
              clearSearch={clearSearch}
              handleSearch={handleSearch}
              searchValue={searchValue}
              setRefresh={setRefresh}
              refresh={refresh}
              key={month}
              data={searchValue ? salidas.filter((item) => item.pedido.codigo === searchValue) : salidas.filter((item) => item.monthName === month)}
            />
          ),
        };
      });
      setTabs(tabsArray);
    }
  }, [salidas, searchValue]);

  return (
    <div>
      <TabComponent tabsElement={tabs} />
    </div>
  );
};
export default Salidas;
