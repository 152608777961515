import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import LabelPdf from "./label-pdf";
import { qrcode } from "pure-svg-code";
import { useState, useEffect } from "react";
import { convertSvgToPngBase64 } from "./functions";

const PrintLabelModal = ({ open, handleClose, data }) => {
  let dataArray = data ? data : [];
  const [data64, setBase64] = useState(null);
  useEffect(() => {
    const createQR = async () => {
      for (let i = 0; i < dataArray.length; i++) {
        const item = dataArray[i]
        const svgString = qrcode({
          content: `${item.id}`,
          padding: 4,
          width: 256,
          height: 256,
          color: "#000000",
          background: "#ffffff",
          ecl: "M",
        });
        const pngData = await convertSvgToPngBase64(svgString, 200, 200);
        let pngDataUrl = pngData.replace("'", "");
        dataArray[i] = { ...dataArray[i], dataURL: pngDataUrl };
      }
      setBase64(dataArray);
    };
    createQR();
  }, []);

  if (!data64) return null;
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent>
        <LabelPdf data={data64} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PrintLabelModal;
