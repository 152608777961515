import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct } from "../functions";
const ProductDelete = ({openDelete,setOpenDelete}) => {
    const dispatch = useDispatch();
    const { productIdSelected } = useSelector(({ producto }) => producto);
    const deleteFnc = async () =>{
    await deleteProduct(productIdSelected)(dispatch);
    setOpenDelete(false);
}
    return (
        <>
            <Dialog
            open={openDelete}
            onClose={()=>setOpenDelete(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación del Producto"}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={()=>setOpenDelete(false)} color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ProductDelete;