import {
  Grid,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { boxSize } from "utils/apis";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { getAllOperadores } from "components-base/operadores/functions";
import { useSelector } from "react-redux";
import { getAllChoferUnidadById } from "../functions";
const UnidadesFormFields = ({ formik, setFieldValue }) => {
  const{defaultDataUnidad} = useSelector(({unidad})=>unidad);
  const [operadores, setOperadores] = useState(null)
  const [defaultOperadores, setDefaultOperadores] = useState(null)
  const [years, setYears] = useState(null);

useEffect(()=>{
 const fetchData = async () => {
  const operadores = await getAllOperadores();
  setOperadores(operadores)
 }
 fetchData();
},[])

useEffect(() => {
  if (operadores) {
      const fetchData = async () => {
          let unidadChofer = await getAllChoferUnidadById(defaultDataUnidad.id);
          let operadores = await getAllOperadores();
          let obj=[]
         for(let i = 0; i < unidadChofer.length ; i ++) {
              let chofer = operadores.filter(item => item.id === unidadChofer[i].chofer.id);
              obj = [...obj,chofer[0]]
          }
          setDefaultOperadores(obj);
    
      }
      fetchData();
  }
}, [defaultDataUnidad.id,operadores])

  useEffect(() => {
    setYears([])
    let years = []
    for (let i = 2024; i > 1989; i--) {
      years.push(String(i))
    }
    setYears(years)
  }, [])

  if (!defaultOperadores || !operadores)return(<>Cargando....</>)

  const handleChangeDate = (value, name) => {
    setFieldValue(name, moment(value).format("YYYY-MM-DD"))
  };
  const handleChangePlacas = (e, val) => {
    let engomado = val
    setFieldValue("engomado", engomado)
    switch (engomado) {
      case "Azul":
        setFieldValue("bimestre1", "Mayo-Junio");
        setFieldValue("bimestre2", "Noviembre-Diciembre");

        break;
      case "Rojo":
        setFieldValue("bimestre1", "Marzo-Abril");
        setFieldValue("bimestre2", "Septiembre-Octubre");

        break;
      case "Amarillo":
        setFieldValue("bimestre1", "Enero-Febrero");
        setFieldValue("bimestre2", "Julio-Agosto");

        break;
      case "Verde":
        setFieldValue("bimestre1", "Abril-Mayo");
        setFieldValue("bimestre2", "Octubre-Noviembre");

        break;
      case "Rosa":
        setFieldValue("bimestre1", "Febrero-Marzo");
        setFieldValue("bimestre2", "Agosto-Septiembre");

        break;

      default:
        setFieldValue("bimestre1", "Invalido");
        setFieldValue("bimestre2", "Invalido");
        break;
    }

  }

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="descripcion"
            name="descripcion"
            label="Descripción"
            multiline
            rows={3}
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={
              formik.touched.descripcion &&
              Boolean(formik.errors.descripcion)
            }
            helperText={
              formik.touched.descripcion &&
              formik.errors.descripcion
            }
            color="primary"
          />
          <Autocomplete
            name="ano"
            options={years || []}
            id="ano"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Año" />}
            getOptionLabel={(year) => year}
            onChange={(e,value) => setFieldValue("ano",value)}
            defaultValue={defaultDataUnidad.ano}
          />
          <TextField
            fullWidth
            id="modelo"
            name="modelo"
            label="Modelo"
            value={formik.values.modelo}
            onChange={formik.handleChange}
            error={
              formik.touched.modelo &&
              Boolean(formik.errors.modelo)
            }
            helperText={
              formik.touched.modelo &&
              formik.errors.modelo
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="marca"
            name="marca"
            label="Marca"
            value={formik.values.marca}
            onChange={formik.handleChange}
            error={
              formik.touched.marca &&
              Boolean(formik.errors.marca)
            }
            helperText={
              formik.touched.marca &&
              formik.errors.marca
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="vin"
            name="vin"
            label="VIN"
            value={formik.values.vin}
            onChange={formik.handleChange}
            error={
              formik.touched.vin &&
              Boolean(formik.errors.vin)
            }
            helperText={
              formik.touched.vin &&
              formik.errors.vin
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="tenencia"
            name="tenencia"
            label="Tenencia"
            value={formik.values.tenencia}
            onChange={formik.handleChange}
            error={
              formik.touched.tenencia &&
              Boolean(formik.errors.tenencia)
            }
            helperText={
              formik.touched.tenencia &&
              formik.errors.tenencia
            }
            color="primary"
          />
          
          <TextField
            fullWidth
            id="valor"
            name="valor"
            label="Valor"
            value={formik.values.valor}
            onChange={formik.handleChange}
            error={
              formik.touched.valor &&
              Boolean(formik.errors.valor)
            }
            helperText={
              formik.touched.valor &&
              formik.errors.valor
            }
            color="primary"
          />


        </Grid>
        <Grid item xs={6}>

          <TextField
            fullWidth
            id="placa"
            name="placa"
            label="Placas"
            value={formik.values.placa}
            onChange={formik.handleChange}
            inputProps={{ maxLength: 10 }}
            error={
              formik.touched.placa &&
              Boolean(formik.errors.placa)
            }
            helperText={
              formik.touched.placa &&
              formik.errors.placa
            }
            color="primary"
          />
          <Autocomplete
            name="engomado"
            options={["Rojo", "Azul", "Amarillo", "Verde", "Rosa"]}
            id="engomado"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Engomado" />}
            getOptionLabel={(engomado) => engomado}
            onChange={handleChangePlacas}
            defaultValue={defaultDataUnidad.engomado}
          />

          <Typography variant={"title"}>
            {"Período de Verificación"}
          </Typography>

          <TextField
            fullWidth
            id="bimestre1"
            name="bimestre1"
            label="Bimestre uno"
            value={formik.values.bimestre1}
            onChange={formik.handleChange}
            error={
              formik.touched.bimestre1 &&
              Boolean(formik.errors.bimestre1)
            }
            helperText={
              formik.touched.bimestre1 &&
              formik.errors.bimestre1
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="bimestre2"
            name="bimestre2"
            label="Bimestre dos"
            value={formik.values.bimestre2}
            onChange={formik.handleChange}
            error={
              formik.touched.bimestre2 &&
              Boolean(formik.errors.bimestre2)
            }
            helperText={
              formik.touched.bimestre2 &&
              formik.errors.bimestre2
            }
            color="primary"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
                <DatePicker
                  name={"fechaAlta"}
                  label={"Fecha Alta"}
                  value={moment(formik.values.fechaAlta) || moment()}
                  closeOnSelect
                  sx={{ width: "100%" }}
                  slotProps={{ textField: { variant: 'outlined', } }}
                  showDaysOutsideCurrentMonth
                  disableHighlightToday
                  onChange={(value) => handleChangeDate(value, "fechaAlta")}

                />
              </LocalizationProvider>

            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
                <DatePicker
                  name={"fechaBaja"}
                  label={"Fecha Baja"}
                  value={moment(formik.values.fechaBaja) || moment()}
                  closeOnSelect
                  sx={{ width: "100%" }}
                  slotProps={{ textField: { variant: 'outlined', } }}
                  showDaysOutsideCurrentMonth
                  disableHighlightToday
                  onChange={(value) => handleChangeDate(value, "fechaBaja")}

                />
              </LocalizationProvider>

            </Grid>

          </Grid>
          <Autocomplete
              name={"operadores"}
              multiple
              limitTags={5}
              getOptionSelected={(option, value) => option.persona.id === value.persona.id}
              options={operadores || []}
              onChange={(e, options) => { e.preventDefault(); setFieldValue("operadores", options) }}
              getOptionLabel={(option) => `${option.persona.nombre} ${option.persona.apellidoPaterno} ${option.persona.apellidoMaterno}`}
              onInputChange={(e, value) => { console.log("hello") }}
              renderInput={(params) => (<TextField {...params} label={"Operadores"}
                helperText={"Seleccione los Operadores para esta Unidad"}
              />
              )}
              defaultValue ={defaultOperadores}
              />

        </Grid>
      </Grid>
    </Box>

  )
}
export default UnidadesFormFields;