
import Card from "components/card";
import { DataGrid } from "@mui/x-data-grid";
import RutaDrawer from "./RutaDrawer";
import React, { useState, useEffect } from "react";
import { getAllRutas } from "../functions";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import RutaDelete from "./RutaDelete";
import { setDefaultData } from "store/modules/Rutas";
import { columns } from "../columns";

const RutaTable = () => {

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [rutaData, setRutaData] = useState(null)
  const [dataColumns, setDataColumns] = useState(null);
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      let rutas = await getAllRutas();
      setRutaData(rutas);
      let col = columns(setOpen,setMode,setOpenDelete,dispatch);
      setDataColumns(col);
    }
    fetchData();
    
  }, [open,openDelete,dispatch]);

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <RutaDelete openDelete={openDelete} setOpenDelete={setOpenDelete}/>
      <RutaDrawer open={open} handleClose={handleClose} mode={mode}/>
      <Card extra={"w-full h-full p-5"}>
        <div className="relative flex items-center justify-end">
        <button 
        onClick={()=>{setOpen(true); dispatch(setDefaultData(null));setMode("POST")}}
        className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
         Agregar Nueva Ruta
        </button>
        </div>
        {rutaData ?(
        <DataGrid
            className="dark:text-white mt-8 h-max w-full"
            rows={rutaData || []}
            columns={dataColumns || []}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
          />
          ):(
            <div className="flex justify-center pt-56">
                 <CircularProgress color="primary" />
                  <Typography>Cargando datos...</Typography>
            </div>
          ) }
      </Card>
    </Box>
  );
};

export default RutaTable;
