import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import SupplierFormFields from "./SupplierFormFields";
import TabComponent from "components-base/tab/Tab";
import { createSupplier,modifySupplier, validationSchemaSupplier } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const SupplierForm = ({ mode, handleClose }) => {
    const {defaultDataSupplier} = useSelector(({suppliers})=>suppliers);
    const dispatch = useDispatch();
    const messages = (mensaje) =>{
        dispatch(
            showMessage({
              message: `Proveedor ${mensaje} con éxito`,
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            })
          );

    }

    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    code: "",
                     phone: "",
                     nombre: "",
                    apellidoPaterno: "",
                    apellidoMaterno: "",
                    direccion: "",
                    rfc: "",
                    category:{id:1}
                }
                : {
                    id: defaultDataSupplier.id,
                    code: defaultDataSupplier.code,
                    nombre: defaultDataSupplier.persona.nombre,
                    phone: defaultDataSupplier.phone,
                    direccion: defaultDataSupplier.persona.direccion,
                    apellidoPaterno: defaultDataSupplier.persona.apellidoPaterno,
                    apellidoMaterno: defaultDataSupplier.persona.apellidoMaterno,
                    direccion: defaultDataSupplier.persona.direccion,
                    rfc: defaultDataSupplier.persona.rfc,
                    category:defaultDataSupplier.category,
                    persona: {id:defaultDataSupplier.persona.id}

                },
      //  validationSchema: validationSchemaSupplier,
        onSubmit: async (values) => {

            switch(mode){
                case "POST":
                  const res = await createSupplier(values);
                    messages(res ? "creado" : "fallo")
                    handleClose();
                break;
                case "PUT":
                  await modifySupplier(values);
                    messages("modificado");
                    handleClose();
                break;
                default:handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información Proveedor",
                    component: <SupplierFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item  >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item >
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default SupplierForm;