import { Box } from "@mui/material";
import * as React from "react";
import TabComponent from "components-base/tab/Tab";
import SystemUser from "components-base/systemUser/systemUser";

const UserManagement = () => {

    const tabsElement = [
        {
            label: "Usuarios del Sistema",
            component: <SystemUser />
        }

    ];

    return (
        <Box>
            <TabComponent tabsElement={tabsElement} />
        </Box>
    );
};

export default UserManagement;