import { client } from "utils/axios";


export async function getAllEvents() {
    try {
      const { data } = await client.get(`/eventoPedido`);
      return data;
    }
    catch (error) {
      return []
    }
  }

  export async function getAllNotifications(){
    try {
        const events = await getAllEvents();
        let sorted = events.sort((a,b)=> {return b.id - a.id})
        let first = sorted.slice(0,10);
        for(let i = 0 ; i < first.length; i ++){
            let item = first[i];
            const pedido = await getPedidoById(item.pedidoId);
            first[i].code = pedido.codigo
        }
        return first;
    } catch (error) {
        return []
    }

  }

  async function getPedidoById(id) {
    try {
      const { data } = await client.get(`/pedidos/${id}`);
      return data;
    }
    catch (error) {
      return false;
    }
  }