import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { setDefaultDataSupervisor, setSupervisorId } from "store/modules/Supervisor";
import { v4 as uuidv4 } from 'uuid';


export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 100,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Editar Operador" placement="top">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => { setOpen(true); setMode("PUT"); dispatch(setDefaultDataSupervisor(data.row));dispatch(setSupervisorId(data.row.id)) }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar Supervisor" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setSupervisorId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip>

      </React.Fragment>
    ),
  },
  {
    field: "codigo",
    headerName: "Código",
    width: 200,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    width: 250,
    renderCell: (data) => (
      <Typography>{`${data.row.persona.nombre} ${data.row.persona.apellidoPaterno} ${data.row.persona.apellidoMaterno} `} </Typography>
  )
  },
  {
    field: "rfc",
    headerName: "RFC",
    width: 250,
    renderCell: (data) => (
      <Typography>{`${data.row.persona.rfc}`} </Typography>
  )
  },
  {
    field: 'direccion',
    headerName: "Dirección",
    width: 350,
    renderCell: (data) => (
      <Typography>{`${data.row.persona.direccion}`} </Typography>
  )
  },
  
]
}
