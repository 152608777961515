/*
 Initial state and properties
 */
export const initialState = {
  mode: "POST",
  prendaIdSelected: 0,
  defaultData: {},
  defaultDataCC: null
}
/*
 Action types
 */
export const SET_MODE = 'SET_MODE'
export const SET_PRENDA_ID = 'SET_PRENDA_ID'
export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'
export const SET_DEFAULT_DATA_CC = 'SET_DEFAULT_DATA_CC'
/*
 Arrow function for change state
 */
export const setMode = (payload) => ({
  type: SET_MODE,
  payload,
});
export const setPrendaId = (payload) => ({
  type: SET_PRENDA_ID,
  payload,
})
export const setDefaultData = (payload) => ({
  type: SET_DEFAULT_DATA,
  payload,
})
export const setDefaultDataCC = (payload) => ({
  type: SET_DEFAULT_DATA_CC,
  payload,
})
/*
 Reducer to describe how the state changed
 */
export default function Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MODE:
      return {
        ...state,
        mode: payload,
      }
    case SET_PRENDA_ID:
      return {
        ...state,
        prendaIdSelected: payload,
      }
    case SET_DEFAULT_DATA:
      return {
        ...state,
        defaultData: payload,
      }
    case SET_DEFAULT_DATA_CC:
      return {
        ...state,
        defaultDataCC: payload,
      }
    default:
      return state
  }
}
