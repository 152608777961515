import { client } from "utils/axios"
import * as yup from "yup";

export async function deleteSupervisor(SupervisorId) {
  try {
    await client.delete(`supervisores/${SupervisorId}`);
  } catch (error) {
    console.log(error)
  }

};
export async function getAllSupervisor() {
  const { data } = await client.get("/supervisores");
  return data;
}

export const validationSchemaSupervisor = yup.object({
  nombre: yup
    .string("Ingresa Nombre")
    .required("Campo Requerido"),
  apellidoPaterno: yup
    .string("Ingresa el apellido Paterno")
    .required("Campo Requerido"),
  apellidoMaterno: yup
    .string("Ingresa el apellido Materno"),
  direccion: yup
    .string("Ingresa la Dirección")
    .required("Campo Requerido"),
  rfc: yup
    .string("Ingresa el RFC"),
  codigo: yup
    .string("Ingresa el Código")
    .required("Campo Requerido"),

});
export async function createSupervisor(dataInput) {
  try {
    let persona = {
      nombre: dataInput.nombre,
      apellidoPaterno: dataInput.apellidoPaterno,
      apellidoMaterno: dataInput.apellidoMaterno,
      direccion: dataInput.direccion,
      rfc: dataInput.rfc
    }
    const { data } = await client.post("personas", persona);

    let supervisor = {
      persona: { id: data.id },
      codigo: dataInput.codigo,
    }
    await client.post("supervisores", supervisor);

    return true;

  } catch (error) {
    console.log(error)
    return false;
  }

};
export async function modifySupervisor(supervisor) {

  let persona = {
    apellidoPaterno: supervisor.apellidoPaterno,
    apellidoMaterno: supervisor.apellidoMaterno,
    nombre: supervisor.nombre,
    rfc: supervisor.rfc,
    direccion: supervisor.direccion,
    id: supervisor.persona.id
  }
  try {
    await client.put("personas", persona);
    await client.put("supervisores", supervisor);
  } catch (error) {

  }

};