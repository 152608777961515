import { client } from "utils/axios";
import * as yup from "yup";

export const validationSchemaWorkers = yup.object({
  nombre: yup.string().required("Ingresa nombre del trabajador"),
  apellidoPaterno: yup.string().required("Ingresa apellido del trabajador"),
  code: yup.string().required("Ingresa codigo del trabajador"),
});

export async function getAllWorkers(){
  try {
    const {data} = await client.get(`worker`);
    return data
  } catch (error) {
    
  }
    return []
}
export async function createWorkers(input){
    try {
        let persona = {
          nombre: input.nombre,
          apellidoPaterno: input.apellidoPaterno,
          apellidoMaterno: input.apellidoMaterno,
          curp:input.curp,
          direccion: input.direccion,
          rfc: input.rfc
        }
        const { data } = await client.post("personas", persona);
    
        let worker = {
          person: { id: data.id },
          code: input.code,
          position: {id:input.position.id},
          department:{id: input.department.id},
          wage: Number(input.wage) || 0,
          employmentDate:input.employmentDate || null,
          terminationDate: input.terminationDate || null,
          photo:input.photo
        }
        await client.post("worker", worker);
        return true;
      } catch (error) {
        console.log(error)
        return false;
      }
}
export async function modifyWorkers(worker){
    let person = {
        apellidoPaterno: worker.apellidoPaterno,
        apellidoMaterno: worker.apellidoMaterno,
        curp:worker.curp,
        nombre: worker.nombre,
        rfc: worker.rfc,
        direccion: worker.direccion,
        id: worker.person.id
      }
      try {
        await client.put("personas", person);
        await client.put("worker", worker);
      } catch (error) {
    
      }
    
}
export async function deleteWorkers(){
    return []
}
