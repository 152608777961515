import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { deleteInsumo } from "../functions";

const InsumoDelete = ({openDelete,setOpenDelete}) => {
    const { insumoIdSelected } = useSelector(({ insumo }) => insumo);
    const deleteFnc = async () =>{
    await deleteInsumo(insumoIdSelected);
    setOpenDelete(false);
}
    return (
        <>
            <Dialog
            open={openDelete}
            onClose={()=>setOpenDelete(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación del Insumo"}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={()=>setOpenDelete(false)}color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc}color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default InsumoDelete;