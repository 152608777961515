import React from "react";
import { Edit, Delete } from "@mui/icons-material";
import { Tooltip, IconButton, Chip, Grid } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { setDefaultDataDetalle } from "store/modules/Pedidos";
import { setDetalleId } from "store/modules/Pedidos";

export const detallesColumns = (
  setOpenDetail,
  setOpen,
  setMode,
  dispatch,
  setOpenDeleteDetalle
) => {
  return [
    {
      field: `acciones`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Ver detalles" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpenDetail(true);
                dispatch(setDetalleId(data.row.id));
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar pedido" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultDataDetalle(data.row));
                dispatch(setDetalleId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Pedido" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDeleteDetalle(true);
                setMode("DELETE");
                dispatch(setDetalleId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },

    {
      field: "producto",
      headerName: "Nombre del Producto",
      width: 220,
      renderCell: (data) => <>{data.row.producto.nombre}</>,
    },

    {
      field: "modelo",
      headerName: "Modelo",
      width: 220,
      renderCell: (data) => <>{data.row.producto.modelo}</>,
    },

    {
      field: "cantidad",
      headerName: "Cantidad",
      width: 150,
    },

    {
      field: "noPrendas",
      headerName: "Prendas x Producto",
      width: 150,
      renderCell: (data) => <>{data.row.producto.prenda.length}</>,
    },
    {
      field: "prendasXproductos",
      headerName: "Total",
      width: 100,
      renderCell: (data) => (
        <>{data.row.producto.prenda.length * data.row.cantidad}</>
      ),
    },
    {
      field: "tela",
      headerName: "Tela utilizada",
      width: 150,
    },

    {
      field: "talla",
      headerName: "Tallas",
      width: 300,
      renderCell: (data) => {
        return (
          <div style={{ overflow: "auto" }}>
            <Grid container flexDirection={"column"} spacing={1}>
              {data.row.details.tallas.map((item) => (
                <Grid item key={item.id}>
                  {" "}
                  <Chip size="small" label={item.talla.nombre} />
                </Grid>
              ))}
            </Grid>
          </div>
        );
      },
    },
    {
      field: "notas",
      headerName: "Notas",
      width: 300,
    },
  ];
};
