import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createEmbroideryOrder,
  dataTransform,
  getAllClothsByProduct,
  modifyEmbroideryOrder,
  transformSizes,
} from "./functions";
import moment from "moment";
import SizesTable from "./sizesTable";
import TablePlaceholder from "./tablePlaceholder";
import { showMessage } from "store/modules/Message";
import EmbroideryOrderForm from "./EmbroideryOrderForm";

const OrderEmbroidery = ({ mode, open, handleClose, defaultDataPedido }) => {
  const { defaultDataEmbroidery } = useSelector(({ embroidery }) => embroidery);
  const { userName } = useSelector(({ session }) => session);
  const [data, setData] = useState(null);
  const [sizes, setSizes] = useState(null);
  const [image, setImage] = useState("");
  const [embroiderer, setEmbroiderer] = useState(null);
  const [model, setModel] = useState(null);
  const [garment, setGarment] = useState("");
  const [defects, setDefects] = useState(null);
  const [cutTime, setCutTime] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(
    defaultDataPedido?.fechaEntrega
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(defaultDataPedido).length > 0) {
      setSizes(null);
      setImage("");
      const fetchData = async () => {
        const resp = await dataTransform(defaultDataPedido);
        setData(resp);
      };
      fetchData();
    }
  }, [defaultDataPedido]);
  useEffect(() => {
    if (defaultDataEmbroidery && mode === "PUT") {
      setImage(defaultDataEmbroidery.product.imagen);
      setSizes(defaultDataEmbroidery.sizes);
      setDefects(defaultDataEmbroidery.defects);
      setCutTime(defaultDataEmbroidery.embroideryTime);
      setDeliveryDate(defaultDataEmbroidery.deliveryDate);
      setEmbroiderer(defaultDataEmbroidery.embroideryManager);
      setGarment(defaultDataEmbroidery.garment);
      setModel(defaultDataEmbroidery.product);
    } else {
      setImage(null);
      setSizes(null);
      setDefects(null);
      setCutTime(null);
      setDeliveryDate(
        moment(defaultDataPedido?.fechaEntrega).format("YYYY-MM-DD")
      );
      setGarment(0);
      setModel(null);
      setEmbroiderer({ id: 1, name: "David Perez Serrano" });
    }
  }, [mode, defaultDataEmbroidery]);

  const handleChangeProduct = async (e, option) => {
    if (!option) return;
    const percentage = data.percentage;
    const _sizes = transformSizes(option.tallas, percentage);
    setSizes(_sizes);
    setImage(option.imagen);
    setModel(option);
  };
  const handleChangeDate = (value) => {
    setDeliveryDate(moment(value).format("YYYY-MM-DD"));
  };

  const handleSave = async () => {
    let obj = {
      embroideryManager: embroiderer,
      garment,
      defects,
      deliveryDate,
      embroideryTime: cutTime,
      product: model,
      sizes,
      createdByUser: userName,
      createdDate: moment().format("YYYY-MM-DD"),
      pedidoId: defaultDataPedido.id,
    };
    switch (mode) {
      case "POST":
        await createEmbroideryOrder(obj);
        dispatch(
          showMessage({
            variant: "success",
            message: "La orden de Bordado se creo satisfactoriamente",
          })
        );
        break;
      case "PUT":
        obj.id = defaultDataEmbroidery.id;
        obj.modifiedByUser = userName;
        obj.modifiedDate = moment().format("YYYY-MM-DD");
        await modifyEmbroideryOrder(obj);
        dispatch(
          showMessage({
            variant: "success",
            message: "La orden se modifico satisfactoriamente",
          })
        );
        break;
      default:
        break;
    }
    setSizes(null);
    handleClose(false);
  };
  if (!data) return null;
  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <div>
        <DialogTitle>
          <Typography variant="h4">{"Orden de Bordado"}</Typography>
        </DialogTitle>
        <DialogContent>
          <EmbroideryOrderForm
            mode={mode}
            handleChangeDate={handleChangeDate}
            handleChangeProduct={handleChangeProduct}
            setEmbroiderer={setEmbroiderer}
            setGarment={setGarment}
            setCutTime={setCutTime}
            image={image}
            data={data}
            setDefects={setDefects}
            defaultDataEmbroidery={defaultDataEmbroidery}
            deliveryDate={defaultDataPedido?.fechaEntrega}
          />
          {sizes ? (
            <SizesTable sizes={sizes} setSizes={setSizes} />
          ) : (
            <TablePlaceholder style={{ paddingTop: 5 }} />
          )}
        </DialogContent>
      </div>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => (handleClose(), setSizes(null))}
        >
          Cancelar
        </Button>
        <Button
          disabled={!model}
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OrderEmbroidery;
