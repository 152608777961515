import { Box } from "@mui/material";
import * as React from "react";
import TabComponent from "components-base/tab/Tab";
import Operadores from "components-base/operadores/Operadores";
import Maquileros from "components-base/maquileros/Maquileros";
import Suppliers from "components-base/suppliers/Suppliers";
import Supervisores from "components-base/supervisor/Supervisores";


const ManejoUsuarios = () => {
  const tabsElement = [
    {
      label: "Operadores",
      component: <Operadores />
    },
    {
      label: "Maquileros",
      component:<Maquileros />
    },
    {
      label: "Supervisores",
      component:<Supervisores />
    },
    {
      label: "Proveedores",
      component:<Suppliers />
    },
  ];

  return (
    <Box>
      <TabComponent tabsElement={tabsElement} />
    </Box>
  );
};

export default ManejoUsuarios;
