import { createContext, useState, useContext } from 'react';

const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }) => {
  const [globalFilter,setGlobalFilter] = useState(null);
  const [filterMonth,setFilterMonth] = useState(null);

  return (
    <ApplicationContext.Provider value={{
        globalFilter,setGlobalFilter,
        filterMonth,setFilterMonth

    }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => {
  return useContext(ApplicationContext)
}

