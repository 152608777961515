
import Card from "components/card";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { columns } from "../columns";
import AddPickups from "./PickupsDrawer";
import ModalPDF from "./modalPDF";
// import DriverLogsDelete from "./DriverLogsDelete";

const PickupsTable = ({ data,setRefresh, refresh }) => {
  const [open, setOpen] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [dataColumns, setDataColumns] = useState(null);
const handleClosePDF = () => setOpenPDF(false);
const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      let col = columns(setOpen, setMode, setOpenDelete, dispatch);
      setDataColumns(col);
    }
    fetchData();

  }, [open, openDelete, dispatch]);

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <ModalPDF open={openPDF} handleClosePDF={handleClosePDF} data={data}/>
      <AddPickups open={open} mode={mode} handleClose={handleClose} setMode={setMode}/>
      {/* <DriverLogsDelete refresh={refresh} setRefresh={setRefresh} openDelete={openDelete} setOpenDelete={setOpenDelete} /> */}
      <Card extra={"w-full h-full p-5"}>
      <div className="relative flex items-center justify-end">
        <button 
        onClick={()=>setOpenPDF(true)}
        className="linear rounded-[20px] bg-lightPrimary px-1 py-1 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
         Generar reporte
        </button>
        </div>
        {data ? (
          <DataGrid
            className="dark:text-white mt-8 h-max w-full"
            rows={data || []}
            columns={dataColumns || []}
            pageSize={20}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}

      </Card>
    </Box>
  );
};

export default PickupsTable;
