import { Edit, Delete, Print, Visibility, MenuBook } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setProductId, setDefaultData } from "store/modules/Productos";
import { v4 as uuidv4 } from "uuid";

export const columns = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  setOpenPDF,
  setOpenSS
) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 180,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar Producto" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultData(data.row));
                dispatch(setProductId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Producto" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setProductId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Hoja de Especificaciones" placement="top">
            <IconButton
              aria-label="specifications"
              color="primary"
              onClick={() => {
                setOpenSS(true);
                dispatch(setDefaultData(data.row));
              }}
            >
              <MenuBook />
            </IconButton>
          </Tooltip>
          <Tooltip title="Visualizacion del modelo" placement="top">
            <IconButton
              aria-label="imprimirpdf"
              color="primary"
              onClick={() => {
                setOpenPDF(true);
                dispatch(setDefaultData(data.row));
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 200,
    },
    {
      field: "imagen",
      headerName: "Imagen",
      width: 100,
      renderCell: (data) => (
        <React.Fragment>
          <div
            style={{
              height: 50,
              width: 50,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <img
              onClick={() => setOpen(true)}
              src={data.row.imagen}
              alt="Sin imagen"
            />
          </div>
        </React.Fragment>
      ),
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      width: 300,
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 150,
    },
    {
      field: "diseno",
      headerName: "Diseño",
      width: 200,
    },
    {
      field: "modelo",
      headerName: "Modelo",
      width: 200,
    },
    {
      field: "category",
      hideable: true,
      headerName: "Empresa",
      width: 200,
      renderCell: (data) => data.row.category.name,
    },
  ];
};
