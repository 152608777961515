import { Box, Grid, Button, Typography, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { useState } from "react";
import ImageModal from "components-base/ImageModal";
import storage from "utils/Firebase/firebase";
import {ref,uploadBytesResumable, getDownloadURL  } from "firebase/storage"
import { Add } from "@mui/icons-material";

const ProductImage = ({ url , setFieldValue}) => {
const [openModal,setOpenModal]= useState(false);
const [image, setImage] = useState(null);
const [newUrl,setNewUrl] = useState(null);
const [open,setOpen]=useState(false)


const handleImageChange = (e) => {
  if (e.target.files[0]) {
    setImage(e.target.files[0]);
  }
};

const handleUpload = () => {
    if (image) {
        const storageRef = ref(storage,`/productos_images/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            },
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setNewUrl(url);
                    setFieldValue("imagen", url || "")
                });
            }
        ); 
    }
    setOpen(false);//
  };
const handleClose = () =>{
    setOpenModal(false);
}
    return (
        <>
        <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        maxWidth={"lg"}
        >
           
            <DialogTitle>
                <Typography variant="subtitle">{"Cargar Imagen"}</Typography>
            </DialogTitle>
            <DialogContent>
            <Grid item sx={12}>
                        <input type="file" onChange={handleImageChange} />
                        <Button onClick={handleUpload} color="primary" variant="contained">
                            {"Cargar imagen"}
                        </Button>
                    </Grid>

            </DialogContent>
        </Dialog>
        <ImageModal handleClose={handleClose}open ={openModal} imageUrl={url} />
            <Typography variant="title">{"Imagen del Producto"}</Typography>
            <Box sx={{ padding: 2}} style={{height:400}} >
                <Grid container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "flex-start", padding: 0 }}
                >
                    <Grid item sx={12}>
                         <IconButton onClick={()=>setOpen(true)} color="primary" variant="contained">
                            <Add />
                        </IconButton>
                    </Grid>
                    <img style={{height:400, cursor:"pointer"}}  src={newUrl || url } alt="Sin imagen del producto" onClick={()=>setOpenModal(true)} />
                </Grid>
            
            </Box>
        </>
    )

}
export default ProductImage;