import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setTelaId, setDefaultData } from "store/modules/Telas";
import { v4 as uuidv4 } from "uuid";

export const columns = (setOpen, setMode, setOpenDelete, dispatch) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar Tela" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultData(data.row));
                dispatch(setTelaId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Tela" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setTelaId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "name",
      headerName: "Nombre",
      width: 120,
    },
    {
      field: "code",
      headerName: "Codigo",
      width: 120,
    },
    {
      field: "description",
      headerName: "Descripción",
      width: 300,
    },
    {
      field: "color",
      headerName: "Color",
      width: 120,
    },
    {
      field: "tone",
      headerName: "Tono",
      width: 120,
    },
  ];
};
