/*
 Initial state and properties
 */
 export const initialState = {
    mode:"POST",
    pedidoIdSelected:0,
    defaultDataPedido:{},
    idDetalle:0,
    defaultDataDetalle: null
 }
 /*
  Action types
  */
 export const SET_MODE = 'SET_MODE'
 export const SET_PEDIDO_ID = 'SET_PEDIDO_ID'
 export const SET_DETALLE_ID = 'SET_DETALLE_ID'
 export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'
 export const SET_DEFAULT_DETALLE = 'SET_DEFAULT_DETALLE'
 /*
  Arrow function for change state
  */
 export const setMode = (payload) => ({
   type: SET_MODE,
   payload,
 });
 export const setPedidoId = (payload) => ({
  type: SET_PEDIDO_ID,
  payload,
})
export const setDetalleId = (payload) => ({
  type: SET_DETALLE_ID,
  payload,
})
export const setDefaultDataPedido = (payload) => ({
  type: SET_DEFAULT_DATA,
  payload,
})
export const setDefaultDataDetalle =(payload) => ({
  type: SET_DEFAULT_DETALLE,
  payload,
})
 /*
  Reducer to describe how the state changed
  */
 export default function Reducer(state = initialState, { type, payload }) {
   switch (type) {
     case SET_MODE:
       return {
         ...state,
         mode: payload,
       }
       case SET_PEDIDO_ID:
        return {
          ...state,
          pedidoIdSelected: payload,
        }
        case SET_DETALLE_ID:
          return {
            ...state,
            idDetalle: payload,
          }
        case SET_DEFAULT_DATA:
          return {
            ...state,
            defaultDataPedido: payload,
          }
          case SET_DEFAULT_DETALLE:
            return {
              ...state,
              defaultDataDetalle: payload,
            }
     default:
       return state
   }
 }
 