import {
  Grid,
  TextField,
  Box,
  Autocomplete
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect } from "react";
import { useState } from "react";
import { getAllOperadores } from "components-base/operadores/functions";
import { getAllRutas } from "components-base/rutas/functions";
import { getAllMaquilero } from "components-base/maquileros/functions";
import { getAllUnidadByChoferId } from "../functions";
import { useSelector } from "react-redux";
const AsignacionesFormFields = ({  setFieldValue }) => {
  const [operadores, setOperadores] = useState(null);
  const [unidades, setUnidades] = useState(null);
  const [rutas, setRutas] = useState(null);
  const [maquileros, setMaquileros] = useState(null);
  const [operadorValues, setOperadorValues] = useState({ persona: { nombre: "", apellidoMaterno: "", apellidoPaterno: "", curp: "" }, codigo: "" });
  const [rutaValues, setRutaValues] = useState({ codigo: "", nombre: "", descripcion: "" });
  const [unidadValues, setUnidadValues] = useState({ descripcion: "", placas: "" });
  const [maquilerosValues, setMaquilerosValues] = useState([]);

  const {defaultDataAsignaciones} = useSelector(({asignaciones})=>asignaciones);

  useEffect(() => {
    const fetchData = async () => {
      const operadores = await getAllOperadores();
      const rutas = await getAllRutas();
      const maquileros = await getAllMaquilero();
      if (operadores) setOperadores(operadores);
      if (rutas) setRutas(rutas);
      if (maquileros) setMaquileros(maquileros)
    }
    fetchData();
  }, [])
  const handleChangeOperador = async (e, value) => {
    setUnidades(null)
    setUnidadValues({ descripcion: "", placas: "" })
    if (value) {
      const unidad = await getAllUnidadByChoferId(value.id);
      setUnidades(unidad.map(item => item.unidad))
    }
    setFieldValue("operador", value || "");
    setOperadorValues(value);
    if (!value)
      setOperadorValues({ persona: { nombre: "", apellidoMaterno: "", apellidoPaterno: "" }, licencia: "" })

  }

  return (
    <Box sx={{ height: boxSize }}>
      <Box sx={{ border: 1, borderColor: "gray", padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Autocomplete
              name="rutas"
              options={rutas || []}
              id="rutas"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Ruta" helperText={"Seleccione la ruta"} />}
              getOptionLabel={(ruta) => `${ruta.codigo}`}
              defaultValue={defaultDataAsignaciones?.ruta}
              onChange={(e, value) => { setFieldValue("ruta", value); setRutaValues({ nombre: value.nombre, descripcion: value.descripcion, codigo: value.codigo }) }}
            />
            <Autocomplete
              name="operadores"
              options={operadores || []}
              id="operadores"
              fullWidth
              defaultValue={defaultDataAsignaciones?.chofer}
              renderInput={(params) => <TextField {...params} label="Operador" helperText={"Seleccione operador de esta ruta"} />}
              getOptionLabel={(operador) => `${operador.persona.nombre} ${operador.persona.apellidoPaterno} ${operador.persona.apellidoPaterno}`}
              onChange={handleChangeOperador}
            />
            <Autocomplete
              name="unidades"
              options={unidades || []}
              id="unidades"
              fullWidth
              defaultValue={defaultDataAsignaciones?.unidad}
              renderInput={(params) => <TextField {...params} label="Unidad" helperText={"Seleccione unidad de esta ruta"} />}
              getOptionLabel={(unidad) => `${unidad.descripcion}`}
              onChange={(e, value) => { setFieldValue("unidad", value); setUnidadValues({ descripcion: value.descripcion, placas: value.placa }) }}
            />
            <Autocomplete
              name={"maquileros"}
              multiple
              limitTags={10}
              getOptionSelected={(option, value) => option.name === value.name}
              options={maquileros || []}
              onChange={(e, options) => { e.preventDefault(); setFieldValue("maquileros", options); setMaquilerosValues(options) }}
              getOptionLabel={(option) => `${option.persona.nombre}`}
              onInputChange={(e, value) => { console.log("hello") }}
              renderInput={(params) => (<TextField {...params} label={"Maquilero"}
                helperText={"Seleccione los maquileros de esta ruta"}
              />
              )}
              defaultValue={defaultDataAsignaciones?.maquileros?.map(item => item.maquilero)}
            />
          </Grid>
          <Grid item xs={7}>
            <div className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] dark:!bg-navy-800 dark:shadow-none">
              <div className="flex items-center gap-3">
                <div className="flex h-12 w-12 items-center justify-center">
                  <img
                    className="h-full w-full rounded-xl"
                    src={'https://firebasestorage.googleapis.com/v0/b/mariferdev.appspot.com/o/images.jpg?alt=media&token=3410e2d1-a4f8-4e0a-a3f9-a0990f2c6e9d'}
                    alt="foto"
                  />
                </div>
                <div className="flex flex-col">
                  <p className="mt-1 text-md font-normal text-gray-700">{`Operador: ${operadorValues.persona.nombre} ${operadorValues.persona.apellidoPaterno} ${operadorValues.persona.apellidoMaterno}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Licencia: ${operadorValues.numeroLicencia}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Tipo Licencia: ${operadorValues.tipoLicencia}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Unidad: ${unidadValues.descripcion}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Placas: ${unidadValues.placas}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Ruta: ${rutaValues.nombre}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Codigo Ruta: ${rutaValues.codigo}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Supervisor Ruta: ${rutaValues.codigo}`}</p>
                  <p className="mt-1 text-md font-normal text-gray-700">{`Maquileros: ${maquilerosValues.map(item => item.persona.nombre).join(", ")}`}</p>
                </div>
                <div className="flex justify-end pl-20">
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>

  )
}
export default AsignacionesFormFields;