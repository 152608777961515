import {
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { boxSize } from "utils/apis";
const TallaFormFields = ({ formik,setFieldValue }) => {

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={
              formik.touched.nombre && Boolean(formik.errors.nombre)
            }
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />

        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="descripcion"
            name="descripcion"
            label="Descripción"
            multiline
            rows={5}
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={
              formik.touched.descripcion &&
              Boolean(formik.errors.descripcion)
            }
            helperText={
              formik.touched.descripcion &&
              formik.errors.descripcion
            }
            color="primary"
          />


        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="codigo"
            name="codigo"
            label="Código"
            value={formik.values.codigo}
            onChange={formik.handleChange}
            error={
              formik.touched.codigo && Boolean(formik.errors.codigo)
            }
            helperText={formik.touched.codigo && formik.errors.codigo}
            color="primary"
          />

        </Grid>
      </Grid>
    </Box>

  )
}
export default TallaFormFields;