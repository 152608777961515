import {
  Autocomplete,
  Grid,
  Typography,
  TextField,
  Skeleton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useEffect, useState } from "react";
import { getAllSupplier } from "components-base/suppliers/functions";
import { useSelector } from "react-redux";
import { getMaxFolio } from "./functions";

const StampingOrderForm = ({
  folio,
  setFolio,
  mode,
  image,
  data,
  setSupplier,
  handleChangePrenda,
  prendas,
  handleChangeDate,
  handleChangeProduct,
  defaultDataStamping,
  setComments,
  comments,
}) => {
  const { orderType } = useSelector(({ stamping }) => stamping);
  const [suppliers, setSuppliers] = useState(null);

  useEffect(() => {
    if (mode === "POST") {
      const fetchFolioNumber = async () => {
        let maxFolio = await getMaxFolio(orderType);
        setFolio(maxFolio);
      };
      fetchFolioNumber();
    }
  }, [orderType]);
  useEffect(() => {
    const fetchSupplier = async () => {
      const response = await getAllSupplier();
      setSuppliers(response);
    };
    fetchSupplier();
  }, []);

  return (
    <Grid container spacing={2} flexDirection={"row"} sx={{ paddingTop: 5 }}>
      <Grid item xs={4}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {image ? (
            <img
              src={image}
              alt="Selcciona un modelo"
              style={{ height: 400, width: 400 }}
            />
          ) : (
            <Skeleton
              animation={false}
              variant="rectangular"
              height={400}
              width={400}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid container flexDirection={"row"} spacing={2}>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Folio:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              disabled={mode === "PUT"}
              fullWidth
              id="folio"
              name="folio"
              label=""
              type="number"
              value={`${String(folio).padStart(4, "0")}`}
              inputProps={{
                sx: {
                  textAlign: "right",
                  "&::placeholder": {
                    textAlign: "right",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">
              {`Responsable de ${orderType}:`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(supplier) =>
                `${supplier.persona.nombre} ${supplier.persona.apellidoPaterno} ${supplier.persona.apellidoMaterno}`
              }
              onChange={(e, o) => setSupplier(o)}
              id="_supplier"
              options={suppliers || []}
              maxWidth
              renderInput={(params) => <TextField {...params} label="" />}
              defaultValue={
                mode === "POST"
                  ? {
                      id: 1,
                      persona: {
                        nombre: "",
                        apellidoPaterno: "",
                        apellidoMaterno: "",
                      },
                    }
                  : defaultDataStamping?.supplier
              }
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Modelo:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              isOptionEqualToValue={(option, value) =>
                Number(option.id) === Number(value.id)
              }
              getOptionLabel={(product) =>
                `${product.modelo} (${product.nombre})`
              }
              onChange={handleChangeProduct}
              id="_product"
              options={data.products || []}
              maxWidth
              renderInput={(params) => <TextField {...params} label="" />}
              defaultValue={defaultDataStamping?.producto}
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Prenda:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              isOptionEqualToValue={(option, value) =>
                Number(option.id) === Number(value.id)
              }
              getOptionLabel={(prenda) => `${prenda.nombre}`}
              onChange={handleChangePrenda}
              id="_prenda"
              options={prendas || []}
              maxWidth
              renderInput={(params) => <TextField {...params} label="" />}
              defaultValue={defaultDataStamping?.prenda}
            />
          </Grid>

          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Fecha de salida:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="es-mx"
            >
              <DatePicker
                name={"fechaEntrega"}
                label={""}
                defaultValue={
                  mode === "POST"
                    ? moment()
                    : moment(defaultDataStamping?.deliveryDate)
                }
                closeOnSelect
                sx={{ width: "100%" }}
                slotProps={{ textField: { variant: "outlined" } }}
                showDaysOutsideCurrentMonth
                disableHighlightToday
                onChange={handleChangeDate}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="h5">{"Observaciones:"}</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="comments"
              name="comments"
              label=""
              rows={4}
              multiline
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default StampingOrderForm;
