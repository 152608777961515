import { getAllConsumptionByPrendaV2 } from "components-base/prendas/functions";
import { getAllTallasByProductoId } from "components-base/productos/functions";

export function dataConvert(data) {
    return { data: data }
}

export function totalSum(detalle) {
    let total = 0;
    let cantidad = 0;
    detalle.details.data.forEach(detail => {
        total += (detail.cantidadAsignada * detalle.details.prendas.length);
        cantidad += detail.cantidadAsignada;
    })
    return { totalCount: total, cantidad: cantidad }
}

export function insumosConvert(detalle) {
    let insumos = []
    let insumosGrouped = [];
    detalle.details.data.forEach(detail => {
        detalle.details.prendas.forEach(prenda => {
            prenda.insumo.forEach((insumo, index) => {
                if (insumo.details) {
                    let total = 0;
                    let factor = 1;
                    insumo.details.forEach(d => {
                        if (d.talla.id === detail.talla.id) {
                            let cantidad = Number(d.cantidad) * Number(detail.cantidadAsignada || 1);
                            total += cantidad;
                            factor = Number(d.unidad.factor);
                            insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.insumo.details.presentacion, cantidad: (total / factor / Number(insumo.insumo.details.cantidad)), nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                        }
                    })
                } else {
                    insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.unidad, cantidad: insumo.cantidad * detail.cantidadAsignada, nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                }
            })
        })
    })
    let groupedData = insumos.reduce((groups, item) => {
        const key = item.nombre;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
        return groups;
    }, {});

    let keys = Object.keys(groupedData)
    keys.forEach(item => {
        insumosGrouped.push({ nombre: item, data: groupedData[item] })
    })
    insumosGrouped.forEach(item => {
        let cantidad = 0;
        let unidad = ""
        item.data.forEach(d => {
            cantidad += d.cantidad;
            unidad = d.unidad
        })
        item.cantidad = cantidad;
        item.unidad = unidad
    })
    return insumosGrouped
}

export async function dataTransform(data) {
    let total_product = 1;
    let total_prenda = data.prenda.length;

    let products = [];
    let images = [];
    let sku = data.sku;
    let model = data.modelo;
    let estampado = data.estampado ? "SI" : "NO";
    let bordado = data.bordado ? "SI" : "NO"
    let name = data.nombre;
    let design = data.diseno;
    let client = data.category.name;
    let tallas = [];
    images.push(data.imagen);

    products.push(data)
    // PROCESS START
    const _tallas = await getAllTallasByProductoId(data.id);
    if (_tallas.length > 0) {
        _tallas.forEach(t => {
            tallas = [...tallas, t.talla]
        })
    }



    products.forEach(p => {
        p.tallas = []
        let insumos = [];
        let tallas = [];
        p.prenda.forEach(prenda => {
            images = [...images, prenda.imagen]
            prenda.insumo.forEach(insumo => {
                if (insumo.details) {
                    let factor = 1;
                    insumo.details.forEach(d => {
                        // let t = tallas.find(t => t.talla.id === d.talla.id);
                        // console.log(t)
                        // if (!t) return;
                      //  let cantidad = Number(d.cantidad) * Number(t.cantidad);
                       // factor = Number(d.unidad?.factor || 1);
                        insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.insumo.details.presentacion,
                         cantidad: Number(d.cantidad)// (cantidad / factor / Number(insumo.insumo.details.cantidad))
                         , nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                    })
                } else {
                    insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.unidad, cantidad: Number(insumo.cantidad), nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                }
            })
            p.insumos = insumos;
        })
    });
    let insumosToGroup = [];
    products.forEach(p => {
        if(p.insumos)
           insumosToGroup = [...insumosToGroup, ...p.insumos]
    })
    let insumosGrouped = [];
    let insumos = insumosToGroup;
    let groupedData = insumos.reduce((groups, item) => {
        const key = item.nombre;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
        return groups;
    }, {});

    let keys = Object.keys(groupedData)
    keys.forEach(item => {
        insumosGrouped.push({ nombre: item, data: groupedData[item] })
    })
    insumosGrouped.forEach(item => {
        let cantidad = 0;
        let unidad = ""
        item.data.forEach((d, index) => {
            cantidad += d.cantidad
            unidad = d.unidad
        })
        item.cantidad = cantidad;
        item.unidad = unidad
    })

    let clothConsumptionTotal = [];
    for (let i = 0; i < products.length; i++) {
        let p = products[i];
        for (let j = 0; j < p.prenda.length; j++) {
            let prenda = p.prenda[j];
            const clothConsumption = await getAllConsumptionByPrendaV2(prenda.id);
            clothConsumptionTotal = [...clothConsumptionTotal, ...clothConsumption]
        }
    }
    let prenda_products = total_prenda * total_product;

    clothConsumptionTotal.forEach(cc => {

        cc.clothTotal = (cc.quantity * prenda_products) / 100;



    })
    let clothConsumptionGrouped = [];
    if (clothConsumptionTotal.length > 0) {
        let clothGrouped = [];
        let cloths = clothConsumptionTotal;
        let groupedData = cloths.reduce((groups, item) => {
            const key = item.cloth.name;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(item);
            return groups;
        }, {});
        let keys = Object.keys(groupedData)
        keys.forEach(item => {
            clothGrouped.push({ nombre: item, data: groupedData[item] })
        })
        clothGrouped.forEach(item => {
            let cantidad = 0;
            let unidad = ""
            item.data.forEach(d => {
                cantidad += d.clothTotal;
                unidad = d.unit
            })
            item.quantity = cantidad;
            item.unit = unidad;
        })
        clothConsumptionGrouped = [...clothConsumptionGrouped, ...clothGrouped]
    }

    let resultObj = {
        sku,
        model,
        name,
        tallas: tallas,
        prenda: data.prenda,
        estampado,
        bordado,
        design,
        products,
        total_product,
        total_prenda,
        clothConsumption: clothConsumptionTotal,
        clothGrouped: clothConsumptionGrouped,
        insumosGrouped,
        client,
        images
    }

    return resultObj;


}