import { Delete, Edit } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setDefaultDataCC } from "store/modules/Prendas";

export const columns = (setOpenCC, setModeCC, setOpenDeleteCC, dispatch) => {
    return [
        {
            field: `acciones`,
            headerName: "Acciones",
            width: 150,
            renderCell: (data) => (
                <React.Fragment>
                    <Tooltip title="Editar consumo" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setOpenCC(true);
                                setModeCC("PUT");
                                dispatch(setDefaultDataCC(data.row));
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar consumo" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setOpenDeleteCC(true);
                                setModeCC("DELETE");
                                dispatch(setDefaultDataCC(data.row));
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            ),
        },
        {
            field: "quantity",
            headerName: "Cantidad",
            width: 100,
            headerClassName: "color:red",
        },
        {
            field: "unit",
            headerName: "Unidad",
            width: 100,
            headerClassName: "color:red",
        },
        {
            field: "notes",
            headerName: "Notas",
            width: 200,
        },
        {
            field: "cloth",
            headerName: "Tela",
            width: 200,
            renderCell: (data) => (
                <React.Fragment>
                      {data.row.cloth.name}
                </React.Fragment>
            ),
        },
        {
            field: "color",
            headerName: "Color",
            width: 200,
            renderCell: (data) => (
                <React.Fragment>
                      {data.row.cloth.color}
                </React.Fragment>
            ),
        },
        {
            field: "tone",
            headerName: "Tono",
            width: 200,
            renderCell: (data) => (
                <React.Fragment>
                      {data.row.cloth.tone}
                </React.Fragment>
            ),
        },
        {
            field: "average",
            headerName: "Promedio",
            width: 200,
            renderCell: (data) => (
                <React.Fragment>
                      {data.row.isAverage ? "SI":"NO" }
                </React.Fragment>
            ),
        },

    ];
};