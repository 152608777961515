import { getAllPickupsByPedidoId } from "components-base/pickups/functions"

export const dataTransform = async (data) => {
  return data
}
export function getProductData(data) {
  let product = ""
  let cantidad = 0;
  let detalle = data[0].pedido.detallePedido
  detalle.forEach(d => {
    product += ` ${d.producto.nombre} -`;
    cantidad += d.cantidad
  });

  return { modelo: product, totalAmount:cantidad }
}

export async function getPickupsData(id){

  let data = await getAllPickupsByPedidoId(id);
  return data
}