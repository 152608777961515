import { Edit, Delete, Warning } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography, Grid } from "@mui/material";
import {
  setRollsId,
  setDefaultData,
  setDefaultDataDetail,
} from "store/modules/StackTelas";
import { v4 as uuidv4 } from "uuid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { ContactsRounded, PrintSharp } from "@mui/icons-material";

export const columns = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  handleOpenPDF,
  handleClosePdf,
  setDataDefault
) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar Rollo" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultData(data.row));
                dispatch(setRollsId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Rollo" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setRollsId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir Etiqueta" placement="top">
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                setDataDefault(data.row);
                handleOpenPDF();
              }}
            >
              <PrintSharp />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "cloth.name",
      hideable: true,
      headerName: "Tela",
      width: 200,
      renderCell: (data) => data.row.cloth.name,
    },
    // {
    //   field: "type",
    //   headerName: "Tipo de Rollo",
    //   width: 150,
    // },
    {
      field: "code",
      headerName: "Codigo",
      width: 150,
    },
    {
      field: "inputs.date",
      headerName: "Fecha de entrada",
      width: 150,
      renderCell: (data) => <DatePickerComponent data={data.row.inputs.date} />,
    },
    {
      field: "totalKg",
      headerName: "Total de kg",
      width: 150,
    },
    {
      field: "meters",
      headerName: "Metros",
      width: 150,
    },
    // {
    //   field: "amount",
    //   headerName: "Disponibilidad",
    //   width: 150,
    // },
  ];
};

const DatePickerComponent = ({ data }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
      <DatePicker
        value={moment(data)}
        closeOnSelect
        sx={{ width: "100%" }}
        slotProps={{ textField: { variant: "standard" } }}
        showDaysOutsideCurrentMonth
        disableHighlightToday
      />
    </LocalizationProvider>
  );
};
