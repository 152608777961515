import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

import image from "../../../../assets/img/marifer.png";
import { dataTransform } from "./functions";

// Create styles
const styles = StyleSheet.create({
  image2: {
    position: "absolute",
    objectPosition: "0",
    zIndex: -999,
    width: "320px",
    height: "320px",
    opacity: 0.2,
  },
  image: {
    position: "relative",
    objectFit: "cover",
    zIndex: -999,
    width: "120px",
    height: "120px",
    paddingLeft: 0,
    opacity: 0.9,
  },
  header: {
    color: "#ff0080",
    textTransform: "uppercase",
    marginTop: 15,
    paddingLeft: 50,
    fontSize: "18pt",
    fontWeight: "extrabold",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
    height: "7.975cm",
  },
  section1: {
    padding: 8,
    textAlign: "center",
    width: "7cm",
    height: "100%",
    backgroundColor: "white",
    border: 1,
    borderColor: "#0A83F5",
    lineHeight: 1.5,
    fontSize: "10pt",
  },
  section2: {
    padding: 8,
    lineHeight: 4,
    border: 1,
    borderColor: "#0A83F5",
    width: "14.6cm",
    height: "100%",
    backgroundColor: "white",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
// Create styles

const ProductSummaryPDF = ({ defaultDataDetail: d, producto }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const convert = async () => {
      const res = await dataTransform(d);
      setData(res);
    };
    convert();
  }, [d]);
  if (!data) return "Calculando........";

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="LETTER" style={styles.page}>
          <View style={styles.mainSection}>
            <View>
              <Image style={styles.image} src={image} />
            </View>
            <View>
              <Text style={styles.header}>{`Detalle del Producto`}</Text>
            </View>
          </View>
          <View
            style={{
              fontSize: 10,
              flexDirection: "row",
              textAlign: "center",
              wordWrap: "word-break",
              paddingLeft: 220,
              marginTop: -150,
              paddingBottom: 5,
            }}
          >
            <View>
              <View>
                <Text
                  style={{
                    border: 1,
                    padding: 1,
                    width: 100,
                    textAlign: "center",
                  }}
                >
                  {"Cliente"}
                </Text>
              </View>
              <Text
                style={{
                  fontSize: "12pt",
                  border: 1,
                  width: 100,
                  padding: 1,
                  height: 45,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                {data.client}
              </Text>
            </View>
            <View>
              <View>
                <Text
                  style={{
                    border: 1,
                    padding: 1,
                    width: 75,
                    textAlign: "center",
                  }}
                >
                  {"Modelo"}
                </Text>
              </View>
              <Text
                style={{
                  border: 1,
                  width: 75,
                  padding: 1,
                  height: 45,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                {data.model}
              </Text>
            </View>
            <View>
              <View>
                <Text
                  style={{
                    border: 1,
                    padding: 1,
                    width: 75,
                    textAlign: "center",
                  }}
                >
                  {"SKU"}
                </Text>
              </View>
              <Text
                style={{
                  border: 1,
                  width: 75,
                  padding: 1,
                  height: 45,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                {data.sku}
              </Text>
            </View>
            <View>
              <View>
                <Text
                  style={{
                    border: 1,
                    padding: 1,
                    width: 70,
                    textAlign: "center",
                  }}
                >
                  {"Estampado"}
                </Text>
              </View>
              <Text
                style={{
                  border: 1,
                  width: 70,
                  padding: 1,
                  height: 45,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                {data.estampado}
              </Text>
            </View>
            <View>
              <View>
                <Text
                  style={{
                    border: 1,
                    padding: 1,
                    width: 50,
                    textAlign: "center",
                  }}
                >
                  {"Bordado"}
                </Text>
              </View>
              <Text
                style={{
                  border: 1,
                  width: 50,
                  padding: 1,
                  height: 45,
                  color: "gray",
                  textAlign: "center",
                }}
              >
                {data.bordado}
              </Text>
            </View>
            {/* <View style={{ textAlign: "left", width: 150, color: "#111" }} >
                            <Text style={{ border: 1, fontSize: 9, padding: 1, height: 20 }}>
                                {`${"Fecha de Creacion: "} ${data.fecha_creacion.split(" ")[0]}`}
                            </Text>
                            <Text>
                            </Text>
                            <Text style={{ border: 1, fontSize: 9, padding: 1, height: 20 }}>
                                {`${"Fecha de Entrega: "} ${data.fecha_entrega.split(" ")[0]}`}
                            </Text>
                            <Text style={{ border: 1, fontSize: 9, padding: 1, height: 20 }}>
                                {`${"Fecha de Estimada: "} ${data.fecha_estimada.split(" ")[0]}`}
                            </Text>
                        </View> */}
          </View>
          <View style={{ height: 600, border: 1, marginTop: 1 }}>
            <View style={{ alignItems: "center" }}>
              <Image src={image} style={styles.image2} />
            </View>
            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                fontWeight: "extrabold",
                fontSize: "11pt",
                backgroundColor: "#e6e6e6",
                height: 20,
              }}
            >
              <View style={{ width: "100%", border: 1 }}>
                <Text style={{ color: "#000" }}>{"PRODUCTO"}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                fontSize: "10pt",
                color: "#000111",
                height: 20,
                backgroundColor: "#f9c6cf",
              }}
            >
              <View style={{ width: "20%", border: 1 }}>
                <Text>{"Nombre"}</Text>
              </View>
              <View style={{ width: "20%", border: 1 }}>
                <Text>{"Diseno"}</Text>
              </View>
              {/* <View style={{ width: "10%", border: 1 }} >
                                <Text>
                                    {"Cantidad"}
                                </Text>
                            </View> */}
              <View style={{ width: "25%", border: 1 }}>
                <Text>{"Tallas"}</Text>
              </View>
              <View style={{ width: "35%", border: 1 }}>
                <Text>{"Prendas"}</Text>
              </View>
            </View>
            {data.products.length === 0 && (
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "10pt",
                  color: "#000111",
                }}
              >
                <View style={{ width: "100%", border: 1 }}>
                  <Text>{"No hay datos para mostrar."}</Text>
                </View>
              </View>
            )}
            {/* {data.products.map(p => ( */}
            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                fontSize: "8pt",
                color: "#000111",
                height: 45,
              }}
            >
              <View style={{ width: "20%", border: 1 }}>
                <Text>{data.name}</Text>
              </View>
              <View style={{ width: "20%", border: 1 }}>
                <Text>{data.design}</Text>
              </View>
              <View style={{ width: "25%", border: 1 }}>
                <Text>
                  {data.tallas.map((i) => (
                    <View>
                      <Text>{`${i.nombre}\n`}</Text>
                    </View>
                  ))}
                </Text>
              </View>
              <View style={{ width: "35%", border: 1, fontSize: "8pt" }}>
                <Text>
                  {data.prenda.map((i) => (
                    <View>
                      <Text>{`${i.nombre}\n`}</Text>
                    </View>
                  ))}
                </Text>
              </View>
            </View>

            {/* // ))} */}
            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                fontWeight: "extrabold",
                fontSize: "11pt",
                backgroundColor: "#e6e6e6",
                height: 20,
              }}
            >
              <View style={{ width: "100%", border: 1 }}>
                <Text style={{ color: "#000" }}>{"INSUMOS"}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                fontSize: "10pt",
                color: "#000111",
                height: 20,
                backgroundColor: "#f9c6cf",
              }}
            >
              <View style={{ width: "45%", border: 1 }}>
                <Text>{"Nombre"}</Text>
              </View>
              <View style={{ width: "20%", border: 1 }}>
                <Text>{"Cantidad"}</Text>
              </View>
              <View style={{ width: "35%", border: 1 }}>
                <Text>{"Unidad"}</Text>
              </View>
            </View>
            {data.insumosGrouped.length === 0 && (
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "10pt",
                  color: "#000111",
                }}
              >
                <View style={{ width: "100%", border: 1 }}>
                  <Text>{"No hay datos para mostrar."}</Text>
                </View>
              </View>
            )}

            {data.insumosGrouped
              .sort((a, b) => {
                return a.cantidad - b.cantidad;
              })
              .map((i) => (
                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    fontSize: "11pt",
                    color: "#000111",
                  }}
                >
                  <View style={{ width: "45%", border: 1 }}>
                    <Text>{i.nombre}</Text>
                  </View>
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{Math.round(i.cantidad, 2)}</Text>
                  </View>
                  <View style={{ width: "35%", border: 1 }}>
                    <Text>{`${i.unidad}(s)`}</Text>
                  </View>
                </View>
              ))}

            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                fontWeight: "extrabold",
                fontSize: "11pt",
                backgroundColor: "#e6e6e6",
                height: 20,
              }}
            >
              <View style={{ width: "100%", border: 1 }}>
                <Text>{"CONSUMO DE TELA POR PRENDA"}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                textAlign: "center",
                fontSize: "10pt",
                color: "#000111",
                backgroundColor: "#f9c6cf",
                height: 20,
              }}
            >
              <View style={{ width: "50%", border: 1 }}>
                <Text>{"Tela"}</Text>
              </View>
              <View style={{ width: "25%", border: 1 }}>
                <Text>{"Prenda"}</Text>
              </View>
              <View style={{ width: "25%", border: 1 }}>
                <Text>{"Cantidad promedio por prenda"}</Text>
              </View>
            </View>
            {data.clothConsumption.length === 0 && (
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "10pt",
                  color: "#000111",
                }}
              >
                <View style={{ width: "100%", border: 1 }}>
                  <Text>{"No hay datos para mostrar."}</Text>
                </View>
              </View>
            )}
            {data.clothConsumption.map((m) => (
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "8pt",
                  color: "#000111",
                }}
              >
                <View style={{ width: "50%", border: 1 }}>
                  <Text>
                    {`(${m.cloth.code})${m.cloth.name}, ${m.cloth.color}, ${m.cloth.tone}`}
                  </Text>
                </View>
                <View style={{ width: "25%", border: 1 }}>
                  <Text>{m.prenda.nombre}</Text>
                </View>
                <View style={{ width: "25%", border: 1 }}>
                  <Text>{`${m.quantity} ${m.unit}`}</Text>
                </View>
              </View>
            ))}
            <View
              style={{
                flexDirection: "row",
                marginTop: 10,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {data.images.map((i, index) => (
                <Image
                  key={index}
                  style={{ width: 120, height: 120, padding: 2 }}
                  src={i}
                />
              ))}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default ProductSummaryPDF;
