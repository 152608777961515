import { client } from "utils/axios";
import * as yup from "yup";

export const createInputs = async (inputs, formName) => {
  try {
    const { data } = await client.post("inputs", inputs);
    if (data.id) {
      switch (formName) {
        case "Telas":
          inputs.rolls.map(async (item) => {
            item.inputs = { id: data.id };
            await client.post("rolls", item);
          });
          break;
        case "Maquinaria":
          inputs.machinary.map(async (item) => {
            item.inputs = { id: data.id };
            item.category = { id: item.category.id };
            await client.post("machinary", item);
          });
          break;
        default:
          null;
      }
    }
  } catch (error) {
    console.log(error);
  }
};
export async function getAllInputs() {
  const { data } = await client.get("/inputs");
  return data;
}
export async function getFilterByRolls(id) {
  const { data } = await client.get(`/rolls/filterInput/${id}`);
  return data;
}
export async function getAllCategorias() {
  const { data } = await client.get("/categorias");
  return data;
}
export async function getAllCategories() {
  const { data } = await client.get("/category");
  return data;
}
export async function getAllTelas() {
  const { data } = await client.get("/cloth");
  return data;
}

export async function getAllSupplier() {
  const { data } = await client.get("/supplier");
  return data;
}
export const validationSchemaInputs = yup.object({
  nombre: yup
    .string("Ingresa nombre de la Entrada")
    .required("Campo Requerido"),
  dimensiones: yup
    .string("Ingresa las dimensiones de la Entrada")
    .required("Campo Requerido"),
  descripcion: yup.string("Ingresa Descripción").required("Campo Requerido"),
  codigo: yup.string("Ingresa Código").required("Campo Requerido"),
});
// export const deleteInputs = async (inputsId) => {
//   await client.delete(`inputs/${inputsId}`);
// };
export const deleteInputs = (inputsId) => async (dispatch) => {
  await client.delete(`inputs/${inputsId}`);
};

export const modifyInputs = async (inputs) => {
  try {
    await client.put("inputs", inputs);
  } catch (error) {
    console.log(error);
  }
};
