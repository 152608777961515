import React, { useState } from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box } from "@mui/material";
import SalidaFormFields from "./SalidaFormFields";
import TabComponent from "components-base/tab/Tab";
import {
  createSalida,
  modifySalida,
  //  validationSchemaSalida
} from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";
import moment from "moment";

const SalidaForm = ({ mode, handleClose }) => {
  const { defaultData } = useSelector(({ salidas }) => salidas);
  const [internal, setInternal] = useState(false);
  const [foliado, setFoliado] = useState(false);
  const [tonos, setTonos] = useState(false);
  const dispatch = useDispatch();
  const messages = (mensaje) => {
    dispatch(
      showMessage({
        message: `Salida ${mensaje} con éxito`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };
  const formik = useFormik({
    initialValues:
      mode === "POST"
        ? {
            comments: "",
            folio: "",
            fechaSalida: moment().format("YYYY-MM-DD"),
            fechaRetorno: moment().format("YYYY-MM-DD"),
            chofer: "",
            maquilero: "",
            // supervisor: "",
            ruta: "",
            details: null,
            pedido: "",
            internal: false,
            foliado: false,
            tonos: false,
            completed: false,
            number: "",
          }
        : {
            id: defaultData.id,
            folio: defaultData.folio,
            comments: defaultData.comments,
            fechaSalida: defaultData.fechaSalida,
            fechaRetorno: defaultData.fechaRetorno,
            chofer: defaultData.chofer,
            supervisor: defaultData.supervisor,
            maquilero: defaultData.maquilero,
            ruta: defaultData.maquilero.ruta.nombre,
            details: defaultData.details,
            pedido: defaultData.pedido,
            internal: defaultData.internal,
            foliado: defaultData.foliado,
            tonos: defaultData.tonos,
            completed: defaultData.completed,
            number: defaultData.pedido.number,
          },
    // validationSchema: validationSchemaSalida,
    onSubmit: async (values) => {
      switch (mode) {
        case "POST":
          if (
            !values.details ||
            !values.pedido ||
            !values.chofer ||
            !values.maquilero
          ) {
            alert("Ingresa la informacion necesaria antes de continuar");
            return;
          }
          await createSalida(values);
          messages("creado");
          handleClose();
          break;
        case "PUT":
          if (
            !values.details ||
            !values.pedido ||
            !values.chofer ||
            !values.maquilero
          ) {
            alert("Ingresa la informacion necesaria antes de continuar");
            return;
          }
          await modifySalida(values);
          messages("modificado");
          handleClose();
          break;
        default:
      }
    },
  });
  const { setFieldValue } = formik;
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        "& .MuiTextField-root": { marginBottom: "25px" },
         justifyContent: "center" 
      }}

    >
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <Grid item>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            Guardar
          </Button>
        </Grid>
      </Grid>
      <TabComponent
        tabsElement={[
          {
            label: "Información de la Salida",
            component: (
              <SalidaFormFields
                foliado={foliado}
                setFoliado={setFoliado}
                tonos={tonos}
                setTonos={setTonos}
                internal={internal}
                setInternal={setInternal}
                formik={formik}
                mode={mode}
                setFieldValue={setFieldValue}
                defaultData={defaultData}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};
export default SalidaForm;
