import React from "react";
import { Drawer, DialogTitle, IconButton } from "@mui/material";
import SalidaForm from "./SalidaForm";
import { Cancel } from "@mui/icons-material";
import { styles } from "utils/apis";

const AddSalida = ({ mode, open, handleClose }) => {

    return (
        <>
            <Drawer
                anchor={"bottom"}
                open={open}
                onClose={handleClose}
                sx={styles}     
            >
                <DialogTitle sx={{display: "flex", justifyContent: "flex-end", padding: 0}}>
                    <IconButton onClick={handleClose} sx={{color:"purple"}}>
                        <Cancel />
                    </IconButton>
                </DialogTitle>
                <SalidaForm mode={mode} handleClose={handleClose} />
          
            </Drawer>
        </>
    )
}
export default AddSalida;