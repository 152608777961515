import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export const deleteRuta = (rutaId) => async (dispatch) => {

  await deleteAllOperadores(rutaId);
  await deleteAllSupervisores(rutaId);
  await client.delete(`rutas/${rutaId}`);
};
export async function getAllRutas() {
  const { data } = await client.get("/rutas");
  return data;
}
export async function getAllCategorias() {
  const { data } = await client.get("/categorias");
  return data;
}
async function getAllRutaSupervisor() {
  const { data } = await client.get("/rutaSupervisor");
  return data;
}
async function getAllRutaOperador() {
  const { data } = await client.get("/rutaChofer");
  return data;
}
export const validationSchemaRuta = yup.object({
  nombre: yup
    .string("Ingresa nombre de Ruta")
    .required("Campo Requerido"),
  descripcion: yup
    .string("Ingresa Descripción")
    .required("Campo Requerido"),
  codigo: yup
    .string("Ingresa CÓdigo")
    .required("Campo Requerido"),

});
export const createRuta = async (ruta, dispatch) => {
  try {

    const supervisor = ruta.supervisor;
    const operadores = ruta.operadores;

    const { data } = await client.post("rutas", ruta);
    if (data) {
      await insertNew_Op_Sup(data, supervisor, operadores);
    }
    dispatch(
      showMessage({
        message: "Ruta agregada con éxito",
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
    );

  } catch (error) {
    dispatch(
      showMessage({
        message:
          "Ocurrió un error al agregar una ruta: ",
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
    );
  }
};

async function deleteAllSupervisores(rutaId) {
  let toDelete = []
  const rutaSup = await getAllRutaSupervisor();
  rutaSup.forEach(rs => {
    if (rs.rutaId === rutaId) {
      toDelete = [...toDelete, rs.id]
    }
  });
  for (let i = 0; i < toDelete.length; i++) {
    const id = toDelete[i];
    try {
      await client.delete(`/rutaSupervisor/${id}`);
    } catch (error) {
      console.log(error)
    }
  }
}
async function deleteAllOperadores(rutaId) {
  let toDelete = []
  const rutaOp = await getAllRutaOperador();
  rutaOp.forEach(ro => {
    if (ro.rutaId === rutaId) {
      toDelete = [...toDelete, ro.id]
    }
  })
  for (let i = 0; i < toDelete.length; i++) {
    const id = toDelete[i];
    try {
      await client.delete(`/rutaChofer/${id}`);
    } catch (error) {
      console.log(error)
    }

  }
}
export const modifyRuta = async (ruta, dispatch) => {
  try {
    const supervisor = ruta.supervisor;
    const operadores = ruta.operadores;
    delete ruta.supervisor;
    delete ruta.operadores;
    await client.put("rutas", ruta);
    await deleteAllSupervisores(ruta.id);
    await deleteAllOperadores(ruta.id);
    await insertNew_Op_Sup(ruta, supervisor, operadores);
    dispatch(
      showMessage({
        message: `Ruta "${ruta.nombre}" modificada con éxito`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  } catch (error) {
    dispatch(
      showMessage({
        message:
          `Ocurrió un error al modificar la ruta "${ruta.nombre}"`,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  }

};
async function insertNew_Op_Sup(data, supervisor, operadores) {
  const rutaId = data.id
  for (let i = 0; i < supervisor.length; i++) {
    let su = supervisor[i];
    let obj = {
      supervisor: { id: su.id },
      rutaId: rutaId,
      activo: true,
      fechaCambio: new Date().toISOString().split("T")[0]
    }
    await client.post("rutaSupervisor", obj);
  }
  for (let i = 0; i < operadores.length; i++) {
    let op = operadores[i];
    let rutaChofer = {
      chofer: { id: op.id },
      rutaId: rutaId,
      fechaCambio: new Date().toISOString().split("T")[0],
      activo: true,
    }
    await client.post("rutaChofer", rutaChofer);
  }
}