import { Grid, TextField, Box, Autocomplete, Typography } from "@mui/material";
import { useEffect } from "react";
import { boxSize } from "utils/apis";

const FurnitureFormFields = ({ formik, setFieldValue }) => {
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            color="primary"
          />
          <TextField
            fullWidth
            id="dimensions"
            name="dimensions"
            label="Dimensiones"
            multiline
            // rows={5}
            value={formik.values.dimensions}
            onChange={formik.handleChange}
            error={
              formik.touched.dimensions && Boolean(formik.errors.dimensions)
            }
            helperText={formik.touched.dimensions && formik.errors.dimensions}
            color="primary"
          />
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            color="primary"
          />
        </Grid>
        {/* <Grid item xs={5}>
        </Grid> */}
        <Grid item xs={7.5}>
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Descripción"
            multiline
            rows={8}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            color="primary"
          />
        </Grid>
        {/* <Grid item xs={3}></Grid> */}
      </Grid>
    </Box>
  );
};
export default FurnitureFormFields;
