import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { deleteEmbroideryOrder } from "./functions";
const DeleteOrderEmbroidery = ({ openDelete, setOpenDelete }) => {
  const { defaultDataEmbroidery } = useSelector(({ embroidery }) => embroidery);
  const deleteFnc = async () => {
    await deleteEmbroideryOrder(defaultDataEmbroidery.id);
    setOpenDelete(false);
  };
  return (
    <>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>
          <Typography variant="title">{"Eliminar"}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle">
            {"Desea Eliminar la Orden de Bordado"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteFnc} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DeleteOrderEmbroidery;
