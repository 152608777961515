
import Card from "components/card";
import { DataGrid } from "@mui/x-data-grid";
import UnidadesDrawer from "./UnidadesDrawer";
import React, { useState, useEffect } from "react";
import { getAllUnidades } from "../functions";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import UnidadesDelete from "./UnidadesDelete";
import { columns } from "../columns";

const UnidadesTable = () => {

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [unidadesData, setUnidadesData] = useState(null)
  const [dataColumns, setDataColumns] = useState(null);
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      let unidades = await getAllUnidades();
      setUnidadesData(unidades);
      let col = columns(setOpen,setMode,setOpenDelete,dispatch);
      setDataColumns(col);
    }
    fetchData();
    
  }, [open,openDelete,dispatch]);


  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <UnidadesDelete openDelete={openDelete} setOpenDelete={setOpenDelete}/>
      <UnidadesDrawer open={open} handleClose={handleClose} mode={mode} />
      <Card extra={"w-full h-full p-5"}>
        <div className="relative flex items-center justify-end">
        <button 
        onClick={()=>{setOpen(true);setMode("POST")}}
        className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
         Agregar Nueva Unidad
        </button>
        </div>
        {unidadesData ?(
        <DataGrid
            className="dark:text-white mt-8 h-max w-full"
            rows={unidadesData || []}
            columns={dataColumns || []}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
          />
          ):(
            <div className="flex justify-center pt-56">
                 <CircularProgress color="primary" />
                  <Typography>Cargando datos...</Typography>
         
            </div>
          ) }

      </Card>
    </Box>
  );
};

export default UnidadesTable;
