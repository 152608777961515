import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import { useState, useEffect } from "react";
import { dataTransform } from "./functions";
import ResumePdf from "./resume-pdf";

const PrintResumeModal = ({ open, handleClose, data }) => {

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent>
        <ResumePdf data={data} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PrintResumeModal;
