import { Grid, TextField, Box, Autocomplete, Typography } from "@mui/material";
import { useEffect } from "react";
import { boxSize } from "utils/apis";
import { useState } from "react";
import { getAllCategories, getAllInputs } from "../functions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { getAllTelas } from "components-base/Inputs/functions";

const StackTelasFormFields = ({ formik, setFieldValue, mode }) => {
  const [categories, setCategoryData] = useState(null);
  const [telasData, setTelasData] = useState([]);
  const [inputsData, setInputsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let categories = await getAllCategories();
      setCategoryData(categories);
    };
    const fetchDataTelas = async () => {
      let telas = await getAllTelas();
      setTelasData(telas);
    };

    const fetchDataInputs = async () => {
      let inputs = await getAllInputs();
      setInputsData(inputs);
    };
    fetchDataInputs();
    fetchDataTelas();
    fetchData();
  }, []);

  const dataFilterInputs = inputsData?.filter(
    (item) => item?.category?.name === "Telas"
  );

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            id="cloth"
            name="cloth"
            fullWidth
            options={telasData || []}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de Tela"
                color="primary"
                error={formik.touched.cloth && Boolean(formik.errors.cloth)}
                helperText={formik.touched.cloth && formik.errors.cloth}
              />
            )}
            getOptionLabel={(option) => `${option.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => setFieldValue("cloth", value)}
            value={formik.values.cloth}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            color="primary"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="color"
            name="color"
            label="Color"
            value={formik.values.color}
            onChange={formik.handleChange}
            error={formik.touched.type && Boolean(formik.errors.color)}
            helperText={formik.touched.type && formik.errors.color}
            color="primary"
            s
            sx={{ mr: 2 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            id="inputs"
            name="inputs"
            fullWidth
            options={dataFilterInputs || []}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Entrada del Rollo"
                color="primary"
                error={formik.touched.cloth && Boolean(formik.errors.inputs)}
                helperText={formik.touched.cloth && formik.errors.inputs}
              />
            )}
            getOptionLabel={(option) => `${option.code} ${option.date} `}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => setFieldValue("inputs", value)}
            disabled={mode === "PUT" ? true : false}
            value={formik.values.inputs}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="totalKg"
            name="totalKg"
            label="Total de kg"
            type="number"
            value={formik.values.totalKg}
            onChange={formik.handleChange}
            error={formik.touched.totalKg && Boolean(formik.errors.totalKg)}
            helperText={formik.touched.totalKg && formik.errors.totalKg}
            color="primary"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="meters"
            name="meters"
            label="Metros"
            type="number"
            value={formik.values.meters}
            onChange={formik.handleChange}
            error={formik.touched.meters && Boolean(formik.errors.meters)}
            helperText={formik.touched.meters && formik.errors.meters}
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default StackTelasFormFields;
