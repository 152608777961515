import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import CategoriesTypeFormFields from "./CategoriesTypeFormFields";
import TabComponent from "components-base/tab/Tab";
import { createCategoriesType,modifyCategoriesType, validationSchemaCategoriesType } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const CategoriesTypeForm = ({ mode, handleClose }) => {
    const {defaultDataCategoriesType} = useSelector(({categoryType})=>categoryType);
    const dispatch = useDispatch();
    const messages = (mensaje) =>{
        dispatch(
            showMessage({
              message: `Categoria ${mensaje} con éxito`,
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            })
          );

    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    name: "",
                    description: "",
                }
                : {
                    id: defaultDataCategoriesType?.id,
                    name: defaultDataCategoriesType?.name,
                    description: defaultDataCategoriesType?.description,
                },
        validationSchema: validationSchemaCategoriesType,
        onSubmit: async (values) => {
            switch(mode){
                case "POST":
                   await createCategoriesType(values,dispatch);
                    messages("Creado")
                    handleClose();
                break;
                case "PUT":
                  await modifyCategoriesType(values,dispatch);
                    messages("Modificado");
                    handleClose();
                break;
                default:handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;

    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información de los tipos de Categorias",
                    component: <CategoriesTypeFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item sx={12} >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item sx={12}>
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default CategoriesTypeForm;