import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import { useSelector } from "react-redux";
import OrderBordadoTable from "./OrderBordadoTable";

const OrderBordadoMain = ({ open, handleClose }) => {
  const { defaultDataPedido } = useSelector(({ pedido }) => pedido);

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
      <DialogContent>
        <OrderBordadoTable defaultDataPedido={defaultDataPedido}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OrderBordadoMain;
