import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export async function deleteDepartment(departmentId) {
    await client.delete(`department/${departmentId}`);
};
export async function getAllDepartment() {
    const { data } = await client.get("/department");
    return data;
}

export const validationSchemaDepartment = yup.object({
    name: yup
        .string("Ingresa nombre de Department")
        .required("Campo Requerido"),
    code: yup
        .string("Ingresa Codigo")
        .required("Campo Requerido"),

});
export const createDepartment = async (department, dispatch) => {
    try {

        const { data } = await client.post("department", department);
        dispatch(
            showMessage({
                message: "Departamento/Area agregada con éxito",
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );

    } catch (error) {
        dispatch(
            showMessage({
                message:
                    "Ocurrió un error al agregar un departamento/area: ",
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );
    }
};

export const modifyDepartment = async (department, dispatch) => {
    try {

        await client.put("department", department);

        dispatch(
            showMessage({
                message: `Departamento/Area "${department.name}" modificada con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    } catch (error) {
        dispatch(
            showMessage({
                message:
                    `Ocurrió un error al modificar la categoria "${department.name}"`,
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    }

};
