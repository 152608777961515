import React from "react";
import { Menu } from "@mui/icons-material";
import { AppBar, Toolbar, Typography, Grid, IconButton } from "@mui/material";
import MainToolbar from "components-base/ToolBar";
import { Link } from "react-router-dom";

const MainAppBar = React.forwardRef(({ classes, open, handleDrawerOpen, ...rest }, ref) => {
    const { onOpenSidenav, brandText } = rest
    return (
        <AppBar
           enableColorOnDark
            sx={ open ? { ...classes.appBar, ...classes.appBarShift, boxShadow: 'none',backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "background.paper" : "primary.main" } : { ...classes.appBar,  boxShadow: 'none', backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "background.paper" : "primary.main" } }
            position="fixed"
        >
            <Toolbar >
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Grid container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <Grid item className="flex flex-row p-1 items-center gap-4">
                                <>
                                    {!open && <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        sx={open ? { ...classes.menuButton, ...classes.hide } : classes.menuButton}
                                    >
                                        <Menu />
                                    </IconButton>}
                                    <div className="ml-[10px]">
                                        <p className="shrink text-[33px] capitalize">
                                            <Link to="#" className="font-bold capitalize">
                                                <Typography variant="h4">
                                                    {brandText || "Boopsi"}
                                                </Typography>
                                            </Link>
                                        </p>
                                    </div>

                                </>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end">
                            <Grid item className="flex flex-row p-1 items-center gap-4">
                                <>
                                    <MainToolbar
                                        onOpenSidenav={onOpenSidenav}
                                        brandText={brandText}
                                    />
                                </>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
});


export default MainAppBar;
