import { Grid, TextField, Box, Autocomplete, Typography } from "@mui/material";
import { getAllCategorias } from "components-base/Inputs/functions";
import { useEffect } from "react";
import { boxSize } from "utils/apis";

const MachinaryFormFields = ({ formik, setFieldValue }) => {
  // const [categories, setCategoryData] = useState(null);
  // useEffect(() => {
  //   const fetchDataCategories = async () => {
  //     let categories = await getAllCategorias();
  //     console.log(categories);
  //     const dataCategories = categories?.filter(
  //       (item) => item?.type?.name === "Machinary"
  //     );
  //     setCategoryData(dataCategories);
  //   };
  //   fetchDataCategories();
  // }, []);
  // console.log(categories);
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            color="primary"
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            id="responsible"
            name="responsible"
            label="Responsable"
            multiline
            // rows={5}
            value={formik.values.responsible}
            onChange={formik.handleChange}
            error={
              formik.touched.responsible && Boolean(formik.errors.responsible)
            }
            helperText={formik.touched.responsible && formik.errors.responsible}
            color="primary"
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Descripción"
            multiline
            rows={5}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            color="primary"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color={"purple"}>
            {"Ingrese cantidad"}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            id="cantidad"
            name="cantidad"
            label="Cantidad"
            type="number"
            value={formik.values.cantidad}
            onChange={formik.handleChange}
            error={formik.touched.cantidad && Boolean(formik.errors.cantidad)}
            helperText={formik.touched.cantidad && formik.errors.cantidad}
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default MachinaryFormFields;
