import React, { Component, useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import {
  Grid,
  TextField,
  Box,
  Autocomplete,
  Typography,
  IconButton,
  useTheme,
  Button,
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useSelector } from "react-redux";
import { getAllCategories, getAllSupplier, getAllTelas } from "../functions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getAllRolls } from "components-base/StackCloth/functions";
import MachinaryFormFields from "components-base/machinary/components/MachinaryFormFields";
import FurnitureFormFields from "components-base/furniture/components/FurnitureFormFields";
import moment from "moment";
import { Add, Remove } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import TabComponent from "components-base/tab/Tab";
import { getAllCategorias } from "components-base/categories/functions";

const InputsFormFields = ({
  formik,
  setFieldValue,
  mode,
  setIsMenuForm,
  isMenuForm,
}) => {
  const { defaultData } = useSelector(({ telas }) => telas);
  const [categories, setCategoryData] = useState(null);
  const [machinary, setMachinaryData] = useState(null);
  const [telasData, setTelasData] = useState([]);
  const [supplierData, setSupplierData] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchDataCategories = async () => {
      let categories = await getAllCategories();
      const dataCategories = categories?.filter(
        (item) => item?.type?.name === "Inventario"
      );
      const dataMachinaryData = categories?.filter(
        (item) => item?.type?.name === "Machinary"
      );
      setMachinaryData(dataMachinaryData);
      setCategoryData(dataCategories);
    };
    const fetchDataTelas = async () => {
      let telas = await getAllTelas();
      setTelasData(telas);
    };
    const fetchSupplier = async () => {
      let supplier = await getAllSupplier();
      setSupplierData(supplier);
    };
    fetchDataTelas();
    fetchDataCategories();
    fetchSupplier();
  }, []);
  const filterSupplier = supplierData?.filter((item) => {
    if (isMenuForm !== null) {
      switch (isMenuForm.name) {
        case "Telas":
          return item.category.name === "Telas";
        default:
          null;
      }
    }
  });
  return (
    <Box sx={{ height: boxSize }}>
      {/* ---------------------------------------------------------------------------------------------------
-------------------------FORMULARIO DE ENTRADAS---------------------------------------------------------
--------------------------------------------------------------------------------------------------- */}
      <Grid container spacing={5}>
        <Grid item xs={2.2}>
          <Autocomplete
            id="category"
            name="category"
            size="small"
            getOptionLabel={(option) => `${option.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => {
              setIsMenuForm(value);
              setFieldValue("category", value);
            }}
            disablePortal
            value={formik.values.category}
            options={categories || []}
            renderInput={(params) => (
              <TextField {...params} label="Categoria" color="primary" />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es-mx"
          >
            <DatePicker
              name="date"
              label="Fecha de Entrada"
              size="small"
              color="primary"
              value={moment(formik.values.date)}
              closeOnSelect
              sx={{ width: "100%" }}
              slotProps={{ textField: { variant: "outlined" } }}
              showDaysOutsideCurrentMonth
              disableHighlightToday
              onChange={(value) => setFieldValue("date", value)}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            id="code"
            name="code"
            size="small"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            color="primary"
          />
          <TextField
            fullWidth
            id={`amount`}
            name={`amount`}
            size="small"
            type="number"
            label={
              isMenuForm?.name === "Telas"
                ? "Cantidad de rollos que ingresa"
                : isMenuForm?.name === "Muebles"
                ? "Cantidad de Muebles que ingresa"
                : "Cantidad de Maquinaria que ingresa"
            }
            value={formik.values.amount}
            onChange={formik.handleChange}
            color="primary"
            sx={{ mr: 2 }}
            error={
              formik.touched.amount &&
              formik.touched.amount &&
              Boolean(formik.errors.amount && formik.errors.amount)
            }
            helperText={
              formik.touched.amount &&
              formik.touched.amount &&
              formik.errors.amount &&
              formik.errors.amount
            }
          />
        </Grid>
        <Grid item xs={3.5}>
          <TextField
            fullWidth
            id="description"
            name="description"
            size="small"
            label="Descripción de la Entrada"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            color="primary"
          />
          <Autocomplete
            id={`supplier`}
            name={`supplier`}
            size="small"
            getOptionLabel={(option) =>
              `${
                option.persona.nombre +
                " " +
                option.persona.apellidoPaterno +
                " " +
                option.persona.apellidoMaterno
              }`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => {
              setFieldValue("supplier", value);
            }}
            disablePortal
            value={formik.values.supplier}
            options={filterSupplier || []}
            renderInput={(params) => (
              <TextField {...params} label="Proveedor" color="primary" />
            )}
            fullWidth
          />
          <Autocomplete
            id={`unit`}
            name={`unit`}
            size="small"
            getOptionLabel={(option) => `${option.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => {
              setFieldValue("unit", value);
            }}
            disablePortal
            value={formik.values.category}
            options={[
              { id: 1, name: "Pieza" },
              { id: 2, name: "Caja" },
              { id: 3, name: "Bolsa" },
              { id: 4, name: "Rollo" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Tipo de Unidad" color="primary" />
            )}
            fullWidth
          />
        </Grid>
        {isMenuForm?.name === "Telas" && (
          <>
            <Grid item xs={2.2}>
              <TextField
                fullWidth
                id={`kg`}
                name={`kg`}
                label="Total de Kg"
                size="small"
                type="number"
                color="primary"
                value={formik.values.kg}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.kg &&
                  formik.touched.kg &&
                  Boolean(formik.errors.kg && formik.errors.kg)
                }
                helperText={
                  formik.touched.kg &&
                  formik.touched.kg &&
                  formik.errors.kg &&
                  formik.errors.kg
                }
                sx={{ mr: 2 }}
              />
              <TextField
                fullWidth
                id="meters"
                name="meters"
                size="small"
                type="number"
                label="Total de Metros"
                value={formik.values.meters}
                onChange={formik.handleChange}
                color="primary"
              />
            </Grid>
            <br />
            {/* Formulario interno con FieldArray */}
            <br />
            <FieldArray
              name="rolls"
              render={(arrayHelpers) => (
                <div>
                  {formik.values.rolls.map((item, index) => (
                    <Grid
                      key={index}
                      mb={2}
                      mt={2}
                      ml={5}
                      display="flex"
                      alignItems="center"
                      container
                      spacing={2}
                    >
                      <Grid item xs={3}>
                        <Autocomplete
                          id={`rolls[${index}].cloth`}
                          name={`rolls[${index}].cloth`}
                          size="small"
                          getOptionLabel={(option) => `${option.name}`}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(e, value) => {
                            setFieldValue(`rolls[${index}].cloth`, value);
                          }}
                          disablePortal
                          value={formik.values.rolls[index].cloth}
                          options={telasData || []}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tela"
                              color="primary"
                              error={
                                formik.touched.rolls &&
                                formik.touched.rolls[index] &&
                                Boolean(
                                  formik.errors.rolls &&
                                    formik.errors.rolls[index]?.cloth
                                )
                              }
                              helperText={
                                formik.touched.rolls &&
                                formik.touched.rolls[index] &&
                                formik.errors.rolls &&
                                formik.errors.rolls[index] &&
                                formik.errors.rolls[index].cloth
                              }
                            />
                          )}
                          fullWidth
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          id={`rolls[${index}].code`}
                          name={`rolls[${index}].code`}
                          label="Code"
                          size="small"
                          color="primary"
                          value={formik.values.rolls[index].code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            Boolean(
                              formik.errors.rolls &&
                                formik.errors.rolls[index]?.code
                            )
                          }
                          helperText={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            formik.errors.rolls &&
                            formik.errors.rolls[index]?.code
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          id={`rolls[${index}].color`}
                          name={`rolls[${index}].color`}
                          label="Color"
                          size="small"
                          color="primary"
                          value={formik.values.rolls[index].color}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            Boolean(
                              formik.errors.rolls &&
                                formik.errors.rolls[index]?.color
                            )
                          }
                          helperText={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            formik.errors.rolls &&
                            formik.errors.rolls[index]?.color
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          id={`rolls[${index}].totalKg`}
                          name={`rolls[${index}].totalKg`}
                          label="Total Kg"
                          size="small"
                          type="number"
                          color="primary"
                          value={formik.values.rolls[index].totalKg}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            Boolean(
                              formik.errors.rolls &&
                                formik.errors.rolls[index]?.totalKg
                            )
                          }
                          helperText={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            formik.errors.rolls &&
                            formik.errors.rolls[index]?.totalKg
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          id={`rolls[${index}].meters`}
                          name={`rolls[${index}].meters`}
                          label="Metros"
                          size="small"
                          type="number"
                          color="primary"
                          value={formik.values.rolls[index].meters}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            Boolean(
                              formik.errors.rolls &&
                                formik.errors.rolls[index]?.meters
                            )
                          }
                          helperText={
                            formik.touched.rolls &&
                            formik.touched.rolls[index] &&
                            formik.errors.rolls &&
                            formik.errors.rolls[index]?.meters
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ ml: 8 }}
                    onClick={() =>
                      arrayHelpers.push({
                        id: 0,
                        code: "",
                        type: "",
                        is_void: false,
                      })
                    }
                    startIcon={<Add />}
                  >
                    Agregar Rollo
                  </Button>
                </div>
              )}
            />
          </>
        )}
        {/* {isMenuForm?.name === "Muebles" && (
          <>
            <Grid item xs={2.2}>
              <TextField
                fullWidth
                id={`unit`}
                name={`unit`}
                label="Unidades que ingresan"
                size="small"
                type="number"
                color="primary"
                value={formik.values.unit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.unit &&
                  formik.touched.unit &&
                  Boolean(formik.errors.unit && formik.errors.unit)
                }
                helperText={
                  formik.touched.unit &&
                  formik.touched.unit &&
                  formik.errors.unit &&
                  formik.errors.unit
                }
                sx={{ mr: 2 }}
              />
            </Grid>
          </>
        )} */}
        {isMenuForm?.name === "Maquinaria" && (
          <>
            <FieldArray
              name="machinary"
              render={(arrayHelpers) => (
                <div>
                  {formik.values.machinary.map((item, index) => (
                    <Grid
                      key={index}
                      mb={2}
                      mt={2}
                      ml={5}
                      display="flex"
                      alignItems="center"
                      container
                      spacing={2}
                    >
                      <Grid item xs={3}>
                        <Autocomplete
                          id={`machinary[${index}].category`}
                          name={`machinary[${index}].category`}
                          size="small"
                          getOptionLabel={(option) => `${option.name}`}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(e, value) => {
                            setFieldValue(
                              `machinary[${index}].category`,
                              value
                            );
                          }}
                          disablePortal
                          value={formik.values.machinary[index].category}
                          options={machinary || []}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Maquinaria"
                              color="primary"
                              error={
                                formik.touched.machinary &&
                                formik.touched.machinary[index] &&
                                Boolean(
                                  formik.errors.machinary &&
                                    formik.errors.machinary[index]?.category
                                )
                              }
                              helperText={
                                formik.touched.machinary &&
                                formik.touched.machinary[index] &&
                                formik.errors.machinary &&
                                formik.errors.machinary[index] &&
                                formik.errors.machinary[index].category
                              }
                            />
                          )}
                          fullWidth
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          id={`machinary[${index}].name`}
                          name={`machinary[${index}].name`}
                          label="Nombre del Producto"
                          size="small"
                          color="primary"
                          value={formik.values.machinary[index].name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            Boolean(
                              formik.errors.machinary &&
                                formik.errors.machinary[index]?.name
                            )
                          }
                          helperText={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            formik.errors.machinary &&
                            formik.errors.machinary[index]?.name
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          id={`machinary[${index}].code`}
                          name={`machinary[${index}].code`}
                          label="Code"
                          size="small"
                          color="primary"
                          value={formik.values.machinary[index].code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            Boolean(
                              formik.errors.machinary &&
                                formik.errors.machinary[index]?.code
                            )
                          }
                          helperText={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            formik.errors.machinary &&
                            formik.errors.machinary[index]?.code
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          id={`machinary[${index}].responsible`}
                          name={`machinary[${index}].responsible`}
                          label="Responsable"
                          size="small"
                          color="primary"
                          value={formik.values.machinary[index].responsible}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            Boolean(
                              formik.errors.machinary &&
                                formik.errors.machinary[index]?.responsible
                            )
                          }
                          helperText={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            formik.errors.machinary &&
                            formik.errors.machinary[index]?.responsible
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <LocalizationProvider
                          dateAdapter={AdapterMoment}
                          adapterLocale="es-mx"
                        >
                          <DatePicker
                            id={`machinary[${index}].dateMaintenance`}
                            name={`machinary[${index}].dateMaintenance`}
                            label="Fecha de Mantenimiento"
                            size="small"
                            color="primary"
                            value={moment(
                              formik.values.machinary[index].dateMaintenance
                            )}
                            closeOnSelect
                            sx={{ width: "100%" }}
                            slotProps={{ textField: { variant: "outlined" } }}
                            showDaysOutsideCurrentMonth
                            disableHighlightToday
                            onChange={(value) =>
                              setFieldValue(
                                `machinary[${index}].dateMaintenance`,
                                value
                              )
                            }
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id={`machinary[${index}].description`}
                          name={`machinary[${index}].description`}
                          label="Descripcion"
                          size="small"
                          color="primary"
                          multiline
                          rows={4}
                          value={formik.values.machinary[index].description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            Boolean(
                              formik.errors.machinary &&
                                formik.errors.machinary[index]?.description
                            )
                          }
                          helperText={
                            formik.touched.machinary &&
                            formik.touched.machinary[index] &&
                            formik.errors.machinary &&
                            formik.errors.machinary[index]?.description
                          }
                          sx={{ mr: 2 }}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ ml: 8 }}
                    onClick={() =>
                      arrayHelpers.push({
                        id: 0,
                        code: "",
                        is_void: false,
                      })
                    }
                    startIcon={<Add />}
                  >
                    Agregar Maquinaria
                  </Button>
                </div>
              )}
            />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default InputsFormFields;
