import UnidadesTable from "./components/UnidadesTable";

const Rutas = () =>{

    return(
        <div>
            <UnidadesTable />
             
        </div>
    )
}
export default Rutas;