import {
  Grid,
  TextField,
  Box, FormGroup, FormControlLabel, Checkbox, FormLabel, Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { boxSize } from "utils/apis";
import { getAllActions } from "../functions";


const SystemRoleFormFields = ({setFieldValue, formik, roleActions,setRoleActions }) => {

  const [actions, setActions] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAllActions();
      setActions(response)
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (actions) {
      let roleActions = formik.values.action.map(a => a.action.name)
      let _roleActions = [];
      actions.forEach(a => {
        if (roleActions.includes(a.name))
          a.selected = true;
        else
          a.selected = false;
        _roleActions = [..._roleActions, a];
      });
      setRoleActions(_roleActions);
    }
  }, [actions]);

  if (!actions || !roleActions) return null;

  const handleChangeAction = (e, a) => {
    setRoleActions(roleActions.filter(item =>item.id !==a.id));
    a.selected = e.target.checked;
    setRoleActions((ra)=>[...ra,a]);
    setFieldValue("action",roleActions);
  }

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={
              formik.touched.nombre && Boolean(formik.errors.nombre)
            }
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />
          <TextField
            fullWidth
            id="descripcion"
            name="descripcion"
            label="Descripcion"
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={
              formik.touched.descripcion &&
              Boolean(formik.errors.descripcion)
            }
            helperText={
              formik.touched.descripcion &&
              formik.errors.descripcion
            }
            color="primary"
          />
          <FormGroup>
            <FormLabel>
              <Typography>
                {"Acciones permitidas del rol"}
              </Typography>
            </FormLabel>
            {actions.map(a => (
              <FormControlLabel
                key={a.id}
                onChange={() => { }}
                checked={a.selected}
                control={<Checkbox onChange={(e) => handleChangeAction(e, a)} />} label={a.name} />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </Box>

  )
}
export default SystemRoleFormFields;