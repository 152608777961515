import { Delete, MapRounded } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { setDriverLogsId } from "store/modules/DriverLogs";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import 'moment/locale/es'
moment.locale('es');

const openLocationGoogleMaps = (lat, long) =>{
  window.open(`https://www.google.com/maps?q=${lat},${long}`)
}

export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 100,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Eliminar DriverLogs" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setDriverLogsId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
  },

  {
    field: "driver",
    headerName: "Chofer",
    width: 250,
    renderCell: (data) => (
      <Typography variant="caption">{`${data.row.driver.persona.nombre} ${data.row.driver.persona.apellidoPaterno} ${data.row.driver.persona.apellidoMaterno} `} </Typography>
  )
  },
  {
    field: 'maquilero',
    headerName: "Maquilero",
    width: 250,
    renderCell: (data) => (
      <Typography variant="caption">{`${data.row.maquilero.persona.nombre} ${data.row.maquilero.persona.apellidoPaterno} ${data.row.maquilero.persona.apellidoMaterno}`} </Typography>
  )
  },
  {
    field: 'direccion',
    headerName: "Dirección de checado",
    width: 350,
    renderCell: (data) => (
      <Typography variant="caption">{` ${data.row.maquilero.persona.direccion}`} </Typography>
  )
  },
  {
    field: 'type',
    headerName: "Tipo",
    width: 100,
    renderCell: (data) => (
      <Typography variant="caption">{`${data.row.type === "arrive" ? "Llegada": "Salida"}`} </Typography>
  )
  },
  {
    field: 'time',
    headerName: "Hora de registro",
    width: 170,
    renderCell: (data) => (
      <Typography variant="caption">{`${data.row.type === "arrive" ? moment(data.row.arrivedDate).format("YYYY-MM-DD hh:mm:ss") :moment(data.row.departureDate).format("YYYY-MM-DD hh:mm:ss")}`} </Typography>
  )
  },
  {
    field: 'ubication',
    headerName: "Ubicacion",
    width: 100,
    renderCell: (data) => (
      <IconButton onClick={data.row.type === "arrive" ? ()=>openLocationGoogleMaps(data.row.arrivedLatitude,data.row.arrivedLongitude) 
      :()=>openLocationGoogleMaps(data.row.departureLatitude,data.row.departureLongitude)}>
   <MapRounded color="primary"/>
      </IconButton>
   
  )
  },
  
]
}
