import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export async function deletePosition(positionId) {
    await client.delete(`position/${positionId}`);
};
export async function getAllPosition() {
    const { data } = await client.get("/position");
    return data;
}

export const validationSchemaPosition = yup.object({
    name: yup
        .string("Ingresa nombre de Position")
        .required("Campo Requerido"),
    code: yup
        .string("Ingresa Codigo")
        .required("Campo Requerido"),

});
export const createPosition = async (position, dispatch) => {
    try {

        const { data } = await client.post("position", position);
        dispatch(
            showMessage({
                message: "Posicion agregada con éxito",
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );

    } catch (error) {
        dispatch(
            showMessage({
                message:
                    "Ocurrió un error al agregar un posicion. ",
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );
    }
};

export const modifyPosition = async (position, dispatch) => {
    try {

        await client.put("position", position);

        dispatch(
            showMessage({
                message: `Posicion "${position.name}" modificada con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    } catch (error) {
        dispatch(
            showMessage({
                message:
                    `Ocurrió un error al modificar la posicion "${position.name}"`,
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    }

};
