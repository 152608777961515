import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "./TabPanel"

const TabComponent = ({ tabsElement }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <Box  sx={{ width: "100%", height: "100%" }}>
      <Box  sx={{padding:2}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorcolor="primary"
          textcolor="primary"
        >
          {tabsElement.map((item, index) => (
            <Tab
             disabled={item.disabled} label={item.label} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </Box>
        {tabsElement.map((item, index) => (
          <TabPanel value={value} index={index} key={index}>
            {item.component}
          </TabPanel>
        ))}
    </Box>
  );
};

export default TabComponent;
