import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import image from "../../../assets/img/marifer.png";

// Create styles
const styles = StyleSheet.create({
  qrImagen: {
    width: "110px",
    height: "110px",
    left: "0px",
  },
  image: {
    position: "relative",
    objectFit: "cover",
    zIndex: -999,
    width: "60px",
    height: "60px",
    left: "100px",
    marginBottom: "10px",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  textTitulo: {
    paddingTop: "55px",
    paddingLeft: "8%",
  },
  text2: {
    paddingLeft: "22%",
    paddingBottom: "30px",
  },
  text3: {
    paddingLeft: "45%",
    color: "#ff0080",
  },
  text4: {
    paddingLeft: "8%",
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
    height: "5cm",
  },
  text5: {
    paddingLeft: "5px",
  },
});

const LabelPdf = ({ data }) => {
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page style={styles.page} size={[286, 223]}>
          {data.length > 0 &&
            data.map((item, index) => {
              data[index].name = data[index]?.cloth?.name;
              return (
                <View key={index}>
                  <View style={styles.text5}>
                    <Text>{`Tela: ${item.name}`}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginTop: "2px" }}>
                    <Image style={styles.qrImagen} src={item.dataURL} />
                    <View
                      style={{ flexDirection: "column", marginTop: "30px" }}
                    >
                      <View>
                        <Text>{`Codigo: ${item.code}`}</Text>
                      </View>
                      <View>
                        <Text>{`Metros: ${item.meters}`}</Text>
                      </View>
                      <View>
                        <Text>{`Kilos: ${item.totalKg}`}</Text>
                      </View>
                      <View>
                        <Text>{`Color: ${item.color}`}</Text>
                      </View>
                      <View>
                        <Text>{`Proveedor: ${
                          item.inputs.supplier === null
                            ? " "
                            : item.inputs.supplier.persona.nombre
                        }`}</Text>
                      </View>
                    </View>
                  </View>
                  <Image style={styles.image} src={image} />
                </View>
              );
            })}
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default LabelPdf;
