
import Card from "components/card";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { columns } from "../columns";
import DriverLogsDelete from "./DriverLogsDelete";

const DriverLogsTable = ({ data,setRefresh, refresh }) => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [dataColumns, setDataColumns] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      let col = columns(setOpen, setMode, setOpenDelete, dispatch);
      setDataColumns(col);
    }
    fetchData();

  }, [open, openDelete, dispatch]);

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <DriverLogsDelete refresh={refresh} setRefresh={setRefresh} openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <Card extra={"w-full h-full p-5"}>
        {data ? (
          <DataGrid
            className="dark:text-white mt-8 h-max w-full"
            rows={data || []}
            columns={dataColumns || []}
            pageSize={20}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}

      </Card>
    </Box>
  );
};

export default DriverLogsTable;
