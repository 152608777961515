import AdministracionUsuarios from "components-base/pages/administraciousuarios"

const Usuarios = () =>{

    return(
        <div className="flex w-full flex-col gap-5">
            <AdministracionUsuarios />
        </div>
    )
}
export default Usuarios;