import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "store/modules/Message";
import {
  Grid,
  Icon,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import {
  CheckCircle,
  Warning,
  ErrorOutline,
  Info,
  Close,
} from "@mui/icons-material";

const variantIcon = {
  success: <CheckCircle fontSize="small" />,
  warning: <Warning fontSize="small" />,
  error: <ErrorOutline fontSize="small" />,
  info: <Info fontSize="small" />,
};

function FuseMessage(props) {
  const dispatch = useDispatch();
  const state = useSelector((store) => store.message.state);
  const options = useSelector((store) => store.message.options);

  const variants = {
    success: "#03B010",
    error: "#D50Aff",
    info: "#1395DA",
    warning: "#DAC513",
  };

  return (
    <Snackbar
      {...options}
      open={state}
      onClose={() => dispatch(hideMessage())}
      ContentProps={{
        variant: "body",
        headlineMapping: {
          body1: "div",
          body2: "div",
        },
      }}
    >
      <SnackbarContent
        style={{
          color: "#ffff",
          background: variants[options.variant],
        }}
        message={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              {variantIcon[options.variant] && (
                <Icon color="inherit">{variantIcon[options.variant]}</Icon>
              )}
            </Grid>
            <Grid item>
              <Typography style={{ fontFamily: "serif", paddingLeft: "5px" }}>
                {options.message}
              </Typography>
            </Grid>
          </Grid>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage())}
          >
            <Close fontSize="small">close</Close>
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default memo(FuseMessage);
