import React from "react";
import { useFormik } from "formik";
import { Box, Grid, Button } from "@mui/material";
import WorkersFormFields from "./WorkersFormFields";
import TabComponent from "components-base/tab/Tab";
import { createWorkers, modifyWorkers, validationSchemaWorkers } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";
import moment from "moment";


const WorkersForm =  ({ mode, handleClose }) => {
    const { defaultDataWorker : defaultData } = useSelector(({ worker }) => worker);
    const dispatch = useDispatch();
    const messages = (mensaje) => {
        dispatch(
            showMessage({
                message: `Trabajador ${mensaje} con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );

    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    code: "",
                    nombre: "",
                    apellidoPaterno: "",
                    apellidoMaterno: "",
                    direccion: "",
                    curp: "",
                    rfc: "",
                    employmentDate: moment().format("YYYY-MM-DD"),
                    terminationDate: "",
                    wage: 0,
                    person:{},
                    photo:"",
                }
                : {
                    id: defaultData.id,
                    nombre: defaultData.person.nombre,
                    apellidoMaterno:defaultData.person.apellidoMaterno,
                    apellidoPaterno:defaultData.person.apellidoPaterno,
                    code:defaultData.code,
                    curp:defaultData.person.curp,
                    rfc:defaultData.person.rfc,
                    direccion:defaultData.person.direccion,
                    position:defaultData.position,
                    department:defaultData.department,
                    employmentDate:defaultData.employmentDate,
                    terminationDate:defaultData.terminationDate,
                    wage: defaultData.wage,
                    person:defaultData.person,
                    photo:defaultData.photo
         
                },
          validationSchema: validationSchemaWorkers,
        onSubmit: async (values) => {
            if(!values.department || !values.position){
                dispatch(
                    showMessage({
                        message: `Por favor selecciona una posicion y/o departamento para este trabajador!`,
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    })
                );
                return;
            }
            switch (mode) {
                case "POST":
                    await createWorkers(values, dispatch);
                    messages("creado")
                    handleClose();
                    break;
                case "PUT":
                    await modifyWorkers(values, dispatch);
                    messages("modificado");
                    handleClose();
                    break;
                default: handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información del Trabajador",
                    component: <WorkersFormFields handleClose={handleClose} formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }

            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item  >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item >
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>

        </Box>
    );
}
export default WorkersForm;