import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export async function deleteCategoriesType(categoryId) {
    await client.delete(`typeCategory/${categoryId}`);
};
export async function getAllCategoriesType() {
    const { data } = await client.get("/typeCategory");
    return data;
}

export const validationSchemaCategoriesType = yup.object({
    name: yup
        .string("Ingresa nombre de CategoriesType")
        .required("Campo Requerido"),
    code: yup
        .string("Ingresa Codigo")
        .required("Campo Requerido"),

});
export const createCategoriesType = async (categoryType, dispatch) => {
    try {

        const { data } = await client.post("typeCategory", categoryType);
        dispatch(
            showMessage({
                message: "Categoria agregada con éxito",
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );

    } catch (error) {
        dispatch(
            showMessage({
                message:
                    "Ocurrió un error al agregar una categoria: ",
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );
    }
};

export const modifyCategoriesType = async (categoryType, dispatch) => {
    try {

        await client.put("typeCategory", categoryType);

        dispatch(
            showMessage({
                message: `Categoria "${categoryType.name}" modificada con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    } catch (error) {
        dispatch(
            showMessage({
                message:
                    `Ocurrió un error al modificar la categoria "${categoryType.name}"`,
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    }

};
