import SupplierTable from "./components/SupplierTable";

const Suppliers = () =>{

    return(
        <div>
            <SupplierTable />
        </div>
    )
}
export default Suppliers;