import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export const deleteProduct = (productoId) => async (dispatch) => {
  await client.delete(`productos/${productoId}`);
};
export async function getAllProducts() {
  const { data } = await client.get("/productos");
  return data;
}
export async function getAllTallasByProductoId(id) {
  const { data } = await client.get(`/productoTalla/filter/${id}`);
  return data;
}
export async function getAllCategorias() {
  const { data } = await client.get("/categorias");
  return data;
}
export async function getAllCategories() {
  const { data } = await client.get("/category");
  return data;
}
export const validationSchemaProduct = yup.object({
  nombre: yup
    .string("Ingresa nombre del producto")
    .required("Campo Requerido"),
  descripcion: yup
    .string("Ingresa descripción")
    .required("Campo Requerido"),
  sku: yup
    .string("Ingresa SKU")
    .required("Campo Requerido"),
  diseno: yup
    .string("Ingresa Diseno"),
  modelo: yup
    .string("Ingresa Modelo"),
  estampado: yup
    .string("Ingresa Estampado"),
  bordado: yup
    .string("Ingresa Bordado")
});
export const createProduct = async (producto, dispatch) => {
  try {

    const tallas = producto.tallas
    delete producto.tallas;
    const { data } = await client.post("productos", producto);
    if (data) {
      const productId = data.id;
      for (let i = 0; i < tallas.length; i++) {
        const talla = tallas[i];
        let obj = {
          producto: { id: productId },
          talla: { id: talla.id }
        }
        await client.post("productoTalla", obj);
      }
    }
    dispatch(
      showMessage({
        message: "Producto agregado con éxito",
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
    );

  } catch (error) {
    console.log(error)
    dispatch(
      showMessage({
        message:
          "Ocurrió un error al agregar un nuevo Producto: ",
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
    );
  }


};
export const modifyProduct = async (producto, dispatch, changed) => {
  try {
    let tallas = [...producto.tallas];
    delete producto.tallas;
    await client.put("productos", producto);

    let currentTallas = await getAllTallasByProductoId(producto.id);

if(changed){
  if (currentTallas.length > 0 ) {
    for (let i = 0; i < currentTallas.length; i++) {
      let id = currentTallas[i].id;
      await client.delete(`productoTalla/${id}`);
    }
  }
  for (let i = 0; i < tallas.length; i++) {
    let obj = {
      producto: { id: producto.id },
      talla: { id: tallas[i].id }
    }
    await client.post("productoTalla", obj);
  }
}
  

    dispatch(
      showMessage({
        message: "Producto modificado con éxito",
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  } catch (error) {
    console.log(error)
    dispatch(
      showMessage({
        message:
          "Ocurrió un error al agregar un nuevo producto",
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  }

};