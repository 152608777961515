import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createStampingOrder,
  dataTransform,
  getAllPrendaByProduct,
  getAllStampingOrders,
  modifyStampingOrder,
  transformSizes,
} from "./functions";
import moment from "moment";
import SizesTable from "./sizesTable";
import TablePlaceholder from "./tablePlaceholder";
import StampingOrderForm from "./StampingOrderForm";
import { showMessage } from "store/modules/Message";

const OrderCut = ({ mode, open, handleClose, defaultDataPedido }) => {
  const { defaultDataStamping, orderType } = useSelector(
    ({ stamping }) => stamping
  );
  const [image, setImage] = useState("");
  const { userName } = useSelector(({ session }) => session);
  const [data, setData] = useState(null);
  const [prenda, setPrenda] = useState(null);
  const [prendas, setPrendas] = useState(null);
  const [folio, setFolio] = useState(1);
  const [sizes, setSizes] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [model, setModel] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(
    defaultDataPedido?.fechaEntrega
  );
  const [comments, setComments] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(defaultDataPedido).length > 0) {
      setSizes(null);
      setImage("");
      const fetchData = async () => {
        const resp = await dataTransform(defaultDataPedido);
        setData(resp);
      };
      fetchData();
    }
  }, [defaultDataPedido]);

  useEffect(() => {
    if (defaultDataStamping && mode === "PUT") {
      setComments(defaultDataStamping.comments);
      setImage(defaultDataStamping.prenda.imagen);
      setSizes(defaultDataStamping.sizes);
      setDeliveryDate(defaultDataStamping.deliveryDate);
      setSupplier(defaultDataStamping.supplier);
      setModel(defaultDataStamping.producto);
      setPrenda(defaultDataStamping.prenda);
      setFolio(defaultDataStamping.folio);
    } else {
      setComments("");
      setImage(null);
      setSizes(null);
      setDeliveryDate(
        moment(defaultDataPedido?.fechaEntrega).format("YYYY-MM-DD")
      );
      setSupplier({
        id: 1,
        persona: { nombre: "", apellidoPaterno: "", apellidoMaterno: "" },
      });
      setModel(null);
      setPrenda(null);
    }
  }, [mode, defaultDataStamping]);

  const handleChangeProduct = async (e, option) => {
    if (!option) return;
    const percentage = data.percentage;
    const _sizes = transformSizes(option.tallas, percentage);
    setSizes(_sizes);
    setPrendas(option.prenda);
    setModel(option);
  };
  const handleChangePrenda = async (e, option) => {
    if (!option) return;
    setPrenda(option);
    setImage(option.imagen);
  };
  const handleChangeDate = (value) => {
    setDeliveryDate(moment(value).format("YYYY-MM-DD"));
  };

  const handleSave = async () => {
    let obj = {
      comments: comments,
      type: orderType,
      folio,
      producto: model,
      deliveryDate,
      supplier,
      prenda,
      sizes,
      createdByUser: userName,
      createdDate: moment().format("YYYY-MM-DD"),
      pedidoId: defaultDataPedido.id,
    };
    switch (mode) {
      case "POST":
        await createStampingOrder(obj);
        dispatch(
          showMessage({
            variant: "success",
            message: "La orden se creo satisfactoriamente",
          })
        );
        break;
      case "PUT":
        obj.id = defaultDataStamping.id;
        obj.modifiedByUser = userName;
        obj.modifiedDate = moment().format("YYYY-MM-DD");
        await modifyStampingOrder(obj);
        dispatch(
          showMessage({
            variant: "success",
            message: "La orden se modifico satisfactoriamente",
          })
        );
        break;
      default:
        break;
    }
    setSizes(null);
    handleClose();
  };
  if (!data) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth>
      <div>
        <DialogTitle>
          <Typography variant="h4">{`Orden de ${orderType}`}</Typography>
        </DialogTitle>
        <DialogContent>
          <StampingOrderForm
            comments={comments}
            setComments={setComments}
            image={image}
            folio={folio}
            setFolio={setFolio}
            mode={mode}
            prendas={prendas}
            handleChangeDate={handleChangeDate}
            handleChangeProduct={handleChangeProduct}
            setPrenda={setPrenda}
            setSupplier={setSupplier}
            data={data}
            defaultDataStamping={defaultDataStamping}
            deliveryDate={defaultDataPedido?.fechaEntrega}
            handleChangePrenda={handleChangePrenda}
          />
          {sizes ? (
            <SizesTable sizes={sizes} setSizes={setSizes} />
          ) : (
            <TablePlaceholder style={{ paddingTop: 5 }} />
          )}
        </DialogContent>
      </div>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => (handleClose(), setSizes(null))}>
          Cancelar
        </Button>
        <Button
          disabled={!model || !prenda}
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OrderCut;
