import { useEffect, useState } from "react";
import { convertMonth } from "components-base/salidas/functions";
import Banner from "./components/Banner";
import HistoryCard from "./components/HistoryCard";
import PedidosTable from "./components/PedidosTable";
import { getAllPedidos } from "./functions";
import { months } from "utils/apis";
import TabComponent from "components-base/tab/Tab";

const Pedidos = () => {
  const [pedidos, setPedidos] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [refreshP, setRefreshP] = useState(false);

  useEffect(() => {
    const fetchDataPedidos = async () => {
      let pedidos = await getAllPedidos();
      let _pedidos = await convertMonth(pedidos);
      setPedidos(_pedidos);
    };
    fetchDataPedidos();
  }, [refreshP]);

  useEffect(() => {
    if (pedidos) {
      let tabsArray = months.map((month) => {
        return {
          label: month,
          component: (
            <PedidosTable
              setRefresh={setRefreshP}
              refresh={refreshP}
              key={month}
              data={pedidos.filter((item) => item.monthName === month)}
            />
          ),
        };
      });
      setTabs(tabsArray);
    }
  }, [pedidos]);

  return (
    <div>
      <TabComponent tabsElement={tabs} />
    </div>
  );
};

export default Pedidos;
