import Card from "components/card";
import { DataGrid } from "@mui/x-data-grid";
import ProductDrawer from "./ProductDrawer";
import React, { useState, useEffect } from "react";
import { getAllProducts } from "../functions";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import ProductDelete from "./ProductDelete";
import { columns } from "../columns";
import ProductSummaryModalPDF from "./ProductSummary/ProductSummaryModal";
import SpecificationSheetMainModal from "./SpecificationSheet/SpecificationSheetMainModal";

const ProductTable = () => {
  const [open, setOpen] = useState(false);
  const [openSS, setOpenSS] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [productData, setProductData] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      let products = await getAllProducts();
      setProductData(products);
      let col = columns(
        setOpen,
        setMode,
        setOpenDelete,
        dispatch,
        setOpenPDF,
        setOpenSS
      );
      setDataColumns(col);
    };
    fetchData();
  }, [open, openDelete, dispatch]);

  const handleClosePDF = () => {
    setOpenPDF(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSS = () => {
    setOpenSS(false);
  };

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <SpecificationSheetMainModal open={openSS} handleClose={handleCloseSS} />
      <ProductSummaryModalPDF open={openPDF} handleClose={handleClosePDF} />
      <ProductDelete openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <ProductDrawer open={open} handleClose={handleClose} mode={mode} />
      <Card extra={"w-full h-full p-5"}>
        <div className="relative flex items-center justify-end">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {/* <ProductMenu setOpen={setOpen} setMode={setMode} /> */}
            <button
              onClick={() => {
                setOpen(true);
                setMode("POST");
              }}
              className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              Agregar Nuevo Producto
            </button>
          </div>
        </div>
        {productData ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={productData || []}
            columns={dataColumns || []}
            autoPageSize
            rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
            checkboxSelection
            disableSelectionOnClick
            getRowHeight={() => "auto"}
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};

export default ProductTable;
