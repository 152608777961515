import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import SupervisorFormFields from "./SupervisorFormFields";
import TabComponent from "components-base/tab/Tab";
import { createSupervisor,modifySupervisor, validationSchemaSupervisor } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const SupervisorForm = ({ mode, handleClose }) => {
    const {defaultDataSupervisor} = useSelector(({supervisor})=>supervisor);
    const dispatch = useDispatch();
    const messages = (mensaje) =>{
        dispatch(
            showMessage({
              message: `Supervisor ${mensaje} con éxito`,
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            })
          );

    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    codigo: "",
                    nombre: "",
                    apellidoPaterno: "",
                    apellidoMaterno: "",
                    direccion: "",
                    rfc: "",
                }
                : {
                    id: defaultDataSupervisor.id,
                    codigo: defaultDataSupervisor.codigo,
                    nombre: defaultDataSupervisor.persona.nombre,
                    apellidoPaterno: defaultDataSupervisor.persona.apellidoPaterno,
                    apellidoMaterno: defaultDataSupervisor.persona.apellidoMaterno,
                    direccion: defaultDataSupervisor.persona.direccion,
                    rfc: defaultDataSupervisor.persona.rfc,
                    persona: {id :defaultDataSupervisor.persona.id}

                },
        validationSchema: validationSchemaSupervisor,
        onSubmit: async (values) => {
            switch(mode){
                case "POST":
                  const res = await createSupervisor(values);
                    messages(res ? "creado" : "fallo")
                    handleClose();
                break;
                case "PUT":
                  await modifySupervisor(values);
                    messages("modificado");
                    handleClose();
                break;
                default:handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información Supervisor",
                    component: <SupervisorFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item  >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item >
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default SupervisorForm;