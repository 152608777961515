import { Typography, CircularProgress, Card, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "./columns";
import { useEffect } from "react";
import { useState } from "react";
import { boxSize } from "utils/apis";
import { useDispatch } from "react-redux";
import SpecificationSheet from "./SpecificationSheet";
import DeleteSpecificationSheet from "./DeleteSpecificationSheet";
import { getAllSpecificationSheetsByProductoId } from "./functions";
import { setDefaultDataSS } from "store/modules/SpecificationSheet";
import ModalSpecificationPDF from "./ModalCutPDF";

const SpecificationSheetTable = ({ producto }) => {
    const [data, setData] = useState(null);
    const [_columns, setColumns] = useState(null);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [mode, setMode] = useState("POST");
    const [openPDF, setOpenPDF] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (Object.keys(producto).length > 0) {
            const fetchData = async () => {
                const obj = await getAllSpecificationSheetsByProductoId(producto.id);
                console.log(obj)
                setData(obj);
                let col = columns(setOpen, setMode, setOpenDelete, dispatch, setOpenPDF);
                setColumns(col)
            }
            fetchData();
        }
    }, [open, openDelete, producto]);

    const handleClose = () => {
        setOpen(false)
    }
    const handleClosePDF = () => {
        setOpenPDF(false);
    };
    const setModeCreate = () => {
       dispatch(setDefaultDataSS(null));
        setMode("POST");
        setOpen(true);
    }
    return (
        <Box sx={{ height: boxSize, justifyContent: "center" }}>
            <ModalSpecificationPDF open={openPDF} handleClose={handleClosePDF} />
            <SpecificationSheet mode={mode} open={open} handleClose={handleClose} producto={producto} />
            <DeleteSpecificationSheet openDelete={openDelete} setOpenDelete={setOpenDelete} />
            <Card extra={"w-full h-full p-5"}
            >
                <div className="flex items-center justify-start rounded-t-3xl p-3">
                    <Typography variant="h4"
                        className="px-4 py-2 text-base  text-brand-500 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        {`Hoja de Especificaciones`}
                    </Typography>
                </div>
                <div className="flex items-center justify-end rounded-t-3xl p-3">
                    <button
                        onClick={setModeCreate}
                        className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        Crear Nueva Hoja
                    </button>
                </div>
                {data ? (
                    <DataGrid
                        className="mt-8 h-max w-full dark:text-white"
                        rows={data || []}
                        columns={_columns || []}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 15, 20, 30]}
                        checkboxSelection
                        disableSelectionOnClick
                        autoHeight
                        getRowHeight={() => 'auto'}

                    />
                ) : (
                    <div className="flex justify-center pt-56">
                        <CircularProgress color="primary" />
                        <Typography>Cargando datos...</Typography>
                    </div>
                )}
            </Card>
        </Box>

    )
}
export default SpecificationSheetTable;