import Card from "components/card";
import BarChart from "components/charts/BarChart";
import {
  barChartDataWeeklyRevenue,
  barChartOptionsWeeklyRevenue,
} from "variables/charts";
import { MdBarChart } from "react-icons/md";
import { useEffect, useState } from "react";
import { dataTransform } from "../functions";

const WeeklyRevenue = ({data}) => {
if(!data) return "Cargando datos"
  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
      <div className="md:mt-16 lg:mt-0">
        <div className="h-[650px] w-full xl:h-[650px]">
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
          <BarChart
            chartData={data.barChartData}
            chartOptions={data.barChartOptions}
          />
        </div>
      </div>
    </Card>
  );
};

export default WeeklyRevenue;
