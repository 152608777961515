import Card from "components/card";
import StackTelasMenu from "./StackTelasMenu";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../columns";
import StackTelasDrawer from "./StackTelasDrawer";
import React, { useState, useEffect } from "react";
import { getAllRolls } from "../functions";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import StackTelasDelete from "./StackTelasDelete";
import PrintLabelModal from "./print-label-modal";

const StackTelasTable = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [mode, setMode] = useState("POST");
  const [rollsData, setRollsData] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const [dataDefault, setDataDefault] = useState(null);
  const dispatch = useDispatch();

  const handleClosePdf = () => {
    setOpenPdf(false);
    setDataDefault(null);
  };

  const handleOpenPdf = () => {
    setOpenPdf(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      let rolls = await getAllRolls();
      setRollsData(rolls);
    };
    fetchData();
    let col = columns(
      setOpen,
      setMode,
      setOpenDelete,
      dispatch,
      handleOpenPdf,
      handleClosePdf,
      setDataDefault
    );
    setDataColumns(col);
  }, [open, openDelete, dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <StackTelasDelete openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <StackTelasDrawer open={open} handleClose={handleClose} mode={mode} />
      {dataDefault !== null ? (
        <PrintLabelModal
          open={openPdf}
          handleClose={handleClosePdf}
          data={[dataDefault]}
        />
      ) : null}
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-end">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            <StackTelasMenu setOpen={setOpen} setMode={setMode} />
          </div>
        </div>
        {rollsData ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={rollsData || []}
            columns={dataColumns || []}
            pageSize={15}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};
export default StackTelasTable;
