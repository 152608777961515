import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import Card from "components/card";

export const ProductoDetail = ({
  pedidosData,
  openDetail,
  handleCloseDetail,
}) => {
  const { idDetalle } = useSelector(({ pedido }) => pedido);

  if (!pedidosData || !idDetalle) return null;

  const filteredData = pedidosData.filter((item) => item.id === idDetalle);
  const producto = filteredData[0];
  if (!producto) return null;
  return (
    <>
      <Dialog open={openDetail} handleClose={handleCloseDetail} maxWidth={"md"}>
        <DialogContent>
          <Card extra={"w-full h-full p-3"}>
            {/* Header */}
            <div style={{ textAlign: "center" }}>
              <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                {`Detalles de ${producto.producto.nombre}`}
              </h4>
              <div className="flex h-60 w-60 text-center">
                <img
                  className="h-full w-full rounded-xl text-center"
                  src={producto.producto.imagen}
                  alt="foto"
                />
              </div>
              <p className="mt-2 px-2 text-base text-gray-600">
                {producto.producto.descripcion}
              </p>
            </div>
            {/* Cards */}
            <div className="grid grid-cols-2 gap-4 px-2">
              <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Nombre del producto:</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {producto.producto.nombre}
                </p>
              </div>

              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">SKU</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {producto.producto.sku}
                </p>
              </div>

              <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Modelo</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {producto.producto.modelo}
                </p>
              </div>

              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Diseno</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {producto.producto.diseno}
                </p>
              </div>

              <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Bordado</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {`${producto.producto.bordado ? "Si" : "No"}`}
                </p>
              </div>

              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Estampado</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {`${producto.producto.estampado ? "Si" : "No"}`}
                </p>
              </div>
            </div>
          </Card>
          <Grid item xs={12}>
            <Typography variant="h4">{"Prendas"}</Typography>

            <div className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] dark:!bg-navy-800 dark:shadow-none">
              {producto.producto.prenda.map((item, index) => (
                <div className="flex items-center gap-3">
                  <div className="flex h-20 w-20 items-center justify-center">
                    {index + 1}
                  </div>
                  <div className="flex h-20 w-20 items-center justify-center">
                    <img
                      className="h-full w-full rounded-xl"
                      src={item.imagen}
                      alt="foto"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-md mt-1 font-normal text-gray-700">{`Nombre: ${item.nombre}`}</p>
                    <p className="text-md mt-1 font-normal text-gray-700">{`Descripcion: ${item.descripcion}`}</p>
                    <p className="text-md mt-1 font-normal text-gray-700">{`Sku: ${producto.producto.sku}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDetail}
            variant="contained"
            color="primary"
          >
            {"Cerrar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
