import {
    doc,
    addDoc,
    collection,
    query,
    getDocs,
    deleteDoc,
    updateDoc,
    
  } from "firebase/firestore/lite";
import { db } from "./firebase";

//////********CRUD MAQUILERO **********//////////
export const CreateMaquilero = async (data) =>{
    await addDoc(collection(db, "maquilero"), data);
}

export const GetAllMaquileros = async () =>{
    const maquileros = collection(db, "maquilero");
    const q = query(maquileros);
    const snapShot = await getDocs(q);
    let data =[]
snapShot.forEach((doc) => {
    let obj = doc.data();
       obj.id = doc.id
     data = [...data,obj];
    });
return data
}
export const DeleteMaquilero = async (id) =>{
    await deleteDoc(doc(db, "maquilero", id));
}
export const ModifyMaquilero = async (data)=>{
    await updateDoc(doc(db, "maquilero", data.id), 
    {
    activo : data.activo,
    nombre: data.nombre,
    apellidoMaterno:data.apellidoMaterno,
    apellidoPaterno: data.apellidoPaterno,
    }
      );
}
//////******** FIN CRUD MAQUILERO **********//////////

//////***********CRUD CHOFER **********************///////
export const CreateChofer = async (data) =>{
    await addDoc(collection(db, "choferes"), data);
}

export const GetAllChoferes = async () =>{
    const maquileros = collection(db, "choferes");
    const q = query(maquileros);
    const snapShot = await getDocs(q);
    let data =[]
snapShot.forEach((doc) => {
    let obj = doc.data();
       obj.id = doc.id
     data = [...data,obj];
    });
return data
}
export const DeleteChofer = async (id) =>{
    await deleteDoc(doc(db, "choferes", id));
}
export const ModifyChofer = async (data)=>{
    await updateDoc(doc(db, "choferes", data.id), 
    {
    activo : data.activo,
    nombre: data.nombre,
    apellidoMaterno:data.apellidoMaterno,
    apellidoPaterno: data.apellidoPaterno
    }
      );
}
//////******** FIN CRUD CHOFER **********//////////

export const GetAllEntity = async (entity, param1, param2) => {
    try {
        const ref = collection(db, entity);
        let q;
        if (param1)
            q = query(ref, param1);
        if (param2)
            q = query(ref, param2);
        if (param1 && param2)
            q = query(ref, param1, param2);
        if (!param1 && !param2)
            q = query(ref);
        const snapShot = await getDocs(q);
        let data = []
        snapShot.forEach((doc) => {
            let obj = doc.data();
            obj.id = doc.id
            data = [...data, obj];
        });
        return data;
    } catch (error) {
        console.log(error)
        return [];
    }

}