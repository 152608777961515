import {
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { boxSize } from "utils/apis";
const OperadoresFormFields = ({ formik, setFieldValue }) => {


  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="codigo"
            name="codigo"
            label="Código"
            value={formik.values.codigo}
            onChange={formik.handleChange}
            error={
              formik.touched.codigo && Boolean(formik.errors.codigo)
            }
            helperText={formik.touched.codigo && formik.errors.codigo}
            color="primary"
          />
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={
              formik.touched.nombre && Boolean(formik.errors.nombre)
            }
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />
          <TextField
            fullWidth
            id="apellidoPaterno"
            name="apellidoPaterno"
            label="Apellido Paterno"
            value={formik.values.apellidoPaterno}
            onChange={formik.handleChange}
            error={
              formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)
            }
            helperText={formik.touched.apellidoPaterno && formik.errors.apellidoPaterno}
            color="primary"
          />
          <TextField
            fullWidth
            id="apellidoMaterno"
            name="apellidoMaterno"
            label="Apellido Materno"
            value={formik.values.apellidoMaterno}
            onChange={formik.handleChange}
            error={
              formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)
            }
            helperText={formik.touched.apellidoMaterno && formik.errors.apellidoMaterno}
            color="primary"
          />
          <TextField
            fullWidth
            id="direccion"
            name="direccion"
            label="Dirección"
            value={formik.values.direccion}
            onChange={formik.handleChange}
            error={
              formik.touched.direccion &&
              Boolean(formik.errors.direccion)
            }
            helperText={
              formik.touched.direccion &&
              formik.errors.direccion
            }
            color="primary"
          />

        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="rfc"
            name="rfc"
            label="RFC"
            value={formik.values.rfc}
            onChange={formik.handleChange}
            error={
              formik.touched.rfc && Boolean(formik.errors.rfc)
            }
            helperText={formik.touched.rfc && formik.errors.rfc}
            color="primary"
          />   
        </Grid>
      </Grid>
    </Box>

  )
}
export default OperadoresFormFields;