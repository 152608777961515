import React from "react";
import Card from "components/card";
import { useState } from "react";
import InsumosModal from "./InsumosModal";
import { useEffect } from "react";
import { deletePrendaInsumo, getAllPrendaInsumo, getAllPrendaInsumoById } from "../functions";
import { imagen_insumos } from "utils/apis";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";


const InsumosPrenda = ({ mode, prendaId, setPrendaInsumo, prendaInsumo }) => {
  const [tallas, setTallas] = useState(null);
  const [open, setOpen] = useState(false);
  const [dataPI, setDataPI] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editData,setEditData]= useState(null);
  const [editMode, setEditMode] = useState("create")
  const openModal = () => {
    setEditMode("create")
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }
  useEffect(() => {
    if (mode === "PUT") {
      setLoading(true);
      const fetchData = async () => {
      //  const testi = await getAllPrendaInsumoById(Number(prendaId));
       const prendaInsumo = await getAllPrendaInsumo();
        setDataPI(prendaInsumo.filter(item=> item.prendaId === prendaId));
        setLoading(false);
      }
      fetchData();
    }
  }, [open, refresh, mode, prendaId]);

  const deleteFnc = async (data, index) => {
    if (mode === "POST") {
      prendaInsumo.splice(index, 1);
      setPrendaInsumo(prendaInsumo);
      setDataPI(prendaInsumo);
      return
    }
    await deletePrendaInsumo(data.id)
    setRefresh(!refresh)
  }
  const handleEdit = (item) =>{
    setTallas(null);
    setEditMode("edit");
    setEditData(item)
    setOpen(true);
  }
  return (
    <>
      <InsumosModal tallas={tallas} setTallas={setTallas} editMode={editMode} editData= {editData} open={open} handleClose={handleClose} mode={mode} setPrendaInsumo={setPrendaInsumo} setDataPI={setDataPI} />
      <Card extra={"mt-3 !z-5 overflow-hidden"} style={{ width: 500 }}>
        <div className="flex items-center justify-between rounded-t-3xl p-3">
          <button onClick={openModal} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            Agregar
          </button>
        </div>
        {loading && (<h3 className="p-10 text-base font-bold text-navy-700 dark:text-white">{"Cargando........"}</h3>)}
        {!loading && dataPI && dataPI.length ===  0 && (<h2 className="p-10 text-base font-bold text-navy-700 dark:text-white">{"No has agregado insumos aun"}</h2>) }
        {dataPI && dataPI.map((data, index) => (
          <div key={index} className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
            <div className="flex items-center gap-3">
              <div className="flex h-12 w-12 items-center justify-center">
                <h3>
                  {`${index + 1}. `}
                </h3>
              </div>
              <div className="flex h-12 w-12 items-center justify-center">
                <img
                  className="h-full w-full rounded-xl"
                  src={imagen_insumos}
                  alt=""
                />
              </div>
              <div className="flex flex-col">
                <h5 className="text-base font-bold text-navy-700 dark:text-white">
                  {" "}
                  <p>{`Insumo : ${data.insumo.nombre}`}</p>
                  <p>{`Código : ${data.insumo.codigo}`}</p>
                </h5>
                <p className="mt-1 text-sm font-normal text-gray-600">
                  {" "}
                  {`CANTIDAD: ${data.cantidad}`}{" "}
                </p>
                <p className="mt-1 text-sm font-normal text-gray-600">{`UNIDAD: ${data.unidad}`}</p>
              </div>
              <div className="flex justify-end pl-20">

                <IconButton size="xl" color="primary" onClick={() =>handleEdit(data)}>
                  <Edit />
                </IconButton>
                <IconButton size="xl" color="primary" onClick={() => deleteFnc(data, index)}>
                  <Delete />
                </IconButton>
            
              </div>
            </div>
          </div>
        ))}
      </Card>
    </>
  );
};

export default InsumosPrenda;
