import {
  Grid,
  TextField,
  Box, Autocomplete
} from "@mui/material";
import { getAllMaquilero } from "components-base/maquileros/functions";
import { getAllOperadores } from "components-base/operadores/functions";
import { boxSize } from "utils/apis";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";


const PickupsFormFields = ({ formik, setFieldValue, mode }) => {
  const { defaultDataPickups } = useSelector(({ pickups }) => pickups);

  const [drivers, setDrivers] = useState(null);

  useEffect(() => {
    const fetchData = async () => {

      let _drivers = await getAllOperadores();
      setDrivers(_drivers);

    };
    fetchData();
  }, []);




  const handleChangeMaquilero = (e, value) => {
    setFieldValue("maquilero", value || "");
  };
  return (
    <Box sx={{ height: boxSize }} >

      <Grid container spacing={2} className="flex justify-start" >
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="folio"
            name="folio"
            label="Folio"
            value={formik.values.folio}
            onChange={formik.handleChange}
            error={
              formik.touched.folio && Boolean(formik.errors.folio)
            }
            helperText={formik.touched.folio && formik.errors.folio}
            color="primary"
          />

          <Autocomplete

            name="chofer"
            options={drivers || []}
            isOptionEqualToValue={(option, value) =>
              Number(option.id) === Number(value.id)
            }
            id="chofer"
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Operador" />
            )}
            getOptionLabel={(operador) =>
              `${operador.persona.nombre} ${operador.persona.apellidoPaterno} ${operador.persona.apellidoMaterno}`
            }
            onChange={(e, value) => setFieldValue("driver", value || "")}
            defaultValue={defaultDataPickups.driver}
          />
          <TextField
            fullWidth
            id="quantity"
            name="quantity"
            label="Cantidad"
            type="number"
            value={formik.values.quantity}
            onChange={formik.handleChange}
            error={
              formik.touched.quantity &&
              Boolean(formik.errors.quantity)
            }
            helperText={
              formik.touched.quantity &&
              formik.errors.quantity
            }
            color="primary"
          />
          <TextField
            multiline
            rows={5}
            fullWidth
            id="comments"
            name="comments"
            label="Comentarios"
            value={formik.values.comments}
            onChange={formik.handleChange}
            error={
              formik.touched.comments &&
              Boolean(formik.errors.comments)
            }
            helperText={
              formik.touched.comments &&
              formik.errors.comments
            }
            color="primary"
          />
        </Grid>
      </Grid>



    </Box>

  )
}
export default PickupsFormFields;