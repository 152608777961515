import Dropdown from "components/dropdown";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useEffect } from "react";
import { useState } from "react";
import { getAllNotifications } from "./functions";

import { NotificationImportant } from "@mui/icons-material";
import { Badge } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
const Notification = () => {
  const [notifications, setNotifications] = useState(null);
  const [reload, setReload] = useState(true);
  const [badgeCounter, setBadgeCounter] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (reload) {
      const fetchData = async () => {
        const _notifications = await getAllNotifications();
        setNotifications(_notifications);
      };
      fetchData();
    }
    return () => {
      setTimeout(() => {
        setReload(false);
        setBadgeCounter(false);
      }, 3000);
    };
  }, [reload]);

  useEffect(() => {
    if (notifications) {
      const interval = setInterval(() => {
        const fetchData = async () => {
          if (notifications) {
            const _notifications = await getAllNotifications();
            if (_notifications && _notifications.length > 0) {
              if (
                !notifications[0] ||
                notifications[0].id !== _notifications[0].id
              ) {
                let notificationSound = new Audio(
                  "https://firebasestorage.googleapis.com/v0/b/ebs-project-21967.appspot.com/o/notification-tone-swift-gesture.mp3?alt=media&token=6799f788-858d-46a2-b168-24e5dc42ca6f"
                );
                notificationSound.play();
                setReload(true);
                setBadgeCounter(true);
                setCounter(1);
                clearInterval(interval);
              }
            }
          }
        };

        fetchData();
        setReload(false);
      }, 60000);
    }
  }, [notifications]);

  if (!notifications) return "Cargando......";

  return (
    <>
      <Dropdown
        button={
          <p className="cursor-pointer">
            <Badge badgeContent={counter} color="primary">
              {badgeCounter ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert variant="filled" severity="success">
                    Hay una nueva notificacion
                  </Alert>
                </Stack>
              ) : (
                <IoMdNotificationsOutline
                  onClick={() => setCounter(0)}
                  className="h-6 w-6 text-gray-600 dark:text-white"
                />
              )}
            </Badge>
          </p>
        }
        animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        children={
          <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
            <div className="flex items-center justify-between">
              <p className="text-base font-bold text-navy-700 dark:text-white">
                Notificaciones
              </p>
            </div>
            {notifications.map((event) => (
              <div key={event.id}>
                <button className="flex w-full items-center border-b">
                  <NotificationImportant className="h-6 w-6 text-gray-600 dark:text-white" />
                  <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm p-2">
                    <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                      {`El pedido ${event.code} cambio a ${event.status.nombre}`}
                    </p>
                    <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                      {event.fechaInicio}
                    </p>
                    <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                      {"Modificado por:"}{" "}
                      {event.modifiedByUser ? event.modifiedByUser : "Admin"}
                    </p>
                  </div>
                </button>
              </div>
            ))}
          </div>
        }
        classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
      />
    </>
  );
};
export default Notification;
