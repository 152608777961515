import { useThemeContext } from "../theme-context";
import { darkTheme, lightTheme } from "../theme-context/themes";
import FormControlLabel from '@mui/material/FormControlLabel';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Switch } from "@mui/material";
import { useEffect } from "react";
import Cookies from "js-cookie";

const ThemeSelector = () => {
  const { theme, setTheme } = useThemeContext();

  useEffect(() => {
    let _theme = Cookies.get("theme");
    if (_theme) {
      if (_theme === "dark") {
        setTheme(darkTheme);
        document.body.classList.add("dark");
      } else {
        setTheme(lightTheme);
        document.body.classList.remove("dark");
      }
    }
  }, []);
  const handleThemeChange = (event) => {

    setTheme(event.target.checked ? darkTheme : lightTheme);
    Cookies.set("theme", event.target.checked ? "dark" : "light");
    if (!event.target.checked)
      document.body.classList.remove("dark");
    else
      document.body.classList.add("dark");
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={theme.palette.mode === 'dark'}
          onChange={handleThemeChange}
          icon={<LightModeIcon />}
          checkedIcon={<DarkModeIcon />}
        />
      }
      label=""
    />
  );
}
export default ThemeSelector;