import { Edit, Delete, Warning } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography, Grid } from "@mui/material";
import {
  setInputsId,
  setDefaultData,
  setDefaultDataDetail,
} from "store/modules/Inputs";
import { v4 as uuidv4 } from "uuid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { ContactsRounded, PrintSharp } from "@mui/icons-material";

export const columns = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  handleOpenPDF,
  handleClosePdf,
  setDataId
) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 80,
      renderCell: (data) => (
        <React.Fragment>
          {/* <Tooltip title="Editar Entrada" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultData(data.row));
                dispatch(setInputsId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Eliminar Entrada" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setInputsId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir Etiquetas" placement="top">
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                handleOpenPDF();
                setDataId(data.row.id);
              }}
            >
              <PrintSharp />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "category",
      hideable: true,
      headerName: "Categoria",
      width: 100,
      renderCell: (data) => data.row.category.name,
    },
    // {
    //   field: "details",
    //   hideable: true,
    //   headerName: "Telas",
    //   width: 200,
    //   renderCell: (data) => {
    //     if (!data.row.details || !data.row.details.data) {
    //       return null; // o proporcionar un valor predeterminado si es necesario
    //     }

    //     let details = data.row.details.data;

    //     return (
    //       <div>
    //         <Grid container spacing={0} flexDirection={"column"}>
    //           {details.map((item) => (
    //             <Grid key={item.telas.id} item>
    //               <Typography variant="caption">
    //                 {`Nombre: ${item.telas.name}`}
    //               </Typography>
    //             </Grid>
    //           ))}
    //         </Grid>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "date",
      headerName: "Fecha Entrada",
      width: 150,
      renderCell: (data) => <DatePickerComponent data={data.row.date} />,
    },
    {
      field: "description",
      headerName: "Descripción",
      width: 250,
    },
    {
      field: "amount",
      headerName: "Cantidad",
      width: 120,
    },
    {
      field: "meters",
      headerName: "Metros",
      width: 80,
    },
    {
      field: "kg",
      headerName: "Kg",
      width: 80,
    },
    {
      field: "code",
      headerName: "Codigo",
      width: 120,
    },
    {
      field: "supplier",
      headerName: "Proveedor",
      width: 120,
      renderCell: (data) => (
        <Typography>{`${data?.row?.supplier?.persona?.nombre}`}</Typography>
      ),
    },
  ];
};

const DatePickerComponent = ({ data }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
      <DatePicker
        value={moment(data)}
        closeOnSelect
        sx={{ width: "100%" }}
        slotProps={{ textField: { variant: "standard" } }}
        showDaysOutsideCurrentMonth
        disableHighlightToday
      />
    </LocalizationProvider>
  );
};
