import {
  Grid,
  TextField,
  Box,
  FormControlLabel, Checkbox, Autocomplete
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect } from "react";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { getAllPosition } from "components-base/positions/functions";
import { getAllDepartment } from "components-base/departments/functions";
import { generateCode } from "utils/apis";
import WorkerImage from "./WorkerImage";
const WorkersFormFields = ({ formik, setFieldValue, mode }) => {
  const [departments, setDepartments] = useState(null)
  const [positions, setPositions] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      let positions = await getAllPosition();
      let departments = await getAllDepartment();
      setDepartments(departments);
      setPositions(positions);
      if (mode === "POST") {
        let code = await generateCode("worker");
        setFieldValue("code", code)
      }
    }
    fetchData();
  }, [])

  const handleChangeDate = (value, name) => {
    setFieldValue(name, moment(value).format("YYYY-MM-DD"));
  };

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2} flexDirection={"row"}>
            <Grid item xs={5}>
             <WorkerImage  url={formik.values.photo} setFieldValue={setFieldValue} />
            </Grid>
            <Grid item xs={7}>
              <Grid container flexDirection={"row"} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="code"
                    name="code"
                    label="Código"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.code && Boolean(formik.errors.code)
                    }
                    helperText={formik.touched.code && formik.errors.code}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nombre && Boolean(formik.errors.nombre)
                    }
                    helperText={formik.touched.nombre && formik.errors.nombre}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="apellidoPaterno"
                    name="apellidoPaterno"
                    label="Apellido Paterno"
                    value={formik.values.apellidoPaterno}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)
                    }
                    helperText={formik.touched.apellidoPaterno && formik.errors.apellidoPaterno}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="apellidoMaterno"
                    name="apellidoMaterno"
                    label="Apellido Materno"
                    value={formik.values.apellidoMaterno}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)
                    }
                    helperText={formik.touched.apellidoMaterno && formik.errors.apellidoMaterno}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <TextField
            fullWidth
            id="rfc"
            name="rfc"
            label="RFC"
            value={formik.values.rfc}
            onChange={formik.handleChange}
            error={
              formik.touched.rfc && Boolean(formik.errors.rfc)
            }
            helperText={formik.touched.rfc && formik.errors.rfc}
            color="primary"
          />
          <TextField
            fullWidth
            multiline
            rows={3}
            id="direccion"
            name="direccion"
            label="Dirección"
            value={formik.values.direccion}
            onChange={formik.handleChange}
            error={
              formik.touched.direccion &&
              Boolean(formik.errors.direccion)
            }
            helperText={
              formik.touched.direccion &&
              formik.errors.direccion
            }
            color="primary"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="curp"
            name="curp"
            label="CURP"
            value={formik.values.curp}
            onChange={formik.handleChange}
            error={
              formik.touched.curp && Boolean(formik.errors.curp)
            }
            helperText={formik.touched.curp && formik.errors.curp}
            color="primary"
          />
          <TextField
            fullWidth
            id="wage"
            name="wage"
            label="Sueldo"
            type="number"
            value={formik.values.wage}
            onChange={formik.handleChange}
            error={
              formik.touched.wage && Boolean(formik.errors.wage)
            }
            helperText={formik.touched.wage && formik.errors.wage}
            color="primary"
          />
          <Autocomplete
            name="position"
            options={positions || []}
            isOptionEqualToValue={(option, value) => Number(option.id) === Number(value.id)}
            id="position"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Posicion" />}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(e, o) => setFieldValue("position", o)}
            defaultValue={formik.values.position}
          />
          <Autocomplete
            name="department-combo"
            options={departments || []}
            isOptionEqualToValue={(option, value) => Number(option.id) === Number(value.id)}
            id="department"
            fullWidth
            renderInput={(params) => 
            <TextField
            name="department"
              error={
                formik.touched.department && Boolean(formik.errors.department)
              }
              helperText={formik.touched.department && formik.errors.department}
              {...params} label="Departamento/Area" />}
            getOptionLabel={(dept) => `${dept.name}`}
            onChange={(e, o) => setFieldValue("department", o)}

            defaultValue={formik.values.department}
          />
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es-mx"
          >
            <DatePicker
              name={"employmentDate"}
              label={"Fecha inicio"}
              value={moment(formik.values.employmentDate) || moment()}
              closeOnSelect
              sx={{ width: "100%" }}
              slotProps={{ textField: { variant: "outlined" } }}
              showDaysOutsideCurrentMonth
              disableHighlightToday
              onChange={(value) =>
                handleChangeDate(value, "employmentDate")
              }
            />
          </LocalizationProvider>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es-mx"
          >
            <DatePicker
              name={"terminationDate"}
              label={"Fecha salida"}
              value={formik.values.terminationDate ? moment(formik.values.terminationDate) : null}
              closeOnSelect
              sx={{ width: "100%" }}
              slotProps={{ textField: { variant: "outlined" } }}
              showDaysOutsideCurrentMonth
              disableHighlightToday
              onChange={(value) =>
                handleChangeDate(value, "terminationDate")
              }
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  )
}
export default WorkersFormFields;