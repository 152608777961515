import {  Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ClothConsumptionList from "./ClothConsumptionList";

const ClothListModal = ({ open, handleClose }) => {

    const { defaultData } = useSelector(({ prenda }) => prenda);
    return (
    <>
            <Dialog
            open={open}
            onClose={handleClose}
            style={{ padding: 10 }}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}>
                <IconButton onClick={handleClose} sx={{ color: "purple" }}>
                    <Cancel />
                </IconButton>
            </DialogTitle>
            <DialogTitle>
            </DialogTitle>
            <DialogContent>
                <ClothConsumptionList defaultData = {defaultData}/>
            </DialogContent>
        </Dialog>

    </>


    )
}
export default ClothListModal;