import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import { useSelector } from "react-redux";
import OrdenEmboideryPDF from "./OrdenEmboideryPDF";

const ModalEmbroideryPDF = ({ open, handleClose }) => {
  const { defaultDataEmbroidery } = useSelector(({ embroidery }) => embroidery);
  if (!defaultDataEmbroidery) return null;
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent>
        <OrdenEmboideryPDF data={defaultDataEmbroidery} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalEmbroideryPDF;
