import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { deleteSpecificationSheet } from "./functions";
const DeleteSpecificationSheet = ({ openDelete, setOpenDelete }) => {
     const { defaultDataSS } = useSelector(({ specifications }) => specifications);
    const deleteFnc = async () => {
        await deleteSpecificationSheet(defaultDataSS.id);
        setOpenDelete(false);
    }
    return (
        <>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación del corte"}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDelete(false)} color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DeleteSpecificationSheet;