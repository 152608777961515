import React, { useState } from "react";
import { Button } from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import { Grid, Box } from "@mui/material";
import InputsFormFields from "./InputsFormFields";
import TabComponent from "components-base/tab/Tab";
import moment from "moment";
import {
  createInputs,
  modifyInputs,
  validationSchemaInputs,
} from "../functions";
import { useSelector, useDispatch } from "react-redux";
import { showMessage } from "store/modules/Message";

const InputsForm = ({ mode, handleClose }) => {
  const { defaultData } = useSelector(({ talla }) => talla);
  const [isMenuForm, setIsMenuForm] = useState(null);
  const dispatch = useDispatch();
  const messages = (mensaje) => {
    dispatch(
      showMessage({
        message: `La entrada se ha ${mensaje} con éxito`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };
  const formik = useFormik({
    initialValues:
      mode === "POST"
        ? {
            date: moment().format("YYYY-MM-DD"),
            description: "",
            code: "",
            rolls: [{ id: 0, code: "", type: "", is_void: false }],
            machinary: [
              {
                id: 0,
                code: "",
                is_void: false,
                dateMaintenance: moment().format("YYYY-MM-DD"),
              },
            ],
          }
        : {
            id: defaultData.id,
            date: defaultData.date,
            telas: defaultData.telas,
            description: defaultData.description,
            amount: defaultData.amount,
            meters: defaultData.meters,
            kg: defaultData.kg,
            code: defaultData.code,
            category: { id: 6 },
          },
    onSubmit: async (values) => {
      if (mode === "POST") {
        values.unit = values.unit.name;
        await createInputs(values, isMenuForm?.name);
        messages("creado");
        handleClose();
      } else {
        console.log("put hehehe");
      }
    },
  });
  const { setFieldValue } = formik;
  return (
    <FormikProvider value={formik}>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          "& .MuiTextField-root": { marginBottom: "10px" },
          marginTop: "10px", // Ajuste el margen superior del formulario
          padding: "20px",
          position: "relative", // Cambio de 'position: fixed' a 'position: relative'
          justifyContent: "center",
        }}
      >
        <TabComponent
          tabsElement={[
            {
              label: "Información de la Entrada",
              component: (
                <InputsFormFields
                  formik={formik}
                  mode={mode}
                  setFieldValue={setFieldValue}
                  setIsMenuForm={setIsMenuForm}
                  isMenuForm={isMenuForm}
                />
              ),
            },
          ]}
        />
        <div style={{ position: "absolute", right: 100, top: 20 }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Grid item>
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isMenuForm?.name === undefined ? true : false}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </FormikProvider>
  );
};

export default InputsForm;
