import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import TallaFormFields from "./TallaFormFields";
import TabComponent from "components-base/tab/Tab";
import { createTalla,modifyTalla, validationSchemaTalla } from "../functions";
import {  useSelector } from "react-redux";

const TallaForm = ({ mode, handleClose }) => {
    const {defaultData} = useSelector(({talla})=>talla);
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    id: null,
                    nombre: "",
                    descripcion: "",
                    codigo: "",
                }
                : {
                    id: defaultData.id,
                    nombre: defaultData.nombre,
                    descripcion: defaultData.descripcion,
                    codigo: defaultData.codigo,
                },
        validationSchema: validationSchemaTalla,
        onSubmit: async (values) => {
            switch(mode){
                case "POST":
                   await createTalla(values);
                    handleClose();
                break;
                case "PUT":
                   await modifyTalla(values);
                    handleClose();
                break;
                default:handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información de la Talla",
                    component: <TallaFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }

            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item sx={12} >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item sx={12}>
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default TallaForm;