import { Autocomplete, Dialog, DialogContent, FormControlLabel, DialogTitle, TextField, Typography, Grid, DialogActions, Button, Checkbox } from "@mui/material";
import { getAllInsumos } from "components-base/insumos/functions";
import React, { useState, useEffect } from "react";
import { createPrendaInsumo, modifyPrendaInsumo } from "../functions";
import { useSelector } from "react-redux";
import { getAllTallasByProductoId } from "components-base/productos/functions";
const InsumosModal = ({tallas,setTallas,editMode,editData, mode, open, handleClose, setDataPI, setPrendaInsumo }) => {
    const [insumos, setInsumos] = useState(null);
    const [cantidad, setCantidad] = useState(0);
    const [unidad, setUnidad] = useState(null);
    const [insumoSelected, setInsumoSelected] = useState(null);
    const [checkTallas, setCheckTallas] = useState(false);
    const { defaultData: prendaSelected } = useSelector(({ prenda }) => prenda);

    useEffect(() => {
        const fecthData = async () => {
            let insumos = await getAllInsumos()
            setInsumos(insumos)
        }
        fecthData();
    }, []);

    useEffect(() => {
        if(editMode !== "edit" || (editData && !editData.details && editData.unidad == "Rollo")){
            const fecthData = async () => {
                let objTalla = [];
                let tallas = await getAllTallasByProductoId(prendaSelected.productoId);
                tallas.forEach(item => {
                    objTalla.push({ talla: item.talla, cantidad: "", unidad, insumo: "" })
                })
                setTallas(objTalla);
            }
            fecthData();
        }

    }, [prendaSelected,editMode]);

    useEffect(() => {
        if (editMode === "edit") {
            setUnidad(editData.unidad);
            setCantidad(editData.cantidad);
            setInsumoSelected(editData.insumo);
            setTallas(editData.details)
            setCheckTallas(editData.details ? true: false)
        } else {
            setUnidad(0);
            setCantidad(null);
            setInsumoSelected(null);
            setTallas(null);
            setCheckTallas(false);
        }
    }, [editMode,editData]);


    const submitFnc = async () => {
        if (insumoSelected &&  unidad) {
            let data = {
                cantidad: cantidad || 0,
                prendaId: prendaSelected.id || 0,
                insumo: { id: insumoSelected.id, nombre: insumoSelected.nombre, codigo: insumoSelected.codigo },
                unidad: unidad,
                details: checkTallas ? tallas : null
            }
            if (mode === "PUT") {
                if(editMode === "create"){
                    await createPrendaInsumo(data);
                }else{
                    data.id = editData.id;
                    await modifyPrendaInsumo(data);
                }
             
            } else {
                setDataPI((dataPI) => [...dataPI, data]);
                setPrendaInsumo((dataPI) => [...dataPI, data]);
            }
            setUnidad(0);
            setInsumoSelected(null);
            setUnidad(null);
            handleClose();
        }
    }
    const handleInsumoChange = (e, value) => {

        setUnidad(value.details.presentacion);
        setInsumoSelected(value);
    }
    const handleChangeT = (e) => {
        setCheckTallas(e.target.checked);

    }
    const handleUnidadChange = (e, value, item) => {
        item.unidad = value;
        setTallas(tallas.filter(t => t.talla.id !== item.talla.id));
        setTallas((talla) => [...talla, item]);
        setTallas(tallas.sort((a, b) => { return a.talla.id - b.talla.id }));
    }
    const changeCantidad = (e, item) => {
        item.cantidad = e.target.value;
        setTallas(tallas.filter(t => t.talla.id !== item.talla.id));
        setTallas((talla) => [...talla, item]);
        setTallas(tallas.sort((a, b) => { return a.talla.id - b.talla.id }));
    }

    return (
        <Dialog
            open={open}
            maxWidth={"lg"}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>
                <Typography variant="h4">
                    {"Insumos ocupados en la Prenda"}
                </Typography>
            </DialogTitle>
            <DialogContent >
                <Grid container spacing={2} sx={{ paddingTop: 5 }}>
                    <Grid item xs={6}>
                        <Autocomplete
                            getOptionLabel={(insumo) => `${insumo.nombre}`}
                            onChange={handleInsumoChange}
                            disablePortal
                            id="combo-box-insumos"
                            options={insumos || []}
                            maxWidth
                            renderInput={(params) => <TextField {...params} label="Insumos" />}
                            defaultValue={editMode === "edit" ? editData.insumo : null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={unidad || ""}
                            maxWidth
                            label="Unidad" />
                    </Grid>
                    {!checkTallas && (<Grid item xs={6}>
                        <TextField
                            value={cantidad || 0}
                            onChange={(e) => setCantidad(e.target.value)}
                            fullWidth
                            type="number"
                            label="Cantidad" />
                    </Grid>)}
                    <Grid item xs={12}>
                        <FormControlLabel control={
                            <Checkbox
                                id="bordado"
                                name="bordado"
                                checked={checkTallas}
                                onChange={handleChangeT}
                                color="primary" />} label="Especificar insumo por talla" />
                    </Grid>
                    {checkTallas && tallas && (
                            <Grid container spacing={2} flexDirection={"row"}>
                                <Grid item xs={12}>
                                    <div style={{ textAlign: "center" }}>
                                        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                                            {"Especifique la cantidad por talla"}
                                        </h4>
                                    </div>
                                    {tallas.length > 0 && tallas.map(item => (
                                        <div className="grid grid-cols-4 gap-4 px-2 p-1">
                                            <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                                <p className="text-sm text-gray-600">Talla:</p>
                                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                                    {item.talla.nombre}
                                                </p>
                                            </div>

                                            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                                <TextField
                                                    label={"Cantidad requerida"}
                                                    onChange={(e) => changeCantidad(e, item)}
                                                    defaultValue={item.cantidad}
                                                    type="number"
                                                />
                                            </div>
                                            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                                <Autocomplete
                                                    getOptionLabel={(unidad) => `${unidad.name}`}
                                                    onChange={(e, value) => handleUnidadChange(e, value, item)}
                                                    disablePortal
                                                    id="combo-box-insumos"
                                                    options={[{ name: "Centimetros", factor: 100 }, { name: "Metros", factor: 1 }, { name: "Milimetros", factor: 1000 }, { name: "Pulgadas", factor: 2.54 }]}
                                                    maxWidth
                                                    renderInput={(params) => <TextField {...params} label="Unidad" />}
                                                    defaultValue={editMode ==="edit" ? item.unidad :null }
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="primary">
                    <Typography>
                        {"Cancelar"}
                    </Typography>
                </Button>
                <Button onClick={submitFnc} variant="contained" color="primary">
                    <Typography>
                        {"Guardar"}
                    </Typography>
                </Button>
            </DialogActions>


        </Dialog>
    )
}
export default InsumosModal;