import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { getAllEventsByPedido } from "components-base/pedidos/functions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EventTable from "./eventTable";

const EventSummary = ({ open, handleClose }) => {
    const [events, setEvents] = useState(null);
    const { defaultDataPedido } = useSelector(({ pedido }) => pedido)


    useEffect(() => {
        if (defaultDataPedido) {
            const fetchData = async () => {
                const res = await getAllEventsByPedido(defaultDataPedido.id);
                let sortedEvents = res.sort((a,b)=> {return b.id - a.id});
                setEvents(sortedEvents);
            }
            fetchData();
        }

    }, [defaultDataPedido]);

    if (!events) return "Cargando......";

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"lg"}
            fullWidth
        >
            <DialogContent>
                <EventTable events={events} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default EventSummary;