import Card from "components/card";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../columns";
import { useEffect, useState } from "react";
import { getAllStatus, updateStatus } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Typography, Box } from "@mui/material";
import PedidosDrawer from "./PedidosDrawer";
import DeletePedido from "./DeletePedido";
import OrderSummaryModalPDF from "./OrderSummary/OrderSummaryModal";
import { showMessage } from "store/modules/Message";
import EventSummary from "./EventSummary/EventSummary";
import OrderCutMainModal from "./OrderCut/OrderCutMainModal";
import StampingOrderMainModal from "./StampingOrder/StampingOrderMainModal";
import OrderBordadoMain from "./OrderBordado/OrderBordadoMain";

const PedidosTable = ({ data,setRefresh,refresh }) => {
  const { userName } = useSelector(({ session }) => session);
  const [open, setOpen] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openBordado, setOpenBordado] = useState(false);
  const [mode, setMode] = useState("POST");
  const [dataColumns, setDataColumns] = useState(null);
  const [openCut, setOpenCut] = useState(false);
  const [openStamping, setOpenStamping] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const dispatch = useDispatch();
  const changeStatusHandle = async (e, option, pedidoId) => {
    if (!option) return;
    await updateStatus(option, pedidoId, userName);
    dispatch(
      showMessage({
        variant: "success",
        message: `El pedido ha cambiado de estatus: el nuevo estatus es ${option.nombre} `,
      })
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      let status = await getAllStatus();
      let col = columns(
        setOpen,
        setMode,
        setOpenDelete,
        dispatch,
        setOpenCut,
        setOpenSummary,
        status,
        changeStatusHandle,
        setOpenEvent,
        setOpenStamping,
        setOpenBordado,
        setOpenEvent,setRefresh,refresh
      );

      setDataColumns(col);
    };
    fetchData();
  }, [open, dispatch, openDelete, refresh]);

  const handleClose = () => {
    setOpen(false);
    setRefresh(!refresh);
  };
  const handleCloseCut = () => {
    setOpenCut(false);
  };
  const handleCloseStamping = () => {
    setOpenStamping(false);
  };

  const handleCloseBordado = () => {
    setOpenBordado(false);
  };

  const setAndOpen = () => {
    setOpen(true);
    setMode("POST");
  };
  const handleCloseSummary = () => {
    setOpenSummary(false);
  };
  const handleCloseEvent = () => {
    setOpenEvent(false);
  };

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <OrderSummaryModalPDF
        open={openSummary}
        handleClose={handleCloseSummary}
      />
      <EventSummary open={openEvent} handleClose={handleCloseEvent} />
      <StampingOrderMainModal
        open={openStamping}
        handleClose={handleCloseStamping}
      />
      <OrderBordadoMain open={openBordado} handleClose={handleCloseBordado} />
      <OrderCutMainModal open={openCut} handleClose={handleCloseCut} />
      <DeletePedido openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <PedidosDrawer open={open} handleClose={handleClose} mode={mode} />
      <Card extra={"w-full h-full p-5"}>
        <div className="relative flex items-center justify-end">
          <button
            onClick={setAndOpen}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Crear Nuevo Pedido
          </button>
        </div>
        {data ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={data || []}
            columns={dataColumns || []}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};
export default PedidosTable;
