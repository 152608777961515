import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import OrdenCutPDF from "./OrdenCutPDF";
import { useSelector } from "react-redux";

const ModalCutPDF = ({ open, handleClose }) => {
  const { defaultDataCut } = useSelector(({ cut }) => cut);
  if (!defaultDataCut) return null;
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent>
        <OrdenCutPDF data={defaultDataCut} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalCutPDF;
