import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { deleteDetalle } from "../functions";
const DeleteDetalle = ({ openDeleteDetalle, setOpenDeleteDetalle }) => {
  const { idDetalle } = useSelector(({ pedido }) => pedido);
  const deleteFnc = async () => {
    await deleteDetalle(idDetalle);
    setOpenDeleteDetalle(false);
  };
  return (
    <>
      <Dialog
        open={openDeleteDetalle}
        onClose={() => setOpenDeleteDetalle(false)}
      >
        <DialogTitle>
          <Typography variant="title">{"Eliminar"}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle">
            {"Continuar con la eliminación del detalle"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDetalle(false)}
            color={"secondary"}
          >
            Cancelar
          </Button>
          <Button onClick={deleteFnc} color={"secondary"}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DeleteDetalle;
