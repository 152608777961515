import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useState,useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "utils/Firebase/firebase";

const App = () => {
  const navigate = useNavigate();

   const [user, setUser] = useState(false);
   useEffect(() => {
     onAuthStateChanged(auth, (user) => {
       if (user) {
         setUser(true);
         navigate("/admin/inicio")
       } else {
         setUser(false);
         navigate("/auth/sign-in")
       }
     });
   }, [user]);

  return (
    <Routes>
      <Route path="/" element={ user ? <Navigate to="/admin" replace /> : <Navigate to="/auth" replace /> } />
      <Route path="auth/*" element={user ? <AdminLayout /> : <AuthLayout />} />
      <Route path="admin/*" element={user ? <AdminLayout /> : <AuthLayout />} />
      <Route path="users/*" element={<AdminLayout />} />
    </Routes>
  );
};

export default App;
