import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import moment from "moment";

import image from "../../../../assets/img/marifer.png";
import { DataStampingConvert } from "./functions";
// Create styles
const styles = StyleSheet.create({
  image2: {
    position: "absolute",
    objectPosition: "0",
    zIndex: -999,
    width: "320px",
    height: "320px",
    opacity: 0.2,
  },
  image: {
    // objectPosition: "0",
    position: "absolute",
    objectFit: "cover",
    zIndex: -999,
    width: "100px",
    height: "100px",
    paddingLeft: 0,
    opacity: 0.6,
  },
  photo: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    color: "#ff0080",
    textTransform: "uppercase",
    marginTop: 25,
    paddingLeft: 150,
    fontSize: "18pt",
    fontWeight: "extrabold",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
  },
  section1: {
    padding: 8,
    textAlign: "center",
    width: "7cm",
    height: "100%",
    backgroundColor: "white",
    border: 1,
    borderColor: "#0A83F5",
    lineHeight: 1.5,
    fontSize: "10pt",
  },
  section2: {
    padding: 8,
    lineHeight: 4,
    border: 1,
    borderColor: "#0A83F5",
    width: "14.6cm",
    height: "100%",
    backgroundColor: "white",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
// Create styles

const StampingOrderPDF = ({ data: defaultData }) => {
  const data = DataStampingConvert(defaultData);

  return (
    <>
      <PDFViewer style={styles.viewer}>
        {
          <Document>
            {[
              `Original-${data.type}`,
              "Copia-Gerencia",
              "Copia-Producción",
            ].map((title) => (
              <Page size="LETTER" style={styles.page} key={title}>
                <View style={styles.mainSection}>
                  <View>
                    <Image style={styles.image} src={image} />
                  </View>
                  <View>
                    <Text style={styles.header}>
                      {`Orden de salida de ${data.type}`}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 20,
                      flexDirection: "row",
                      marginTop: 25,
                      marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        color: "black",
                        padding: 0,
                        backgroundColor: "pink",
                      }}
                    >
                      {"FOLIO:"}
                    </Text>
                    <Text style={{ color: "red", padding: 0 }}>
                      {`${data.folio}`}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    fontSize: "9pt",
                    color: "gray",
                    wordWrap: "word-break",
                    paddingLeft: 200,
                    marginTop: 20,
                  }}
                  // style={{ fontSize: 10, flexDirection: "row", textAlign: "center", wordWrap: "word-break", paddingLeft: 140, marginTop: -140, paddingBottom: 10 }}
                >
                  {/* <View style={{marginTop:0}}>
                                    <Checkbox isChecked={detalle.foliado} label="Foliado" />
                                    <Checkbox isChecked={detalle.tonos}   label="Tonos  " />
                                </View> */}
                  {/* <View>
                    <Text
                      style={{
                        border: 1,
                        padding: 1,
                        width: 75,
                        textAlign: "center",
                      }}
                    >
                      {data.type}
                    </Text>
                    <Text
                      style={{
                        border: 1,
                        padding: 1,
                        height: 25,
                        width: 75,
                        textAlign: "center",
                      }}
                    ></Text>
                  </View> */}
                  <View>
                    <View>
                      <Text
                        style={{
                          border: 1,
                          padding: 1,
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        {"Cantidad"}
                      </Text>
                    </View>

                    <Text
                      style={{
                        border: 1,
                        width: 75,
                        padding: 1,
                        height: 25,
                        textAlign: "center",
                      }}
                    >
                      {/* {cantidad.toString()} */}
                      {data.totals.grandTotal}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        wordWrap: "break-word",
                        border: 1,
                        padding: 1,
                        width: 100,
                        textAlign: "center",
                      }}
                    >
                      {data.type}
                    </Text>

                    <Text
                      style={{
                        width: 100,
                        textAlign: "center",
                        wordWrap: "word-break",
                        border: 1,
                        fontSize: 7,
                        paddingTop: 2,
                        height: 25,
                      }}
                    >
                      {`${data.supplier}`}
                    </Text>
                  </View>
                  <View
                    style={{ textAlign: "left", width: 150, color: "#111" }}
                  >
                    <Text
                      style={{ border: 1, fontSize: 9, padding: 1, height: 20 }}
                    >
                      {`${"Fecha de Salida: "} ${moment(
                        data.deliveryDate.split(" ")[0]
                      ).format("DD-MM-YYYY")}`}
                    </Text>
                    <Text></Text>
                    <Text
                      style={{ border: 1, fontSize: 9, padding: 1, height: 20 }}
                    >
                      {/* {`${"Fecha de Retorno: "} ${moment(data.deliveryDate.split(" ")[0]).format("DD-MM-YYYY")}`} */}
                      {`${"Fecha de Retorno: "}`}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    padding: 5,
                    backgroundColor: "pink",
                    marginTop: 20,
                  }}
                >
                  <View style={{ width: "60%" }}>
                    <Text style={{ fontSize: 10 }}>
                      {`MODELO: ${data.model}`}
                    </Text>
                  </View>
                  <View>
                    <Text>{"PRECIO:"}</Text>
                  </View>
                </View>
                <View style={{ height: 270, border: 1, marginTop: 5 }}>
                  <View style={{ alignItems: "center" }}>
                    <Image src={image} style={styles.image2} />
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      textAlign: "center",
                      fontSize: "11pt",
                      color: "#000111",
                    }}
                  >
                    <View style={{ width: "20%", border: 1 }}>
                      <Text>{"Prenda"}</Text>
                    </View>
                    <View style={{ width: "10%", border: 1 }}>
                      <Text>{"Fecha"}</Text>
                    </View>
                    <View style={{ width: "30%", border: 1 }}>
                      <Text>{"Talla"}</Text>
                    </View>
                    <View style={{ width: "15%", border: 1 }}>
                      <Text>{"Total"}</Text>
                    </View>
                    <View style={{ width: "25%", border: 1 }}>
                      <Text>{"Entregado por"}</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      height: data.sizes.length * 12,
                      flexDirection: "row",
                      textAlign: "center",
                      fontSize: "8pt",
                      wordWrap: "word-break",
                    }}
                  >
                    <View style={{ width: "20%", border: 1 }}>
                      <Text style={{ fontSize: 7 }}>
                        {`${data.prenda.nombre}`}
                      </Text>
                    </View>
                    <View style={{ width: "10%", border: 1 }}>
                      <Text>{""}</Text>
                    </View>
                    <View style={{ width: "30%", border: 1, fontSize: 7 }}>
                      {data.sizes.map((m) => (
                        <Text key={m.name} style={{ fontWeight: "heavy" }}>
                          {m.name}
                        </Text>
                      ))}
                    </View>
                    <View style={{ width: "15%", border: 1, fontSize: 7 }}>
                      {data.sizes.map((m) => (
                        <Text>
                          {m.cut || 0}
                          {/* {totalSumByPrenda(m, p.id)} */}
                        </Text>
                      ))}
                    </View>
                    <View style={{ width: "25%", border: 1 }}>
                      <Text>{""}</Text>
                    </View>
                  </View>

                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((i) => (
                    <View
                      key={i}
                      style={{
                        height: 20,
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <View style={{ width: "20%", border: 1, fontSize: 7 }}>
                        <Text>{""}</Text>
                      </View>
                      <View style={{ width: "10%", border: 1 }}>
                        <Text>{""}</Text>
                      </View>
                      <View style={{ width: "30%", border: 1, fontSize: 7 }}>
                        <Text>{""}</Text>
                      </View>
                      <View style={{ width: "15%", border: 1, fontSize: 7 }}>
                        <Text>{""}</Text>
                      </View>
                      <View style={{ width: "25%", border: 1 }}>
                        <Text>{""}</Text>
                      </View>
                    </View>
                  ))}
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    fontSize: "13pt",
                    color: "gray",
                    height: 80,
                    border: 1,
                  }}
                >
                  <View>
                  
                    <Text
                      style={{
                        padding:2,
                        fontSize: 10,
                        flexWrap: "wrap",
                        textAlign: "left",
                      }}
                    >
                      {"Observaciones: "}  {data.comments}
                    </Text>
                  </View>
                </View>
                {/* <View style={{ flexDirection: "row", textAlign: "center", fontSize: "13pt", color: "pink", height: 30, backgroundColor: "pink", border: 1, marginTop: 10 }}>
                                <Text style={{ color: "gray" }}>
                                    {"Reposiciones"}
                                </Text>
                            </View> */}
                {/* <View style={{ flexDirection: "row", paddingTop: 5 }}>
                               
                                <View style={{ width: "65%" }}>
                                    {
                                        insumos.map(item => (
                                            <View key={item.id} style={{ flexDirection: "row", paddingTop: 0, textAlign: "center" }}>
                                                <Text style={{ border: 1, width: "60%", fontSize: "9pt" }} >
                                                    {item.nombre}
                                                </Text>
                                                <Text style={{ border: 1, width: "40%", fontSize: "9pt" }} >
                                                    {`${Math.round(item.cantidad, 2)} / ${item.unidad}(s)`}
                                                </Text>
                                            </View>
                                        ))
                                    }
                                </View>
                                <View style={{ width: "35%", textAlign: "center", paddingLeft: 5 }}>
                                    <View style={{ border: 1 }}>
                                        <Text style={{ fontSize: "12pt" }}>
                                            {"Habilitado en General"}
                                        </Text>
                                    </View>
                                    <View style={{ border: 1, height: 105 }}>
                                        <Text>
                                        </Text>
                                    </View>
                                </View>
                            </View> */}
                <View
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "11pt",
                  }}
                >
                  <Text>
                    {"La responsabilidad del material es de quien lo recibe."}
                  </Text>
                  <Text>
                    {"Revisar su material completo al ser entregado."}
                  </Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontSize: "11pt",
                    color: "red",
                  }}
                >
                  <Text>{title}</Text>
                </View>
              </Page>
            ))}
          </Document>
        }
      </PDFViewer>
    </>
  );
};
export default StampingOrderPDF;
