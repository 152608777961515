import { Delete, Edit, Print } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setDefaultDataStamping } from "store/modules/StampingOrder";

export const columnsCut = (setOpen, setMode, setOpenDelete, dispatch, setOpenPDF) => {
  return [
    {
      field: `acciones`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar orden de estampado" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultDataStamping(data.row));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar orden de estampado" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setDefaultDataStamping(data.row));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir Orden de Corte" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenPDF(true);
                dispatch(setDefaultDataStamping(data.row));
              }}
            >
              <Print />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "type",
      headerName: "Tipo:",
      width: 150,
      renderCell:(data)=>(
        `${String(data.row.type).toUpperCase()}`
      )
    },
    {
      field: "folio",
      headerName: "Folio:",
      width: 100,
      renderCell:(data)=>(
        `${String(data.row.folio).padStart(4, '0')}`
      )
    },
    {
      field: "supplier",
      headerName: "Responsable",
      width: 250,
      renderCell: (data) => (
        <React.Fragment>
          {`${data.row.supplier.persona.nombre} ${data.row.supplier.persona.apellidoPaterno} ${data.row.supplier.persona.apellidoMaterno}`}
        </React.Fragment>
      ),
    },
    {
      field: "prenda",
      headerName: "Prenda",
      width: 300,
      renderCell: (data) => (
        <React.Fragment>
          {`${data.row.prenda.nombre}`}
        </React.Fragment>
      ),
    },
    // {
    //   field: "cloth",
    //   headerName: "Tela",
    //   width: 200,
    //   renderCell: (data) => (
    //     <React.Fragment>
    //       {data.row.cloths.map(c => c.name).join(", ")}
    //     </React.Fragment>
    //   ),
    // },
    {
      field: "createdByUser",
      headerName: "Creado por:",
      width: 200,
    },
    {
      field: "modifiedByUser",
      headerName: "Modificado por:",
      width: 200,
    },
    // {
    //     field: "cloth",
    //     headerName: "Tela",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.cloth.name}
    //         </React.Fragment>
    //     ),
    // },
    // {
    //     field: "color",
    //     headerName: "Color",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.cloth.color}
    //         </React.Fragment>
    //     ),
    // },
    // {
    //     field: "tone",
    //     headerName: "Tono",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.cloth.tone}
    //         </React.Fragment>
    //     ),
    // },
    // {
    //     field: "average",
    //     headerName: "Promedio",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.isAverage ? "SI":"NO" }
    //         </React.Fragment>
    //     ),
    // },

  ];
};