import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const TablePlaceholder = () => {
  return [1, 2, 3, 4, 5].map((i) => (
    <>
      <Grid
        key={i}
        container
        spacing={2}
        flexDirection={"row"}
        style={{ paddingTop: 20 }}
      >
        <Grid item xs={2}>
          <Skeleton animation={false} variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton animation={false} variant="rectangular" height={20} />
        </Grid>

        <Grid item xs={3}>
          <Skeleton animation={false} variant="rectangular" height={20} />
        </Grid>

        <Grid item xs={2}>
          <Skeleton animation={false} variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton animation={false} variant="rectangular" height={20} />
        </Grid>
      </Grid>
    </>
  ));
};
export default TablePlaceholder;
