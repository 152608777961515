import { client } from "utils/axios";
import * as yup from "yup";

export const  createInsumo = async (insumo) =>{
    await client
      .post("insumos", insumo)
  

}
export async function getAllInsumos(){
    const {data} = await client.get("/insumos");
    return data;
}
export const validationSchemaInsumo = yup.object({
    nombre: yup
        .string("Ingresa nombre del Insumo")
        .required("Campo Requerido"),
    descripcion: yup
        .string("Ingresa Descripción")
        .required("Campo Requerido"),
    codigo: yup
        .string("Ingresa Código")
        .required("Campo Requerido"),
});
export const deleteInsumo = async (insumoId)=> {
    await client
      .delete(`insumos/${insumoId}`)
    
  };

  export const modifyInsumo =  async (insumo)  => {
    await client.put("insumos", insumo)
  };