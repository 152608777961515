import {
  Grid,
  TextField,
  Box, Autocomplete
} from "@mui/material";
import { useEffect, useState } from "react";
import { boxSize } from "utils/apis";
import { getAllCategories } from "../functions";
const SupplierFormFields = ({ formik, setFieldValue }) => {
const [categories,setCategories] = useState();
  
useEffect(()=>{
  const fetchData = async () =>{
    const categories = await getAllCategories();
    setCategories(categories)
  }
  fetchData();
},[]);

const handleChange = (e, option) =>{
setFieldValue("category", option)
}
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={
              formik.touched.code && Boolean(formik.errors.code)
            }
            helperText={formik.touched.code && formik.errors.code}
            color="primary"
          />
          <TextField
            fullWidth
            id="phone"
            name="phone"
            label="Telefono"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={
              formik.touched.phone && Boolean(formik.errors.phone)
            }
            helperText={formik.touched.phone && formik.errors.phone}
            color="primary"
          />
                    <TextField
            fullWidth
            rows={5}
            multiline
            id="direccion"
            name="direccion"
            label="Direccion"
            value={formik.values.direccion}
            onChange={formik.handleChange}
            error={
              formik.touched.direction && Boolean(formik.errors.direction)
            }
            helperText={formik.touched.direction && formik.errors.direction}
            color="primary"
          />
            <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={
              formik.touched.nombre && Boolean(formik.errors.nombre)
            }
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />
          <TextField
            fullWidth
            id="apellidoPaterno"
            name="apellidoPaterno"
            label="Nombre 2"
            value={formik.values.apellidoPaterno}
            onChange={formik.handleChange}
            error={
              formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)
            }
            helperText={formik.touched.apellidoPaterno && formik.errors.apellidoPaterno}
            color="primary"
          />
          <TextField
            fullWidth
            id="apellidoMaterno"
            name="apellidoMaterno"
            label="Nombre 3"
            value={formik.values.apellidoMaterno}
            onChange={formik.handleChange}
            error={
              formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)
            }
            helperText={formik.touched.apellidoMaterno && formik.errors.apellidoMaterno}
            color="primary"
          />


        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            getOptionLabel={(category) => `${category.name}`}
            onChange={handleChange}
            disablePortal
            id="combo-box-category"
            options={categories || []}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Categoria de Proveedor" />}
            defaultValue={formik.values.category || null}
          />
        </Grid>
         <Grid item xs={6}>
          <TextField
            fullWidth
            id="rfc"
            name="rfc"
            label="RFC"
            value={formik.values.rfc}
            onChange={formik.handleChange}
            error={
              formik.touched.rfc && Boolean(formik.errors.rfc)
            }
            helperText={formik.touched.rfc && formik.errors.rfc}
            color="primary"
          />   
        </Grid>
      </Grid>
    </Box>

  )
}
export default SupplierFormFields;