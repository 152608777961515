import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { logInWithEmailAndPassword } from "utils/Firebase/firebase";
import { useDispatch } from "react-redux";
import { showMessage } from "store/modules/Message";
import { setUserName } from "store/modules/Session";

export default function SignIn() {
  const dispatch = useDispatch();

  const submit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let user_email = data.get("email");
    let user_password = data.get("password");
    let response = await logInWithEmailAndPassword(user_email, user_password);
    if (!response.result) {
      dispatch(
        showMessage({
          message:
            "Hubo un error en el servico de autenticacion. " + response.error,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
    } else {
      dispatch(setUserName( response.user.user.email))
      dispatch(
        showMessage({
          message: "Bienvenido al sistema Marifer " + response.user.user.email,
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        })
      );
    }
  };
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Inicia sesion en Marifer
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Entra tu usuario y contrasena
        </p>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Inicia con Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> o </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <form onSubmit={submit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Usuario*"
            placeholder="mail@ejemplo.com"
            id="email"
            type="text"
            name="email"
            required={true}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Contrasena*"
            placeholder="Min. 8 caracteres"
            id="password"
            type="password"
            name="password"
            required={true}
          />
          <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Mantener mi sesion activa
              </p>
            </div>
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href=" "
            >
              Olvidaste tu contrasena?
            </a>
          </div>
          <button
            type={"submit"}
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Entrar
          </button>
        </form>

        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            No tienes cuenta?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Solicita un usuario de Marifer
          </a>
        </div>
      </div>
    </div>
  );
}
