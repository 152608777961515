import { Grid, TextField, Box, Autocomplete } from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllCategoriesType } from "../functions";

const CategoriesFormFields = ({ formik, setFieldValue, mode }) => {
  const { defaultDataCategories } = useSelector(({ category }) => category);
  const [categoriesType, setCategoriesType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAllCategoriesType();
      setCategoriesType(response);
    };
    fetchData();
  }, []);

  if (!categoriesType) return null;
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            color="primary"
          />
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            color="primary"
          />
          <Autocomplete
            name={"type"}
            options={categoriesType || []}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            id="type"
            fullWidth
            renderInput={(params) => (
              <TextField
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                {...params}
                label="Categoria"
              />
            )}
            getOptionLabel={(category) => `${category.name}`}
            onChange={(e, value) => setFieldValue("type", value || "")}
            //  value={formik.values.type}
            defaultValue={defaultDataCategories?.type}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default CategoriesFormFields;
