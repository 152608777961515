import react from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { Typography, CircularProgress, Card, Box } from "@mui/material";
import { boxSize } from "utils/apis";
import { getAllEmbroideryOrderById } from "./functions";
import { columnsEmbroidery } from "./columns";
import { setDefaultDataEmbroidery } from "store/modules/EmbroideryOrder";
import ModalEmbroideryPDF from "./ModalEmbroideryPDF";
import OrderEmbroidery from "./OrderEmbroidery";
import DeleteOrderEmbroidery from "./DeleteOrderEmbroidery";

const OrderBordadoTable = ({ defaultDataPedido }) => {
  const [data, setData] = useState(null);
  const [_columns, setColumns] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [openPDF, setOpenPDF] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(defaultDataPedido).length > 0) {
      const fetchData = async () => {
        const embroideryOrder = await getAllEmbroideryOrderById(
          defaultDataPedido.id
        );
        setData(embroideryOrder);
        let col = columnsEmbroidery(
          setOpen,
          setMode,
          setOpenDelete,
          dispatch,
          setOpenPDF
        );
        setColumns(col);
      };
      fetchData();
    }
  }, [open, openDelete, defaultDataPedido]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePDF = () => {
    setOpenPDF(false);
  };
  const setModeCreate = () => {
    dispatch(setDefaultDataEmbroidery(null));
    setMode("POST");
    setOpen(true);
  };
  return (
    <Box sx={{ height: boxSize, justifyContent: "center" }}>
      <ModalEmbroideryPDF open={openPDF} handleClose={handleClosePDF} />
      <OrderEmbroidery
        mode={mode}
        open={open}
        handleClose={handleClose}
        defaultDataPedido={defaultDataPedido}
      />
      <DeleteOrderEmbroidery
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
      />
      <Card extra={"w-full h-full p-5"}>
        <div className="flex items-center justify-start rounded-t-3xl p-3">
          <Typography
            variant="h4"
            className="px-4 py-2 text-base  text-brand-500 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            {`Orden de Bordado`}
          </Typography>
        </div>
        <div className="flex items-center justify-end rounded-t-3xl p-3">
          <button
            onClick={setModeCreate}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Agregar Orden de Bordado
          </button>
        </div>
        {data ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={data || []}
            columns={_columns || []}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            getRowHeight={() => "auto"}
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};

export default OrderBordadoTable;
