import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteRolls } from "../functions";

const StackTelasDelete = ({ openDelete, setOpenDelete }) => {
  const dispatch = useDispatch();
  const { rollsIdSelected } = useSelector(({ stackRolls }) => stackRolls);

  const deleteFnc = async () => {
    await deleteRolls(rollsIdSelected, dispatch);
    setOpenDelete(false);
  };

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
          console.log("Cerrando el diálogo");
        }}
      >
        <DialogTitle>
          <Typography variant="title">{"Eliminar"}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle">
            {"Continuar con la Eliminación del Rollo"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteFnc} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StackTelasDelete;
