import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setOrderType } from "store/modules/StampingOrder";
import { useEffect, useState } from "react";

const OrderType = ({ setModeCreate, open, handleClose }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    if (selected) {
      setSelected(false);
    }
  }, [open]);
  const handleClick = () => {
    setModeCreate();
    handleClose();
  };
  const handleSelectOrderType = (e, o) => {
    setSelected(true);
    dispatch(setOrderType(o));
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <DialogContent sx={{ height: 200 }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={["Estampado", "Lavanderia", "Smog"]}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Tipo de Orden" />
            )}
            onChange={handleSelectOrderType}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selected}
            variant="contained"
            onClick={handleClick}
            fullWidth
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default OrderType;
