import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { deleteConsumption } from "components-base/prendas/functions";
import { useSelector } from "react-redux";
const DeleteConsumption = ({ openDeleteCC, setOpenDeleteCC }) => {
    const { defaultDataCC } = useSelector(({ prenda }) => prenda);
    const deleteFnc = async () => {
        await deleteConsumption(defaultDataCC.id);
        setOpenDeleteCC(false);
    }
    return (
        <>
            <Dialog
                open={openDeleteCC}
                onClose={() => setOpenDeleteCC(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación del consumo"}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDeleteCC(false)} color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DeleteConsumption;