/*
 Initial state and properties
 */
export const initialState = {
    userName: ""
}
/*
 Action types
 */
export const SET_USER_NAME = 'SET_MODE'
/*
 Arrow function for change state
 */
export const setUserName = (payload) => ({
    type: SET_USER_NAME,
    payload,
});
/*
 Reducer to describe how the state changed
 */
export default function Reducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_USER_NAME:
            return {
                ...state,
                userName: payload,
            }
        default:
            return state
    }
}
