/*
 Initial state and properties
 */
export const initialState = {
  mode: "POST",
  inputsIdSelected: 0,
  defaultData: {},
  defaultDataDetail: null,
};
/*
    Action types
    */
export const SET_MODE = "SET_MODE";
export const SET_INPUTS_ID = "SET_INPUTS_ID";
export const SET_DEFAULT_DATA = "SET_DEFAULT_DATA";
export const SET_DEFAULT_DATA_DETAIL = "SET_DEFAULT_DATA_DETAIL";
/*
    Arrow function for change state
    */
export const setMode = (payload) => ({
  type: SET_MODE,
  payload,
});
export const setInputsId = (payload) => ({
  type: SET_INPUTS_ID,
  payload,
});
export const setDefaultData = (payload) => ({
  type: SET_DEFAULT_DATA,
  payload,
});
export const setDefaultDataDetail = (payload) => ({
  type: SET_DEFAULT_DATA_DETAIL,
  payload,
});
/*
    Reducer to describe how the state changed
    */
export default function Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MODE:
      return {
        ...state,
        mode: payload,
      };
    case SET_INPUTS_ID:
      return {
        ...state,
        inputsIdSelected: payload,
      };
    case SET_DEFAULT_DATA:
      return {
        ...state,
        defaultData: payload,
      };
    case SET_DEFAULT_DATA_DETAIL:
      return {
        ...state,
        defaultDataDetail: payload,
      };
    default:
      return state;
  }
}
