import { Delete, Edit } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setDefaultData } from "store/modules/Prendas";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";

export const columns = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  setRowId,
  setOpenModalConsumption
) => {
  return [
    {
      field: `acciones`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar prenda" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultData(data.row));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar prenda" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setDefaultData(data.row));
                setRowId(data.row.id);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Agregar consumo de tela" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                dispatch(setDefaultData(data.row));
                setRowId(data.row.id);
                setOpenModalConsumption(true);
              }}
            >
              <ShapeLineIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 300,
      headerClassName: "color:red",
    },
    {
      field: "descripcion",
      headerName: "Descripcion",
      width: 300,
    },
    {
      field: "imagen",
      headerName: "Imagen",
      width: 100,
      renderCell: (data) => (
        <React.Fragment>
          <div
            style={{
              height: 120,
              width: 120,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <img src={data.row.imagen} alt="Sin imagen" />
          </div>
        </React.Fragment>
      ),
    },
  ];
};
