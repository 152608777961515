import { Edit, Delete, Visibility } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setPickupsId,setDefaultData } from "store/modules/Pickups";
import { v4 as uuidv4 } from 'uuid';


export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 100,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Ver detalles" placement="top">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => { setOpen(true); setMode("PUT"); dispatch(setDefaultData(data.row));dispatch(setPickupsId(data.row.id)) }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Eliminar Departamento/Area" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setPickupsId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip> */}

      </React.Fragment>
    ),
  },
  {
    field: "folio",
    headerName: "Folio de Salida",
    width: 150,
  },
  {
    field: "codigo",
    headerName: "Codigo pedido",
    width: 200,
    renderCell:(data)=>(
<>
{data.row.pedidos.codigo}
</>
    )
  },
  {
    field: "driver",
    headerName: "Chofer",
    width: 300,
    renderCell: (data)=>(
      <>
      {`${data.row.driver.persona.nombre} ${data.row.driver.persona.apellidoPaterno} ${data.row.driver.persona.apellidoMaterno}`}
      </>
    )
  },
  {
    field: "fechaEntrada",
    headerName: "Fecha Entrada",
    width: 300,
    renderCell: (data)=>(
      <>
      {`${data.row.fechaEntrada.replace("T"," ")}`}
      </>
    )
  },
  {
    field: "maquilero",
    headerName: "Maquilero",
    width: 300,
    renderCell: (data)=>(
      <>
       {data.row.maquilero && (
        <>
         {`${data.row.maquilero.persona.nombre} ${data.row.maquilero.persona.apellidoPaterno} ${data.row.maquilero.persona.apellidoMaterno}`}
        </>
       
       )}
      </>
    )
  },
  {
    field: "cantidad",
    headerName: "Cantidad recolectada",
    width: 150,
  },
  {
    field: "comments",
    headerName: "Comentarios",
    width: 300,
  },
]
}
