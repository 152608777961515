import { client } from "utils/axios"
import * as yup from "yup";

export const deleteUnidades = async (unidadId) => {
  await client.delete(`unidades/${unidadId}`);
};
export async function getAllUnidades() {
  const { data } = await client.get("/unidades");
  return data;
}
export async function getAllChoferUnidadById(unidadId) {
  const { data } = await client.get(`/choferUnidad/filterUnidad/${unidadId}`);
  return data;
}
export const validationSchemaUnidades = yup.object({
  descripcion: yup
    .string("Ingresa descripcion")
    .required("Campo Requerido"),
  fechaAlta: yup.date("Ingresa fecha de alta"),
  fechaBaja: yup.date("Ingresa fecha de baja"),
  marca: yup
    .string("Ingresa Marca").required("Campo Requerido"),
  modelo: yup
    .string("Ingresa Modelo").required("Campo Requerido"),
  ano: yup
    .string("Ingresa Year"),
  tenencia: yup
    .string("Ingresa Tenencia")
  ,
  vin: yup
    .string("Ingresa VIN")
  ,
  valor: yup
    .number("Ingresa Valor")
  ,
  placas: yup
    .string("Ingresa Placas")
    .required("Campo Requerido"),
  bimestre1: yup
    .string("Ingresa Bimestre 1")
  ,
  bimestre2: yup
    .string("Ingresa Bimestre 2")
  ,
  engomado: yup
    .string("Ingresa Engomado")
  ,

});
export async function createUnidades(unidad) {
  try {
    const data = await client.post("unidades", unidad);
    let unidadId = data.data.id;

    for (let i = 0; i < unidad.operadores.length; i++) {
      let obj = {
        activo: true,
        unidadId: {id:unidadId},
        choferId: {id:unidad.operadores[i].id}
      }
      const resp = await client.post("choferUnidad", obj);
      console.log(resp)
    }



  } catch (error) {
    console.log(error)
  }

};
export const modifyUnidades = async (unidad) => {
  try {
    let unidadId = unidad.id
    await client.put("unidades", unidad);
    if (unidadId > 0) {
      const choferUnidad = await client.get(`choferUnidad/filterUnidad/${unidadId}`);
      let result = choferUnidad['data'];
      for (let i = 0; i < result.length; i++) {
        await client.delete(`choferUnidad/${result[i].id}`);
      }
      let operadores = unidad['operadores'];
      for (let i = 0; i < operadores.length; i++) {
        let obj = {
          activo: true,
          fechaCambio: new Date().toISOString().split("T")[0],
          unidad: { id: unidadId },
          chofer: { id: operadores[i].id }
        }
        await client.post("choferUnidad", obj);
      }
    }
  } catch (error) {
    return false
  }

};