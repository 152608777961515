import { useApplicationContext } from 'components-base/application-context/AppContext';
import React, { Component, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';

const BarChart = ({ chartData, chartOptions }) => {
  const { setGlobalFilter,globalFilter } = useApplicationContext();
  const navigate = useNavigate();

  useEffect(() => {
    const xAxisLabels = document.querySelectorAll('.apexcharts-xaxis-label');
    const handleLabelClick = (event) => {
      const categoryValue = event.target.textContent;
      setGlobalFilter(categoryValue);
      navigate("/admin/salidas");
    };

    xAxisLabels.forEach(label => {
      label.addEventListener('click', handleLabelClick);
      label.style.cursor = 'pointer';

    });

    return () => {
      xAxisLabels.forEach(label => {
        label.removeEventListener('click', handleLabelClick);
      });
    };
  }, [chartData,chartOptions]);

  return (
    <Chart
      options={chartOptions}
      series={chartData}
      type="bar"
      width="100%"
      height="100%"
    />
  );

}

export default BarChart;
