import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import PickupsFormFields from "./PickupsFormFields";
import TabComponent from "components-base/tab/Tab";
import { createPickups, modifyPickups, validationSchemaPickups } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const PickupsForm = ({ mode, handleClose }) => {
    const { defaultDataPickups } = useSelector(({ pickups }) => pickups);
    const dispatch = useDispatch();
    const messages = (mensaje) => {
        dispatch(
            showMessage({
                message: `Modificado excelentemente`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );

    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    driver: "",
                    comments: "",
                    quantity:0,
                    folio: "",

                }
                : {
                    id: defaultDataPickups?.id,
                    driver: defaultDataPickups?.driver,
                    comments: defaultDataPickups?.comments,
                    folio: defaultDataPickups?.folio,
                    quantity: defaultDataPickups?.cantidad,

                },
        validationSchema: validationSchemaPickups,
        onSubmit: async (values) => {
            switch (mode) {
                case "POST":
                    // await createPickups(values, dispatch);
                    // messages("Creado")
                    handleClose();
                    break;
                case "PUT":
                    // await modifyPickups(values, dispatch);
                    // messages("Modificado");
                    handleClose();
                    break;
                default: handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;

    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Detalle de entrada",
                    component: <PickupsFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item sx={12} >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item sx={12}>
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default PickupsForm;