import React from "react";
import { Drawer, DialogTitle, IconButton } from "@mui/material";
import ProductForm from "./ProductForm";
import { background_marifer } from "utils/apis";
import { Cancel } from "@mui/icons-material";
import { styles } from "utils/apis";


const AddProduct = ({ mode, defaultData, open, handleClose }) => {

    return (
        <>
            <Drawer
                anchor={"bottom"}
                open={open}
                onClose={handleClose}
                sx={styles}     
            >
                <DialogTitle sx={{display: "flex", justifyContent: "flex-end", padding: 0}}>
                    <IconButton onClick={handleClose} sx={{color:"purple"}}>
                        <Cancel />
                    </IconButton>
                </DialogTitle>
                <ProductForm mode={mode} defaultData={defaultData} handleClose={handleClose} />
            </Drawer>
        </>
    )
}
export default AddProduct;