import { Edit, Delete, QrCode2 } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography, Chip } from "@mui/material";
import {
  setDefaultDataMaquilero,
  setMaquileroId,
} from "store/modules/Maquilero";
import { v4 as uuidv4 } from "uuid";

export const columns = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  handleOpenPDF
) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 140,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar Maquilero" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultDataMaquilero(data.row));
                dispatch(setMaquileroId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Maquilero" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setMaquileroId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir QR" placement="top">
            <IconButton
              aria-label="imprimirQr"
              color="primary"
              onClick={() => {
                handleOpenPDF();
                dispatch(setDefaultDataMaquilero(data.row));
                // dispatch(setMaquileroId(data.row.id));
              }}
            >
              <QrCode2 />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "codigo",
      headerName: "Código",
      width: 200,
    },
    {
      field: "foraneo",
      headerName: "Foráneo",
      width: 200,
      renderCell: (data) => (
        <Typography>{`${data.row.foraneo ? "Si" : "No"}`} </Typography>
      ),
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 350,
      renderCell: (data) => (
        <Typography>
          {`${data.row.persona.nombre} ${data.row.persona.apellidoPaterno} ${data.row.persona.apellidoMaterno} `}{" "}
        </Typography>
      ),
    },
    {
      field: "rfc",
      headerName: "RFC",
      width: 250,
      renderCell: (data) => (
        <Typography>{`${data.row.persona.rfc}`} </Typography>
      ),
    },
    {
      field: "direccion",
      headerName: "Dirección",
      width: 350,
      renderCell: (data) => (
        <Typography>{`${data.row.persona.direccion}`} </Typography>
      ),
    },
    {
      field: "capacidad",
      headerName: "Capacidad",
      width: 150,
      renderCell: (data) => (
        <Chip color="error" label={`${data.row.capacidad}`} />
      ),
    },
  ];
};
