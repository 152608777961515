import {
  Grid,
  TextField,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import ProductImage from "./ProductImage";
import { boxSize } from "utils/apis";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllCategories, getAllTallasByProductoId } from "../functions";
import { getAllTallas } from "components-base/tallas/functions";
const ProductFormFields = ({ formik, setFieldValue, handleClose }) => {
  const [categories, setCategoryData] = useState(null);
  const [tallas, setTallas] = useState(null);
  const { productIdSelected } = useSelector(({ producto }) => producto);
  const [tallasSelected, setTallasSelected] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let categories = await getAllCategories();
      let tallas = await getAllTallas();
      setTallas(tallas);
      setCategoryData(categories);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (tallas) {
      const fetchData = async () => {
        let tallas = await getAllTallasByProductoId(productIdSelected);
        setTallasSelected(tallas.map((item) => item.talla));
      };
      fetchData();
    }
  }, [productIdSelected, tallas]);

  if (!tallasSelected) return "Cargando datos...";

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />

          <TextField
            fullWidth
            id="sku"
            name="sku"
            label="SKU"
            value={formik.values.sku}
            onChange={formik.handleChange}
            error={formik.touched.sku && Boolean(formik.errors.sku)}
            helperText={formik.touched.sku && formik.errors.sku}
            color="primary"
          />
          <TextField
            fullWidth
            id="modelo"
            name="modelo"
            label="Modelo"
            value={formik.values.modelo}
            onChange={formik.handleChange}
            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
            helperText={formik.touched.modelo && formik.errors.modelo}
            color="primary"
          />
          <TextField
            fullWidth
            id="diseno"
            name="diseno"
            label="Diseño"
            value={formik.values.diseno}
            onChange={formik.handleChange}
            error={formik.touched.diseno && Boolean(formik.errors.diseno)}
            helperText={formik.touched.diseno && formik.errors.diseno}
            color="primary"
          />
          <Autocomplete
            name="category"
            options={categories || []}
            id="category"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Empresa" />}
            getOptionLabel={(category) => `${category.name}`}
            onChange={(e, value) => setFieldValue("category", value || "")}
            defaultValue={formik.values.category}
          />
          <Autocomplete
            name={"tallas"}
            multiple
            limitTags={10}
            loading={tallas ? false : true}
            getOptionSelected={(option, value) =>
              option.nombre === value.nombre
            }
            options={tallas || []}
            onChange={(e, options) => {
              e.preventDefault();
              setFieldValue("tallas", options);
            }}
            getOptionLabel={(option) => `${option.nombre}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Tallas disponibles"}
                helperText={"Seleccione las tallas que tendra este modelo"}
              />
            )}
            defaultValue={tallasSelected}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="estampado"
                name="estampado"
                checked={formik.values.estampado}
                onChange={formik.handleChange}
                error={
                  formik.touched.estampado && Boolean(formik.errors.estampado)
                }
                helperText={formik.touched.estampado && formik.errors.estampado}
                color="primary"
              />
            }
            label="Incluye Estampado"
          />
          <FormControlLabel
            control={
              <Checkbox
                id="bordado"
                name="bordado"
                checked={formik.values.bordado}
                onChange={formik.handleChange}
                error={formik.touched.bordado && Boolean(formik.errors.bordado)}
                helperText={formik.touched.bordado && formik.errors.bordado}
                color="primary"
              />
            }
            label="Incluye Bordado"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="descripcion"
            name="descripcion"
            label="Descripción"
            multiline
            rows={5}
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={
              formik.touched.descripcion && Boolean(formik.errors.descripcion)
            }
            helperText={formik.touched.descripcion && formik.errors.descripcion}
            color="primary"
          />
          <ProductImage
            url={formik.values.imagen}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
        >
          <Grid item>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancelar
            </Button>
          </Grid>
          <Grid item sx={12}>
            <Button color="primary" variant="contained" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProductFormFields;
