import { Delete, Edit, Print } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setDefaultDataCut } from "store/modules/CutOrder";

export const columnsCut = (setOpen, setMode, setOpenDelete, dispatch, setOpenPDF) => {
  return [
    {
      field: `acciones`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar orden de corte" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                  dispatch(setDefaultDataCut(data.row));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar orden de corte" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                 dispatch(setDefaultDataCut(data.row));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir Orden de Corte" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenPDF(true);
                 dispatch(setDefaultDataCut(data.row));
              }}
            >
              <Print />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "cutter",
      headerName: "Cortador",
      width: 100,
      renderCell: (data) => (
        <React.Fragment>
          {`${data.row.cutter.name}`}
        </React.Fragment>
      ),
    },
    {
      field: "product",
      headerName: "Modelo",
      width: 300,
      renderCell: (data) => (
        <React.Fragment>
          {`${data.row.product.modelo} (${data.row.product.nombre})`}
        </React.Fragment>
      ),
    },
    {
      field: "cloth",
      headerName: "Tela",
      width: 200,
      renderCell: (data) => (
        <React.Fragment>
          {data.row.cloths.map(c => c.name).join(", ")}
        </React.Fragment>
      ),
    },
    {
        field: "createdByUser",
        headerName: "Creado por:",
        width: 200,
    },
    {
      field: "modifiedByUser",
      headerName: "Modificado por:",
      width: 200,
  },
    // {
    //     field: "cloth",
    //     headerName: "Tela",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.cloth.name}
    //         </React.Fragment>
    //     ),
    // },
    // {
    //     field: "color",
    //     headerName: "Color",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.cloth.color}
    //         </React.Fragment>
    //     ),
    // },
    // {
    //     field: "tone",
    //     headerName: "Tono",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.cloth.tone}
    //         </React.Fragment>
    //     ),
    // },
    // {
    //     field: "average",
    //     headerName: "Promedio",
    //     width: 200,
    //     renderCell: (data) => (
    //         <React.Fragment>
    //               {data.row.isAverage ? "SI":"NO" }
    //         </React.Fragment>
    //     ),
    // },

  ];
};