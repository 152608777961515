import {
  Grid,
  TextField,
  Box, Autocomplete
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect } from "react";
import { useState } from "react";
import { getAllSupervisor } from "components-base/supervisor/functions";
// import { getAllMaquilero } from "components-base/maquileros/functions";
import { getAllOperadores } from "components-base/operadores/functions";
import { useSelector } from "react-redux";

const RutaFormFields = ({ formik, setFieldValue, mode }) => {
  const [supervisores, setSupervisores] = useState(null);
  // const [maquileros, setMaquileros] = useState(null);
  // const [maquilerosValues, setMaquilerosValues] = useState([]);
  const [operadores, setOperadores] = useState(null);
  const { defaultDataRuta } = useSelector(({ ruta }) => ruta)

  useEffect(() => {
    const fetchData = async () => {
      const operadores = await getAllOperadores();
      const supervisores = await getAllSupervisor();
      // const maquileros = await getAllMaquilero();
      if (operadores) setSupervisores(supervisores);
      // if (maquileros) setMaquileros(maquileros);
      if (operadores) setOperadores(operadores);
    }
    fetchData();
  }, []);



  const handleChangeOperador = async (e, value) => {
    // setUnidades(null)
    // setUnidadValues({ descripcion: "", placas: "" })
    // if (value) {
    //   const unidad = await getAllUnidadByChoferId(value.id);
    //   setUnidades(unidad.map(item => item.unidad))
    // }
    // setFieldValue("operador", value || "");
    // setOperadorValues(value);
    // if (!value)
    //   setOperadorValues({ persona: { nombre: "", apellidoMaterno: "", apellidoPaterno: "" }, licencia: "" })
  }

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={
              formik.touched.nombre && Boolean(formik.errors.nombre)
            }
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />

          <TextField
            fullWidth
            id="codigo"
            name="codigo"
            label="Código"
            value={formik.values.codigo}
            onChange={formik.handleChange}
            error={
              formik.touched.codigo &&
              Boolean(formik.errors.codigo)
            }
            helperText={
              formik.touched.codigo &&
              formik.errors.codigo
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="region"
            name="region"
            label="Región"
            value={formik.values.region}
            onChange={formik.handleChange}
            error={
              formik.touched.region &&
              Boolean(formik.errors.region)
            }
            helperText={
              formik.touched.region &&
              formik.errors.region
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="zona"
            name="zona"
            label="Zona"
            value={formik.values.zona}
            onChange={formik.handleChange}
            error={
              formik.touched.zona &&
              Boolean(formik.errors.zona)
            }
            helperText={
              formik.touched.zona &&
              formik.errors.zona
            }
            color="primary"
          />
          <TextField
            fullWidth
            id="descripcion"
            name="descripcion"
            label="Descripción"
            multiline
            rows={7}
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={
              formik.touched.descripcion &&
              Boolean(formik.errors.descripcion)
            }
            helperText={
              formik.touched.descripcion &&
              formik.errors.descripcion
            }
            color="primary"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name={"supervisor"}
            options={supervisores || []}
            multiple
            limitTags={10}
            getOptionSelected={(option, value) => option.name === value.name}
            id="supervisor"
            fullWidth
            renderInput={(params) => <TextField helperText={"Seleccione el Supervisor(es) de esta Ruta"} {...params} label="Supervisor de Ruta" />}
            getOptionLabel={(supervisor) => `${supervisor.persona.nombre} ${supervisor.persona.apellidoPaterno} ${supervisor.persona.apellidoMaterno}`}
            onChange={(e, value) => setFieldValue("supervisor", value || "")}
            defaultValue={defaultDataRuta?.supervisores?.map(item => item.supervisor)}
          />
          {/* <Autocomplete
            name={"maquileros"}
            multiple
            limitTags={10}
            getOptionSelected={(option, value) => option.name === value.name}
            options={maquileros || []}
            onChange={(e, options) => { e.preventDefault(); setFieldValue("maquileros", options); setMaquilerosValues(options) }}
            getOptionLabel={(option) => `${option.persona.nombre} ${option.persona.apellidoPaterno} ${option.persona.apellidoMaterno}`}
            onInputChange={(e, value) => { console.log("hello") }}
            renderInput={(params) => (<TextField {...params} label={"Maquilero"}
              helperText={"Seleccione los Maquileros de esta Ruta"}
            />
            )}
            defaultValue={defaultDataRuta?.maquileros?.map(item => item.maquilero)}
          /> */}
          <Autocomplete
            name={"operadores"}
            multiple
            limitTags={10}
            getOptionSelected={(option, value) => option.name === value.name}
            options={operadores || []}
            id="operadores"
            fullWidth
            defaultValue={defaultDataRuta?.operadores?.map(item => item.chofer)}
            renderInput={(params) => <TextField {...params} label="Operador" helperText={"Seleccione Operador de esta Ruta"} />}
            getOptionLabel={(operador) => `${operador.persona.nombre} ${operador.persona.apellidoPaterno} ${operador.persona.apellidoPaterno}`}
            onChange={(e, options) => { setFieldValue("operadores", options) }}
          />
        </Grid>
      </Grid>
    </Box>

  )
}
export default RutaFormFields;