import { client } from "utils/axios";
import * as yup from "yup";

export const  createTalla = async (talla) =>{
    client
      .post("tallas", talla)
      .then((response) => {
    
      })
      .catch(function (error) {
        console.log(error);
      });

}
export async function getAllTallas(){
    const {data} = await client.get("/tallas");
    return data;
}
export const validationSchemaTalla = yup.object({
    nombre: yup
        .string("Ingresa nombre de la Talla")
        .required("Campo Requerido"),
    descripcion: yup
        .string("Ingresa Descripción")
        .required("Campo Requerido"),
    codigo: yup
        .string("Ingresa Código")
        .required("Campo Requerido"),
});
export const deleteTalla = async  (tallaId) => {
   await client
      .delete(`tallas/${tallaId}`)
    
  };

  export const modifyTalla = async (talla) =>  {
  await  client
      .put("tallas", talla)
     
  };