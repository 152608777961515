import OperadoresTable from "./components/OperadoresTable";

const Operadores = () =>{

    return(
        <div>
            <OperadoresTable />
             
        </div>
    )
}
export default Operadores;