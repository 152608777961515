import {  Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PrendaForm from "./PrendaForm";
import { Cancel } from "@mui/icons-material";
import { useSelector } from "react-redux";

const PrendaModal = ({ mode, open, handleClose }) => {

    const { defaultData } = useSelector(({ prenda }) => prenda);
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            style={{ padding: 10 }}
            fullScreen
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}>
                <IconButton onClick={handleClose} sx={{ color: "purple" }}>
                    <Cancel />
                </IconButton>
            </DialogTitle>
            <DialogTitle>
                {mode === "POST" ? "Agregar Prenda" : "Editar Prenda"}
            </DialogTitle>
            <DialogContent>
                <PrendaForm defaultData={defaultData} handleClose={handleClose} mode={mode} />
            </DialogContent>
        </Dialog>

    )
}
export default PrendaModal;