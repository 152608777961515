import React, { useState } from "react";
import { Button } from "@mui/material";
import { useFormik, getValues } from "formik";
import { Grid, Box, } from "@mui/material";
import InsumoFormFields from "./InsumoFormFields";
import TabComponent from "components-base/tab/Tab";
import { createInsumo,modifyInsumo, validationSchemaInsumo } from "../functions";
import {  useSelector } from "react-redux";

const InsumoForm = ({ mode, handleClose }) => {
    const [cantidad,setCantidad] = useState(0);
    const [unidad,setUnidad] = useState("");
    const [presentacion,setPresentacion]= useState("")
    const {defaultData} = useSelector(({talla})=>talla);
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    id: null,
                    nombre: "",
                    descripcion: "",
                    codigo: "",
                    internal:false
                }
                : {
                    id: defaultData.id,
                    nombre: defaultData.nombre,
                    descripcion: defaultData.descripcion,
                    codigo: defaultData.codigo,
                    details:defaultData.details,
                    internal:defaultData.internal

                },
        validationSchema: validationSchemaInsumo,
        onSubmit: async (values) => {
            let details = {presentacion:presentacion,cantidad:cantidad}
            if(unidad)
            details.unidad = unidad
          values.details = details;
            switch(mode){
                case "POST":
                   await createInsumo(values);
                    handleClose();
                break;
                case "PUT":
                  await  modifyInsumo(values);
                    handleClose();
                break;
                default:handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información del Insumo",
                    component: <InsumoFormFields cantidad={cantidad} setUnidad={setUnidad} setPresentacion={setPresentacion} setCantidad={setCantidad} formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }

            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item sx={12} >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item sx={12}>
                    <Button color="primary" variant="contained" type="secondary">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default InsumoForm;