import { getAllConsumptionByPrendaV2 } from "components-base/prendas/functions"
import { client } from "utils/axios";
import moment from "moment";
export async function getAllClothsByProduct(prendas) {
    let cloths = [];

    for (let j = 0; j < prendas.length; j++) {
        const pp = prendas[j];
        const prendaId = pp.id;
        const cloth = await getAllConsumptionByPrendaV2(prendaId);
        cloth.forEach(c => {
            let _cloth = c.cloth;
            if (cloths.length === 0) {
                cloths.push(_cloth)
            } else {
                let _clothCheck = cloths.find(item => item.id === _cloth.id)
                if (!_clothCheck)
                    cloths.push(_cloth)
            }
        })

    }
    return cloths;
}

export async function dataTransform(detail) {

    let products = [];
    let cloths = [];
    detail.detallePedido.forEach(item => {
        item.producto.tallas = item.details.tallas
        products = [...products, item.producto]
    })

    let obj = {
        cloths,
        products,
        percentage: detail.percentage
    }
    return obj;
}
export function transformSizes(sizes, percentage) {

    let data = [];
    sizes.forEach(s => {
        let _percentage = Math.floor(Number(s.cantidad * percentage / 100));
        let obj = {
            name: s.talla.nombre,
            pieces: s.cantidad,
            percentage: _percentage,
            total: Number(s.cantidad) + _percentage,
            cut: ""

        }
        data = [...data, obj];
    })

    return data;

}

export async function getAllSpecificationSheetsById(id) {
    const { data } = await client.get(`/productos/${id}`);
    return data;
}
export async function getAllSpecificationSheetsByProductoId(id) {
    const { data } = await client.get(`/specificationSheet/filter/${id}`);
    return data;
}
export async function getAllSpecificationSheets() {
    const { data } = await client.get("/specificationSheet");
    return data;
}
export async function getAllSpecificationSheetById(id) {
    const { data } = await client.get(`/specificationSheet/${id}`);
    return data;
}
export const createSpecificationSheet = async (input) => {
    let obj = {
        createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    }
    obj = { ...obj, ...input };
    try {
        await client.post("specificationSheet", obj);

    } catch (error) {
        console.log(error)
    }
};
export const modifySpecificationSheet = async (input) => {
    let obj = {
        modifiedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    }
    obj = { ...obj, ...input };
    try {
        await client.put("specificationSheet", obj);

    } catch (error) {

    }
};
export const deleteSpecificationSheet = async (id) => {
    await client.delete(`specificationSheet/${id}`);
};
export function DataCutConvert(data) {
    let obj = {
        cloth: data.cloths.map(c => c.name).join(", "),
        cutter: data.cutter.name,
        model: `${data.product.modelo} (${data.product.nombre})`,
        verifier: data.verifier,
        defects: data.defects || "",
        cutTime: data.cutTime,
        deliveryDate: data.deliveryDate.split(" ")[0],
        image: data.product.imagen,
        sizes: data.sizes
    }

    return obj;
}