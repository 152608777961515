import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

import image from "../../../assets/img/marifer.png";
import {
  dataConvert,
  insumosConvert,
  totalSum,
  totalSumByPrenda,
} from "../functions";
import moment from "moment";
import checkmark from "../../../assets/img/checkmark.png";
import { convertData } from "./functions";

const Checkbox = ({ isChecked, label }) => (
  <View style={styleMark.checkboxContainer}>
    <Text>{label} </Text>
    <View style={[styleMark.checkbox, isChecked && styleMark.checked]}>
      {isChecked && <Image src={checkmark} style={styles.checkMarkImage} />}
    </View>
  </View>
);
const styleMark = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 20,
    height: 20,
    marginRight: 5,
    border: "1 solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  checked: {
    backgroundColor: "white",
  },
  checkMarkImage: {
    width: 15,
    height: 15,
  },
});

// Create styles
const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 15,
    height: 15,
    marginRight: 4,
    border: "1 solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  checked: {
    backgroundColor: "pink",
  },
  cross: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
  },
  image2: {
    position: "absolute",
    objectPosition: "0",
    zIndex: -999,
    width: "320px",
    height: "320px",
    opacity: 0.2,
  },
  image: {
    position: "relative",
    objectFit: "cover",
    zIndex: -999,
    width: "120px",
    height: "120px",
    paddingLeft: 0,
    opacity: 0.9,
  },
  header: {
    color: "#ff0080",
    textTransform: "uppercase",
    marginTop: 25,
    paddingLeft: 40,
    fontSize: "18pt",
    fontWeight: "extrabold",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
    height: "7.975cm",
  },
  section1: {
    padding: 8,
    textAlign: "center",
    width: "7cm",
    height: "100%",
    backgroundColor: "white",
    border: 1,
    borderColor: "#0A83F5",
    lineHeight: 1.5,
    fontSize: "10pt",
  },
  section2: {
    padding: 8,
    lineHeight: 4,
    border: 1,
    borderColor: "#0A83F5",
    width: "14.6cm",
    height: "100%",
    backgroundColor: "white",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
// Create styles

const PickupsPDF = ({ data }) => {
  
const details = convertData(data);

  return (
    <PDFViewer style={styles.viewer}>
      {
        <Document>
   
            <Page size="LETTER" style={styles.page}>
              <View style={styles.mainSection}>
                <View>
                  <Image style={styles.image} src={image} />
                </View>
                <View>
                  <Text style={styles.header}>
                    {"Reporte de entradas por chofer"}
                  </Text>
                </View>
                <View
                  style={{
                    height: 20,
                    flexDirection: "row",
                    marginTop: 25,
                    marginLeft: 20,
                  }}
                >
               
                  <Text style={{ color: "red", padding: 0 }}>
                   
                  </Text>
                </View>
              </View>
              <View
                //   style={{ flexDirection: "row", textAlign: "center", fontSize: "9pt", color: "gray",wordWrap:"word-break" }}
                style={{
                  fontSize: 10,
                  flexDirection: "row",
                  textAlign: "center",
                  wordWrap: "word-break",
                  paddingLeft: 320,
                  marginTop: -140,
                  paddingBottom: 10,
                }}
              >
          
              
                <View>
                  <View>
                    <Text
                      style={{
                        border: 1,
                        padding: 1,
                        width: 75,
                        textAlign: "center",
                      }}
                    >
                      {"Total"}
                    </Text>
                  </View>

                  <Text
                    style={{
                      border: 1,
                      width: 75,
                      padding: 1,
                      height: 25,
                      textAlign: "center",
                    }}
                  >
                    {details.total}
                 
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      wordWrap: "break-word",
                      border: 1,
                      padding: 1,
                      width: 100,
                      textAlign: "center",
                    }}
                  >
                    {"Chofer"}
                  </Text>

                  <Text
                    style={{
                      width: 100,
                      textAlign: "center",
                      wordWrap: "word-break",
                      border: 1,
                      fontSize: 7,
                      paddingTop: 2,
                      height: 25,
                    }}
                  >
                    {details.driver}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      wordWrap: "break-word",
                      border: 1,
                      padding: 1,
                      width: 100,
                      textAlign: "center",
                    }}
                  >
                    {"Fecha de entrada"}
                  </Text>

                  <Text
                    style={{
                      width: 100,
                      textAlign: "center",
                      wordWrap: "word-break",
                      border: 1,
                      fontSize: 7,
                      paddingTop: 2,
                      height: 25,
                    }}
                  >
                    {details.date}
                  </Text>
                </View>
         
              </View>
              <View
                style={{
                  flexDirection: "row",
                  padding: 5,
                  backgroundColor: "pink",
                }}
              >
                <View style={{ width: "60%", marginRight: "10px" }}>
                  <Text style={{ fontSize: 10 }}>
                    {`Detalles: `}
                  </Text>
                </View>
                <View>
                  
                </View>
              </View>
              <View style={{ height: 600, border: 1, marginTop: 10 }}>
                <View style={{ alignItems: "center" }}>
                  <Image src={image} style={styles.image2} />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    fontSize: 7,
                    color: "#000111",
                    height:20
                  }}
                >
                  <View style={{ width: "8%", border: 1 }}>
                    <Text>{"Fecha de recoleccion"}</Text>
                  </View>
                  <View style={{ width: "7%", border: 1 }}>
                    <Text>{"Cantidad"}</Text>
                  </View>
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{"Prendas"}</Text>
                  </View>
                  <View style={{ width: "18%", border: 1 }}>
                    <Text>{"Talla"}</Text>
                  </View>
                  <View style={{ width: "13%", border: 1 }}>
                    <Text>{"Maquilero"}</Text>
                  </View>
                  <View style={{ width: "7%", border: 1 }}>
                    <Text>{"Folio Salida"}</Text>
                  </View>
                  <View style={{ width: "27%", border: 1 }}>
                    <Text>{"Observaciones"}</Text>
                  </View>
                </View>

           

                {data.map((i,index) => (
                  <View
                    key={index}
                    style={{
                      height: 38,
                      flexDirection: "row",
                      textAlign: "center",         
                      fontSize:7
                    }}
                  >
                    <View style={{ width: "8%", border: 1 }}>
                      <Text>{i.fechaEntrada.replace("T", " ")}</Text>
                    </View>
                    <View style={{ width: "7%", border: 1, fontSize:9 }} >
                      <Text>{i.cantidad}</Text>
                    </View>
                    <View style={{ width: "20%", border: 1}}>
                      { i.prenda ? <Text>{i.prenda} </Text> :     i.details.prendas.map((p,index )=>(
                        <View key={index}>
                     <Text>{`${index + 1}.- ${p.nombre}`} </Text>
                     {/* <Image style={{left:"50%",top:"30%", width:25, height:25,borderRadius:5}} src={p.imagen || image}  /> */}
                        </View>
                      ))}
                    </View>
                    <View style={{ width: "18%", border: 1}}>
                      {i.talla ? <Text>{i.talla}</Text>  :   i.details.prendas.map((p,index )=>(
                        <View key={index}>
                     <Text>{`${index + 1}.-${i.salida.details.data.find(item => item.talla.id === p.tallaId).talla.nombre}`} </Text>
                     {/* <Image style={{left:"50%",top:"30%", width:25, height:25,borderRadius:5}} src={p.imagen || image}  /> */}
                        </View>
                      ))}
                    </View>
                    <View style={{ width: "13%", border: 1}}>
                      {
                        i.maquilero && (
                          <Text>{`${i.maquilero.persona.nombre} ${i.maquilero.persona.apellidoPaterno} ${i.maquilero.persona.apellidoMaterno}`}</Text>
                        )
                      }
                    </View>
                    <View style={{ width: "7%", border: 1 }}>
                      {
                        i.salida && (
                          <Text>{i.salida.folio}</Text>
                        )
                      }
                    
                    </View>
                    <View style={{ width: "27%", border: 1 }}>
                      {
                    
                          <Text>{i.comments}</Text>
                    
                      }
                    
                    </View>
                  </View>
                ))}

              </View>

              {/* <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "13pt",
                  color: "gray",
                  height: 100,
                  border: 1,
                }}
              >
                <Text>{`Observaciones: ${details.comments}`}</Text>
              </View> */}
              {/* <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "13pt",
                  color: "pink",
                  height: 30,
                  backgroundColor: "pink",
                  border: 1,
                  marginTop: 10,
                }}
              >
                
              </View> */}
 
            </Page>
        
        </Document>
      }
    </PDFViewer>
  );
};
export default PickupsPDF;
