import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box } from "@mui/material";
import PedidosFormFields from "./PedidosFormFields";
import TabComponent from "components-base/tab/Tab";
import {
  createPedido,
  modifyPedido,
  validationSchemaPedido,
} from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";
import PedidoDetalle from "./PedidoDetalles";
import moment from "moment";

const PedidosForm = ({ mode, handleClose }) => {
  const { defaultData } = useSelector(({ producto }) => producto);
  const dispatch = useDispatch();
  const messages = (mensaje) => {
    dispatch(
      showMessage({
        message: `Producto ${mensaje} con éxito`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
    const { setFieldValue } = formik;
    return (
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          "& .MuiTextField-root": { marginBottom: "25px" },
        }}
        style={{ padding: "20px", justifyContent: "center" }}
      >
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "flex-row", padding: 2 }}
        >
          <TabComponent
            tabsElement={[
              {
                label: "Información del Básica del Pedido",
                component: (
                  <PedidosFormFields
                    formik={formik}
                    mode={mode}
                    setFieldValue={setFieldValue}
                  />
                ),
              },
              {
                label: "Producto",
                component: (
                  <PedidoDetalle
                    formik={formik}
                    mode={mode}
                    setFieldValue={setFieldValue}
                  />
                ),
                disabled: mode === "POST" ? true : false,
              },
            ]}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
        >
          <Grid item>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };
  const formik = useFormik({
    initialValues:
      mode === "POST"
        ? {
            codigo: "",
            fechaCreacion: moment().format("YYYY-MM-DD"),
            fechaEntrega: moment().format("YYYY-MM-DD"),
            fechaEstimadaTermino: moment().format("YYYY-MM-DD"),
            percentage: 0,
            number: 1
          }
        : {
            id: defaultData.id,
            codigo: defaultData.codigo,
            fechaCreacion: defaultData.fechaCreacion,
            fechaEntrega: defaultData.fechaEntrega,
            fechaEstimadaTermino: defaultData.fechaEstimadaTermino,
            percentage: defaultData.percentage,
            number: defaultData.number
          },
    validationSchema: validationSchemaPedido,
    onSubmit: async (values) => {
      switch (mode) {
        case "POST":
          await createPedido(values, dispatch);
          messages("creado");
          handleClose();
          break;
        case "PUT":
          await modifyPedido(values, dispatch);
          messages("modificado");
          handleClose();
          break;
        default:
          handleClose();
      }
    },
  });
  const { setFieldValue } = formik;
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        "& .MuiTextField-root": { marginBottom: "25px" },
      }}
      style={{ padding: "20px", justifyContent: "center" }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-row", padding: 2 }}
      >
        <TabComponent
          tabsElement={[
            {
              label: "Información Básica del Pedido",
              component: (
                <PedidosFormFields
                  formik={formik}
                  mode={mode}
                  setFieldValue={setFieldValue}
                />
              ),
            },
            {
              label: "Producto",
              component: (
                <PedidoDetalle
                  formik={formik}
                  mode={mode}
                  setFieldValue={setFieldValue}
                />
              ),
              disabled: mode === "POST" ? true : false,
            },
          ]}
        />
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <Grid item>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PedidosForm;
