import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export async function deletePickups(pickupsId) {
    await client.delete(`pickups/${pickupsId}`);
};
export async function getAllPickups(date) {
    const { data } = await client.get(`/entradaCorte/date/${date}`);
    return data;
}
export async function getAllPickupsByPedidoId(id) {
    const { data } = await client.get(`/entradaCorte/pedido/${id}`);
    return data;
}

export const validationSchemaPickups = yup.object({
    name: yup
        .string("Ingresa nombre de Pickups")
        .required("Campo Requerido"),
    code: yup
        .string("Ingresa Codigo")
        .required("Campo Requerido"),

});
export const createPickups = async (pickups, dispatch) => {
    try {

        const { data } = await client.post("pickups", pickups);
        dispatch(
            showMessage({
                message: "Departamento/Area agregada con éxito",
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );

    } catch (error) {
        dispatch(
            showMessage({
                message:
                    "Ocurrió un error al agregar un departamento/area: ",
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );
    }
};

export const modifyPickups = async (pickups, dispatch) => {
    try {

        await client.put("entradaCorte", pickups);

        dispatch(
            showMessage({
                message: `"${pickups.folio}" modificada con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    } catch (error) {
        dispatch(
            showMessage({
                message:
                    `Ocurrió un error al modificar la categoria "${pickups.name}"`,
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    }

};
