/*
 Initial state and properties
 */
 export const initialState = {
    defaultDataSS: null
}
/*
 Action types
 */
export const SET_DEFAULT_DATA_SS = 'SET_DEFAULT_DATA_SS'
/*
 Arrow function for change state
 */
export const setDefaultDataSS = (payload) => ({
    type: SET_DEFAULT_DATA_SS,
    payload,
});
/*
 Reducer to describe how the state changed
 */
export default function Reducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_DEFAULT_DATA_SS:
            return {
                ...state,
                defaultDataSS: payload,
            }
        default:
            return state
    }
}
