import { background } from "@chakra-ui/system";
import { client } from "utils/axios";

export async function getAllChoferes() {
  const { data } = await client.get("choferes");
  return data;
}
export const months = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];
export async function generateCode(entity) {
  const { data } = await client.get(entity);

  let prefix = "";
  switch (entity) {
    case "worker":
      prefix = "TR";
      break;
    case "position":
      prefix = "POS";
      break;
    case "incidentType":
      prefix = "INC";
      break;
    case "cloth":
      prefix = "TEL";
      break;

    default:
      break;
  }
  let ids = data.map((i) => Number(i.id));
  if (ids.length === 0) ids = [0];
  let max = Math.max(...ids);
  let code = `${prefix}-${String(max + 1).padStart(5, "0")}`;
  return code;
}

export const boxSize = "600px";
export const imageMap =
  "https://firebasestorage.googleapis.com/v0/b/mariferdev.appspot.com/o/productos_images%2Fmap_trucks.jpg?alt=media&token=1f8392de-deb9-46e5-ba1b-8f41f505db28&_gl=1*1uc9w43*_ga*MTA5Mjc1NTU5Mi4xNjc5MDE4MjMy*_ga_CW55HF8NVT*MTY4NjYyMTExNC4xOC4xLjE2ODY2MjEzMjIuMC4wLjA.";
//export const background_marifer = "https://firebasestorage.googleapis.com/v0/b/mariferdev.appspot.com/o/background_marifer.png?alt=media&token=b3ed6b96-a700-40f6-8bd2-163e3c40f762&_gl=1*fa9lhq*_ga*MTA5Mjc1NTU5Mi4xNjc5MDE4MjMy*_ga_CW55HF8NVT*MTY4NjIzODc4NC4xMS4xLjE2ODYyMzg4MzEuMC4wLjA."
export const background_marifer =
"https://firebasestorage.googleapis.com/v0/b/mariferdev.appspot.com/o/Boopsy_Logo.png?alt=media&token=7523bd05-3b07-4230-9179-3c29fd5376f7"
export const imagen_insumos =
  "https://firebasestorage.googleapis.com/v0/b/mariferdev.appspot.com/o/image_2023-07-08_103915397.png?alt=media&token=9fb12bee-20bc-4608-91f3-37c7493fb19c";
export const boopsi_back = "https://firebasestorage.googleapis.com/v0/b/mariferdev.appspot.com/o/boopsy_back.png?alt=media&token=3e76826e-2940-4a26-ae68-16c01a58885c"
export const optionMonths = [
  { name: "ENERO", number: 0 },
  { name: "FEBRERO", number: 1 },
  { name: "MARZO", number: 2 },
  { name: "ABRIL", number: 3 },
  { name: "MAYO", number: 4 },
  { name: "JUNIO", number: 5 },
  { name: "JULIO", number: 6 },
  { name: "AGOSTO", number: 7 },
  { name: "SEPTIEMBRE", number: 8 },
  { name: "OCTUBRE", number: 9 },
  { name: "NOVIEMBRE", number: 10 },
  { name: "DICIEMBRE", number: 11 },
];
export const styles = {

  '& .MuiDrawer-paper': {
      position: 'absolute',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      '&:before': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          content: '""',
          display: 'block',
      }
  }
}