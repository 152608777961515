import { client } from "utils/axios";
import * as yup from "yup";
import { showMessage } from "store/modules/Message";
import moment from "moment";

export const deletePedido = async (id) => {
  await client.delete(`pedidos/${id}`);
};
export const deleteDetalle = async (id) => {
  await client.delete(`detallePedido/${id}`);
};
export async function getAllPedidos() {
  const { data } = await client.get("/pedidos");

  return data;
}
export async function getAllEntradaCorte() {
  const { data } = await client.get("/entradaCorte");

  return data;
}
export async function getAllStatus() {
  const { data } = await client.get("/status");
  return data;
}
export async function getAllEventsByPedido(id) {
  try {
    const { data } = await client.get(`/eventoPedido/filter/${id || 0}`);
    return data;
  } catch (error) {
    return [];
  }
}
export async function getAllDetallePedidos(id) {
  const { data } = await client.get(`/detallePedido/filter/${id}`);
  return data;
}
export const validationSchemaPedido = yup.object({
  codigo: yup.string("Ingresa código del Pedido").required("Campo Requerido"),
  fechaCreacion: yup.date("Ingresa fecha de creación"),
  fechaEntrega: yup.date("Ingresa fecha de creación"),
  fechaEstimadaTermino: yup.date("Ingresa fecha de creación"),
});
export const validationSchemaPedidoDetalle = yup.object({
  productoId: yup
    .number("Selecciona código del Producto")
    .required("Campo Requerido")
    .moreThan(0),
  cantidad: yup.number("Cantidad").required("Campo Requerido").moreThan(0),
  tallaId: yup
    .number("Selecciona una talla")
    .required("Campo Requerido")
    .moreThan(0),
  pedidoId: yup.number("Pedido").required("Campo Requerido").moreThan(0),
});
export const createPedido = async (pedido, dispatch) => {
  try {
    await client.post("pedidos", pedido);
    dispatch(
      showMessage({
        message: "Pedido agregado con éxito",
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
    );
  } catch (error) {
    dispatch(
      showMessage({
        message: "Ocurrió un error al agregar un nuevo pedido: ",
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
    );
  }
};
export const modifyPedido = async (pedido, dispatch) => {
  try {
    await client.put("pedidos", pedido);
    dispatch(
      showMessage({
        message: "Pedido modificado con éxito",
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  } catch (error) {
    dispatch(
      showMessage({
        message: "Ocurrió un error al modificar un nuevo pedido",
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  }
};

export const createPedidoDetalle = async (pedidoDetalle) => {
  try {
    await client.post("detallePedido", pedidoDetalle);
    return true;
  } catch (error) {
    return error;
  }
};

export const modifyPedidoDetalle = async (pedido) => {
  try {
    await client.put("detallePedido", pedido);
  } catch (error) {
    console.error(error);
  }
};
export const modifyEvent = async (event) => {
  try {
    await client.put("eventoPedido", event);
  } catch (error) {
    console.error(error);
  }
};
export const createEvent = async (event) => {
  try {
    await client.post("eventoPedido", event);
  } catch (error) {
    console.error(error);
  }
};
export async function updateStatus(option, pedidoId, userName) {
  //close previous status
  let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
  try {
    const data = await getAllEventsByPedido(pedidoId);
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        if (obj.fechaCompletado) continue;
        obj.fechaCompletado = currentDateTime;
        obj.modifiedByUser = userName;
        await modifyEvent(obj);
      }
    }
    // create ne event with new status
    let newObj = {
      fechaInicio: currentDateTime,
      notas: "",
      status: option,
      pedidoId: pedidoId,
      modifiedByUser: userName,
    };
    await createEvent(newObj);
  } catch (error) {}
}
export function DataCutConvert(data) {
  const sizes = data.sizes;

  let totalPercentage = 0;
  let totalPieces = 0;
  let grandTotal = 0;
  sizes.forEach((item) => {
    totalPercentage += Number(item.percentage);
    totalPieces += Number(item.pieces);
    grandTotal += Number(item.total);
  });
  const totals = {
    totalPercentage,
    totalPieces,
    grandTotal,
  };
  let obj = {
    cloth: data.cloths.map((c) => c.name).join(", "),
    cutter: data.cutter.name,
    model: `${data.product.modelo} (${data.product.nombre})`,
    verifier: data.verifier,
    defects: data.defects || "",
    cutTime: data.cutTime,
    deliveryDate: data.deliveryDate.split(" ")[0],
    image: data.product.imagen,
    sizes: data.sizes,
    totals: totals,
  };

  return obj;
}
