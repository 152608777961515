import React, { useState } from "react";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import ProductFormFields from "./ProductFormFields";
import TabComponent from "components-base/tab/Tab";
import {
  createProduct,
  modifyProduct,
  validationSchemaProduct,
} from "../functions";
import PrendasTable from "components-base/prendas/components/PrendasTable";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const ProductForm = ({ mode, handleClose }) => {
  const { defaultData } = useSelector(({ producto }) => producto);
  const [changed, setChanged] = useState(false);
  const dispatch = useDispatch();
  const messages = (mensaje) => {
    dispatch(
      showMessage({
        message: `Producto ${mensaje} con éxito`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };
  const formik = useFormik({
    initialValues:
      mode === "POST"
        ? {
            nombre: "",
            descripcion: "",
            sku: "",
            modelo: "",
            diseno: "",
            estampado: false,
            bordado: false,
            imagen: "",
            prenda: [],
            tallas: [],
            category: { id: 14, nombre: "Coppel" },
          }
        : {
            id: defaultData.id,
            nombre: defaultData.nombre,
            descripcion: defaultData.descripcion,
            sku: defaultData.sku,
            modelo: defaultData.modelo,
            diseno: defaultData.diseno,
            estampado: defaultData.estampado,
            bordado: defaultData.bordado,
            imagen: defaultData.imagen,
            prenda: defaultData.prenda,
            category: defaultData.category,
            tallas: [],
          },
    validationSchema: validationSchemaProduct,
    onSubmit: async (values) => {
      switch (mode) {
        case "POST":
          await createProduct(values, dispatch);
          messages("creado");
          handleClose();
          break;
        case "PUT":
          await modifyProduct(values, dispatch, changed);
          messages("modificado");
          handleClose();
          break;
        default:
          handleClose();
      }
    },
  });
  const { setFieldValue } = formik;
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        "& .MuiTextField-root": { marginBottom: "25px" },
      }}
      style={{ padding: "20px", justifyContent: "center" }}
    >
      <TabComponent
        tabsElement={[
          {
            label: "Información del Producto",
            component: (
              <ProductFormFields
                setChanged={setChanged}
                handleClose={handleClose}
                formik={formik}
                mode={mode}
                setFieldValue={setFieldValue}
              />
            ),
          },
          {
            label: "Detalles, Prendas, Insumos, Consumo de tela",
            component: (
              <PrendasTable
                prendas={mode === "POST" ? [] : defaultData.prenda}
              />
            ),
            disabled: mode === "POST" ? true : false,
          },
        ]}
      />
    </Box>
  );
};
export default ProductForm;
