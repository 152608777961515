
import { Dialog, DialogContent, Grid, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";

const AddMaquina = ({ setMaquina, open, handleClose, defaultValues }) => {
    const [tipo, setTipo] = useState('');
    const [total, setTotal] = useState(0);
    const [operarios, setOperarios] = useState(0);
    const [minutos, setMinutos] = useState(0);
    const [oldName, setOldName]= useState('')

    useEffect(()=>{
        if(defaultValues){
            setTipo(defaultValues.nombre);
            setOldName(defaultValues.nombre)
            setTotal(defaultValues.numero);
            setOperarios(defaultValues.operarios);
            setMinutos(defaultValues.minutosCorteOperario);
        }else{
            setTipo("");
            setOldName("")
            setTotal(0);
            setOperarios(0);
            setMinutos(0);
        }
    },[defaultValues]);

    const handleSave = () => {
        if (tipo !== "") {
            let obj = {
                numero: total,
                nombre: tipo,
                totalOperarios: operarios,
                minutosCorteOperario: minutos
            }
            if(defaultValues)
                setMaquina((m) => [...m.filter(item => item.nombre !== oldName), obj]);
            else
                setMaquina((m) => [...m, obj]);
            
            handleClose();
        }

    }

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={"sm"}
            fullWidth
        >
            <DialogContent>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="maquina"
                            name="maquina"
                            label="Tipo de Máquina"
                            value={tipo}
                            onChange={(e) => setTipo(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={total}
                            id="totalMaquinas"
                            onChange={(e) => setTotal(e.target.value)}
                            type="number"
                            name="total"
                            label="Total de Máquinas"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={operarios}
                            id="operadores"
                            type="number"
                            name="operadores"
                            label="Número de Operarios"
                            onChange={(e) => setOperarios(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}


                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={minutos}
                            id="minutos"
                            type="number"
                            name="minutos"
                            label="Minutos por corte"
                            onChange={(e) => setMinutos(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}


                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Button color="primary" variant="contained" fullWidth onClick={handleClose}>
                            {"Cancelar"}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth variant="contained" color="primary" onClick={handleSave}>
                            {"Guardar"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )


}
export default AddMaquina;