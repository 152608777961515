import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import UnidadesFormFields from "./UnidadesFormFields";
import TabComponent from "components-base/tab/Tab";
import { createUnidades,modifyUnidades,
    // validationSchemaUnidades 
    } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";
import moment from "moment";

const UnidadesForm = ({ mode, handleClose }) => {
    const{defaultDataUnidad} = useSelector(({unidad})=>unidad);
    const dispatch = useDispatch();
    const messages = (mensaje) =>{
        dispatch(
            showMessage({
              message: `Unidades ${mensaje} con éxito`,
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            })
          );

    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    nombre: "",
                    descripcion: "",
                    modelo:"",
                    marca: "",
                    vin: "",
                    ano:"",
                    bimestre1:"",
                    bimestre2:"",
                    tenencia:"",
                    valor:0,
                    fechaAlta:moment().format("YYYY-MM-DD"),
                    fechaBaja:moment().format("YYYY-MM-DD"),
                    engomado: "",
                    placa:""
                }
                : {
                    id: defaultDataUnidad.id,
                    nombre: defaultDataUnidad.nombre,
                    descripcion: defaultDataUnidad.descripcion,
                    modelo:defaultDataUnidad.modelo,
                    marca: defaultDataUnidad.marca,
                    vin: defaultDataUnidad.vin,
                    bimestre1:defaultDataUnidad.bimestre1,
                    bimestre2:defaultDataUnidad.bimestre2,
                    tenencia:defaultDataUnidad.tenencia,
                    valor:defaultDataUnidad.valor,
                    fechaAlta:defaultDataUnidad.fechaAlta,
                    fechaBaja: defaultDataUnidad.fechaBaja,
                    engomado: defaultDataUnidad.engomado,
                    placa:defaultDataUnidad.placa,
                    ano:defaultDataUnidad.ano
                },
       //validationSchema: validationSchemaUnidades,
        onSubmit: async (values) => {

            switch(mode){
                case "POST":
                   await createUnidades(values);
                    messages("creada")
                    handleClose();
                break;
                case "PUT":
                  await modifyUnidades(values);
                    messages("modificada");
                    handleClose();
                break;
                default:handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información de la Unidad",
                    component: <UnidadesFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item  >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item >
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default UnidadesForm;