import { Box } from "@mui/material";
import * as React from "react";
import TabComponent from "components-base/tab/Tab";
import CategoriesComponent from "components-base/categories/categories";
import CategoriesTypeComponent from "components-base/categoriesType/categoriesType";
import DepartmentComponent from "components-base/departments/department";
import PositionComponent from "components-base/positions/positions";
import IncidentTypeComponent from "components-base/incidentTypes/incidentType";
import SystemRoleComponent from "components-base/systemRoles/systemRole";


const SystemManagement = () => {

    const tabsElement = [
        {
            label: "Tipos Categorias",
            component: <CategoriesComponent />
        },
        {
            label: "Categorias",
            component: <CategoriesTypeComponent />
        },
        {
            label: "Departamentos/Areas",
            component: <DepartmentComponent />
        },
        {
            label: "Posiciones",
            component: <PositionComponent />
        },
        {
            label: "Tipos de incidencias",
            component: <IncidentTypeComponent />
        },
        {
            label: "Roles de sistema",
            component: <SystemRoleComponent />
        },

    ];

    return (
        <Box>
            <TabComponent tabsElement={tabsElement} />
        </Box>
    );
};

export default SystemManagement;