import { client } from "utils/axios";
import * as yup from "yup";

export const createMachinary = async (machinary) => {
  try {
    await client.post("machinary", machinary);
  } catch (error) {
    console.log(error);
  }  
};
export async function getAllMachinary() {
  const { data } = await client.get("/machinary");
  return data;
}
export const validationSchemaMachinary = yup.object({
  nombre: yup
    .string("Ingresa nombre de la Maquinaria")
    .required("Campo Requerido"),
  responsable: yup
    .string("Ingresa al Respnsable de la Maquinaria")
    .required("Campo Requerido"),
  descripcion: yup.string("Ingresa Descripción").required("Campo Requerido"),
  codigo: yup.string("Ingresa Código").required("Campo Requerido"),
});
export const deleteMachinary = async (machinaryId) => {
  await client.delete(`machinary/${machinaryId}`);
};

export const modifyMachinary = async (machinary) => {
  console.log(machinary);
  try {
    await client.put("machinary", machinary);
  } catch (error) {
    console.log(error);
  }
  
};
