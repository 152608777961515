import Card from "components/card";
import { DataGrid } from "@mui/x-data-grid";
import SalidaDrawer from "./SalidaDrawer";
import React, { useState, useEffect } from "react";
import { getAllSalidas } from "../functions";
import {
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import SalidaDelete from "./SalidaDelete";
import { columns } from "../columns";
import { setDefaultData } from "store/modules/Salidas";
import SalidaOrdenModal from "./SalidaOrdenModal";
import { CloseOutlined, Download, Search } from "@mui/icons-material";
import PrintResumeModal from "./resume-pdf/print-resume-modal";

const SalidaTable = ({
  data,
  refresh,
  setRefresh,
  searchValue,
  handleSearch,
  clearSearch,
}) => {
  const [open, setOpen] = useState(false);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [salidaData, setSalidaData] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const [openPDF, setOpenPDF] = useState(false);
  const dispatch = useDispatch();

  const handleOpenPDF = () => setOpenPDF(true);
  const handleClose = () => {
    setOpen(false);
    setRefresh(!refresh);
  };
  const handleClosePDF = () => setOpenPDF(false);
  const handleCloseResumeModal = () => setOpenResumeModal(false);

  useEffect(() => {
    const fetchData = async () => {
      let col = columns(
        setOpen,
        setMode,
        setOpenDelete,
        dispatch,
        handleOpenPDF
      );
      setDataColumns(col);
    };
    fetchData();
  }, [open, openDelete, dispatch]);
  const handleOpen = () => {
    setOpen(true);
    setMode("POST");
    dispatch(setDefaultData({}));
  };

  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <PrintResumeModal open={openResumeModal} handleClose={handleCloseResumeModal} data={data} />
      <SalidaOrdenModal handleClosePDF={handleClosePDF} open={openPDF} />
      <SalidaDelete openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <SalidaDrawer open={open} handleClose={handleClose} mode={mode} />
      <Card extra={"w-full h-full p-5"}>
      <div className="relative flex items-center justify-start gap-4">
      <TextField
        id="input-with-icon-textfield"
        label="Buscar por Pedido"
        value={searchValue || ""}
        onChange={handleSearch}
        InputProps={{
          endAdornment:(
            <InputAdornment position="start">
            <CloseOutlined style={{cursor:"pointer"}} onClick={clearSearch} />
          </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      {
        searchValue && <Button startIcon={<Download />} onClick={()=> setOpenResumeModal(true)} variant="contained" color="primary" sx={{width:300}}>
        Descargar Informe
      </Button>
      }

        </div>
        <div className="relative flex items-center justify-end">
          <button
            onClick={handleOpen}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Generar Nueva Orden de Salida
          </button>
        </div>
        {data ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={data || []}
            columns={dataColumns || []}
            autoPageSize
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            getRowHeight={() => "auto"}
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};

export default SalidaTable;
