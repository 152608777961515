import React, { useState } from "react";
import { Button } from "@mui/material";
import { useFormik, getValues } from "formik";
import { Grid, Box } from "@mui/material";
import MachinaryFormFields from "./MachinaryFormFields";
import TabComponent from "components-base/tab/Tab";
import {
  createMachinary,
  modifyMachinary,
  validationSchemaMachinary,
} from "../functions";
import { useSelector } from "react-redux";

const MachinaryForm = ({ mode, handleClose }) => {
  //console.log(mode);
  const { defaultData } = useSelector(({ talla }) => talla);
  const formik = useFormik({
    initialValues:
      mode === "POST"
        ? {
            
            name: "",
            responsible: "",
            description: "",
            code: "",
            cantidad: 0,
            category: { id: 6 },
          }
        : {
            id: defaultData.id,
            name: defaultData.name,
            responsible: defaultData.responsible,
            description: defaultData.description,
            code: defaultData.code,
            cantidad: defaultData.cantidad,
            category: { id: 6 },
          },
    //validationSchema: validationSchemaMachinary,
    onSubmit: async (values) => {
      console.log(values);
      console.log(mode);
      switch (mode) {
        case "POST":
          await createMachinary(values);
          handleClose();
          break;
        case "PUT":
          await modifyMachinary(values);
          handleClose();
          break;
        default:
          handleClose();
      }
    },
  });
  const { setFieldValue } = formik;
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        "& .MuiTextField-root": { marginBottom: "25px" },
      }}
      style={{ padding: "20px", justifyContent: "center" }}
    >
      <TabComponent
        tabsElement={[
          {
            label: "Información de la Maquinaria",
            component: (
              <MachinaryFormFields
                formik={formik}
                mode={mode}
                setFieldValue={setFieldValue}
              />
            ),
          },
        ]}
      />
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <Grid item>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default MachinaryForm;
