/*
 Initial state and properties
 */
 export const initialState = {
    mode:"POST",
    productIdSelected:0,
    defaultData:{}
 }
 /*
  Action types
  */
 export const SET_MODE = 'SET_MODE'
 export const SET_PRODUCT_ID = 'SET_PRODUCT_ID'
 export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'
 /*
  Arrow function for change state
  */
 export const setMode = (payload) => ({
   type: SET_MODE,
   payload,
 });
 export const setProductId = (payload) => ({
  type: SET_PRODUCT_ID,
  payload,
})
export const setDefaultData = (payload) => ({
  type: SET_DEFAULT_DATA,
  payload,
})
 /*
  Reducer to describe how the state changed
  */
 export default function Reducer(state = initialState, { type, payload }) {
   switch (type) {
     case SET_MODE:
       return {
         ...state,
         mode: payload,
       }
       case SET_PRODUCT_ID:
        return {
          ...state,
          productIdSelected: payload,
        }
        case SET_DEFAULT_DATA:
          return {
            ...state,
            defaultData: payload,
          }
     default:
       return state
   }
 }
 