import MiniCalendar from "components/calendar/MiniCalendar";
import PedidosBarByMonth from "pages/admin/inicio/components/WeeklyRevenue";
import TotalSpent from "pages/admin/inicio/components/TotalSpent";
import PieChartCard from "pages/admin/inicio/components/PieChartCard";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import OrderCheckTable from "pages/admin/inicio/components/OrderCheckTable";
import ComplexTable from "pages/admin/inicio/components/ComplexTable";
import TaskCard from "pages/admin/inicio/components/TaskCard";
import tableDataComplex from "./variables/tableDataComplex.json";
import { useEffect, useState } from "react";
import { dataTransform } from "./functions";
import FilterByMonthComponent from "./filterbymonth";
import { useApplicationContext } from "components-base/application-context/AppContext";
import { optionMonths } from "utils/apis";
import DriversPickup from "./components/DriversPickup";
import Loader from "./components/loader";
import Dropdown from "components/dropdown";
import { Grid } from "@mui/material";

const Dashboard = () => {
  const { filterMonth, setFilterMonth } = useApplicationContext();

  const [data, setData] = useState(null);
  const defaultMonth = new Date().getMonth();
  const month = optionMonths.filter(m => m.number === defaultMonth)[0];


  useEffect(() => {
    const fetchData = async () => {
      const _data = await dataTransform(!filterMonth ? month : filterMonth);
      setData(_data)
    }
    fetchData();


  }, [month, filterMonth])

  if (!data) return (<div className="p-12 w-full h-full"><Loader /></div>)
  return (
    <div className="p-5 w-full h-full">
      <Grid container direction="row" justifyContent="flex-end">
        <FilterByMonthComponent setFilterMonth={setFilterMonth} filterMonth={filterMonth} />
        </Grid>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
        <PedidosBarByMonth data={data} />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
          <OrderCheckTable
            columnsData={columnsDataCheck}
            tableData={data.salidas}
            month={month}
          />
   
        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DriversPickup data ={data.drivers}/>
          <PieChartCard data = {data.pieData}/>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
