import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export async function deleteCategories(categoryId) {
    await client.delete(`category/${categoryId}`);
};
export async function getAllCategoriesType() {
    const { data } = await client.get("/typeCategory");
    return data;
}
export async function getAllCategories() {
    const { data } = await client.get("/category");
    return data;
}
export async function getAllCategorias() {
    const { data } = await client.get("/category");
    return data;
}

export const validationSchemaCategories = yup.object({
    name: yup
        .string("Ingresa nombre de Categories")
        .required("Campo Requerido"),
    code: yup
        .string("Ingresa Codigo")
        .required("Campo Requerido"),
    type: yup.string().required("El tipo es requerido")

});
export const createCategories = async (category, dispatch) => {
    try {

        const { data } = await client.post("category", category);
        dispatch(
            showMessage({
                message: "Categories agregada con éxito",
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );

    } catch (error) {
        dispatch(
            showMessage({
                message:
                    "Ocurrió un error al agregar una category: ",
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );
    }
};

export const modifyCategories = async (category, dispatch) => {
    try {

        await client.put("category", category);

        dispatch(
            showMessage({
                message: `Categoria "${category.name}" modificada con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    } catch (error) {
        dispatch(
            showMessage({
                message:
                    `Ocurrió un error al modificar la category "${category.name}"`,
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    }

};
