import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Typography, Button, Dialog, DialogContent, DialogActions, DialogTitle, Divider } from "@mui/material"
import { logout } from 'utils/Firebase/firebase';
import { useAuthContext } from 'utils/authcontext/AuthContext';

const SessionTimeout = () => {
    const [events, setEvents] = useState(['click', 'scroll']);
    const [second, setSecond] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const {currentUser} = useAuthContext();

    let timeStamp;
    let warningInactiveInterval;

    // start inactive check
    const timeChecker = () => {
            let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
            if(!storedTimeStamp) return;
                warningInactive(storedTimeStamp);
    };
    // warning timer
    const warningInactive = (timeString) => {

        warningInactiveInterval = setInterval(() => {
            const maxTime = 2;
            const popTime = 1;

            const diff = moment.duration(moment().diff(moment(timeString)));
            const minPast = diff.minutes();
            const leftSecond = 60 - diff.seconds();
            if (minPast === popTime) {
                setSecond(leftSecond);
                setOpen(true);
            }
            if (minPast === maxTime) {
                closeSessionNow()
            }
        }, 1000);
    };
    const closeSessionNow = () => {
        clearInterval(warningInactiveInterval);
        setOpen(false);
        sessionStorage.removeItem('lastTimeStamp');
        logout();
    }
    // reset interval timer
    const resetTimer = () => {
        clearInterval(warningInactiveInterval);

        if (currentUser) {
            timeStamp = moment();
            sessionStorage.setItem('lastTimeStamp', timeStamp);
        } else {
            sessionStorage.removeItem('lastTimeStamp');
        }
        timeChecker();
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        resetTimer();
    };

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });
        timeChecker();
        return () => {
            clearInterval(warningInactiveInterval);
        };
    }, [resetTimer, events, timeChecker]);

    return (
        <div>
            <Dialog onClose={handleClose} open={isOpen} aria-label="sessionExpire">
                <DialogTitle>
                    Sesion terminara
                </DialogTitle>
                <DialogContent>
               
                    <Typography className=' font-ebs text-lg'>
                        {`En 
                        ${second} 
                        segundos tu sesion terminara por inactividad.`}
                    </Typography>
                   
                    <Divider />
                    <DialogActions>
                        <Button onClick={closeSessionNow} color="primary">
                           Salir
                        </Button>
                        <Button onClick={handleClose} color="primary"  >
                           No salir
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
};

export default SessionTimeout;