import { Delete, Edit, Print } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setDefaultDataSS } from "store/modules/SpecificationSheet";

export const columns = (setOpen, setMode, setOpenDelete, dispatch, setOpenPDF) => {
  return [
    {
      field: `acciones`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar hoja de especificaciones" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                  dispatch(setDefaultDataSS(data.row));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar hoja de especificaciones" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                 dispatch(setDefaultDataSS(data.row));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir hoja de especificaciones" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenPDF(true);
                 dispatch(setDefaultDataSS(data.row));
              }}
            >
              <Print />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "createdDate",
      headerName: "Fecha Creacion",
      width: 200,
  },

    {
        field: "createdByUser",
        headerName: "Creado por:",
        width: 200,
    },
    {
      field: "modifiedDate",
      headerName: "Ultima modificacion",
      width: 200,
  },
    {
      field: "modifiedByUser",
      headerName: "Modificado por:",
      width: 200,
  },


  ];
};