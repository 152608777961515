import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { store, persister } from "store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Suspense } from "react";

import App from "./App";
import Message from "components-base/message/Message";
import { AuthProvider } from "utils/authcontext/AuthContext";
import SessionTimeout from "timeout/timeout";
import { ApplicationContextProvider } from "components-base/application-context/AppContext";
import { MaterialUIProvider } from "components-base/style-provider/styleProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <MaterialUIProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <Suspense fallback={"Conectando app ......"}>
          <AuthProvider>
            <Message />
            <BrowserRouter>
              <ApplicationContextProvider>
                <App />
              </ApplicationContextProvider>
            </BrowserRouter>
          </AuthProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </MaterialUIProvider>

);
