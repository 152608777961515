export const columnsData = [
    {
      Header: "ID del Evento",
      accessor: "id",
    },
    {
      Header: "STATUS",
      accessor: "status.nombre",
    },
    {
      Header: "Fecha de Inicio",
      accessor: "fechaInicio",
    },
    {
      Header: "Completado el:",
      accessor: "fechaCompletado",
    },
    {
      Header: "Modificado por:",
      accessor: "modifiedByUser",
    },
  ];