import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import {  useSelector } from "react-redux";
import { deleteSupplier } from "../functions";
const SupplierDelete = ({openDelete,setOpenDelete}) => {
      const { supplierIdSelected } = useSelector(({ suppliers }) => suppliers);
    
    const deleteFnc = async () =>{
        
    await deleteSupplier(supplierIdSelected)
    setOpenDelete(false);
}
    return (
        <>
            <Dialog
            open={openDelete}
            onClose={()=>setOpenDelete(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación del Supplier"}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={()=>setOpenDelete(false)} color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color="primary"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default SupplierDelete;