import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Drawer, CssBaseline, IconButton, Box, } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import styled from "@emotion/styled";
import MainAppBar from "./app-bar";
import Links from "./components/Links";
import routes from "routes/adminRoutes/routes";
import { background_marifer } from "utils/apis";

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  height: `calc(100vh)`,
  boxSizing: 'border-box'
}));
const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));
const MainLayout = ({ children, ...rest }) => {
  const theme = useTheme();
  const drawerWidth = 280;
  const classes = {
    appBar: {
      padding:"5px",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 2,
    },
    hide: {
      display: "none",
    },
    actions: {
      flexGrow: 1,
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
        display: "block",
      },
    },
    drawer: {
      marginTop: '70px',
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },

    drawerOpen: {
      marginTop: '70px',
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      padding: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    logo: {
      marginLeft: 0,
      width: 250,
      height: 92,
    },
    content: {
      flexGrow: 1,
      overflow: "hidden",
      padding: theme.spacing(2),
    },
    footer: {
      position: "fixed",
      bottom: 0,
    },
  }
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex">
      {/* <CssBaseline /> */}
      <MainAppBar classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} {...rest} />
      <>
        {open && (
          <Drawer
            variant="permanent"
            sx={open ? { ...classes.drawer, ...classes.drawerOpen, '& .MuiDrawer-paper': {
              border: 'none',
            }, } : { ...classes.drawer, ...classes.drawerClose,'& .MuiDrawer-paper': {
              border: 'none',
            }, }}
            classes={{
              paper: open ?
                classes.drawerOpen :
                classes.drawerClose
            }}
          >
            <Box sx={{paddingLeft:"45px"}}>
            <img
            width="150px"
            height="150px"
            src={background_marifer}
            loading="lazy"
          />
            </Box>
            <Box sx={{ width: 280 }}>
              <Links routes={routes} />
            </Box>

            <div className="absolute bottom-5 right-0">
              <IconButton color="primary" onClick={handleDrawerClose}>
                {open ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </div>
            {open ? (
              <div className={classes.footer}>
                {""}
              </div>
            ) : (
              ""
            )}
          </Drawer>
        )}

      </>
      <Main>
        <DrawerHeader />
        <Box sx={{...classes.toolbar, background: (theme) => theme.palette.mode === "light" ? "#FFF0F5" : "#111c44"}}>
          {children}
        </Box>
      </Main>
    </div>
  );
}
export default MainLayout;