import { getAllConsumptionByPrendaV2 } from "components-base/prendas/functions";
import { client } from "utils/axios";
export async function getAllClothsByProduct(prendas) {
  let cloths = [];

  for (let j = 0; j < prendas.length; j++) {
    const pp = prendas[j];
    const prendaId = pp.id;
    const cloth = await getAllConsumptionByPrendaV2(prendaId);
    cloth.forEach((c) => {
      let _cloth = c.cloth;
      if (cloths.length === 0) {
        cloths.push(_cloth);
      } else {
        let _clothCheck = cloths.find((item) => item.id === _cloth.id);
        if (!_clothCheck) cloths.push(_cloth);
      }
    });
  }
  return cloths;
}

export async function dataTransform(detail) {
  let products = [];
  let cloths = [];
  detail.detallePedido.forEach((item) => {
    item.producto.tallas = item.details.tallas;
    products = [...products, item.producto];
  });

  let obj = {
    cloths,
    products,
    percentage: detail.percentage,
  };
  return obj;
}
export function transformSizes(sizes, percentage) {
  let data = [];
  sizes.forEach((s) => {
    let _percentage = Math.floor(Number((s.cantidad * percentage) / 100));
    let obj = {
      name: s.talla.nombre,
      pieces: s.cantidad,
      percentage: _percentage,
      total: Number(s.cantidad) + _percentage,
      stamping: "",
    };
    data = [...data, obj];
  });

  return data;
}
export async function getMaxFolio(type) {
  const { data } = await client.get(`/stampingOrder/folio/${type}`);
  return data;
}

export async function getAllStampingOrdersByPedidoId(id) {
  const { data } = await client.get(`/stampingOrder/filter/${id}`);
  return data;
}
export async function getAllStampingOrders() {
  const { data } = await client.get("/stampingOrder");
  return data;
}
export async function getAllStampingOrderById(id) {
  const { data } = await client.get(`/stampingOrder/${id}`);
  return data;
}
export const createStampingOrder = async (stampingInput) => {
  try {
    await client.post("stampingOrder", stampingInput);
  } catch (error) {}
};
export const modifyStampingOrder = async (stampingInput) => {
  try {
    await client.put("stampingOrder", stampingInput);
  } catch (error) {}
};
export const deleteStampingOrder = async (id) => {
  await client.delete(`stampingOrder/${id}`);
};

export function DataStampingConvert(data) {
  const sizes = data.sizes;

  let totalPercentage = 0;
  let totalPieces = 0;
  let grandTotal = 0;
  sizes.forEach((item) => {
    totalPercentage += Number(item.percentage);
    totalPieces += Number(item.pieces);
    grandTotal += Number(item.cut || 0);
  });
  const totals = {
    totalPercentage,
    totalPieces,
    grandTotal,
  };
  let folio = `${String(data.folio).padStart(4, "0")}`;
  let obj = {
    comments: data.comments,
    type: data.type,
    folio: folio,
    prenda: data.prenda,
    supplier: `${data.supplier.persona.nombre} ${data.supplier.persona.apellidoPaterno} ${data.supplier.persona.apellidoMaterno}  `,
    model: `${data.producto.modelo} (${data.producto.nombre})`,
    deliveryDate: data.deliveryDate.split(" ")[0],
    image: data.prenda.imagen,
    sizes: data.sizes,
    totals: totals,
  };

  return obj;
}
