import ImpresionQrPdf from "./ImpresionQrPdf";
import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";

const ImpresionQRModal = ({ open, handleClosePDF }) => {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClosePDF}>
      <DialogContent>
        <ImpresionQrPdf />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClosePDF}
          color={"secondary"}
          variant="contained"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ImpresionQRModal;
