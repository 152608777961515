/*
 Initial state and properties
 */
 export const initialState = {
    mode:"POST",
    categoryTypeIdSelected:0,
    defaultDataCategoriesType:null
 }
 /*
  Action types
  */
 export const SET_MODE = 'SET_MODE'
 export const SET_CATEGORY_TYPE_ID = 'SET_CATEGORY_TYPE_ID'
 export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'
 /*
  Arrow function for change state
  */
 export const setMode = (payload) => ({
   type: SET_MODE,
   payload,
 });
 export const setCategoriesTypeId = (payload) => ({
  type: SET_CATEGORY_TYPE_ID,
  payload,
})
export const setDefaultData = (payload) => ({
  type: SET_DEFAULT_DATA,
  payload,
})
 /*
  Reducer to describe how the state changed
  */
 export default function Reducer(state = initialState, { type, payload }) {
   switch (type) {
     case SET_MODE:
       return {
         ...state,
         mode: payload,
       }
       case SET_CATEGORY_TYPE_ID:
        return {
          ...state,
          categoryTypeIdSelected: payload,
        }
        case SET_DEFAULT_DATA:
          return {
            ...state,
            defaultDataCategoriesType: payload,
          }
     default:
       return state
   }
 }
 