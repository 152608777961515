
import { client } from "utils/axios"
import * as yup from "yup";

export async function deleteAsignaciones(AsignacionesId) {
  try {
    await client.delete(`rutaChofer/${AsignacionesId}`);
  } catch (error) {
    console.log(error)
  }

};
export async function getAllUnidadByChoferId(choferId) {
  try {
    const { data } = await client.get(`choferUnidad/filterChofer/${choferId}`);
    return data;
  } catch (error) {
    console.log(error);
    return []
  }

};
export async function getAllAsignaciones() {
  
  const data = await client.get("rutaChofer");
  const datama = await client.get("rutaMaquileros");
  let objData = data.data;
  let maquileros = datama.data;
  objData.forEach(ruta => {
    ruta.maquileros = maquileros.filter(item => item.rutaId === ruta.ruta.id)
  });

  return objData;
}

export const validationSchemaAsignaciones = yup.object({
  nombre: yup
    .string("Ingresa nombre")
    .required("Campo Requerido"),
  apellidoPaterno: yup
    .string("Ingresa el apellido Paterno")
    .required("Campo Requerido"),
  apellidoMaterno: yup
    .string("Ingresa el apellido Materno"),
  direccion: yup
    .string("Ingresa la direccion")
    .required("Campo Requerido"),
  rfc: yup
    .string("Ingresa el RFC"),
  codigo: yup
    .string("Ingresa el codigo")
    .required("Campo Requerido"),
  capacidad: yup.number
    ("Ingresa el capacidad")

});
async function checkRuta(rutaId) {
  const { data } = await client.get("rutaChofer");
  let result = data.filter(item => item.ruta.id === rutaId)
  if (result.length > 0)
    return true;
  else
    return false;


}

export async function createAsignaciones(input) {

  try {
    const check = await checkRuta(input.ruta.id);
    if (check)
      return { code: "Ya existe una Asignacion creada para esta ruta, solo se puede editar.", status: false }

    let rutaChofer = {
      chofer: { id: input.operador.id },
      ruta: { id: input.ruta.id },
      unidad: { id: input.unidad.id },
      fechaCambio: new Date().toISOString().split("T")[0],
      activo: true,

    } 
    await client.post("rutaChofer", rutaChofer);
    const maquileros = input.maquileros;
    for (let i = 0; i < maquileros.length; i++) {
      let obj = {
        maquilero: { id: maquileros[i].id },
        rutaId: input.ruta.id,
        activo: true
      }
      await client.post("rutaMaquileros", obj);
    }
    return { code: "Asignacion creada correctamente", status: true }
  }
  catch (error) {
    console.log(error)
    return { code: "Ocurrio un error en el sistema Marifer " + error.toString(), status: false }
  }

};
export async function modifyAsignaciones(asignaciones) {

  let persona = {
    apellidoPaterno: asignaciones.apellidoPaterno,
    apellidoMaterno: asignaciones.apellidoMaterno,
    nombre: asignaciones.nombre,
    rfc: asignaciones.rfc,
    direccion: asignaciones.direccion,
    id: asignaciones.persona.id
  }
  try {
    await client.put("personas", persona);
    await client.put("asignacioness", asignaciones);
  } catch (error) {

  }

};