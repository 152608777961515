import React from "react";
import { Drawer, DialogTitle, IconButton } from "@mui/material";
import RutaForm from "./RutaForm";
import { background_marifer } from "utils/apis";
import { Cancel } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { styles } from "utils/apis";
const AddRuta = ({ mode, open, handleClose,setMode }) => {
    const {defaultData} = useSelector(({ruta})=>ruta);

    return (
        <>
            <Drawer
                anchor={"bottom"}
                open={open}
                onClose={handleClose}
                sx={styles}     
            >
                <DialogTitle sx={{display: "flex", justifyContent: "flex-end", padding: 0}}>
                    <IconButton onClick={handleClose} sx={{color:"purple"}}>
                        <Cancel />
                    </IconButton>
                </DialogTitle>
                <RutaForm mode={mode} setMode={setMode}defaultData={defaultData} handleClose={handleClose} />
            </Drawer>
        </>
    )
}
export default AddRuta;