import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { deleteDriverLogs } from "../functions";
const DriverLogsDelete = ({ openDelete, setOpenDelete, refresh, setRefresh }) => {
    const { driverLogsIdSelected } = useSelector(({ driverlogs }) => driverlogs);

    const deleteFnc = async () => {

        await deleteDriverLogs(driverLogsIdSelected);
        setOpenDelete(false);
        setRefresh(!refresh)
    }
    return (
        <>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminacion del registro"}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDelete(false)} color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DriverLogsDelete;