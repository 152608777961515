import React, { useEffect, useState, useRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Svg,
  pdf,
  BlobProvider,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import image from "../../../assets/img/marifer.png";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { useSelector } from "react-redux";
import { toPng, toJpeg, toBlob, toPixelData } from "html-to-image";
import { Button } from "@mui/material";

// Create styles
const styles = StyleSheet.create({
  qrImagen: {
    width: "850px",
    height: "480px",
    paddingLeft: "8%",
    paddingBottom: "50px",
  },
  image: {
    position: "relative",
    objectFit: "cover",
    zIndex: -999,
    width: "120px",
    height: "120px",
    paddingLeft: 0,
    opacity: 0.9,
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  textTitulo: {
    paddingTop: "55px",
    paddingLeft: "8%",
  },
  text2: {
    paddingLeft: "22%",
    paddingBottom: "30px",
  },
  text3: {
    paddingLeft: "45%",
    color: "#ff0080",
  },
  text4: {
    paddingLeft: "8%",
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
    height: "5cm",
  },
  qr: {
    paddingLeft: "30%",
  },
});

const ImpresionQrPdf = ({}) => {
  const qrCodeRef = useRef(null);
  const [data64, setBase64] = useState(null);
  const { defaultDataMaquilero } = useSelector(({ maquilero }) => maquilero);
  useEffect(() => {
    const base64image = async () => {
      const data = await htmlToImage.toPng(qrCodeRef.current);
      setBase64(data);
    };
    base64image();
  }, []);

  const idData = defaultDataMaquilero.id;
  const nameData =
    defaultDataMaquilero.persona.nombre +
    " " +
    defaultDataMaquilero.persona.apellidoPaterno +
    " " +
    defaultDataMaquilero.persona.apellidoMaterno;
  const direccionName = defaultDataMaquilero.persona.direccion;

  const PdfWithQR = () => {
    if (data64 === null) return "cargando datos";
    return (
      <Document>
        <Page style={styles.page}>
          <View>
            <View style={styles.mainSection}>
              <Image style={styles.image} src={image} />
              <Text style={styles.textTitulo}>
                ¡Hola!, Este es tu código QR:
              </Text>
            </View>
            <Text style={{ paddingLeft: "8%", paddingBottom: "30px" }}>
              Maquilero: {nameData}
            </Text>
            <Image style={styles.qrImagen} src={data64} />
            <Text style={styles.text4}>Con dirección en:</Text>
            <Text style={{ paddingLeft: "8%", paddingBottom: "30px" }}>
              {direccionName}
            </Text>
            <Text style={styles.text2}>
              Escanea el QR para registrar tus entradas y salidas.
            </Text>
            <Text style={styles.text3}>©Marifer 2023.</Text>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <div style={styles.qr}>
      <div ref={qrCodeRef}>
        <QRCode
          size={256}
          style={{
            height: "auto",
            maxWidth: "60%",
            width: "60%",
          }}
          value={idData.toString()}
          viewBox={`0 0 256 256`}
        />
      </div>
      <br></br>
      <PDFDownloadLink
        document={<PdfWithQR />}
        fileName={`Maquilero ${nameData}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <div style={{ marginLeft: "20%" }}>Cargando documento...</div>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "20%" }}
            >
              Descargar Codigo QR
            </Button>
          )
        }
      </PDFDownloadLink>
      {/* <PdfWithQR /> */}
    </div>
  );
};

export default ImpresionQrPdf;
