import {
  Grid,
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect } from "react";
import { useState } from "react";
import { getAllPersons } from "../functions";
import { getAllSystemRole } from "components-base/systemRoles/functions";

const SystemUserFormFields = ({ formik, setFieldValue, mode }) => {
  const [persons, setPersons] = useState(null);
  const [roles, setRoles] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      let persons = await getAllPersons();
      let roles = await getAllSystemRole();
      roles.forEach((r) => {
        setRoles((rol) => [...rol, { role: r }])
      })
      setPersons(persons);
    }
    fetchData();
  }, [])
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container flexDirection={"row"} spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="username"
            name="username"
            label="Usuario/Email"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={
              formik.touched.username && Boolean(formik.errors.username)
            }
            helperText={formik.touched.username && formik.errors.username}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={
              formik.touched.password && Boolean(formik.errors.password)
            }
            helperText={formik.touched.password && formik.errors.password}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name="person"
            options={persons || []}
            isOptionEqualToValue={(option, value) => Number(option.id) === Number(value.id)}
            id="person"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Persona" />}
            getOptionLabel={(option) => `${option.nombre} ${option.apellidoPaterno} ${option.apellidoMaterno}`}
            onChange={(e, o) => setFieldValue("persona", o)}
            defaultValue={formik.values.persona}
          />
        </Grid>
        <Grid item xs={12} >
          <Autocomplete
            name="roles"
            options={roles || []}
            isOptionEqualToValue={(option, value) => Number(option.role.id) === Number(value.role.id)}
            id="roles"
            fullWidth
            multiple
            renderInput={(params) => <TextField {...params} label="Roles" />}
            getOptionLabel={(option) => `${option.role.nombre}`}
            onChange={(e, o) => setFieldValue("roles", o)}
            defaultValue={formik.values.roles}
          />
        </Grid>
        <Grid item xs={12} >
        <FormControlLabel control={
            <Checkbox
              id="activo"
              name="activo"
              checked={formik.values.activo}
              onChange={formik.handleChange}
              error={
                formik.touched.activo && Boolean(formik.errors.activo)
              }
              
              helperText={formik.touched.activo && formik.errors.activo}
              color="primary" />} label="Activar/Desactivar usuario" />
        </Grid>
      </Grid>

    </Box>
  )
}
export default SystemUserFormFields;