import Card from "components/card";
import InputsMenu from "./InputsMenu";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../columns";
import InputsDrawer from "./InputsDrawer";
import React, { useState, useEffect } from "react";
import { getAllInputs, getFilterByRolls } from "../functions";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import InputsDelete from "./InputsDelete";
import PrintLabelModal from "components-base/StackCloth/components/print-label-modal";

const InputsTable = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [inputsData, setInputsData] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [openPdf, setOpenPdf] = useState(false);
  const [filterRolls, setFilterRolls] = useState(null);

  const dispatch = useDispatch();

  const handleClosePdf = () => {
    setOpenPdf(false);
    setDataId(null);
    setFilterRolls(null)
  };

  const handleOpenPdf = () => {
    setOpenPdf(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      let inputs = await getAllInputs();
      setInputsData(inputs);
    };
    fetchData();
    let col = columns(
      setOpen,
      setMode,
      setOpenDelete,
      dispatch,
      handleOpenPdf,
      handleClosePdf,
      setDataId
    );
    setDataColumns(col);
  }, [open, openDelete, dispatch]);

  useEffect(() => {
    const dataFilter = async () => {
      if (dataId !== null) {
        let filterRolls = await getFilterByRolls(dataId);
        setFilterRolls(filterRolls);
      }
    };
    dataFilter();
  }, [dataId]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <InputsDelete openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <InputsDrawer open={open} handleClose={handleClose} mode={mode} />
      {filterRolls !== null ? (
        <PrintLabelModal
          open={openPdf}
          handleClose={handleClosePdf}
          data={filterRolls}
        />
      ) : null}
      <Card extra={"w-full h-full p-4"}>
        {/* <div className="relative flex items-center justify-end">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            <InputsMenu setOpen={setOpen} setMode={setMode} />
          </div>
        </div> */}

        <div className="relative flex items-center justify-end">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            <button
              onClick={() => {
                setOpen(true);
                setMode("POST");
              }}
              className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              Agregar Nueva Entrada
            </button>
          </div>
        </div>
        {inputsData ? (
          <DataGrid
            className="mt-8 h-max w-full dark:text-white"
            rows={inputsData || []}
            columns={dataColumns || []}
            pageSize={15}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};
export default InputsTable;
