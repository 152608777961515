import { IconButton, Typography, Grid, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { detallesColumns } from "../detallesColumns";
import { Add } from "@mui/icons-material";
import { useState, useEffect } from "react";
import PedidosDetailFormFields from "./PedidosDetailsFormFields";
import { getAllDetallePedidos } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { ProductoDetail } from "./ProductoDetail";
import { CircularProgress } from "@mui/material";
import DeleteDetalle from "./DeleteDetalle";

const PedidoDetalle = () => {
  const [open, setOpen] = useState(false);
  const [pedidosData, setPedidosData] = useState(null);
  const [mode, setMode] = useState("POST");
  const [dataColumns, setDataColumns] = useState(null);
  const { pedidoIdSelected } = useSelector(({ pedido }) => pedido);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDeleteDetalle, setOpenDeleteDetalle] = useState(false);
  const [height, setHeight] = useState(50);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      let pedidos = await getAllDetallePedidos(pedidoIdSelected);
      setPedidosData(pedidos);
      let col = detallesColumns(
        setOpenDetail,
        setOpen,
        setMode,
        dispatch,
        setOpenDeleteDetalle,
        setHeight
      );
      setDataColumns(col);
    };
    fetchData();
  }, [open, pedidoIdSelected, openDeleteDetalle, dispatch]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setMode("POST");
    setOpen(true);
  };
  const handleCloseDetail = () => {
    setOpenDetail(false);
  };
  if (!pedidosData) return <>{"Recolectando datos del pedido....."}</>;

  return (
    <>
      <ProductoDetail
        pedidosData={pedidosData}
        openDetail={openDetail}
        handleCloseDetail={handleCloseDetail}
      />
      <DeleteDetalle
        setOpenDeleteDetalle={setOpenDeleteDetalle}
        openDeleteDetalle={openDeleteDetalle}
      />
      <PedidosDetailFormFields
        mode={mode}
        open={open}
        handleClose={handleClose}
        pedidosData={pedidosData}
      />
      <Box style={{ height: "600px" }}>
        <Grid container style={{ width: "100%" }} direction={"row"}>
          <Grid item xs={12}>
            <Typography variant="h4">{"Producto del pedido"}</Typography>
            <IconButton onClick={handleOpen} size="small" title="Agregar">
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {dataColumns ? (
              <DataGrid
                rows={pedidosData || []}
                columns={dataColumns || []}
                pageSize={10}
                rowsPerPageOptions={[1]}
                checkboxSelection
                disableSelectionOnClick
                style={{ height: 600 }}
                getRowHeight={() => "auto"}
              />
            ) : (
              <div className="flex justify-center pt-56">
                <CircularProgress color="primary" />
                <Typography>Cargando datos...</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default PedidoDetalle;
