import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from 'utils/Firebase/firebase';
// import { GetAllEntity } from 'utils/firebase/functions';
// import { where } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  // const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  // useEffect(() => {
  //   if (currentUser) {
  //     const fetchUserData = async () => {
  //       const filter = where("userId", "==", currentUser.uid);
  //       const response = await GetAllEntity("users", filter);
  //       setUserProfile(response[0]);
  //     }
  //     fetchUserData();
  //   }

  // }, [currentUser])

  const value = {
    currentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
