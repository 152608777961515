import Card from "components/card";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
const SizesTable = ({sizes}) => {
    
    const [value, setValue] = useState(null);
    const [newValue, setNewValue] = useState("");
    const [item, setItem] = useState(null);

    useEffect(() => {
        if (value === 0) {
            setValue(null)
        }
    }, [value]);

    useEffect(() => {
        if(newValue ){
          const delayDebounceFn = setTimeout( async () => {
            try {
                  
                    let newItem = item;
                    newItem.cut = Number(newValue);
                    setSizes(sizes.filter(t => t.talla.id !== item.talla.id))
                    setSizes((talla) => [...talla, newItem])
                    setSizes(tallasByProduct.sort((a, b) => { return a.talla.id - b.talla.id }));
                

            } catch ({ message }) {
            } 
          }, 1000);
          return () => clearTimeout(delayDebounceFn);
        }
      }, [newValue]);

    const changeCantidad = (e, item) => {
        setNewValue(e.target.value)
        setItem(item)
    }


    return (
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            {sizes.map((item, index) => (
                <div className="grid grid-cols-5 gap-2 px-1 p-1" key={index}>
                    <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <p className="text-sm text-gray-600">Tallas:</p>
                        <p className="text-base font-medium text-navy-700 dark:text-white">
                            {item.name}
                        </p>
                    </div>
                    <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <p className="text-sm text-gray-600">Piezas:</p>
                        <p className="text-base font-medium text-navy-700 dark:text-white">
                            {item.pieces}
                        </p>
                    </div>
                    <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <p className="text-sm text-gray-600">Tolerancia:</p>
                        <p className="text-base font-medium text-navy-700 dark:text-white">
                            {item.percentage}
                        </p>
                    </div>
                    <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <p className="text-sm text-gray-600">Total:</p>
                        <p className="text-base font-medium text-navy-700 dark:text-white">
                            {item.total}
                        </p>
                    </div>
                
                    <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <p className="text-sm text-gray-600">Corte</p>
                        <TextField
                            
                            type="number"
                            onChange={(e) => changeCantidad(e, item)}
                            defaultValue={item.cut || 0}
                        
                            InputProps={{ inputProps: { min: "0", max: item.cut } }}

                        />
                    </div>
                </div>
            ))}

        </Card>
    );
};

export default SizesTable;
