// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import 'firebase/storage';
import { getAnalytics } from "firebase/analytics";
import { addDoc, getFirestore,collection } from 'firebase/firestore/lite';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket:process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId:process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId:process.env.REACT_APP_APP_ID,
  measurementId:process.env.REACT_APP_MEASUREMENT_ID
};

 const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const logout = () =>{
  signOut(auth)
}

const storage = getStorage(app);
export default storage;

export const logInWithEmailAndPassword = async (email,password)=>{
  try {
 let user =   await signInWithEmailAndPassword(auth,email,password);
 return {result:true, user:user}
  } catch (error) {

    return {result:false,error:error};
  }
}
export const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const uid = user.uid;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      fullName: name,
      userName: email,
      password: password
    })
    return { result: "OK", uid: uid };
  } catch (error) {
    alert(error.message)
    return { result: "ERROR", errorMessage:error.message };
  }
}
// Initialize Firebase

