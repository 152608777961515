import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { setSystemRoleId,setDefaultData } from "store/modules/SystemRole";
import { v4 as uuidv4 } from 'uuid';

export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 100,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Editar Rol" placement="top">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => { setOpen(true); setMode("PUT"); dispatch(setDefaultData(data.row));dispatch(setSystemRoleId(data.row.id)) }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar Rol" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setSystemRoleId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    width:150,
  },
  {
    field: "descripcion",
    headerName: "Descripcion",
    width: 250,
  },
  {
    field: "actions",
    headerName: "Acciones permitidas",
    width: 400,
    renderCell: (data) => (

        <Typography variant="caption">
          {data.row.action.map(i => i.action.name).join(", ")}
        </Typography>
    )
  },
  
]
}
