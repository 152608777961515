import {
  Grid,
  TextField,
  Box
} from "@mui/material";
import { boxSize } from "utils/apis";


const CategoriesTypeFormFields = ({ formik, setFieldValue, mode }) => {

  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={
              formik.touched.name && Boolean(formik.errors.name)
            }
            helperText={formik.touched.name && formik.errors.name}
            color="primary"
          />
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Descripcion"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description &&
              Boolean(formik.errors.description)
            }
            helperText={
              formik.touched.description &&
              formik.errors.description
            }
            color="primary"
          />
        </Grid>
      </Grid>
    </Box>

  )
}
export default CategoriesTypeFormFields;