import { Box } from "@mui/material";
import * as React from "react";
import TabComponent from "components-base/tab/Tab";

import Unidades from "components-base/unidades/Unidades";
import AsignacionRutas from "components-base/asignacionRutas/asignacionRutas";
import Rutas from "components-base/rutas/Rutas";
import Operadores from "components-base/operadores/Operadores";

const _Rutas = () => {
  const tabsElement = [
    {
      label: "Rutas",
      component: <Rutas />
    },
    {
      label: "Unidades",
      component: <Unidades />
    },
    // {
    //   label: "Operadores",
    //   component: <Operadores />
    // },
    // {
    //   label: "Asignacion de Ruta",
    //   component: <AsignacionRutas />
    // }
  ];

  return (
    <Box>
      <TabComponent tabsElement={tabsElement} />
    </Box>
  );
};

export default _Rutas;
