import { client } from "utils/axios";
import * as yup from "yup";

export const createFurniture = async (furniture) => {
  try {
    await client.post("furniture", furniture);
  } catch (error) {
    console.log(error);
  }
};
export async function getAllFurniture() {
  const { data } = await client.get("/furniture");
  return data;
}
export const validationSchemaFurniture = yup.object({
  nombre: yup.string("Ingresa nombre del Mueble").required("Campo Requerido"),
  dimensiones: yup
    .string("Ingresa las dimensiones del Mueble")
    .required("Campo Requerido"),
  descripcion: yup.string("Ingresa Descripción").required("Campo Requerido"),
  codigo: yup.string("Ingresa Código").required("Campo Requerido"),
});
export const deleteFurniture = async (furnitureId) => {
  await client.delete(`furniture/${furnitureId}`);
};

export const modifyFurniture = async (furniture) => {
  try {
    await client.put("furniture", furniture);
  } catch (error) {
    console.log(error);
  }
};
