import { Box } from "@mui/material";
import * as React from "react";
import TabComponent from "components-base/tab/Tab";

import Salidas from "components-base/salidas/Salidas"
import Pickups from "components-base/pickups";


const _Salidas = () => {
  const tabsElement = [
    {
      label: "Salidas",
      component: <Salidas />
    },
    {
      label: "Recolecciones por chofer",
      component: <Pickups />
    }
  ];

  return (
    <Box>
      <TabComponent tabsElement={tabsElement} />
    </Box>
  );
};

export default _Salidas;
