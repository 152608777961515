import { getAllConsumptionByPrendaV2 } from "components-base/prendas/functions";

export function dataConvert(data) {
    return { data: data }
}

export function totalSum(detalle) {
    let total = 0;
    let cantidad = 0;
    detalle.details.data.forEach(detail => {
        total += (detail.cantidadAsignada * detalle.details.prendas.length);
        cantidad += detail.cantidadAsignada;
    })
    return { totalCount: total, cantidad: cantidad }
}

export function insumosConvert(detalle) {
    let insumos = []
    let insumosGrouped = [];
    detalle.details.data.forEach(detail => {
        detalle.details.prendas.forEach(prenda => {
            prenda.insumo.forEach((insumo, index) => {
                if (insumo.details) {
                    let total = 0;
                    let factor = 1;
                    insumo.details.forEach(d => {
                        if (d.talla.id === detail.talla.id) {
                            let cantidad = Number(d.cantidad) * Number(detail.cantidadAsignada || 1);
                            total += cantidad;
                            factor = Number(d.unidad.factor);
                            insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.insumo.details.presentacion, cantidad: (total / factor / Number(insumo.insumo.details.cantidad)), nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                        }
                    })
                } else {
                    insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.unidad, cantidad: insumo.cantidad * detail.cantidadAsignada, nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                }
            })
        })
    })
    let groupedData = insumos.reduce((groups, item) => {
        const key = item.nombre;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
        return groups;
    }, {});

    let keys = Object.keys(groupedData)
    keys.forEach(item => {
        insumosGrouped.push({ nombre: item, data: groupedData[item] })
    })
    insumosGrouped.forEach(item => {
        let cantidad = 0;
        let unidad = ""
        item.data.forEach(d => {
            cantidad += d.cantidad;
            unidad = d.unidad
        })
        item.cantidad = cantidad;
        item.unidad = unidad
    })
    return insumosGrouped
}

export async function dataTransform(data) {
    let total_product = 0;
    let total_prenda = 0;
    let products = [];
    let fecha_creacion = "";
    let fecha_entrega = "";
    let fecha_estimada = ""
    let codigo = "";
    let detail = [];
  

    // PROCESS START

    codigo = data.codigo;
    fecha_creacion = data.fechaCreacion;
    fecha_entrega = data.fechaEntrega;
    fecha_estimada = data.fechaEstimadaTermino;
    detail = data.detallePedido;
   const percentage = data.percentage;
    detail.forEach(d => {
        total_product += d.cantidad;
        total_prenda += d.producto.prenda.length;
        d.producto.tallas = d.details.tallas;
        d.producto.cantidad = d.cantidad
        products = [...products, d.producto];

    })

    products.forEach(p => {
        let insumos = [];
        let tallas = p.tallas;
        p.prenda.forEach(prenda => {
            prenda.insumo.forEach(insumo => {
                if (insumo.details) {
                    let factor = 1;
                    insumo.details.forEach(d => {
                        let t = tallas.find(t => t.talla.id === d.talla.id);
                        if(!t) return;
                        let cantidad = Number(d.cantidad) * Number(t.cantidad);
                        factor = Number(d.unidad?.factor || 1);
                        insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.insumo.details.presentacion, cantidad: (cantidad / factor / Number(insumo.insumo.details.cantidad)), nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                    })
                } else {
                    insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.unidad, cantidad: insumo.cantidad * (p.cantidad), nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
                }
            })
            p.insumos = insumos;
        })
    });
    let insumosToGroup = [];
    products.forEach(p => {
        insumosToGroup = [...insumosToGroup, ...p.insumos]
    })

        let insumosGrouped = [];
        let insumos = insumosToGroup;
        let groupedData = insumos.reduce((groups, item) => {
            const key = item.nombre;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(item);
            return groups;
        }, {});

        let keys = Object.keys(groupedData)
        keys.forEach(item => {
            insumosGrouped.push({ nombre: item, data: groupedData[item] })
        })
        insumosGrouped.forEach(item => {
            let cantidad = 0;
            let unidad = ""
            item.data.forEach(d => {
                cantidad += d.cantidad;
                unidad = d.unidad
            })
            if(percentage && percentage > 0){
                let plus = (cantidad * percentage) / 100;
                item.cantidad = cantidad + plus;
            }
            else{
                item.cantidad = cantidad;
            }

            item.unidad = unidad
        })

    let clothConsumptionTotal = [];
    for (let i = 0; i < products.length; i++) {
        let p = products[i];
        for (let j = 0; j < p.prenda.length; j++) {
            let prenda = p.prenda[j];
            const clothConsumption = await getAllConsumptionByPrendaV2(prenda.id);
            clothConsumptionTotal = [...clothConsumptionTotal, ...clothConsumption]
        }
    }
    let prenda_products = total_prenda * total_product;

    clothConsumptionTotal.forEach(cc => {
        if(percentage && percentage > 0){
            let plus = cc.quantity + (cc.quantity * percentage / 100)
            cc.clothTotal =   (plus* prenda_products) / 100;
        }else{
            cc.clothTotal = (cc.quantity * prenda_products) / 100;
        }


    })
    let clothConsumptionGrouped = [];
    if (clothConsumptionTotal.length > 0) {
        let clothGrouped = [];
        let cloths = clothConsumptionTotal;
        let groupedData = cloths.reduce((groups, item) => {
            const key = item.cloth.name;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(item);
            return groups;
        }, {});
        let keys = Object.keys(groupedData)
        keys.forEach(item => {
            clothGrouped.push({ nombre: item, data: groupedData[item] })
        })
        clothGrouped.forEach(item => {
            let cantidad = 0;
            let unidad = ""
            item.data.forEach(d => {
                cantidad += d.clothTotal;
                unidad = d.unit
            })
            item.quantity = cantidad;
            item.unit = unidad;
        })
        clothConsumptionGrouped = [...clothConsumptionGrouped, ...clothGrouped]
    }
if(percentage && percentage > 0){
    total_prenda = total_prenda + ((total_prenda * percentage) / 100) 
    total_product = total_product + ((total_product * percentage) / 100) 
}

    let resultObj = {
        codigo,
        fecha_creacion,
        fecha_entrega,
        fecha_estimada,
        products,
        total_product,
        total_prenda,
        clothConsumption: clothConsumptionTotal,
        clothGrouped: clothConsumptionGrouped,
        insumosGrouped,
        percentage: percentage
    }

    return resultObj;


}