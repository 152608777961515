import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import backgroundImage from "assets/img/marifer.png";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { IoMdInformationCircleOutline } from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import { logout } from "utils/Firebase/firebase";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import Notification from "./components/notifications";
import readme from "docs/README.pdf";
import ThemeSelector from "components-base/style-provider/theme-selector";

const Toolbar = (props) => {
  const [darkmode, setDarkmode] = React.useState(false);
  const { userName } = useSelector(({ session }) => session);

  const pdfUrl = readme;

  return (
      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
        >
          hola
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <Notification />
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdInformationCircleOutline className="h-6 w-6 text-gray-600 dark:text-white" />
            </p>
          }
          children={
            <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div />
              <img style={{ width: 100, height: 100 }} src={backgroundImage} />
              <a
                target="blank"
                // href={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                href={pdfUrl}
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
              >
                Consultar la documentacion
              </a>
            </div>
          }
          classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        />
        <ThemeSelector />
        <Dropdown
          button={
            <img className="h-10 w-10 rounded-full" src={avatar} alt="Admin" />
          }
          children={
            <div className="flex h-48 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-xs font-bold text-navy-700 dark:text-white">
                    👋 Hola, {userName}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <Button className="mt-3 text-sm font-medium text-red-500 hover:text-red-500">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👤 Mi Perfil
                    </p>{" "}
                  </div>
                </Button>
                <Button
                  onClick={() => logout()}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      🚪 Salir
                    </p>{" "}
                  </div>
                </Button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
  
  );
};

export default Toolbar;
