/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { Box, Typography } from "@mui/material";
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (

            <Link key={index} to={route.layout + "/" + route.path}>
              <div className="relative mb-3 flex hover:cursor-pointer ">
                <li
                  className="my-[3px] flex cursor-pointer items-center pl-2"
                  key={index}
                >
                  <span
                    className={`${activeRoute(route.path) === true
                        ? " text-brand-500 dark:text-white"
                        : " text-gray-600"
                      }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <Typography variant="h6"
                    className={`leading-1 ml-5 flex ${activeRoute(route.path) === true
                        ? " text-navy-700  dark:text-gray-50"
                        : " text-blueSecondary"
                      }`}
                  >
                    {route.name}
                  </Typography>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>

        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
