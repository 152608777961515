import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box } from "@mui/material";
import TelaFormFields from "./TelaFormFields";
import TabComponent from "components-base/tab/Tab";
import { createTela, modifyTela, validationSchemaTela } from "../functions";
import { useSelector } from "react-redux";

const TelaForm = ({ mode, handleClose }) => {
  const { defaultData } = useSelector(({ telas }) => telas);
  const formik = useFormik({
    initialValues:
      mode === "POST"
        ? {
            id: null,
            name: "",
            color: "",
            tone: "",
            description: "",
            code_cloth: "",
            supplier: { id: 0 },
            category: { id: 6 },
          }
        : {
            id: defaultData.id,
            name: defaultData.name,
            tone: defaultData.tone,
            color: defaultData.color,
            description: defaultData.description,
            code: defaultData.code,
            supplier: defaultData.supplier,
            category: { id: 6 },
          },
    // validationSchema: validationSchemaTela,
    onSubmit: async (values) => {
      switch (mode) {
        case "POST":
          await createTela(values);
          handleClose();
          break;
        case "PUT":
          await modifyTela(values);
          handleClose();
          break;
        default:
          handleClose();
      }
    },
  });
  const { setFieldValue } = formik;
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        "& .MuiTextField-root": { marginBottom: "25px" },
      }}
      style={{ padding: "20px", justifyContent: "center" }}
    >
      <TabComponent
        tabsElement={[
          {
            label: "Información de la Tela",
            component: (
              <TelaFormFields
                formik={formik}
                mode={mode}
                setFieldValue={setFieldValue}
              />
            ),
          },
        ]}
      />
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <Grid item sx={12}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancelar
          </Button>
        </Grid>
        <Grid item sx={12}>
          <Button type="submit" color="primary" variant="contained">
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TelaForm;
