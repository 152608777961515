import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import { columnsData } from "./columns";
const EventTable = (props) => {
    const { events } = props;

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => events, [events]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;

    return (
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Cambios en el Pedido
                </div>
                {/* <CardMenu /> */}
            </div>

            <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={index}
                                        className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                                    >
                                        <p className="text-xs tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </p>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "ID del Evento") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "STATUS") {
                                            data = (
                                                <div className="flex items-center gap-2">
                                                    <div className={`rounded-full text-xl`}>
                                                        {cell.value === "Approved" ? (
                                                            <MdCheckCircle className="text-green-500" />
                                                        ) : cell.value === "Disable" ? (
                                                            <MdCancel className="text-red-500" />
                                                        ) : cell.value === "Error" ? (
                                                            <MdOutlineError className="text-orange-500" />
                                                        ) : null}
                                                    </div>
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                </div>
                                            );
                                        } else if (cell.column.Header === "Fecha de Inicio") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "Completado el:") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value ? cell.value : "En ejecucion"}
                                                </p>
                                            );
                                        }else if (cell.column.Header === "Modificado por:") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value ? cell.value : "Admin"}
                                                </p>
                                            );
                                        }
                                        return (
                                            <td
                                                className="pt-[14px] pb-[18px] sm:text-[14px]"
                                                {...cell.getCellProps()}
                                                key={index}
                                            >
                                                {data}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default EventTable;
