import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box } from "@mui/material";
import StackTelasFormFields from "./StackTelasFormFields";
import TabComponent from "components-base/tab/Tab";
import moment from "moment";
import { createRolls, modifyRolls, validationSchemaRolls } from "../functions";
import { useSelector, useDispatch } from "react-redux";
import { showMessage } from "store/modules/Message";

const StackTelasForm = ({ mode, handleClose }) => {
  const { defaultData } = useSelector(({ talla }) => talla);
  const [availability, setAvailability] = useState(null);
  const dispatch = useDispatch();
  const messages = (mensaje) => {
    dispatch(
      showMessage({
        message: `El rollo se ha ${mensaje} con éxito`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };

  // console.log(defaultData);

  const formik = useFormik({
    initialValues:
      mode === "POST"
        ? {
            color: "",
            totalKg: "",
            meters: "",
            code: "",
            is_void: true
          }
        : defaultData,
    onSubmit: async (values) => {
      switch (mode) {
        case "POST":
          await createRolls(values);
          messages("creado")
          handleClose();
          break;
        case "PUT":
          await modifyRolls(values);
          messages("editado")
          handleClose();
          break;
        default:
          handleClose();
      }
    },
  });

  const { setFieldValue } = formik;

  // useEffect(() => {
  //   const fetchAvailability = async () => {
  //     try {
  //       const response = await fetch(`/marisoft/rolls/${defaultData.id}`);
  //       if (response.ok) {
  //         const data = await response.json();
  //         setAvailability(data);
  //       } else {
  //         console.error("Error al obtener la disponibilidad de rollos");
  //       }
  //     } catch (error) {
  //       console.error("Error al obtener la disponibilidad de rollos", error);
  //     }
  //   };

  //   if (mode === "PUT" && defaultData.id) {
  //     fetchAvailability();
  //   }
  // }, [mode, defaultData.id]);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        "& .MuiTextField-root": { marginBottom: "25px" },
      }}
      style={{ padding: "20px", justifyContent: "center" }}
    >
      <TabComponent
        tabsElement={[
          {
            label: "Información del Rollo",
            component: (
              <StackTelasFormFields
                formik={formik}
                mode={mode}
                setFieldValue={setFieldValue}
              />
            ),
          },
        ]}
      />

      {availability !== null && (
        <div>
          <p>Disponibilidad de rollos: {availability}</p>
        </div>
      )}

      <div style={{ position: "absolute", right: 100, top: 20 }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Grid item>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default StackTelasForm;
