/*
 Initial state and properties
 */
export const initialState = {
  defaultDataEmbroidery: null,
};
/*
 Action types
 */
export const SET_DEFAULT_DATA_EMBROIDERY = "SET_DEFAULT_DATA_EMBROIDERY";
/*
 Arrow function for change state
 */
export const setDefaultDataEmbroidery = (payload) => ({
  type: SET_DEFAULT_DATA_EMBROIDERY,
  payload,
});
/*
 Reducer to describe how the state changed
 */
export default function Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_DEFAULT_DATA_EMBROIDERY:
      return {
        ...state,
        defaultDataEmbroidery: payload,
      };
    default:
      return state;
  }
}
