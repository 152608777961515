import { Typography, CircularProgress, Card, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "./columnsModal";
import { useEffect } from "react";
import { getAllConsumptionByPrendaV2 } from "components-base/prendas/functions";
import { useState } from "react";
import { boxSize } from "utils/apis";
import ClothConsumption from "./ClothConsumption";
import { useDispatch } from "react-redux";
import ClothConsumptionListModal from "./ClothConsumptionModal";
import { setDefaultDataCC } from "store/modules/Prendas";
import DeleteConsumption from "./DeleteConsumption";
import { getAllConsumptionByPrenda } from "components-base/prendas/functions";
import { getAllConsumption } from "components-base/prendas/functions";

const ClothConsumptionList = ({ defaultData }) => {

    const [data, setData] = useState(null);
    const [_columns, setColumns] = useState(null);
    const [openCC, setOpenCC] = useState(false);
    const [openDeleteCC, setOpenDeleteCC] = useState(false);
    const [rowId, setRowId] = useState(0);
    const [modeCC, setModeCC] = useState("POST");
    const dispatch = useDispatch();

    useEffect(() => {
        if (defaultData) {
            const fetchData = async () => {
               // const consumptionList = await getAllConsumptionByPrendaV2(defaultData.id);
                const consumptionList = await getAllConsumption();
                setData(consumptionList.filter(item => item.prenda.id === defaultData.id));
                let col = columns(setOpenCC, setModeCC, setOpenDeleteCC, dispatch);
                setColumns(col)
            }
            fetchData();
        }

    }, [openCC,openDeleteCC,defaultData]);
    const handleCloseCC = () => {
        setOpenCC(false)
    }
const setModeCreate = () =>{
    setDefaultDataCC(null)
    setModeCC("POST");
    setOpenCC(true);
}
    return (
        <Box sx={{ height: boxSize, justifyContent: "center" }}>
            <ClothConsumptionListModal open={openCC} handleClose={handleCloseCC} mode= {modeCC} />
            <DeleteConsumption openDeleteCC={openDeleteCC} setOpenDeleteCC={setOpenDeleteCC} />
            <Card extra={"w-full h-full p-5"}
            >
            <div className="flex items-center justify-start rounded-t-3xl p-3">
                    <Typography variant="h4"
                        className="px-4 py-2 text-base  text-brand-500 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                       {`Consumo de tela para ${defaultData.nombre}`}
                    </Typography>
                </div>
                <div className="flex items-center justify-end rounded-t-3xl p-3">
                    <button
                        onClick={setModeCreate}
                        className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        Agregar Consumo
                    </button>
                </div>
                {data ? (
                    <DataGrid
                        className="mt-8 h-max w-full dark:text-white"
                        rows={data || []}
                        columns={_columns || []}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 15, 20, 30]}
                        checkboxSelection
                        disableSelectionOnClick
                        autoHeight
                        getRowHeight={() => 'auto'}

                    />
                ) : (
                    <div className="flex justify-center pt-56">
                        <CircularProgress color="primary" />
                        <Typography>Cargando datos...</Typography>
                    </div>
                )}
            </Card>
        </Box>

    )
}
export default ClothConsumptionList;