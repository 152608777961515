import RutaTable from "./components/RutaTable";

const Rutas = () =>{

    return(
        <div>
            <RutaTable />
             
        </div>
    )
}
export default Rutas;