import { getAllPrendasById } from "components-base/prendas/functions";
import { client } from "utils/axios"
import * as yup from "yup";
import moment from "moment";
import 'moment/locale/es'
moment.locale('es')

export const deleteSalida = async (salidaId) => {
  try {
    await client.delete(`salidaCorte/${salidaId}`);
    return true
  } catch (error) {
    console.log(error)
    return false
  }

};
export const deleteDetalleSalida = async (id) => {
  try {
    await client.delete(`detalleSalidaCorte/${id}`);
  } catch (error) {
    console.log(error)
  }

};
export async function getAllSalidas() {
  const { data } = await client.get("/salidaCorte");
  return data;
}
export async function getAllDetallesSalida(id) {
  const { data } = await client.get(`/detalleSalidaCorte/filterSalidaCorte/${id}`);
  return data;
}
export async function getDetalleSalida(id) {
  const { data } = await client.get(`/detalleSalidaCorte/${id}`);
  return data;
}
export const validationSchemaSalida = yup.object({
  nombre: yup
    .string("Ingresa nombre del salida")
    .required("Campo Requerido"),
  descripcion: yup
    .string("Ingresa descripción")
    .required("Campo Requerido"),
  codigo: yup
    .string("Ingresa Código")
    .required("Campo Requerido"),

});
export const createSalida = async (salida) => {
  try {
    let obj = {
      chofer: { id: salida.chofer.id },
      maquilero: { id: salida.maquilero.id },
      details: salida.details,
      folio: salida.folio,
      fechaRetorno: salida.fechaRetorno,
      fechaSalida: salida.fechaSalida,
      pedido: { id: salida.pedido.id },
      internal:salida.internal,
      foliado: salida.foliado,
      tonos:salida.tonos,
      comments:salida.comments,
      completed:salida.completed,
      percentage:0
    }
    if (salida["supervisor"])
      obj = { ...obj, ...{ supervisor: { id: salida.supervisor.id } } }
    await client.post("salidaCorte", obj)

  } catch (error) {
    console.log(error)
  }
};
export async function createDetalle(salida) {
  try {
    await client.post("detalleSalidaCorte", salida)

  } catch (error) {
    console.log(error)
  }
};
export const modifySalida = async (salida) => {
  try {
    await client.put("salidaCorte", salida);
  } catch (error) {
    console.log(error)
  }

};
export const modifyDetalle = async (salida) => {
  try {
    await client.put("detalleSalidaCorte", salida);
  } catch (error) {
    console.log(error)
  }
};

export function dataConvert(data) {
  return { data: data }
}

export function totalSumByPrenda(obj, prendaId) {
  let prendas = obj.prendas || [];
  const _obj = prendas.find(item => item.tallaId === obj.talla.id && item.id === prendaId);
  if (!_obj) return 0;
  return Number(_obj.cantidadAsignada);

}


export function totalSum(detalle) {
  let total = 0;
  let cantidad = 0;
  detalle.details.data.forEach(detail => {
    cantidad = detail.cantidadAsignada ? detail.cantidadAsignada + cantidad : cantidad + 0;
    detail.prendas && detail.prendas.forEach(i => {
      total += i.cantidadAsignada
    })
  })
  return { totalCount: total, cantidad: cantidad }
}

export async function insumosConvert(detalle) {
const internal = detalle.internal;
  const prendas = detalle.details.prendas;
  const details = detalle.details.data;
  let insumos = []
  let insumosGrouped = [];
  for (let i = 0; i < prendas.length; i++) {
    const p = prendas[i];
    let prenda = await getAllPrendasById(p.id);
    let prendaInsumos;
    if (internal)
      prendaInsumos = prenda.insumo;
    else
      prendaInsumos = prenda.insumo.filter(i => !i.insumo.internal);

   prendaInsumos.forEach((insumo, index) => {
      if (insumo.details) {
        let factor = 1;
        insumo.details.forEach(d => {
          let obj = details.find(i => Number(i.talla.id) === Number(d.talla.id));
          if (obj) {
            let cantidad = Number(d.cantidad) * Number(obj.cantidadAsignada || 1);
            factor = Number(d.unidad?.factor || 1);
            insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.insumo.details.presentacion, cantidad: (cantidad / factor / Number(insumo.insumo.details.cantidad)), nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
          }
        })
      }
    })
  }
  for (let i = 0; i < detalle.details.data.length; i++) {
    const detail = detalle.details.data[i];
    for (let i = 0; i < prendas.length; i++) {
      const p = prendas[i]
      let prenda = await getAllPrendasById(p.id);
        let prendaInsumos;
    if (internal)
      prendaInsumos = prenda.insumo;
    else
      prendaInsumos = prenda.insumo.filter(i => !i.insumo.internal);

   prendaInsumos.forEach((insumo, index) => {
        if (!insumo.details) {
          insumos.push({ prenda: `${prenda.descripcion}`, unidad: insumo.unidad, cantidad: insumo.cantidad * detail.cantidadAsignada ? detail.cantidadAsignada : 0, nombre: insumo.insumo.nombre, descripcion: insumo.insumo.descripcion })
        }
      })
    }
  }

  let groupedData = insumos.reduce((groups, item) => {
    const key = item.nombre;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
    return groups;
  }, {});

  let keys = Object.keys(groupedData)
  keys.forEach(item => {
    insumosGrouped.push({ nombre: item, data: groupedData[item] })
  })
  insumosGrouped.forEach(item => {
    let cantidad = 0;
    let unidad = ""
    item.data.forEach(d => {
      cantidad += d.cantidad;
      unidad = d.unidad
    })
    item.cantidad = cantidad;
    item.unidad = unidad
  })
  
  return insumosGrouped
}

export async function getDataDetalle(pedidoId) {

  let result = await getAllSalidas();
  const detallesData = result.filter(item => item.pedido.id === pedidoId)
  let obj = []
  detallesData.forEach(d => {
    d.details.data.forEach(dd => {
      obj = [...obj, dd]
    })
  })
  let groupedData = obj.reduce((groups, item) => {
    const key = item.talla.nombre;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
    return groups;
  }, {});

  let dataG = []
  let keys = Object.keys(groupedData)
  keys.forEach(item => {
    dataG.push({ nombre: item, data: groupedData[item] })
  })
  dataG.forEach(item => {
    let cantidad = 0;
    let talla;
    item.data.forEach(d => {
      cantidad = d.cantidadAsignada ? d.cantidadAsignada + cantidad : 0;
      talla = d.talla
    })
    item.cantidadAsignada = cantidad;
    item.talla = talla
  })
  dataG.forEach(item => {
    delete item.data;
    delete item.nombre;
  })

  return dataG;
}

export function getDataSizes(data) {
  return []
}
export function convertMonth(data){
const newData = data.map(d =>{
  let date = moment(d.fechaEntrega || d.fechaSalida);
  let monthName = date.format("MMMM");
  let monthNumber = date.month();
  let year = date.year();
  return {
    monthName: monthName.toUpperCase(),
    monthNumber,
    year,
    ...d
  }
});
  return newData;
}