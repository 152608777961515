import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import {  useSelector } from "react-redux";
import { deleteSystemUser } from "../functions";
const SystemUserDelete = ({openDelete,handleCloseDelete}) => {
  
    const { userIdSelected } = useSelector(({ user }) => user);

    const deleteFnc = async () =>{
    await deleteSystemUser(userIdSelected);
    handleCloseDelete();
}
    return (
        <>
            <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación del usuario"}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={handleCloseDelete} color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default SystemUserDelete;