/*
 Initial state and properties
 */
 export const initialState = {
    mode:"POST",
    userIdSelected:0,
    defaultDataUser:null
 }
 /*
  Action types
  */
 export const SET_MODE = 'SET_MODE'
 export const SET_USER_ID = 'SET_USER_ID'
 export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'
 /*
  Arrow function for change state
  */
 export const setMode = (payload) => ({
   type: SET_MODE,
   payload,
 });
 export const setUserId = (payload) => ({
  type: SET_USER_ID,
  payload,
})
export const setDefaultData = (payload) => ({
  type: SET_DEFAULT_DATA,
  payload,
})
 /*
  Reducer to describe how the state changed
  */
 export default function Reducer(state = initialState, { type, payload }) {
   switch (type) {
     case SET_MODE:
       return {
         ...state,
         mode: payload,
       }
       case SET_USER_ID:
        return {
          ...state,
        userIdSelected: payload,
        }
        case SET_DEFAULT_DATA:
          return {
            ...state,
            defaultDataUser: payload,
          }
     default:
       return state
   }
 }
 