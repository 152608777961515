import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import { useSelector } from "react-redux";
import SpecificationPDF from "./SpecificationPDF";

const ModalSpecificationPDF =({open, handleClose}) =>{
const {defaultDataSS}= useSelector(({specifications})=> specifications);
if(!defaultDataSS) return null;
    return(
        <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
    >
        <DialogContent>
            <SpecificationPDF data= {defaultDataSS}  />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose} color={"secondary"}variant="contained"
            >
                Cancelar
            </Button>

        </DialogActions>
    </Dialog>
    )
}
export default ModalSpecificationPDF;