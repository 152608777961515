import { Edit, Delete, Warning, Equalizer } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography, Grid, CircularProgress, Box } from "@mui/material";
import {
  setSalidaId,
  setDefaultData,
  setDefaultDataDetail,
} from "store/modules/Salidas";
import { v4 as uuidv4 } from "uuid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { ContactsRounded, MapRounded, PrintSharp } from "@mui/icons-material";

export const columns = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  handleOpenPDF
) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 200,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar Salida" placement="top">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultData(data.row));
                dispatch(setSalidaId(data.row.id));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Salida" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setSalidaId(data.row.id));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir Orden de Salida (Todas)" placement="top">
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                dispatch(setDefaultDataDetail({ detalleSalida: [data.row] }));
                handleOpenPDF();
              }}
            >
              <PrintSharp />
            </IconButton>
          </Tooltip>
          <Tooltip title="Progreso" placement="top">
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
             alert("Aqui podras revisar a detalle el avance de este folio (En construccion)")
                
              }}
            >
              <Equalizer />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "pedido",
      headerName: "Codigo Pedido",
      width: 150,
      renderCell: (data) => (
        <>{data.row.pedido.codigo}</>
      )
    },
    {
      field: "folio",
      headerName: "Folio",
      width: 100,
    },

    {
      field: "percentage",
      headerName: "% Completado",
      width: 120,
      renderCell: (data) => (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress color="success" variant="determinate" value={data.row.percentage || 0}/>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(data.row.percentage || 0)}%`}
          </Typography>
        </Box>
      </Box>
      )
      ,
    },
    {
      field: "fechaSalida",
      headerName: "Fecha Salida",
      width: 150,
      renderCell: (data) => <DatePickerComponent data={data.row.fechaSalida} />,
    },
    {
      field: "fechaRetorno",
      headerName: "Fecha Retorno",
      width: 150,
      renderCell: (data) => (
        <DatePickerComponent data={data.row.fechaRetorno} />
      ),
    },
    {
      field: "routeDays",
      headerName: "Dias en ruta",
      width: 100,
      renderCell: (data) => (
        <div style={{ flexFlow: "row", justifyItems: "end" }}>
          <>
            <Warning color="primary" />
            {moment(data.row.fechaSalida).diff(moment(), "days")}
          </>
        </div>
      ),
    },
    {
      field: "chofer",
      headerName: "Operador",
      width: 200,
      renderCell: (data) => (
        <div style={{ flexFlow: "row", justifyItems: "end" }}>
          <ContactsRounded color="primary" />
          {`${data.row.chofer.persona.nombre}  ${data.row.chofer.persona.apellidoPaterno}`}
        </div>
      ),
    },
    {
      field: "ruta",
      headerName: "Ruta",
      width: 150,
      renderCell: (data) => (
        <div style={{ flexFlow: "row", justifyItems: "end" }}>
          <MapRounded color="primary" />
          {`${data.row.maquilero.ruta.nombre}`}
        </div>
      ),
    },
    {
      field: "supervisor",
      headerName: "Supervisor",
      width: 200,
      renderCell: (data) => (
        <div style={{ flexFlow: "row", justifyItems: "end" }}>
          {data.row.supervisor ? (
            <>
              <ContactsRounded color="primary" />
              {`${data.row.supervisor.persona.nombre}  ${data.row.supervisor.persona.apellidoPaterno}`}
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      field: "maquilero",
      headerName: "Maquilero",
      width: 200,
      renderCell: (data) => (
        <div style={{ flexFlow: "row", justifyItems: "end" }}>
          <ContactsRounded color="primary" />
          {`${data.row.maquilero.persona.nombre}  ${data.row.maquilero.persona.apellidoPaterno}`}
        </div>
      ),
    },
    {
      field: "details",
      headerName: "Producto/ Talla / Cantidad",
      width: 450,
      renderCell: (data) => {
        let details = data.row.details.data;
        return (
          <Grid container spacing={0} flexDirection={"column"}>
            {details.map((item) => (
              <Typography variant="caption" key={item.talla.id}>
                {` ${item.producto.nombre} / ${item.talla.nombre} / ${
                  item.cantidadAsignada || 0
                }`}
              </Typography>
            ))}
          </Grid>
        );
      },
    },
  ];
};
const DatePickerComponent = ({ data }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
      <DatePicker
        value={moment(data)}
        closeOnSelect
        sx={{ width: "100%" }}
        slotProps={{ textField: { variant: "standard" } }}
        showDaysOutsideCurrentMonth
        disableHighlightToday
      />
    </LocalizationProvider>
  );
};

export const detallesColumn = (
  setOpenDeleteDetalle,
  dispatch,
  setEditData,
  setOpenModal,
  setMode,
  handleOpenPDF
) => {
  return [
    {
      field: `acciones`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar Pedido de la Salida" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setEditData(data.row);
                setOpenModal(true);
                setMode("PUT");
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Pedido de la Salida" placement="top">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => {
                setOpenDeleteDetalle(true);
                dispatch(setDefaultDataDetail(data.row));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <IconButton
            aria-label="print"
            color="primary"
            onClick={() => {
              handleOpenPDF();
              dispatch(setDefaultDataDetail({ detalleSalida: [data.row] }));
            }}
          >
            <PrintSharp />
          </IconButton>
        </React.Fragment>
      ),
    },
    {
      field: "codigo",
      headerName: "Código Pedido",
      width: 180,
    },

    {
      field: "maquilero",
      headerName: "Maquilero",
      width: 200,
      renderCell: (data) => (
        <>
          <ContactsRounded color="primary" />
          <Typography variant="caption">
            {`${data.row.maquilero.persona.nombre} ${data.row.maquilero.persona.apellidoPaterno} ${data.row.maquilero.persona.apellidoMaterno}`}
          </Typography>
        </>
      ),
    },
    {
      field: "details",
      headerName: "Producto/ Talla / Cantidad",
      width: 400,
      renderCell: (data) => {
        let details = data.row.details.data;
        return (
          <div>
            <Grid container spacing={0} flexDirection={"column"}>
              {details.map((item) => (
                <Grid key={item.talla.id} item>
                  <Typography variant="caption">
                    {` ${item.producto.nombre} / ${item.talla.nombre} / ${item.cantidadAsignada}`}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        );
      },
    },
    {
      field: "total",
      headerName: "Total de corte",
      width: 800,
      renderCell: (data) => {
        let Total = 0;
        data.row.details.data.forEach((item) => {
          Total += item.cantidadAsignada;
        });
        return (
          <div>
            <Grid container spacing={0} flexDirection={"column"}>
              {
                <Grid item>
                  <Typography variant="caption">{` ${Total}`}</Typography>
                </Grid>
              }
            </Grid>
          </div>
        );
      },
    },
  ];
};
