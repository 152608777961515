import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { deletePedido } from "../functions";
const DeletePedido = ({ openDelete, setOpenDelete }) => {
  const { pedidoIdSelected } = useSelector(({ pedido }) => pedido);
  const deleteFnc = async () => {
    await deletePedido(pedidoIdSelected);
    setOpenDelete(false);
  };
  return (
    <>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>
          <Typography variant="title">{"Eliminar"}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle">
            {"Continuar con la eliminación del pedido"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color={"secondary"}>
            Cancelar
          </Button>
          <Button onClick={deleteFnc} color={"secondary"}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DeletePedido;
