
export const convertSvgToPngBase64 = async (svgString, width, height) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
  
      canvas.width = width;
      canvas.height = height;
  
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = (error) => reject(error);
      img.src = `data:image/svg+xml;base64,${btoa(svgString)}`;
    });
  };