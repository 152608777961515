import { useEffect, useState } from "react";
import DriverLogsTable from "./components/DriverLogsTable";
import { getAllOperadores } from "components-base/operadores/functions";
import { getAllDriverLogs } from "./functions";
import TabComponent from "components-base/tab/Tab";
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Paper } from "@mui/material";
import RouteMap from "components-base/pedidos/components/DriverRoute";
const DriverLogs = () => {
    const [drivers, setDrivers] = useState([]);
    const [logs, setLogs] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());


    useEffect(() => {
        const fetchData = async () => {
            let drivers = await getAllOperadores();
            setDrivers(drivers);
            let logs = await getAllDriverLogs(moment(selectedDate).format("YYYY-MM-DD"));
            setLogs(logs);
        }
        fetchData();
    }, [refresh]);

    useEffect(() => {
        let tabsArray = drivers.map((driver) => {
            return {
                label: `${driver.persona.nombre} ${driver.persona.apellidoPaterno}  ${driver.persona.apellidoMaterno}`,
                component: (
                    <DriverLogsTable
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        key={driver.id}
                        data={logs.filter((item) => item.driver.id === driver.id)}
                    />
                ),
            };
        });
        setTabs(tabsArray);

    }, [logs, drivers]);
    const handleChangeDate = (value) => {
        setSelectedDate(moment(value));
        setRefresh(!refresh)
    };
    const center = [51.505, -0.09]; // [latitude, longitude]
    const zoom = 13;
    const markerPosition = [51.505, -0.09]; // [latitude, longitude]
    return (
        <Paper elevation={5} >
            <Box item xs={12} sm={12} md={8} style={{padding:20}}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
                    <DatePicker
                        label={"Filtro por fecha"}
                        value={selectedDate}
                        onChange={handleChangeDate}
                    />
                </LocalizationProvider>
            </Box>
            <TabComponent tabsElement={tabs} />
            <RouteMap selectedDate={selectedDate}  center={center} zoom={zoom} markerPosition={markerPosition} />
        </Paper>
    )
}
export default DriverLogs;