import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteFurniture } from "../functions";

const FurnitureDelete = ({ openDelete, setOpenDelete }) => {
  const dispatch = useDispatch();
  const { furnitureIdSelected } = useSelector(({ furniture }) => furniture);

  const deleteFnc = async () => {
    try {
      await deleteFurniture(furnitureIdSelected, dispatch);
      setOpenDelete(false);
    } catch (error) {
      console.error("Error deleting furniture:", error);
    }
  };

  return (
    <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
      <DialogTitle>
        <Typography variant="title">{"Eliminar"}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle">
          {"Continuar con la Eliminación del Mueble"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDelete(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={deleteFnc} color="primary">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FurnitureDelete;
