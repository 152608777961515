import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setCategoriesId,setDefaultData } from "store/modules/Categories";
import { v4 as uuidv4 } from 'uuid';


export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 100,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Editar Categorias" placement="top">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => { setOpen(true); setMode("PUT"); dispatch(setDefaultData(data.row));dispatch(setCategoriesId(data.row.id)) }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar Categoria" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setCategoriesId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip>

      </React.Fragment>
    ),
  },
  {
    field: "code",
    headerName: "Código",
    width: 200,
  },
  {
    field: "name",
    headerName: "Nombre",
    width: 200,
  },
  {
    field: "type",
    headerName: "Tipo",
    width: 200,
    renderCell: (data) => (
        <>{data.row.type.name}</>
    )
  },
  
]
}
