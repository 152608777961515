import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { setDefaultData, setUserId } from "store/modules/Users";
import { v4 as uuidv4 } from 'uuid';


export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 100,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Editar Usuario" placement="top">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => { setOpen(true); setMode("PUT"); dispatch(setDefaultData(data.row));dispatch(setUserId(data.row.id)) }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Eliminar Usuario" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setUserId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip> */}
      </React.Fragment>
    ),
  },
  {
    field: "username",
    headerName: "Usuario/Email",
    width: 400,
    renderCell: (data) => (
      <Typography>{`${data.row.persona.nombre} ${data.row.persona.apellidoPaterno} ${data.row.persona.apellidoMaterno} `} </Typography>
  )
  },
  {
    field: "fechaUltimoAccesso",
    headerName: "Ultimo Acceso al sistema",
    width: 300,
    renderCell: (data) => (
      <Typography>{`${data.row.fechaUltimoAccesso ? data.row.fechaUltimoAccesso :"Nunca" }`} </Typography>
  )
  },
  {
    field: 'activo',
    headerName: "Activo",
    width: 350,
    renderCell: (data) => (
      <Typography>{`${data.row.activo ? "SI" : "NO"}`} </Typography>
  )
  },
]
}