import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import StampingOrderPDF from "./StampingOrderPDF";
import { useSelector } from "react-redux";

const ModalStampingPDF = ({ open, handleClose }) => {
  const { defaultDataStamping } = useSelector(({ stamping }) => stamping);
  if (!defaultDataStamping) return null;
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent>
        <StampingOrderPDF data={defaultDataStamping} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalStampingPDF;
