/*
 Initial state and properties
 */
 export const initialState = {
    mode:"POST",
    asignacionesIdSelected:0,
    defaultDataAsignaciones:{}
 }
 /*
  Action types
  */
 export const SET_MODE = 'SET_MODE'
 export const SET_ASIGNACIONES_ID = 'SET_ASIGNACIONES_ID'
 export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'
 /*
  Arrow function for change state
  */
 export const setMode = (payload) => ({
   type: SET_MODE,
   payload,
 });
 export const setAsignacionesId = (payload) => ({
  type: SET_ASIGNACIONES_ID,
  payload,
})
export const setDefaultDataAsignaciones = (payload) => ({
  type: SET_DEFAULT_DATA,
  payload,
})
 /*
  Reducer to describe how the state changed
  */
 export default function Reducer(state = initialState, { type, payload }) {
   switch (type) {
     case SET_MODE:
       return {
         ...state,
         mode: payload,
       }
       case SET_ASIGNACIONES_ID:
        return {
          ...state,
          asignacionesIdSelected: payload,
        }
        case SET_DEFAULT_DATA:
          return {
            ...state,
            defaultDataAsignaciones: payload,
          }
     default:
       return state
   }
 }
 