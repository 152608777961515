import {
  Edit,
  Delete,
  CheckCircle,
  Warning,
  ContentCut,
  Visibility,
  Yard,
  Interests,
  Grading,
  Block
} from "@mui/icons-material";
import React from "react";
import {
  Tooltip,
  IconButton,
  Autocomplete,
  TextField,
  Switch,
  Chip,
} from "@mui/material";
// import GradingIcon from "@mui/icons-material/Grading";
import { setPedidoId, setDefaultDataPedido } from "store/modules/Pedidos";
import { v4 as uuidv4 } from "uuid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { modifyPedido } from "./functions";
const cancelPedido = async (e, data, dispatch, setRefresh, refresh) => {
  data.voided = !data.voided;
  await modifyPedido(data, dispatch)
  setRefresh(!refresh);
}
export const columns = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  setOpenCut,
  setOpenSummary,
  status,
  changeStatusHandle,
  setOpenEvent,
  setOpenStamping,
  setOpenBordado,
  setOpenEvent2,
  setRefresh,
  refresh
) => {
  return [
    {
      field: `${uuidv4()}`,
      headerName: "Acciones",
      width: 350,
      renderCell: (data) => {
        const dataEmbroideryFlag = data?.row?.detallePedido?.filter(
          (item) => item.producto.bordado === true
        );
        return (
          <React.Fragment>

            <Tooltip title="Cancelar pedido" placement="top">
              <Switch
              color="primary"
                checked={!data.row.voided}
                onChange={(e) => cancelPedido(e, data.row, dispatch, setRefresh, refresh)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
    
            </Tooltip>
            <Tooltip title="Editar pedido" placement="top">
              <IconButton
                disabled={data.row.voided}
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setOpen(true);
                  setMode("PUT");
                  dispatch(setDefaultDataPedido(data.row));
                  dispatch(setPedidoId(data.row.id));
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar Pedido" placement="top">
              <IconButton
                disabled={data.row.voided}
                aria-label="delete"
                color="primary"
                onClick={() => {
                  setOpenDelete(true);
                  setMode("DELETE");
                  dispatch(setPedidoId(data.row.id));
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generar Orden de Corte" placement="top">
              <IconButton
                disabled={data.row.voided}
                aria-label="delete"
                color="primary"
                onClick={() => {
                  setOpenCut(true);
                  dispatch(setDefaultDataPedido(data.row));
                }}
              >
                <ContentCut />
              </IconButton>
            </Tooltip>
            <Tooltip title="Resumen del Pedido" placement="top">
              <IconButton
                disabled={data.row.voided}
                aria-label="delete"
                color="primary"
                onClick={() => {
                  setOpenSummary(true);
                  dispatch(setDefaultDataPedido(data.row));
                }}
              >
                <Grading />
              </IconButton>
            </Tooltip>
            <Tooltip title="Resumen de Eventos" placement="top">
              <IconButton
                disabled={data.row.voided}
                aria-label="delete"
                color="primary"
                onClick={() => {
                  setOpenEvent(true);
                  dispatch(setDefaultDataPedido(data.row));
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Orden de Bordado" placement="top">
              <IconButton
                aria-label="Bordado"
                color="primary"
                disabled={dataEmbroideryFlag.length === 0 || data.row.voided}
                onClick={() => {
                  setOpenBordado(true);
                  dispatch(setDefaultDataPedido(data.row));
                }}
              >
                <Yard />
              </IconButton>
            </Tooltip>
            <Tooltip title="Orden de Estampado" placement="top">
              <IconButton
                disabled={data.row.voided}
                aria-label="Estampado"
                color="primary"
                onClick={() => {
                  setOpenStamping(true);
                  dispatch(setDefaultDataPedido(data.row));
                }}
              >
                <Interests />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      field: "codigo",
      headerName: "Código",
      width: 150,
      headerClassName: "color:red",
    },
    {
      field: "fechaCreacion",
      headerName: "Fecha de Creación",
      width: 170,
      renderCell: (data) => (
        <DatePickerComponent data={data.row.fechaCreacion} />
      ),
    },
    {
      field: "fechaEntrega",
      headerName: "Fecha de Entrega",
      width: 170,
      renderCell: (data) => (
        <DatePickerComponent data={data.row.fechaEntrega} />
      ),
    },
    {
      field: "fechaEstimadaTermino",
      headerName: "Fecha Estimada de Entrega",
      width: 220,
      renderCell: (data) => (
        <DatePickerComponent data={data.row.fechaEstimadaEntrega} />
      ),
    },
    {
      field: "remainingDays",
      headerName: "Días restantes",
      width: 120,
      renderCell: (data) => (
        <div style={{ flexFlow: "row", justifyItems: "end" }}>
          {moment(data.row.fechaEntrega).diff(moment(), "days") > 0 ? (
            <>
              {moment(data.row.fechaEntrega).diff(moment(), "days")}
              <CheckCircle color="success" />
            </>
          ) : (
            <>
              {moment(data.row.fechaEntrega).diff(moment(), "days")}
              <Warning style={{ color: "#ff1744" }} />
            </>
          )}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Etapa del Pedido",
      width: 300,
      renderCell: (data) => (
        <>
          <Autocomplete
            name="status"
            options={status || []}
            id="status"
            fullWidth
            renderInput={(params) => (
              <TextField variant="standard" {...params} label="" />
            )}
            getOptionLabel={(status) => `${status.nombre}`}
            isOptionEqualToValue={(option, value) =>
              Number(option.id) === Number(value.id)
            }
            onChange={(e, option) => changeStatusHandle(e, option, data.row.id)}
            defaultValue={
              data.row.event.length > 0
                ? data.row.event.find((item) => item.fechaCompletado === null)
                  .status
                : {}
            }
          />
        </>
      ),
    },
  ];
};

const DatePickerComponent = ({ data }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
      <DatePicker
        value={moment(data)}
        closeOnSelect
        sx={{ width: "100%" }}
        slotProps={{ textField: { variant: "standard" } }}
        showDaysOutsideCurrentMonth
        disableHighlightToday
      />
    </LocalizationProvider>
  );
};
