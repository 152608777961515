export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "FOLIO",
    accessor: "folio",
  },
  {
    Header: "PROGRESO",
    accessor: "percentage",
  },
  {
    Header: "CANTIDAD RECOLECTADA",
    accessor: "total_pickup",
  },
  {
    Header: "TOTAL",
    accessor: "total",
  },
  {
    Header: "FECHA DE SALIDA",
    accessor: "fechaSalida",
  },
];

export const columnsDataColumns = [
  {
    Header: "FOLIO",
    accessor: "name",
  },
  {
    Header: "PROGRES0",
    accessor: "progress",
  },
  {
    Header: "CANTIDAD",
    accessor: "quantity",
  },
  {
    Header: "FECHA ULTIMA RECOLECCION",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "FOLIO",
    accessor: "name",
  },
  {
    Header: "PROGRES0",
    accessor: "progress",
  },
  {
    Header: "CANTIDAD",
    accessor: "quantity",
  },
  {
    Header: "FECHA ULTIMA RECOLECCION",
    accessor: "date",
  },
];
