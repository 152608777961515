import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setTallaId,setDefaultData } from "store/modules/Tallas";
import { v4 as uuidv4 } from 'uuid';

export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 150,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Editar Talla" placement="top">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => { setOpen(true); setMode("PUT"); dispatch(setDefaultData(data.row));dispatch(setTallaId(data.row.id)) }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar Talla" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setTallaId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip>

      </React.Fragment>
    ),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    width: 300,
  },
  {
    field: 'descripcion',
    headerName: "Descripción",
    width: 300,
  },
  {
    field: 'codigo',
    headerName: "Código",
    width: 300,
  },

]
}
