import { Delete, Edit } from "@mui/icons-material";
import { Dialog, DialogActions, Paper, Box, Grid, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AddMaquina from "./AddMaquina";

const MaquileroDetails = ({ open, handleClose, maquinas, setMaquinas }) => {
    const [maquina, setMaquina] = useState([]);
    const [totalOperarios, setTotalOperarios] = useState(0);
    const [totalMaquinas, setTotalMaquinas] = useState(0);
    const [openMaquina, setOpenMaquina] = useState(false);
    const [defaultValues, setDefaultValues] = useState(null);

    useEffect(()=>{
        if(maquinas)
           setMaquina(maquinas)
    },[maquinas])
    
    useEffect(()=>{
        setMaquinas(maquina)
    },[maquina,setMaquinas])

    useEffect(() => {
        let totalO = 0
        let totalM = 0
        maquina.forEach(maquina => {
            totalO += Number(maquina.totalOperarios);
            totalM += Number(maquina.numero)
        });
        setTotalOperarios(totalO);
        setTotalMaquinas(totalM);
    }, [maquina, maquinas]);

    const handleCloseMaquina = () => {
        setOpenMaquina(false);
        setDefaultValues(null);
    }
    const handleDelete = (obj) => {
        setMaquina((m) => m.filter(item => item.nombre !== obj.nombre))
    }
    const handleEdit = (obj, index) => {
        setDefaultValues({
            numero: obj.numero,
            nombre: obj.nombre,
            operarios: obj.totalOperarios,
            minutosCorteOperario: obj.minutosCorteOperario,
            index: index
        })
        setOpenMaquina(true);
    }
    const handleOpenMaquina = () => {
        setDefaultValues(null);
        setOpenMaquina(true);
    }
    return (
        <>
            < AddMaquina defaultValues={defaultValues} open={openMaquina} handleClose={handleCloseMaquina} setMaquina={setMaquina} />
            <Box
              sx={{padding:5}}
            >
                <Paper elevation={2}   sx={{padding:5}}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="operadores"
                                type="number"
                                name="operadores"
                                label="Número de operadores"
                                value={totalOperarios}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="maquinas"
                                type="mauinas"
                                name="mauinas"
                                label="Total Máquinas"
                                value={totalMaquinas}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="minutaje"
                                type="number"
                                name="minutaje"
                                label="Minutaje"
                                value={totalOperarios * 1}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="total"
                                type="number"
                                name="total"
                                label="Total"
                                disabled

                                color="primary"
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 10, textAlign: "center" }} >
                        <Grid item xs={2}>
                            {"Borrar/Editar"}
                        </Grid>
                        <Grid item xs={2}>
                            {"Tipo de Máquina"}
                        </Grid>
                        <Grid item xs={2}>
                            {"Total Máquinas"}
                        </Grid>
                        <Grid item xs={2}>
                            {"Operarios"}
                        </Grid>
                        <Grid item xs={2}>
                            {"Minutos X Corte"}
                        </Grid>
                        <Grid item xs={2}>
                            {"Corte Total"}
                        </Grid>
                        {maquina && maquina.map((item, index) => (

                            <Grid container>
                                <Grid item xs={2}>
                                    <Delete color="primary" onClick={() => handleDelete(item)} />
                                    <Edit onClick={() => handleEdit(item, index)} color="primary" />
                                </Grid>
                                <Grid item xs={2}>
                                    {item.nombre}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.numero}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.totalOperarios}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.minutosCorteOperario}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.totalOperarios * item.minutosCorteOperario}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <DialogActions>
                    <Button fullWidth variant="contained" color="primary" onClick={handleOpenMaquina}>
                        {"Agregar Máquina"}
                    </Button>
                </DialogActions>
                </Paper>
            </Box>
        </>
    )
}
export default MaquileroDetails;