export const convertData = (data) => {
    if (data.length === 0)
        return { date: "Sin datos aun", driver: "Sin datos aun", total: 0, comments: "Sin datos aun" }
    let obj = {
        date: data[0].fechaEntrada.split("T")[0],
        driver: `${data[0].driver.persona.nombre} ${data[0].driver.persona.apellidoPaterno}  ${data[0].driver.persona.apellidoMaterno}`,
        total: 0,
        comments: ""
    }

    data.forEach(element => {
        obj.total += Number(element.cantidad || 0);
        obj.comments += `Folio ${element.salida ? element.salida.folio : " "} : ${element.comment ? element.comment : "Sin comentarios"}\n`
    });
    return obj
}