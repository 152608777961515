
import Card from "components/card";
import TelaMenu from "./TelaMenu";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../columns";
import TelaDrawer from "./TelaDrawer";
import React, { useState, useEffect } from "react";
import {  getAllTelas } from "../functions";
import { Box, CircularProgress,Typography } from "@mui/material";
import { useDispatch } from "react-redux";
 import TelaDelete from "./TelaDelete";

const TelasTable = () =>{
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [mode, setMode] = useState("POST");
    const [telaData, setTelaData] = useState(null)
    const [dataColumns, setDataColumns] = useState(null);
    const dispatch = useDispatch()
    
  useEffect(() => {
    const fetchData = async () => {
      let telas = await getAllTelas();
      setTelaData(telas)
    }
    fetchData();
    let col = columns(setOpen,setMode,setOpenDelete,dispatch);
     setDataColumns(col);
    
  }, [open,openDelete,dispatch]);

  const handleClose = () => {
    setOpen(false);
  }
    return (
        <Box sx={{ height: "750px", width: "100%" }}>
          <TelaDelete openDelete={openDelete} setOpenDelete={setOpenDelete}/>
          <TelaDrawer open={open} handleClose={handleClose} mode={mode} />
          <Card extra={"w-full h-full p-4"}>
            <div className="relative flex items-center justify-end">
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                <TelaMenu setOpen={setOpen} setMode={setMode} />
              </div>
            </div>
            {telaData ? (
            <DataGrid
                className="dark:text-white mt-8 h-max w-full"
                rows={telaData || []}
                columns={dataColumns || []}
                pageSize={15}
                rowsPerPageOptions={[5, 10, 15, 20, 30]}
                checkboxSelection
                disableSelectionOnClick
              />) : (
                <div className="flex justify-center pt-56">
                <CircularProgress color="primary" />
                 <Typography>Cargando datos...</Typography>
        
           </div>
              )}

          </Card>
        </Box>
      );
}
export default TelasTable;