import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes/adminRoutes/routes";
import {
  Apps,
  ChevronRight,
  HourglassBottomOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Toolbar from "components-base/ToolBar";
import MainLayout from "components-base/main-layout/mainLayout";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 600 ? setOpen(false) : setOpen(true)
    );
  }, []);

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  // document.documentElement.dir = "ltr";
  return (
    <>
      <MainLayout
        currentRoute={currentRoute}
        brandText={currentRoute}
        secondary={getActiveNavbar(routes)}
        {...rest} >
        <Routes>
          {getRoutes(routes)}
          <Route
            path="/"
            element={<Navigate to="/admin/inicio" replace />}
          />
        </Routes>
      </MainLayout>

    </>
  );
}
