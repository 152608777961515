import { client } from "utils/axios";
import * as yup from "yup";

export const createTela = async (tela) => {
  try {
    await client.post("cloth", tela);
  } catch (error) {
    return false;
  }
};
export async function getAllTelas(id) {
  const { data } = await client.get(`/cloth`);
  return data;
}

export async function getAllDetalleTelas(id) {
  const { data } = await client.get(`/detalleTelas/filter/${id}`);
  return data;
}

export const validationSchemaTela = yup.object({
  nombre: yup.string("Ingresa nombre de la Tela").required("Campo Requerido"),
  descripcion: yup.string("Ingresa Descripción").required("Campo Requerido"),
  codigo: yup.string("Ingresa Código").required("Campo Requerido"),
});
export const deleteTela = async (telaId) => {
  await client.delete(`cloth/${telaId}`);
};

export const modifyTela = async (tela) => {
  await client.put("cloth", tela);
};
