import SupervisorTable from "./components/SupervisorTable";

const Supervisors = () =>{

    return(
        <div>
            <SupervisorTable />
        </div>
    )
}
export default Supervisors;