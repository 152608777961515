
import * as materialUIStyles from "@mui/material/styles";
import { ThemeContextProvider } from "./theme-context";
import MUIThemeProvider from "./theme-provider";

export function MaterialUIProvider(props) {
  return (
    <materialUIStyles.StyledEngineProvider
    >
      <ThemeContextProvider>
      <MUIThemeProvider children = {props.children} />
      </ThemeContextProvider>
    </materialUIStyles.StyledEngineProvider>
  )
}
