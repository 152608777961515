import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

import image from "../../../assets/img/marifer.png";
import {
  dataConvert,
  insumosConvert,
  totalSum,
  totalSumByPrenda,
} from "../functions";
import moment from "moment";
import checkmark from "../../../assets/img/checkmark.png";

const Checkbox = ({ isChecked, label }) => (
  <View style={styleMark.checkboxContainer}>
    <Text>{label} </Text>
    <View style={[styleMark.checkbox, isChecked && styleMark.checked]}>
      {isChecked && <Image src={checkmark} style={styles.checkMarkImage} />}
    </View>
  </View>
);
const styleMark = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 20,
    height: 20,
    marginRight: 5,
    border: "1 solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  checked: {
    backgroundColor: "white",
  },
  checkMarkImage: {
    width: 15,
    height: 15,
  },
});

// Create styles
const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 15,
    height: 15,
    marginRight: 4,
    border: "1 solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  checked: {
    backgroundColor: "pink",
  },
  cross: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
  },
  image2: {
    position: "absolute",
    objectPosition: "0",
    zIndex: -999,
    width: "320px",
    height: "320px",
    opacity: 0.2,
  },
  image: {
    position: "relative",
    objectFit: "cover",
    zIndex: -999,
    width: "120px",
    height: "120px",
    paddingLeft: 0,
    opacity: 0.9,
  },
  header: {
    color: "#ff0080",
    textTransform: "uppercase",
    marginTop: 25,
    paddingLeft: 40,
    fontSize: "18pt",
    fontWeight: "extrabold",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
    height: "7.975cm",
  },
  section1: {
    padding: 8,
    textAlign: "center",
    width: "7cm",
    height: "100%",
    backgroundColor: "white",
    border: 1,
    borderColor: "#0A83F5",
    lineHeight: 1.5,
    fontSize: "10pt",
  },
  section2: {
    padding: 8,
    lineHeight: 4,
    border: 1,
    borderColor: "#0A83F5",
    width: "14.6cm",
    height: "100%",
    backgroundColor: "white",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
// Create styles

const OrdenSalidaPDF = ({ defaultDataDetail }) => {
  const { data } = dataConvert(defaultDataDetail);
  const detalle = data.detalleSalida[0];

  const [insumos, setInsumos] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let ins = await insumosConvert(detalle);
      setInsumos(ins);
    };
    fetchData();
  }, []);

  if (!insumos) return null;

  return (
    <PDFViewer style={styles.viewer}>
      {
        <Document>
          {[
            "Original-Maquilero",
            "Copia-Supervisor",
            "Copia-Gerencia",
            "Copia-Producción",
          ].map((title) => (
            <Page size="LETTER" style={styles.page} key={detalle.id}>
              <View style={styles.mainSection}>
                <View>
                  <Image style={styles.image} src={image} />
                </View>
                <View>
                  <Text style={styles.header}>
                    {"Orden de salida de corte"}
                  </Text>
                </View>
                <View
                  style={{
                    height: 20,
                    flexDirection: "row",
                    marginTop: 25,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      color: "black",
                      padding: 0,
                      backgroundColor: "pink",
                    }}
                  >
                    {"FOLIO:"}
                  </Text>
                  <Text style={{ color: "red", padding: 0 }}>
                    {`${detalle.folio}`}
                  </Text>
                </View>
              </View>
              <View
                //   style={{ flexDirection: "row", textAlign: "center", fontSize: "9pt", color: "gray",wordWrap:"word-break" }}
                style={{
                  fontSize: 10,
                  flexDirection: "row",
                  textAlign: "center",
                  wordWrap: "word-break",
                  paddingLeft: 140,
                  marginTop: -140,
                  paddingBottom: 10,
                }}
              >
                <View style={{ marginTop: 0 }}>
                  <Checkbox isChecked={detalle.foliado} label="Foliado" />
                  <Checkbox isChecked={detalle.tonos} label="Tonos  " />
                </View>
                <View>
                  <Text
                    style={{
                      border: 1,
                      padding: 1,
                      width: 75,
                      textAlign: "center",
                    }}
                  >
                    {"Corte"}
                  </Text>
                  <Text
                    style={{
                      border: 1,
                      padding: 1,
                      height: 25,
                      width: 75,
                      textAlign: "center",
                    }}
                  ></Text>
                </View>
                <View>
                  <View>
                    <Text
                      style={{
                        border: 1,
                        padding: 1,
                        width: 75,
                        textAlign: "center",
                      }}
                    >
                      {"Cantidad"}
                    </Text>
                  </View>

                  <Text
                    style={{
                      border: 1,
                      width: 75,
                      padding: 1,
                      height: 25,
                      textAlign: "center",
                    }}
                  >
                    {/* {cantidad.toString()} */}
                    {totalSum(detalle)["cantidad"]}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      wordWrap: "break-word",
                      border: 1,
                      padding: 1,
                      width: 100,
                      textAlign: "center",
                    }}
                  >
                    {"Maquilero"}
                  </Text>

                  <Text
                    style={{
                      width: 100,
                      textAlign: "center",
                      wordWrap: "word-break",
                      border: 1,
                      fontSize: 7,
                      paddingTop: 2,
                      height: 25,
                    }}
                  >
                    {`${detalle.maquilero.persona.nombre} ${detalle.maquilero.persona.apellidoPaterno}`}
                  </Text>
                </View>
                <View style={{ textAlign: "left", width: 150, color: "#111" }}>
                  <Text
                    style={{ border: 1, fontSize: 9, padding: 1, height: 20 }}
                  >
                    {`${"Fecha de Salida: "} ${moment(
                      detalle.fechaSalida.split(" ")[0]
                    ).format("DD-MM-YYYY")}`}
                  </Text>
                  <Text></Text>
                  <Text
                    style={{ border: 1, fontSize: 9, padding: 1, height: 20 }}
                  >
                    {`${"Fecha de Retorno: "} ${moment(
                      detalle.fechaRetorno.split(" ")[0]
                    ).format("DD-MM-YYYY")}`}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  padding: 5,
                  backgroundColor: "pink",
                }}
              >
                <View style={{ width: "60%", marginRight: "10px" }}>
                  <Text style={{ fontSize: 10 }}>
                    {`MODELO: ${detalle.pedido.detallePedido
                      .map((item) => item.producto.modelo)
                      .join(",")}, `}
                    {`${detalle.details?.prendas
                      ?.map((p) => p?.nombre)
                      .join(",")}`}
                    {`${" " + "(" + detalle.pedido?.number + ")"}`}
                  </Text>
                </View>
                <View>
                  <Text>{"PRECIO:"}</Text>
                </View>
              </View>
              <View style={{ height: 270, border: 1, marginTop: 10 }}>
                <View style={{ alignItems: "center" }}>
                  <Image src={image} style={styles.image2} />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    fontSize: "11pt",
                    color: "#000111",
                  }}
                >
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{"Prenda"}</Text>
                  </View>
                  <View style={{ width: "10%", border: 1 }}>
                    <Text>{"Fecha"}</Text>
                  </View>
                  <View style={{ width: "30%", border: 1 }}>
                    <Text>{"Talla"}</Text>
                  </View>
                  <View style={{ width: "15%", border: 1 }}>
                    <Text>{"Total"}</Text>
                  </View>
                  <View style={{ width: "25%", border: 1 }}>
                    <Text>{"Entregado por"}</Text>
                  </View>
                </View>

                {detalle.details.prendas.map((p, i) => (
                  <View
                    key={i}
                    style={{
                      height:
                        detalle.details.data.filter(
                          (f) =>
                            f.producto.id === p.productoId && f.cantidadAsignada
                        ).length * 12,
                      flexDirection: "row",
                      textAlign: "center",
                      fontSize: "8pt",
                      wordWrap: "word-break",
                    }}
                  >
                    <View style={{ width: "20%", border: 1 }}>
                      <Text style={{ fontSize: 7 }}>{`${p.nombre}`}</Text>
                    </View>
                    <View style={{ width: "10%", border: 1 }}>
                      <Text>{""}</Text>
                    </View>
                    <View style={{ width: "30%", border: 1, fontSize: 7 }}>
                      {detalle.details.data
                        .filter(
                          (f) =>
                            f.producto.id === p.productoId && f.cantidadAsignada
                        )
                        .filter((f) => f.cantidadAsignada > 0)
                        .map((m) => (
                          <Text
                            key={m.talla.nombre}
                            style={{ fontWeight: "heavy" }}
                          >
                            {m.talla.nombre}
                          </Text>
                        ))}
                    </View>
                    <View style={{ width: "15%", border: 1, fontSize: 7 }}>
                      {detalle.details.data
                        .filter(
                          (f) =>
                            f.producto.id === p.productoId && f.cantidadAsignada
                        )
                        .filter((f) => f.cantidadAsignada > 0)
                        .map((m) => (
                          <Text>{totalSumByPrenda(m, p.id)}</Text>
                        ))}
                    </View>
                    <View style={{ width: "25%", border: 1 }}>
                      <Text>{""}</Text>
                    </View>
                  </View>
                ))}

                {/* 
                                <View style={{ flexDirection: "row", textAlign: "center", fontSize: "9pt", color: "#000111" }}>
                                    <View style={{ width: "20%" }} >
                                    </View>
                                    <View style={{ width: "10%" }} >
                                    </View>
                                    <View style={{ width: "30%" }} >
                                    </View>
                                    <View style={{ width: "15%", border: 0 }} >
                                        <Text>
                                            {totalSum(detalle)['totalCount']}
                                        </Text>
                                    </View>
                                    <View style={{ width: "25%" }} >
                                    </View>
                                </View> */}

                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((i) => (
                  <View
                    key={i}
                    style={{
                      height: 20,
                      flexDirection: "row",
                      textAlign: "center",
                    }}
                  >
                    <View style={{ width: "20%", border: 1, fontSize: 7 }}>
                      <Text>{""}</Text>
                    </View>
                    <View style={{ width: "10%", border: 1 }}>
                      <Text>{""}</Text>
                    </View>
                    <View style={{ width: "30%", border: 1, fontSize: 7 }}>
                      <Text>{""}</Text>
                    </View>
                    <View style={{ width: "15%", border: 1, fontSize: 7 }}>
                      <Text>{""}</Text>
                    </View>
                    <View style={{ width: "25%", border: 1 }}>
                      <Text>{""}</Text>
                    </View>
                  </View>
                ))}
              </View>

              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "13pt",
                  color: "gray",
                  height: 80,
                  border: 1,
                }}
              >
                <Text>{`Observaciones: ${detalle.comments}`}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "13pt",
                  color: "pink",
                  height: 30,
                  backgroundColor: "pink",
                  border: 1,
                  marginTop: 10,
                }}
              >
                <Text style={{ color: "gray" }}>{"Reposiciones"}</Text>
              </View>
              <View style={{ flexDirection: "row", paddingTop: 5 }}>
                <View style={{ width: "65%" }}>
                  {insumos.map((item) => (
                    <View
                      key={item.id}
                      style={{
                        flexDirection: "row",
                        paddingTop: 0,
                        textAlign: "center",
                      }}
                    >
                      <Text
                        style={{ border: 1, width: "60%", fontSize: "9pt" }}
                      >
                        {item.nombre}
                      </Text>
                      <Text
                        style={{ border: 1, width: "40%", fontSize: "9pt" }}
                      >
                        {`${Math.round(item.cantidad, 2)} / ${item.unidad}(s)`}
                      </Text>
                    </View>
                  ))}
                </View>
                <View
                  style={{ width: "35%", textAlign: "center", paddingLeft: 5 }}
                >
                  <View style={{ border: 1 }}>
                    <Text style={{ fontSize: "12pt" }}>
                      {"Habilitado en General"}
                    </Text>
                  </View>
                  <View style={{ border: 1, height: 105 }}>
                    <Text></Text>
                  </View>
                </View>
              </View>
              <View
                style={{ width: "100%", textAlign: "center", fontSize: "11pt" }}
              >
                <Text>
                  {"La responsabilidad del material es de quien lo recibe."}
                </Text>
                <Text>{"Revisar su material completo al ser entregado."}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: "11pt",
                  color: "red",
                }}
              >
                <Text>{title}</Text>
              </View>
            </Page>
          ))}
        </Document>
      }
    </PDFViewer>
  );
};
export default OrdenSalidaPDF;
