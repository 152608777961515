import { Grid, TextField, Typography, DialogActions, Button } from "@mui/material";
import InsumosPrenda from "./InsumosPrenda";
import { modifyPrenda, createPrenda } from "../functions";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import PrendaImage from "./PrendatImage";


const PrendaForm = ({ mode, defaultData, handleClose }) => {
    const { productIdSelected } = useSelector(({ producto }) => producto);
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [imagen, setImagen] = useState("");
    const [prendaInsumo, setPrendaInsumo] = useState([])

    useEffect(() => {
        if (mode === "PUT") {
            setNombre(defaultData?.nombre);
            setDescripcion(defaultData?.descripcion);
            setImagen(defaultData?.imagen);
        }
    }, [defaultData, mode])

    const onChangeNombre = (event) => {
        setNombre(event.target.value)
    }
    const onChangeDescripcion = (event) => {
        setDescripcion(event.target.value)
    }
    const submitAction = async () => {

        let data = {
            id: mode === "POST" ? 0 : defaultData.id,
            nombre: nombre,
            descripcion: descripcion,
            imagen: imagen,
            productoId: productIdSelected
        }
        if (mode === "POST") {
            if (prendaInsumo.length > 0) {
                let pI = prendaInsumo;
                await createPrenda(data, pI);
            } else {
                await createPrenda(data)
            }
        }
        else{
           
            await modifyPrenda(data);
        }
           

        handleClose();
    }
    return (
        <Grid container spacing={2} sx={{ padding: 5 }}>
            <Grid item xs={6} sx={{ padding: 10 }}>
                <Typography variant="h4">{"Detalles de la Prenda"}</Typography>
                <Grid container spacing={2} sx={{ padding: 2 }} >
                    <Grid item xs={12}>
                        <TextField
                            name="nombre"
                            label="Nombre"
                            value={nombre}
                            fullWidth
                            onChange={onChangeNombre}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={5}
                            name="descripcion"
                            label="Descripción"
                            value={descripcion}
                            fullWidth
                            onChange={onChangeDescripcion}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PrendaImage url={imagen} setImagen={setImagen} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h4">{"Insumos utilizados"}</Typography>
                <Grid container spacing={2} sx={{ padding: 1 }} >
                    <InsumosPrenda prendaInsumo={prendaInsumo} prendaId={mode === "POST" ? 0 : defaultData.id} mode={mode} setPrendaInsumo={setPrendaInsumo} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button color="primary" variant="contained" onClick={submitAction}>
                        Guardar
                    </Button>
                </DialogActions>
            </Grid>

        </Grid>
    )
}
export default PrendaForm;