import { getAllConsumptionByPrendaV2 } from "components-base/prendas/functions";
import { client } from "utils/axios";
export async function getAllClothsByProduct(prendas) {
  let cloths = [];

  for (let j = 0; j < prendas.length; j++) {
    const pp = prendas[j];
    const prendaId = pp.id;
    const cloth = await getAllConsumptionByPrendaV2(prendaId);
    cloth.forEach((c) => {
      let _cloth = c.cloth;
      if (cloths.length === 0) {
        cloths.push(_cloth);
      } else {
        let _clothCheck = cloths.find((item) => item.id === _cloth.id);
        if (!_clothCheck) cloths.push(_cloth);
      }
    });
  }
  return cloths;
}

export async function dataTransform(detail) {
  let products = [];
  let cloths = [];
  detail.detallePedido.forEach((item) => {
    item.producto.tallas = item.details.tallas;
    products = [...products, item.producto];
  });

  let obj = {
    cloths,
    products,
    percentage: detail.percentage,
  };
  return obj;
}
export function transformSizes(sizes, percentage) {
  let data = [];
  sizes.forEach((s) => {
    let _percentage = Math.floor(Number((s.cantidad * percentage) / 100));
    let obj = {
      name: s.talla.nombre,
      pieces: s.cantidad,
      percentage: _percentage,
      total: Number(s.cantidad) + _percentage,
      cut: "",
    };
    data = [...data, obj];
  });

  return data;
}

export async function getAllCutOrdersByPedidoId(id) {
  const { data } = await client.get(`/cutOrder/filter/${id}`);
  return data;
}
export async function getAllCutOrders() {
  const { data } = await client.get("/cutOrder");
  return data;
}
export async function getAllCutOrderById(id) {
  const { data } = await client.get(`/cutOrder/${id}`);
  return data;
}
export const createCutOrder = async (cutInput) => {
  try {
    await client.post("cutOrder", cutInput);
  } catch (error) {}
};
export const modifyCutOrder = async (cutInput) => {
  try {
    await client.put("cutOrder", cutInput);
  } catch (error) {}
};
export const deleteCutOrder = async (id) => {
  await client.delete(`cutOrder/${id}`);
};
