import React from "react";
import { Drawer, DialogTitle, IconButton } from "@mui/material";
import StackTelasForm from "./StackTelasForm";
import { background_marifer } from "utils/apis";
import { Cancel } from "@mui/icons-material";
import { styles } from "utils/apis";
const AddStackTelas = ({ mode, defaultData, open, handleClose }) => {
  return (
    <>
      <Drawer anchor={"bottom"} open={open} onClose={handleClose} sx={styles}>
        <DialogTitle
          sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
        >
          <IconButton onClick={handleClose} sx={{ color: "purple" }}>
            <Cancel />
          </IconButton>
        </DialogTitle>
        <StackTelasForm
          mode={mode}
          defaultData={defaultData}
          handleClose={handleClose}
        />
      </Drawer>
    </>
  );
};
export default AddStackTelas;
