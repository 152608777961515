import { client } from "utils/axios"
import * as yup from "yup";

export async function deleteMaquilero(MaquileroId) {
  try {
    await client.delete(`maquileros/${MaquileroId}`);
  } catch (error) {
    console.log(error)
  }

};
export async function getAllMaquilero() {
  const { data } = await client.get("/maquileros");
  return data;
}

export const validationSchemaMaquilero = yup.object({
  nombre: yup
    .string("Ingresa nombre")
    .required("Campo Requerido"),
  apellidoPaterno: yup
    .string("Ingresa el apellido Paterno")
    .required("Campo Requerido"),
  apellidoMaterno: yup
    .string("Ingresa el apellido Materno"),
  direccion: yup
    .string("Ingresa la dirección")
    .required("Campo Requerido"),
  rfc: yup
    .string("Ingresa el RFC"),
  codigo: yup
    .string("Ingresa el código")
    .required("Campo Requerido"),
  capacidad: yup.number
    ("Ingresa la capacidad")

});
export async function createMaquilero(dataInput) {
  try {
    let persona = {
      nombre: dataInput.nombre,
      apellidoPaterno: dataInput.apellidoPaterno,
      apellidoMaterno: dataInput.apellidoMaterno,
      direccion: dataInput.direccion,
      rfc: dataInput.rfc
    }
    const { data } = await client.post("personas", persona);

    let maquilero = {
      persona: { id: data.id },
      codigo: dataInput.codigo,
      capacidad: dataInput.capacidad,
      foraneo: dataInput.foraneo,
      details: dataInput.details,
      ruta:{id:dataInput.ruta.id}
    }
    await client.post("maquileros", maquilero);
    return true;
  } catch (error) {
    console.error(error)
    return false;
  }

};
export async function modifyMaquilero(maquilero) {

  let persona = {
    apellidoPaterno: maquilero.apellidoPaterno,
    apellidoMaterno: maquilero.apellidoMaterno,
    nombre: maquilero.nombre,
    rfc: maquilero.rfc,
    direccion: maquilero.direccion,
    id: maquilero.persona.id
  }
  try {
    await client.put("personas", persona);
    await client.put("maquileros", maquilero);
  } catch (error) {

  }

};