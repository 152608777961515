import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";

import image from "../../../assets/img/marifer.png";
import { DataCutConvert } from "../functions";
// Create styles
const styles = StyleSheet.create({
  image2: {
    position: "absolute",
    objectPosition: "0",
    zIndex: -999,
    width: "320px",
    height: "320px",
    opacity: 0.2,
  },
  image: {
    // objectPosition: "0",
    position: "absolute",
    objectFit: "cover",
    zIndex: -999,
    width: "100px",
    height: "100px",
    paddingLeft: 0,
    opacity: 0.6,
  },
  photo: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    color: "#ff0080",
    textTransform: "uppercase",
    marginTop: 25,
    paddingLeft: 200,
    fontSize: "18pt",
    fontWeight: "extrabold",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
  },
  section1: {
    padding: 8,
    textAlign: "center",
    width: "7cm",
    height: "100%",
    backgroundColor: "white",
    border: 1,
    borderColor: "#0A83F5",
    lineHeight: 1.5,
    fontSize: "10pt",
  },
  section2: {
    padding: 8,
    lineHeight: 4,
    border: 1,
    borderColor: "#0A83F5",
    width: "14.6cm",
    height: "100%",
    backgroundColor: "white",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
// Create styles

const OrdenCutPDF = ({ data: defaultData }) => {
  const data = DataCutConvert(defaultData);

  return (
    <>
      <PDFViewer style={styles.viewer}>
        <Document>
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <Page size="LETTER" style={styles.page}>
              <View>
                <Image style={styles.image} src={image} />
              </View>
              <View style={styles.mainSection}>
                <View>
                  <Text style={styles.header}>{"Orden de corte"}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ paddingTop: 20, paddingLeft: 150, fontSize: "14pt" }}
                >
                  {`Encargado de Corte:  `}
                </Text>
                <Text style={{ paddingTop: 20, fontSize: "9pt" }}>
                  {data.cutter}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ paddingTop: 10, paddingLeft: 230, fontSize: "14pt" }}
                >
                  {`Modelo:  `}
                </Text>
                <Text style={{ paddingTop: 10, fontSize: "9pt" }}>
                  {data.model}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ paddingTop: 10, paddingLeft: 250, fontSize: "14pt" }}
                >
                  {`Tela:  `}
                </Text>

                <Text style={{ paddingTop: 10, fontSize: "9pt" }}>
                  {data.cloth}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ paddingTop: 10, paddingLeft: 137, fontSize: "14pt" }}
                >
                  {`Verificacion de vueltas:  `}
                </Text>
                <Text style={{ paddingTop: 10, fontSize: "9pt" }}>
                  {data.verifier}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ paddingTop: 10, paddingLeft: 221, fontSize: "14pt" }}
                >
                  {`Defectos:  `}
                </Text>
                <Text style={{ paddingTop: 10, fontSize: "10pt" }}>
                  {data.defects}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ paddingTop: 10, paddingLeft: 111, fontSize: "14pt" }}
                >
                  {`Tiempo promedio de corte:  `}
                </Text>
                <Text style={{ paddingTop: 10, fontSize: "10pt" }}>
                  {data.cutTime}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ paddingTop: 10, paddingLeft: 166, fontSize: "14pt" }}
                >
                  {`Fecha de entrega:  `}
                </Text>
                <Text style={{ paddingTop: 10, fontSize: "10pt" }}>
                  {data.deliveryDate}
                </Text>
              </View>
              <View>
                <Image
                  style={{
                    width: 250,
                    height: 250,
                    paddingTop: 5,
                    marginLeft: 170,
                  }}
                  src={data.image}
                />
              </View>

              <View
                style={{
                  marginTop: 10,
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "10pt",
                  color: "#000111",
                  height: 20,
                  backgroundColor: "#f9c6cf",
                }}
              >
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{"Talla"}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{"Piezas"}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{"Tolerancia"}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{"Total"}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{"Corte"}</Text>
                </View>
              </View>
              {data.sizes.map((s) => (
                <View
                  style={{
                    flexDirection: "row",
                    textAlign: "center",
                    fontSize: "8pt",
                    color: "#000111",
                    height: 20,
                  }}
                >
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{s.name}</Text>
                  </View>
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{s.pieces}</Text>
                  </View>
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{s.percentage}</Text>
                  </View>
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{s.total}</Text>
                  </View>
                  <View style={{ width: "20%", border: 1 }}>
                    <Text>{s.cut}</Text>
                  </View>
                </View>
              ))}
              <View
                style={{
                  flexDirection: "row",
                  textAlign: "center",
                  fontSize: "12pt",
                  color: "#000111",
                  height: 20,
                }}
              >
                <View style={{ width: "20%" }}>
                  <Text>{""}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{data.totals.totalPieces}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{data.totals.totalPercentage}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{data.totals.grandTotal}</Text>
                </View>
                <View style={{ width: "20%", border: 1 }}>
                  <Text>{""}</Text>
                </View>
              </View>

              <View
                style={{
                  marginTop: 25,
                  flexDirection: "column",
                  textAlign: "center",
                  fontSize: "12pt",
                }}
              >
                <View style={{ width: "100%" }}>
                  <Text>{"________________________________"}</Text>
                </View>
                <View style={{ width: "100%" }}>
                  <Text>{"Firma de enterado"}</Text>
                </View>
              </View>
              <View
                style={{
                  marginTop: 20,
                  flexDirection: "column",
                  textAlign: "center",
                  fontSize: "12pt",
                }}
              >
                <View style={{ width: "100%" }}>
                  <Text>{"ATTE:"}</Text>
                </View>
                <View style={{ width: "100%" }}>
                  <Text>{"Gerencia de produccion"}</Text>
                </View>
              </View>
            </Page>
          ))}
        </Document>
      </PDFViewer>
    </>
  );
};
export default OrdenCutPDF;
