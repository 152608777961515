import {
  Grid,
  TextField,
  Box,
  Autocomplete,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useEffect } from "react";
import { boxSize } from "utils/apis";
const InsumoFormFields = ({ formik, setFieldValue, setCantidad, setPresentacion, cantidad, setUnidad }) => {
  
  useEffect(() => {
    setCantidad(formik.values.details?.cantidad || 0)
    setPresentacion(formik.values.details?.presentacion || "")
  }, [])
  const handleChange = (e, value) => {
    setPresentacion(value.name)
  }
  const handleChangeUnidad = (e, value) => {
    setUnidad(value.name)
  }
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={
              formik.touched.nombre && Boolean(formik.errors.nombre)
            }
            helperText={formik.touched.nombre && formik.errors.nombre}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="descripcion"
            name="descripcion"
            label="Descripción"
            multiline
            rows={5}
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={
              formik.touched.descripcion &&
              Boolean(formik.errors.descripcion)
            }
            helperText={
              formik.touched.descripcion &&
              formik.errors.descripcion
            }
            color="primary"
          />

        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="codigo"
            name="codigo"
            label="Código"
            value={formik.values.codigo}
            onChange={formik.handleChange}
            error={
              formik.touched.codigo && Boolean(formik.errors.codigo)
            }
            helperText={formik.touched.codigo && formik.errors.codigo}
            color="primary"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            getOptionLabel={(presentacion) => `${presentacion.name}`}
            onChange={handleChange}
            disablePortal
            id="combo-box-insumos"
            options={[{ name: "Rollo" }, { name: "Pieza" }, { name: "Paquete" }]}
            maxWidth
            renderInput={(params) => <TextField {...params} label="Presentacion" />}
            defaultValue={{ name: formik.values.details?.presentacion || "" }}
          />
        </Grid>
        <Grid item xs={12}>
        <Typography variant="h6" color={"purple"}>
          {"Ingrese cantidad y unidad si aplica a la presentacion del insumo. Ejemplo: Rollo => Cantidad: 50, Unidad: Metros"}
        </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            id="cantidad"
            name="cantidad"
            label="Cantidad"
            type="number"
            value={cantidad}
            onChange={(e) => setCantidad(Number(e.target.value))}
            color="primary"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            getOptionLabel={(unidad) => `${unidad.name}`}
            onChange={handleChangeUnidad}
            disablePortal
            id="combo-box-unidad"
            options={[{ name: "Metro" }, { name: "Centimetros" }, { name: "Milimetros" }]}
            maxWidth
            renderInput={(params) => <TextField {...params} label="Unidad" />}
            defaultValue={{ name: formik.values.details?.unidad || "" }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel control={
            <Checkbox
              id="internal"
              name="internal"
              checked={formik.values.internal}
              onChange={formik.handleChange}
              error={
                formik.touched.internal && Boolean(formik.errors.internal)
              }
              helperText={formik.touched.internal && formik.errors.internal}
              color="primary" />} label="Marcar como insumo interno" />
        </Grid>
      </Grid>
    </Box>
  )
}
export default InsumoFormFields;