import { Box } from "@mui/material";
import * as React from "react";
import Workers from "../../workers";
import TabComponent from "components-base/tab/Tab";

const WorkerManagement = () => {

  const tabsElement = [
    {
      label: "Personal",
      component: <Workers />
    },
    {
      label: "Incidencias",
      component: <></>
    },

  ];

  return (
    <Box>
      <TabComponent tabsElement={tabsElement} />
    </Box>
  );
};

export default WorkerManagement;