import { getAllEntradaCorte } from "components-base/pedidos/functions";
import { getAllPedidos } from "components-base/pedidos/functions";
import { getAllSalidas } from "components-base/salidas/functions";
import { convertMonth } from "components-base/salidas/functions";

export const barChartDataWeeklyRevenue = [
  {
    name: "COMPLETADO",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
    color: "#008000",
  },
  {
    name: "PENDIENTE",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
    color: "#EE0000",
  },
  // {
  //   name: "PRODUCT C",
  //   data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  //   color: "#EFF4FB",
  // },
];

export const barChartOptionsWeeklyRevenue = {
  chart: {
    stacked: true,
    toolbar: {
      show: true,
    },
  },
  colors: ['#ff3322', '#faf'],
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000"
    },
    theme: 'dark',
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
  },
  xaxis: {
    categories: ["17", "18", "19", "20", "21", "22", "23", "24", "25"],
    show: true,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: false,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },

  grid: {
    borderColor: "rgba(163, 174, 208, 0.3)",
    show: true,
    yaxis: {
      lines: {
        show: false,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#d94496", "#6AD2FF", "#E1E9F8"],
  },
  legend: {
    show: false,
  },
  colors: ["#d94496", "#6AD2FF", "#E1E9F8"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "20px",
    },
  },
};
export const dataTransform = async (month) => {
  let pie_data ={
    pieChartOptions: {
      labels: ["Pendiente", "Completada"],
      colors: ["#6AD2FF", "#d94496", ],
      chart: {
        width: "50px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      fill: {
        colors: [ "#6AD2FF","#d94496", "#EFF4FB"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#000000"
        },
      },
    },
    
    pieChartData : []
  }

  let data_drivers = {
     barChartDataDrivers: [
      {
        name: "Entradas por chofer",
        data: [],
      },
    ],
    
  barChartOptionsDrivers:{
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#000000"
        },
        onDatasetHover: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
        theme: "dark",
      },
      xaxis: {
        categories: [],
        show: false,
        labels: {
          show: true,
          style: {
            colors: "#A3AED0",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        color: "black",
        labels: {
          show: true,
          style: {
            colors: "#CBD5E0",
            fontSize: "14px",
          },
        },
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            [
              {
                offset: 0,
                color: "#d94496",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#f47dbb",
                opacity: 0.28,
              },
            ],
          ],
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "40px",
        },
      },
    }
  }

  let data = {
    barChartData: [{
      name: "PRENDAS RECOLECTADAS",
      data: [],
      color: "#ff0080",
    },
    {
      name: "PRENDAS EN MAQUILA",
      data: [],
      color: "#ffcbfe",
    },],
    barChartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: true,
        },
      },
      colors: ['#ff3322', '#faf'],
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#000000"
        },
        theme: 'dark',
        onDatasetHover: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
      },
      xaxis: {
        categories: [],
        show: true,
        labels: {
          show: true,
          style: {
            colors: "#A3AED0",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        color: "black",
        labels: {
          show: false,
          style: {
            colors: "#A3AED0",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
      },

      grid: {
        borderColor: "rgba(163, 174, 208, 0.3)",
        show: true,
        yaxis: {
          lines: {
            show: false,
            opacity: 0.5,
          },
        },
        row: {
          opacity: 0.5,
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "solid",
        colors: ["#d94496", "#6AD2FF", "#E1E9F8"],
      },
      legend: {
        show: false,
      },
      colors: ["#d94496", "#6AD2FF", "#E1E9F8"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "75%",
        },
      },
      title: {
        text: `PROGRESO DE LOS PEDIDOS ${month?.name || ""}`,
        floating: true,
        offsetY: 30,
        align: 'left',
        style: {
          color: '#444'
        }
      }

    }
  };
  let pedidos = await getAllPedidos();
  let salidas = await getAllSalidas();

  let _pedidos = await convertMonth(pedidos).filter(item => item.monthNumber === month.number);
  let _salidas = await convertMonth(salidas).filter(item => item.monthNumber === month.number);


  _salidas.forEach(s => {
    s.total_pickup = 0;
    s.total = 0
    s.details.data.forEach(d => {
      if (d.total_pickup) {
        s.total_pickup += d.total_pickup;
      }else if(d.prendas && d.prendas.length > 0){
         for(let k = 0; k < d.prendas.length; k ++){
          let obj = d.prendas[k];
          if(obj.total_pickup){
            s.total_pickup += obj.total_pickup;
          }
         }

      }
      s.total += d.cantidadAsignada || 0;
    })
  });
  let drivers =[]

  _salidas.forEach((item => {
    drivers = [...drivers, { id: item.chofer.id, name: `${item.chofer.persona.nombre} ${item.chofer.persona.apellidoPaterno}` }]
  }))
  let setObj = new Set(drivers.map(JSON.stringify));
  let unique_drivers = Array.from(setObj).map(JSON.parse);
  unique_drivers.forEach(driver => {
    let sum = 0
    let new_array = _salidas.filter(item => item.chofer.id === driver.id)//.reduce(function(a,b){return Number(a.total_pickup || 0) + Number(b.total_pickup || 0)})
    new_array.forEach(a => {
     
      sum += Number(a.total_pickup || 0)
    });
    data_drivers.barChartOptionsDrivers.xaxis.categories.push(driver.name)
    data_drivers.barChartDataDrivers[0].data.push(sum)
 
  })

let total_global=0;
let total_pickup_salida = 0;
  let obj = { data_1: [], data_2: [], data_3: [] }
  for (let i = 0; i < _pedidos.length; i++) {
    let a = 0;
    let b = 0;
    const _item = _pedidos[i];
    _item.detallePedido.forEach(j => {
      j.details.tallas.forEach(t => {
        let total = Number(t.cantidad);

        b += total || 0;
        total_global += total;
      })
    })
    const _sal = salidas.filter(item => item.pedido.id === _item.id);
    if (_sal.length > 0) {
      for (let k = 0; k < _sal.length; k++) {
        const s = _sal[k];
        s.details.data.forEach(d => {
          if(d.total_pickup){
            a += Number(d.total_pickup || 0);
            total_pickup_salida +=  Number(d.total_pickup || 0)
          }else if(d.prendas && d.prendas.length > 0){
            for(let p = 0; p < d.prendas.length; p++){
              let obj = d.prendas[p];
              if(obj.total_pickup){
                a += Number(obj.total_pickup || 0);
                total_pickup_salida +=  Number(obj.total_pickup || 0)
              }
            }
          }
        })
      }
    }
    if (a > b)
      a = b;
    let current = (((b - a) * 100) / b) || 0;

    let pending = ((a * 100) / b) || 0;

let _pickup = a;
let remaining = b-a
    // obj.data_1.push(pending);
    // obj.data_2.push(current);
    obj.data_2.push(remaining);
    obj.data_1.push(_pickup);
    obj.data_3.push(_item.codigo)
  }
 pie_data.pieChartData= [total_global - total_pickup_salida,total_pickup_salida]
  data.barChartData[0].data = obj.data_1;
  data.barChartData[1].data = obj.data_2;
  data.barChartOptions.xaxis.categories = obj.data_3;
  data.salidas = _salidas;
  data.drivers = data_drivers
  data.pieData = pie_data

  return data;
}