import React from "react";
import { useFormik } from "formik";
import { Box, Grid, Button } from "@mui/material";
import SystemUserFormFields from "./SystemUserFormFields";
import TabComponent from "components-base/tab/Tab";
import { createSystemUser, modifySystemUser, validationSchemaSystemUser } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const SystemUserForm =  ({ mode, handleClose }) => {
    const { defaultDataUser : defaultData } = useSelector(({ user }) => user);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    username: "",
                    activo: false,
                    password: "",
                    uid:""
                }
                : {
                    id: defaultData.id,
                    username: defaultData.username,
                    password:defaultData.password,
                    activo:defaultData.activo,
                    persona:defaultData.persona,
                    uid:defaultData.uid,
                    roles:defaultData.roles
                },
        validationSchema: validationSchemaSystemUser,
        onSubmit: async (values) => {
            if(!values.persona){
                dispatch(
                    showMessage({
                        variant:"error",
                        message:"Por favor elije una persona"
                    })
                )
                return;
            }
            switch (mode) {
                case "POST":
                    const res = await createSystemUser(values, dispatch);
                    handleClose();
                    break;
                case "PUT":
                    await modifySystemUser(values, dispatch);
                    handleClose();
                    break;
                default: handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información del Usuario",
                    component: <SystemUserFormFields handleClose={handleClose} formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }

            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item  >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item >
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>

        </Box>
    );
}
export default SystemUserForm;