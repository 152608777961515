import {
  Grid,
  TextField,
  Box,
  FormControlLabel, Checkbox, Autocomplete
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect, useState } from "react";
import MaquileroDetails from "./MaquileroDetails";
import { getAllRutas } from "components-base/rutas/functions";
import { useSelector } from "react-redux";
const MaquileroFormFields = ({ formik, maquinas, setMaquinas, setFieldValue }) => {
  const { defaultDataMaquilero } = useSelector(({ maquilero }) => maquilero)
  const [open, setOpen] = useState(false);
  const [rutas, setRutas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const routes = await getAllRutas();
      setRutas(routes);
    }
    fetchData();
  }, [])

  const handleCloseModal = () => {
    setOpen(false)
  }
  const handleChangeRuta = (e, option) => {
    setFieldValue("ruta", option)
  }
  return (
    <>
      <Box sx={{ height: boxSize }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="codigo"
              name="codigo"
              label="Código"
              value={formik.values.codigo}
              onChange={formik.handleChange}
              error={
                formik.touched.codigo && Boolean(formik.errors.codigo)
              }
              helperText={formik.touched.codigo && formik.errors.codigo}
              color="primary"
            />
            <TextField
              fullWidth
              id="nombre"
              name="nombre"
              label="Nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={
                formik.touched.nombre && Boolean(formik.errors.nombre)
              }
              helperText={formik.touched.nombre && formik.errors.nombre}
              color="primary"
            />
            <TextField
              fullWidth
              id="apellidoPaterno"
              name="apellidoPaterno"
              label="Apellido Paterno"
              value={formik.values.apellidoPaterno}
              onChange={formik.handleChange}
              error={
                formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)
              }
              helperText={formik.touched.apellidoPaterno && formik.errors.apellidoPaterno}
              color="primary"
            />
            <TextField
              fullWidth
              id="apellidoMaterno"
              name="apellidoMaterno"
              label="Apellido Materno"
              value={formik.values.apellidoMaterno}
              onChange={formik.handleChange}
              error={
                formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)
              }
              helperText={formik.touched.apellidoMaterno && formik.errors.apellidoMaterno}
              color="primary"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="rfc"
              name="rfc"
              label="RFC"
              value={formik.values.rfc}
              onChange={formik.handleChange}
              error={
                formik.touched.rfc && Boolean(formik.errors.rfc)
              }
              helperText={formik.touched.rfc && formik.errors.rfc}
              color="primary"
            />
            <TextField
              fullWidth
              id="direccion"
              name="direccion"
              label="Dirección"
              value={formik.values.direccion}
              onChange={formik.handleChange}
              error={
                formik.touched.direccion &&
                Boolean(formik.errors.direccion)
              }
              helperText={
                formik.touched.direccion &&
                formik.errors.direccion
              }
              color="primary"
            />
            <Autocomplete
              name="ruta"
              options={rutas || []}
              isOptionEqualToValue={(option, value) => Number(option.id) === Number(value.id)}
              id="ruta"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Ruta" />}
              getOptionLabel={(ruta) => `${ruta.nombre}`}
              onChange={handleChangeRuta}
              defaultValue={defaultDataMaquilero.ruta}
            />

            <FormControlLabel control={
              <Checkbox
                id="foraneo"
                name="foraneo"
                checked={formik.values.foraneo}
                onChange={formik.handleChange}
                error={
                  formik.touched.foraneo && Boolean(formik.errors.foraneo)
                }
                helperText={formik.touched.foraneo && formik.errors.foraneo}
                color="primary"

              />} label="Es Foráneo" />

          </Grid>

          <Grid item xs={12}>
            <MaquileroDetails open={open} maquinas={maquinas} handleClose={handleCloseModal} setMaquinas={setMaquinas} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default MaquileroFormFields;