import React, {useState} from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import SystemRoleFormFields from "./SystemRoleFormFields";
import TabComponent from "components-base/tab/Tab";
import { createSystemRole, modifySystemRole, validationSchemaSystemRole } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const SystemRoleForm = ({ mode, handleClose }) => {
    const { defaultDataSystemRole } = useSelector(({ systemRole }) => systemRole);
    const [roleActions, setRoleActions] = useState(null);
    const dispatch = useDispatch();
    const messages = (mensaje) => {
        dispatch(
            showMessage({
                message: `Rol ${mensaje} con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );

    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    nombre: "",
                    descripcion: "",
                    action:[]
                }
                : {
                    id: defaultDataSystemRole?.id,
                    nombre: defaultDataSystemRole?.nombre,
                    descripcion: defaultDataSystemRole?.descripcion,
                    action: defaultDataSystemRole?.action,
                },
        validationSchema: validationSchemaSystemRole,
        onSubmit: async (values) => {
            console.log(values)
            switch (mode) {
                case "POST":
                    await createSystemRole(values, dispatch);
                    messages("Creado")
                    handleClose();
                    break;
                case "PUT":
                    await modifySystemRole(values, dispatch);
                    messages("Modificado");
                    handleClose();
                    break;
                default: handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;

    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información del Rol",
                    component: <SystemRoleFormFields setFieldValue={setFieldValue} formik={formik} roleActions={roleActions} setRoleActions={setRoleActions} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item sx={12} >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item sx={12}>
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default SystemRoleForm;