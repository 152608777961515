import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { Map, TileLayer, Marker } from 'react-leaflet';
import { MapContainer as Map, TileLayer, Marker, Tooltip } from "react-leaflet";
import { GetAllEntity } from "utils/Firebase/firebaseFunctions";
import { where } from "firebase/firestore/lite";
import { position } from "@chakra-ui/system";
import moment from "moment/moment";

const RouteMap = ({ center, zoom, markerPosition, selectedDate }) => {
  const [markets, setMarkets] = useState(null);
  const [points, setPoints] = useState(null);
  useEffect(() => {
    const entity = "driver-tracking";
    const fetchData = async () => {
      let data = await GetAllEntity(entity);
      setMarkets(data);
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (markets) {
      const date = moment(selectedDate).format("YYYY-MM-DD");
      console.log(date);
      let _points = [];
      markets.forEach((m) => {
        if (m.datetime.includes(date)) {
          _points.push({
            driver: m.user.name,
            position: [m.latitude, m.longitude],
          });
        }
      });
      setPoints(_points);
    }
  }, [selectedDate]);

  if (!markets || !points)
    return <LinearProgress color="primary" variant="indeterminate" />;
  return (
    <Map center={center} zoom={zoom} style={{ width: "100%", height: "400px" }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {points.map((point, index) => (
        <Marker key={index} position={point.position}>
          <Tooltip>{point.driver}</Tooltip>
        </Marker>
      ))}
    </Map>
  );
};

export default RouteMap;
