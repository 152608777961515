import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import OrdenSalidaPDF from "./pickupsPDF";
import { useSelector } from "react-redux";
import PickupsPDF from "./pickupsPDF";

const ModalPDF =({open, handleClosePDF,data}) =>{

    return(
        <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClosePDF}
    >
        <DialogContent>
            <PickupsPDF data= {data}  />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClosePDF} color={"secondary"}variant="contained"
            >
                Cancelar
            </Button>

        </DialogActions>
    </Dialog>
    )
}
export default ModalPDF;