import { client } from "utils/axios"
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export async function deleteSystemRole(rolesId) {
    await client.delete(`roles/${rolesId}`);
};
export async function getAllSystemRole() {
    const { data } = await client.get("/roles");
    return data;
}
export async function getAllActions() {
    const { data } = await client.get("/action");
    return data;
}

export const validationSchemaSystemRole = yup.object({
    nombre: yup
        .string("Ingresa nombre de rol")
        .required("Campo Requerido"),
    descripcion: yup
        .string("Ingresa Codigo")
        .required("Campo Requerido"),

});
export const createSystemRole = async (roles, dispatch) => {
    try {

        const { data } = await client.post("roles", roles);
        dispatch(
            showMessage({
                message: "Rol agregado con éxito",
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );

    } catch (error) {
        dispatch(
            showMessage({
                message:
                    "Ocurrió un error al agregar un rol. ",
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            })
        );
    }
};

export const modifySystemRole = async (roles, dispatch) => {
    try {
        const actions = roles.action.filter(item => item.selected);
        delete roles.action;
        await client.put("roles", roles);

        const roleId = roles.id
        const { data } = await client.get(`roleAction/role/${roleId}`);

        if (data && data.length > 0) {
            for(let i =  0; i < data.length; i ++){
                const element = data[i];
                await client.delete(`roleAction/action/${element.action.id}/role/${element.roleId}`)
            };
        }

        if (actions && actions.length > 0) {
            for(let i = 0 ; i <actions. length; i++){
                const item = actions[i]
                const obj = {
                    roleId: item.roleId,
                    action: item.action
                }
                await client.post(`roleAction`,obj);
            }
        }

        dispatch(
            showMessage({
                message: `Rol "${roles.name}" modificado con éxito`,
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    } catch (error) {
        dispatch(
            showMessage({
                message:
                    `Ocurrió un error al modificar el rol "${roles.name}"`,
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            })
        );
    }

};
