import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSpecificationSheet, dataTransform, getAllClothsByProduct, modifySpecificationSheet, transformSizes } from "./functions";
import moment from "moment";
import SizesTable from "./sizesTable";
import TablePlaceholder from "./tablePlaceholder";
import SpecificationSheetForm from "./SpecificationSheetForm";
import { showMessage } from "store/modules/Message";
import { background_marifer } from "utils/apis";

const SpecificationSheet = ({ mode, open, handleClose, producto }) => {
    const styles = {
        display: { xs: 'none', sm: 'block' },
        '& .MuiDialog-paper': {
            boxSizing: 'border-box',
            backgroundImage: `url(${background_marifer})`,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            '&:before': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                content: '""',
                display: 'block',
                background: '#fff',
                opacity: '0.6',
                zIndex: -11
            }
        }
    }
    const { defaultDataSS } = useSelector(({ specifications }) => specifications)

    const { userName } = useSelector(({ session }) => session);

    return (
        <Dialog
            sx={styles}
            open={open}
            onClose={handleClose}
            fullWidth
            fullScreen
        >
            <div
            >
                <DialogTitle>
                    <Typography variant="h4">
                        {"Hoja de Especificaciones"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <SpecificationSheetForm
                        mode={mode}
                        handleClose={handleClose}
                    />
                </DialogContent>
            </div>
        </Dialog>
    )
}
export default SpecificationSheet;