import {
    Autocomplete,
    Grid, Typography,
    TextField, Button, Paper, DialogActions
} from "@mui/material";
import { getAllTallasByProductoId } from "components-base/productos/functions";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SpecificationImage from "./SpecificationImage";
import { createSpecificationSheet, modifySpecificationSheet } from "./functions";

const SpecificationSheetForm = ({ handleClose, mode }) => {
    const { userName } = useSelector(({ session }) => session);
    const { defaultData } = useSelector(({ producto }) => producto);
    const { defaultDataSS } = useSelector(({ specifications }) => specifications);
    const [aguja, setAguja] = useState("");
    const [image, setImage] = useState("");
    const [hilo, setHilo] = useState("");
    const [puntadas, setPuntadas] = useState("");
    const [indicaciones, setIndicaciones] = useState("");
    const [especiales, setEspeciales] = useState("");
    const [tallas, setTallas] = useState([]);
    useEffect(() => {
        if (mode === "PUT") {
            setAguja(defaultDataSS.aguja);
            setHilo(defaultDataSS.hilo);
            setPuntadas(defaultDataSS.puntada);
            setEspeciales(defaultDataSS.special);
            setIndicaciones(defaultDataSS.indications.indications);
            setImage(defaultDataSS.indications.image)
        }

    }, [mode, defaultDataSS])

    useEffect(() => {
        const fetchData = async () => {
            const sizes = await getAllTallasByProductoId(defaultData.id);
            const sizesName = sizes.map(item => {
                return item.talla.nombre
            });
            setTallas(sizesName);
        }
        fetchData();
    }, [defaultData]);
    const handleSave = async () => {
        let data = {
            aguja,
            hilo,
            producto: { id: defaultData.id },
            puntada: puntadas,
            special: especiales,
            indications: { image: image, indications: indicaciones }
        }
        switch (mode) {
            case "POST":
                data = { ...data, ...{ createdByUser: userName } }
                await createSpecificationSheet(data);
                break;
            case "PUT":
                data = { ...data, ...{ id: defaultDataSS.id, modifiedByUser: userName } }
                await modifySpecificationSheet(data);
                break;
            default:
                break;
        }
        handleClose();
    }
    return (
        <Paper elevation={5} style={{ opacity: 0.8, padding: 20 }} sx={{ paddingTop: 2 }}>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained" color="primary">
                    Guardar
                </Button>

            </DialogActions>
            <Grid container spacing={2} flexDirection={"row"} >
                <Grid item xs={4}>
                    <TextField
                        label={"Modelo"}
                        variant="outlined"
                        fullWidth
                        value={`Modelo: ${defaultData.modelo}`}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        label={"Tallas"}
                        variant="outlined"
                        fullWidth
                        value={tallas.join(", ")}
                    />
                </Grid>
            </Grid>
            {" "}
            <Grid container spacing={2} flexDirection={"row"} sx={{ paddingTop: 2 }} >
                <Grid item xs={4}>
                    <TextField
                        label={"Calibre de Aguja"}
                        variant="outlined"
                        fullWidth
                        value={aguja}
                        onChange={(e) => setAguja(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label={"Hilo"}
                        variant="outlined"
                        fullWidth
                        value={hilo}
                        onChange={(e) => setHilo(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label={"Puntadas"}
                        variant="outlined"
                        fullWidth
                        value={puntadas}
                        onChange={(e) => setPuntadas(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} flexDirection={"row"} sx={{ paddingTop: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        label={"Cliente"}
                        variant="outlined"
                        fullWidth
                        value={defaultData.category.name}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} flexDirection={"row"} sx={{ paddingTop: 5 }}>
                <Grid item xs={5}>
                    <SpecificationImage url={image || ""} setUrl={setImage} />
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        label={"Indicaciones de Costura"}
                        variant="outlined"
                        rows={13}
                        multiline
                        fullWidth
                        value={indicaciones}
                        onChange={(e) => setIndicaciones(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} flexDirection={"row"} sx={{ paddingTop: 5 }}>
                <Grid item xs={12}>
                    <TextField
                        label={"Indicaciones especiales"}
                        variant="outlined"
                        rows={5}
                        multiline
                        fullWidth
                        value={especiales}
                        onChange={(e) => setEspeciales(e.target.value)}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}
export default SpecificationSheetForm;