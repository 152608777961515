import { client } from "utils/axios";
import * as yup from "yup";

export const createRolls = async (rolls) => {
  try {
    await client.post("/rolls", rolls);
  } catch (error) {
    console.log(error);
  }
};
export async function getAllRolls() {
  const { data } = await client.get("/rolls");
  return data;
}
export async function getAllInputs() {
  const { data } = await client.get("/inputs");
  return data;
}
export async function getAllCategories() {
  const { data } = await client.get("/rolls");
  return data;
}
export const validationSchemaRolls = yup.object({
  nombre: yup.string("Ingresa nombre del Rollo").required("Campo Requerido"),
  descripcion: yup.string("Ingresa Descripción").required("Campo Requerido"),
  codigo: yup.string("Ingresa Código").required("Campo Requerido"),
});
export const deleteRolls = async (rollsId) => {
  await client.delete(`rolls/${rollsId}`);
};

export const modifyRolls = async (rolls) => {
  try {
    await client.put("rolls", rolls);
  } catch (error) {
    console.log(error);
  }
};
