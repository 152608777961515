import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteSalida } from "../functions";
import { showMessage } from "store/modules/Message";
const SalidaDelete = ({ openDelete, setOpenDelete }) => {
    const { salidaIdSelected } = useSelector(({ salidas }) => salidas);
    const dispatch = useDispatch();
    
    const deleteFnc = async () => {
        let response = await deleteSalida(salidaIdSelected);

        if (response) {
            dispatch(showMessage({ variant: 'success', message: "La salida se ha borrado del sistema" }))
        } else {
            const message = "Hubo un error en la red";

                dispatch(showMessage({ variant: 'error', message: message }))
        }
        setOpenDelete(false);
    }
    return (
        <>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
            >
                <DialogTitle >
                    <Typography variant="title">{"Eliminar"}</Typography >
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación del salida"}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDelete(false)} color={"secondary"}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color={"secondary"}>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default SalidaDelete;