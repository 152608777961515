import MaquileroTable from "./components/MaquileroTable";
// import React from "react";
// import ReactDOM from "react-dom";

const Maquileros = () => {
  return (
    <div>
      <MaquileroTable />
    </div>
  );
};
export default Maquileros;
