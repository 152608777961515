import { client } from "utils/axios";
import * as yup from "yup";

export const createOutputs = async (outputs) => {
  try {
    await client.post("outputs", outputs);
  } catch (error) {
    console.log(error);
  }
};
export async function getAllOutputs() {
  const { data } = await client.get("/outputs");
  return data;
}
// export async function getAllCategories() {
//   const { data } = await client.get("/rolls");
//   return data;
// }
export async function getAllCategorias() {
  const { data } = await client.get("/categorias");
  return data;
}
export async function getAllCategories() {
  const { data } = await client.get("/category");
  return data;
}
export const validationSchemaOutputs = yup.object({
  nombre: yup.string("Ingresa nombre de la Salida").required("Campo Requerido"),
  dimensiones: yup
    .string("Ingresa las dimensiones de la Salida")
    .required("Campo Requerido"),
  descripcion: yup.string("Ingresa Descripción").required("Campo Requerido"),
  codigo: yup.string("Ingresa Código").required("Campo Requerido"),
});
// export const deleteOutputs = async (outputsId) => {
//   await client.delete(`outputs/${outputsId}`);
// };
export const deleteOutputs = (outputsId) => async (dispatch) => {
  await client.delete(`outputs/${outputsId}`);
};

export const modifyOutputs = async (outputs) => {
  try {
    await client.put("outputs", outputs);
  } catch (error) {
    console.log(error);
  }
};
