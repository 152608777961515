import { ThemeProvider } from '@mui/material/styles';
import { useThemeContext } from '../theme-context';
import CssBaseline from '@mui/material/CssBaseline';

const MUIThemeProvider = (props) =>{
const {theme} = useThemeContext ()
    return(
      <ThemeProvider theme={theme}>
          <CssBaseline /> 
            {props.children}
          </ThemeProvider>
    )
}
export default MUIThemeProvider;