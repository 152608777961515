import { Grid, TextField, Box, Typography } from "@mui/material";
import { boxSize } from "utils/apis";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const PedidosFormFields = ({ formik, setFieldValue }) => {
  const handleChangeDate = (value, name) => {
    setFieldValue(name, moment(value).format("YYYY-MM-DD"));
  };
  return (
    <Box sx={{ height: boxSize }}>
      <Typography variant="h4">{"Pedido"}</Typography>
      <br />
      <Grid container spacing={5}>
        <Grid item>
          <TextField
            fullWidth
            id="codigo"
            name="codigo"
            label="Código"
            value={formik.values.codigo}
            onChange={formik.handleChange}
            error={formik.touched.codigo && Boolean(formik.errors.codigo)}
          />
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es-mx"
          >
            <DatePicker
              name={"fechaCreacion"}
              label={"Fecha de Creación"}
              value={moment(formik.values.fechaCreacion) || moment()}
              closeOnSelect
              sx={{ width: "100%" }}
              slotProps={{ textField: { variant: "outlined" } }}
              showDaysOutsideCurrentMonth
              disableHighlightToday
              onChange={(value) => handleChangeDate(value, "fechaCreacion")}
            />
          </LocalizationProvider>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es-mx"
          >
            <DatePicker
              name={"fechaEntrega"}
              label={"Fecha de Entrega"}
              value={moment(formik.values.fechaEntrega) || moment()}
              closeOnSelect
              sx={{ width: "100%" }}
              slotProps={{ textField: { variant: "outlined" } }}
              showDaysOutsideCurrentMonth
              disableHighlightToday
              onChange={(value) => handleChangeDate(value, "fechaEntrega")}
            />
          </LocalizationProvider>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es-mx"
          >
            <DatePicker
              name={"fechaEstimadaTermino"}
              label={"Fecha Estimada de Término"}
              value={moment(formik.values.fechaEstimadaTermino) || moment()}
              closeOnSelect
              sx={{ width: "100%" }}
              slotProps={{ textField: { variant: "outlined" } }}
              showDaysOutsideCurrentMonth
              disableHighlightToday
              onChange={(value) =>
                handleChangeDate(value, "fechaEstimadaTermino")
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Grid container flexDirection={"row"} spacing={2} >
            <Grid item xs={5}>
            <TextField
              id="percentage"
              name="percentage"
              label=""
              type="number"
              value={formik.values.percentage}
              onChange={formik.handleChange}
              error={formik.touched.percentage && Boolean(formik.errors.percentage)}
              helperText={"Indica el porcentage extra a incluir"}
              InputProps={{
                inputProps: {
                    style: { textAlign: "right", fontSize: 20 },
                }
            }}
            />
              </Grid>
            <Grid item xs={1}>
            <Typography variant="h3">{"%"}</Typography>
            </Grid>
       
          

            <Grid item xs={5}>
              <TextField
                fullWidth
                type="number"
                id="number"
                name="number"
                label="Numero de Pedido"
                value={formik.values.number}
                onChange={formik.handleChange}
                InputProps={{
                  inputProps: {
                      style: { textAlign: "right", fontSize: 20 },
                  }
              }}
                error={
                  formik.touched.number && Boolean(formik.errors.number)
                }
                helperText={formik.touched.number && formik.errors.number}
                color="primary"
              />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Box>
  );
};
export default PedidosFormFields;
