import { Grid, TextField, Box, Autocomplete } from "@mui/material";
import { getAllSupplier } from "components-base/suppliers/functions";
import { useEffect, useState } from "react";
import { boxSize } from "utils/apis";
const TelaFormFields = ({ formik, setFieldValue }) => {
  const [suppliers, setSupplier] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const suppliers = await getAllSupplier();
      setSupplier(suppliers);
    };
    fetchData();
  }, []);

  const handleChange = (e, option) => {
    setFieldValue("supplier", option);
  };
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Descripción"
            multiline
            rows={5}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Código"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            color="primary"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="color"
            name="color"
            label="Color"
            value={formik.values.color}
            onChange={formik.handleChange}
            error={formik.touched.color && Boolean(formik.errors.color)}
            helperText={formik.touched.color && formik.errors.color}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="tone"
            name="tone"
            label="Tone"
            value={formik.values.tone}
            onChange={formik.handleChange}
            error={formik.touched.tone && Boolean(formik.errors.tone)}
            helperText={formik.touched.tone && formik.errors.tone}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            getOptionLabel={(supplier) => `${supplier?.persona?.nombre}`}
            onChange={handleChange}
            id="combo-box-category"
            options={suppliers || []}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Proveedor" />
            )}
            defaultValue={formik.values.supplier || null}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default TelaFormFields;
