import { registerWithEmailAndPassword } from "utils/Firebase/firebase";
import { client } from "utils/axios";
import * as yup from "yup";
import { showMessage } from "store/modules/Message";

export const validationSchemaSystemUser = yup.object({
  username: yup.string().required("EL usuario/ email es requerido"),
  password: yup.string().required("El password es requerido").min(8),
});

export async function getAllPersons() {
  try {
    const { data } = await client.get(`personas`);
    return data
  } catch (error) {

  }
  return []
}
export async function getAllSystemUser() {
  try {
    const { data } = await client.get(`usuarios`);
    return data
  } catch (error) {

  }
  return []
}
export async function createSystemUser(input, dispatch) {
  try {
    if(input.persona){

    }
    const name = `${input.persona.apellidoPaterno} ${input.persona.apellidoMaterno}, ${input.persona.nombre}`
    const res = await registerWithEmailAndPassword(name, input.username, input.password);
    if (res.result === "ERROR") {
      console.error(res.errorMessage)
      messages(res.errorMessage, dispatch, "error")
      return;
    }
 
    const roles = input.roles;
    delete input.roles;
    const user = {
      username: input.username,
      password: input.password,
      activo: input.activo,
      persona: input.persona,
      uid: res.uid
    }

    const { data } = await client.post("usuarios", user);

    if (roles && roles.length > 0) {
      for (let i = 0; i < roles.length; i++) {
        const item = roles[i]
        const obj = {
          role: {id:item.id},
          user: data.id
        }

        await client.post(`roleUser`, obj);
      }
    }
    messages("El usuario se creo correctamente.", dispatch, "success")
    return true;
  } catch (error) {
    console.log(error)
    return false;
  }
}
export async function modifySystemUser(user) {
  const roles = user.roles;
  delete user.roles;
  let updatedUser = {
    id:user.id,
    username:user.username,
    password:user.password,
    activo:user.activo,
    uid:user.uid,
    persona:{id:user.persona.id}
  }

  try {
    await client.put("usuarios", updatedUser);
    
    const userId = user.id
    const { data } = await client.get(`roleUser/user/${userId}`);

    if (data && data.length > 0) {
        for(let i =  0; i < data.length; i ++){
            const element = data[i];
            await client.delete(`roleUser/user/${element.user}/role/${element.role.id}`)
        };
    }

    if (roles && roles.length > 0) {
        for(let i = 0 ; i <roles. length; i++){
            const item = roles[i]
            const obj = {
                role: {id:item.role.id},
                user: userId
            }
            await client.post(`roleUser`,obj);
        }
    }

    
  } catch (error) {

  }

}
export async function deleteSystemUser(userId) {
  const { data } = await client.get(`roleUser/user/${userId}`);
  if (data && data.length > 0) {
      for(let i =  0; i < data.length; i ++){
          const element = data[i];
             await client.delete(`roleUser/user/${userId}/role/${element.role.id}`)
      };
  }
  await client.delete(`usuarios/${userId}`);
}

const messages = (message, dispatch, variant) => {
  dispatch(
    showMessage({
      message: message,
      variant: variant,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    })
  );

}