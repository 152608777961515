import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";

import image from "../../../../assets/img/marifer.png";
// import { DataCutConvert } from "../functions";
import { DataCutConvert } from "./functions";

// Create styles
const styles = StyleSheet.create({
  image2: {
    position: "absolute",
    objectPosition: "0",
    zIndex: -999,
    width: "320px",
    height: "320px",
    opacity: 0.2,
  },
  image: {
    // objectPosition: "0",
    position: "absolute",
    objectFit: "cover",
    zIndex: -999,
    width: "100px",
    height: "100px",
    paddingLeft: 0,
    opacity: 0.6,
  },
  photo: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    color: "#ff0080",
    textTransform: "uppercase",
    marginTop: 25,
    paddingLeft: 200,
    fontSize: "18pt",
    fontWeight: "extrabold",
  },
  page: {
    fontSize: "14pt",
    padding: 10,
  },
  mainSection: {
    flexDirection: "row",
    textAlign: "center",
  },
  section1: {
    padding: 8,
    textAlign: "center",
    width: "7cm",
    height: "100%",
    backgroundColor: "white",
    border: 1,
    borderColor: "#0A83F5",
    lineHeight: 1.5,
    fontSize: "10pt",
  },
  section2: {
    padding: 8,
    lineHeight: 4,
    border: 1,
    borderColor: "#0A83F5",
    width: "14.6cm",
    height: "100%",
    backgroundColor: "white",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
// Create styles

const SpecificationPDF = ({ data: defaultData }) => {
  const data = DataCutConvert(defaultData);

  return (
    <>
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="LETTER" style={styles.page}>
            <View>
              <Image style={styles.image} src={image} />
            </View>
            <View style={styles.mainSection}>
              <View>
                <Text style={styles.header}>{"Hoja de Especificaciones"}</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};
export default SpecificationPDF;
