/*
 Initial state and properties
 */
export const initialState = {
    defaultDataStamping: null,
    orderType: "Estampado"
}
/*
 Action types
 */
export const SET_DEFAULT_DATA_STAMPING = 'SET_DEFAULT_DATA_STAMPING'
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE'
/*
 Arrow function for change state
 */
export const setDefaultDataStamping = (payload) => ({
    type: SET_DEFAULT_DATA_STAMPING,
    payload,
});
export const setOrderType = (payload) => ({
    type: SET_ORDER_TYPE,
    payload,
});
/*
 Reducer to describe how the state changed
 */
export default function Reducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_DEFAULT_DATA_STAMPING:
            return {
                ...state,
                defaultDataStamping: payload,
            }
        case SET_ORDER_TYPE:
            return {
                ...state,
                orderType: payload,
            }
        default:
            return state
    }
}
