import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import OrdenSalidaPDF from "./OrdenSalidaPDF";
import { useSelector } from "react-redux";

const SalidaOrdenModal =({open, handleClosePDF}) =>{
const {defaultDataDetail }= useSelector(({salidas})=> salidas);
    return(
        <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClosePDF}
    >
        <DialogContent>
            <OrdenSalidaPDF defaultDataDetail= {defaultDataDetail}  />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClosePDF} color={"secondary"}variant="contained"
            >
                Cancelar
            </Button>

        </DialogActions>
    </Dialog>
    )
}
export default SalidaOrdenModal;