import { Edit, Delete } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setUnidadId,setDefaultDataUnidad } from "store/modules/Unidades";
import { v4 as uuidv4 } from 'uuid';


export const columns = (setOpen, setMode,setOpenDelete, dispatch) => {
  return [{
    field: `${uuidv4()}`,
    headerName: "Acciones",
    width: 100,
    renderCell: (data) => (
      <React.Fragment>
        <Tooltip title="Editar Unidad" placement="top">
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => { setOpen(true); setMode("PUT"); dispatch(setDefaultDataUnidad(data.row));dispatch(setUnidadId(data.row.id)) }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar Unidad" placement="top">
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => { setOpenDelete(true); setMode("DELETE");dispatch(setUnidadId(data.row.id)) }}
          >
            <Delete />
          </IconButton>
        </Tooltip>

      </React.Fragment>
    ),
  },
  {
    field: "modelo",
    headerName: "Modelo",
    width: 100,
  },
  {
    field: "marca",
    headerName: "Marca",
    width: 100,
  },
  {
    field: "descripcion",
    headerName: "Descripción",
    width: 250
  },
  {
    field: 'ano',
    headerName: "Year",
    width: 50,
  },
  {
    field: 'vin',
    headerName: "VIN",
    width: 150,
  },
  {
    field: 'bimestre1',
    headerName: "Verificación B1",
    width: 150,
  },
  {
    field: 'bimestre2',
    headerName: "Verificación B2",
    width: 150,
  },
  {
    field: 'tenencia',
    headerName: "Tenencia",
    width: 100,
  },
  {
    field: 'fechaAlta',
    headerName: "Fecha Alta",
    width: 100,
  },
  {
    field: 'fechaBaja',
    headerName: "Fecha Baja",
    width: 100,
  },
  {
    field: 'placa',
    headerName: "Placas",
    width: 50,
  },
  {
    field: 'engomado',
    headerName: "Engomado",
    width: 50,
  },

  
]
}
