import { Delete, Edit, Print } from "@mui/icons-material";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { setDefaultDataCut } from "store/modules/CutOrder";
import { setDefaultDataEmbroidery } from "store/modules/EmbroideryOrder";

export const columnsEmbroidery = (
  setOpen,
  setMode,
  setOpenDelete,
  dispatch,
  setOpenPDF
) => {
  return [
    {
      field: `acciones`,
      headerName: "Acciones",
      width: 150,
      renderCell: (data) => (
        <React.Fragment>
          <Tooltip title="Editar orden de corte" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpen(true);
                setMode("PUT");
                dispatch(setDefaultDataEmbroidery(data.row));
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar orden de corte" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenDelete(true);
                setMode("DELETE");
                dispatch(setDefaultDataEmbroidery(data.row));
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Imprimir Orden de Corte" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenPDF(true);
                dispatch(setDefaultDataEmbroidery(data.row));
              }}
            >
              <Print />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      field: "cutter",
      headerName: "Bordador",
      width: 100,
      renderCell: (data) => (
        <React.Fragment>{`${data.row.embroideryManager?.name}`}</React.Fragment>
      ),
    },
    {
      field: "product",
      headerName: "Modelo",
      width: 300,
      renderCell: (data) => (
        <React.Fragment>
          {`${data.row.product.modelo} (${data.row.product.nombre})`}
        </React.Fragment>
      ),
    },
    {
      field: "garment",
      headerName: "Prenda",
      width: 200,
      renderCell: (data) => <React.Fragment>{data.row.garment}</React.Fragment>,
    },
    {
      field: "createdByUser",
      headerName: "Creado por:",
      width: 200,
    },
    {
      field: "modifiedByUser",
      headerName: "Modificado por:",
      width: 200,
    },
  ];
};
