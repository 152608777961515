import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useState } from "react";

const AddTallaPedido = ({ open, handleClose, setDetails, tallas }) => {
  const [tallaSelected, setTallaSelected] = useState(null);
  const [cantidad, setCantidad] = useState(0);
  const handleAdd = () => {
    if (cantidad > 0 && tallaSelected) {
      setDetails((d) => [...d, { talla: tallaSelected, cantidad: cantidad }]);
      handleClose();
    } else {
      alert("Seleccione una talla y cantidad");
    }
  };
  return (
    <Dialog open={open} fullWidth onClose={handleClose} maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              name="tallaId"
              options={tallas || []}
              id="tallas"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Tallas" />}
              getOptionLabel={(talla) => `${talla.nombre}`}
              onChange={(e, option) => setTallaSelected(option)}
            // defaultValue={defaultDataDetalle?.talla}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="cantidad"
              type="number"
              name="cantidad"
              label="Cantidad"
              onChange={(e) => setCantidad(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={handleClose}>
          {"Cancelar"}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          {"Guardar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddTallaPedido;
