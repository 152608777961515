import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect, useState } from "react";
import { getAllProducts } from "components-base/productos/functions";
import { showMessage } from "store/modules/Message";
import { useDispatch, useSelector } from "react-redux";
import { getAllTallas } from "components-base/tallas/functions";
import { createPedidoDetalle, modifyPedidoDetalle } from "../functions";
import { Delete, Edit } from "@mui/icons-material";
import AddTallaPedido from "./AddTallaPedido";
import { getAllTallasByProductoId } from "components-base/productos/functions";
const PedidosDetailFormFields = ({ mode, open, handleClose }) => {
  const { defaultDataPedido: defaultData, defaultDataDetalle } = useSelector(
    ({ pedido }) => pedido
  );
  const [notes, setNotes] = useState("");
  const [productoId, setProductoId] = useState(0);
  const [products, setProductData] = useState(null);
  const [tallas, setTallas] = useState(null);
  const [image, setImage] = useState(null);
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (defaultDataDetalle && mode === "PUT") {
      setDetails(
        defaultDataDetalle.details ? defaultDataDetalle.details.tallas : []
      );
      setNotes(
        defaultDataDetalle.details ? defaultDataDetalle.details.notas : ""
      );
      setProductoId(defaultDataDetalle.producto.id);
      setImage(defaultDataDetalle.producto.imagen);
    } else {
      setDetails([]);
      setNotes("");
    }
  }, [defaultDataDetalle, mode]);

  useEffect(() => {
    const fetchData = async () => {
      let products = await getAllProducts();
      setProductData(products);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (mode === "PUT") {
      const fetchData = async () => {
        let response = await getAllTallasByProductoId(productoId);
        let tallas = [];
        response.forEach((item) => {
          tallas = [...tallas, item.talla];
        });
      };
      fetchData();
    }
  }, [mode, productoId]);

  const saveData = async () => {
    let total = 0;
    details.forEach((item) => {
      total += Number(item.cantidad);
    });
    let data = {
      id: mode === "PUT" ? defaultDataDetalle.id : 0,
      producto: { id: productoId },
      pedidoId: defaultData.id,
      cantidad: total,
      details: { tallas: details, notas: notes },
    };
    const res =
      mode === "PUT"
        ? await modifyPedidoDetalle(data)
        : await createPedidoDetalle(data);
    messages(
      res
        ? "Se agregó el detalle del producto"
        : "Hubo un error al intentar agregar el detalle",
      res
    );
    handleClose();
  };
  const messages = (mensaje, res) => {
    dispatch(
      showMessage({
        message: mensaje,
        variant: res ? "success" : "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    );
  };
  const handleDelete = (item) => {
    setDetails((d) => d.filter((i) => i.talla.nombre !== item.talla.nombre));
  };
  const handleEdit = () => {};
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleChangeProducto = async (e, option) => {
    if (!option) {
      setImage(null);
      setProductoId(0);
      setTallas(null);
      return;
    }
    setImage(option.imagen);
    let response = await getAllTallasByProductoId(option.id);
    let tallas = [];
    response.forEach((item) => {
      tallas = [...tallas, item.talla];
    });
    setProductoId(option.id || 0);
    setTallas(tallas);
  };
  return (
    <>
      <AddTallaPedido
        open={openModal}
        handleClose={handleCloseModal}
        setDetails={setDetails}
        tallas={tallas}
      />
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        xs={"lg"}
      >
        <DialogContent>
          <Box
            sx={{ height: boxSize + 100 }}
            style={{
              textAlign: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1">
              {"Cantidad, productos y tallas del pedido"}
            </Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  name="productoId"
                  options={products || []}
                  id="products"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Producto" />
                  )}
                  getOptionLabel={(product) => `${product.nombre}`}
                  onChange={handleChangeProducto}
                  defaultValue={
                    mode === "PUT" ? defaultDataDetalle?.producto : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div className="flex h-60 w-60" style={{ textAlign: "center" }}>
                  <img
                    className="h-full w-full rounded-xl"
                    //style={{ marginLeft: 450 }}
                    src={image}
                    alt="Foto del producto"
                  />
                </div>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 10, textAlign: "center" }}
              >
                <Grid item xs={3}>
                  {"Borrar/Editar"}
                </Grid>
                <Grid item xs={3}>
                  {"Talla"}
                </Grid>
                <Grid item xs={3}>
                  {"Código Talla"}
                </Grid>
                <Grid item xs={3}>
                  {"Cantidad"}
                </Grid>
                {details &&
                  details.map((item, index) => (
                    <>
                      <Grid item xs={3}>
                        <Delete
                          color="primary"
                          onClick={() => handleDelete(item)}
                        />
                        <Edit
                          onClick={() => handleEdit(item, index)}
                          color="primary"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        {item.talla.nombre}
                      </Grid>
                      <Grid item xs={3}>
                        {item.talla.codigo}
                      </Grid>
                      <Grid item xs={3}>
                        {item.cantidad}
                      </Grid>
                    </>
                  ))}
              </Grid>
              <Button
                onClick={handleOpen}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ margin: 5 }}
              >
                {"Agregar tallas"}
              </Button>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="notas"
                  name="notas"
                  label="Notas"
                  multiline
                  rows={5}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  color="primary"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                color={"secondary"}
              >
                Cancelar
              </Button>
              <Button
                onClick={saveData}
                variant="contained"
                color={"secondary"}
              >
                Guardar
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default PedidosDetailFormFields;
