import React from "react";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { Grid, Box, } from "@mui/material";
import OperadoresFormFields from "./OperadoresFormFields";
import TabComponent from "components-base/tab/Tab";
import { createOperadores,modifyOperadores, validationSchemaOperadores } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/modules/Message";

const OperadoresForm = ({ mode, handleClose }) => {
    const {defaultDataOperadores} = useSelector(({operador})=>operador);
    const dispatch = useDispatch();
    const messages = (mensaje,res) =>{
        dispatch(
            showMessage({
              message: `Operador: ${mensaje}`,
              variant: res ? "success" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            })
          );

    }
    const formik = useFormik({
        initialValues:
            mode === "POST"
                ? {
                    codigo: "",
                    numeroLicencia: "",
                    tipoLicencia: "",
                    vigenciaLicencia: "",
                    nombre: "",
                    apellidoPaterno: "",
                    apellidoMaterno: "",
                    direccion: "",
                    rfc: "",
                    curp:""
                }
                : {
                    id: defaultDataOperadores.id,
                    codigo: defaultDataOperadores.codigo,
                    numeroLicencia: defaultDataOperadores.numeroLicencia,
                    vigenciaLicencia: defaultDataOperadores.vigenciaLicencia,
                    tipoLicencia: defaultDataOperadores.tipoLicencia,
                    nombre: defaultDataOperadores.persona.nombre,
                    apellidoPaterno: defaultDataOperadores.persona.apellidoPaterno,
                    apellidoMaterno: defaultDataOperadores.persona.apellidoMaterno,
                    direccion: defaultDataOperadores.persona.direccion,
                    rfc: defaultDataOperadores.persona.rfc,
                    persona: {id :defaultDataOperadores.persona.id},
                    curp: defaultDataOperadores.curp,

                },
        validationSchema: validationSchemaOperadores,
        onSubmit: async (values) => {
            switch(mode){
                case "POST":
                  const res = await createOperadores(values);
                    messages(res ? "creado correctamente." : "falló creación.",res)
                    handleClose();
                break;
                case "PUT":
                  await modifyOperadores(values);
                    messages("modificado");
                    handleClose();
                break;
                default:handleClose();
            }
        },
    },);
    const { setFieldValue } = formik;
    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
                "& .MuiTextField-root": { marginBottom: "25px" },
            }}
            style={{ padding: "20px", justifyContent: "center" }}
        >
            <TabComponent tabsElement={[
                {
                    label: "Información Operadores",
                    component: <OperadoresFormFields formik={formik} mode={mode} setFieldValue={setFieldValue} />
                }
            ]} />
            <Grid container
                spacing={2}
                sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
            >
                <Grid item  >
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancelar
                    </Button>
                </Grid>
                <Grid item >
                    <Button color="primary" variant="contained" type="submit">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default OperadoresForm;