import { useApplicationContext } from "components-base/application-context/AppContext"
import { optionMonths } from "utils/apis"
import Card from "components/card";
import Dropdown from "components/dropdown";
import React from "react";
import { BsCalendar } from "react-icons/bs";

const FilterByMonth = ({ setFilterMonth }) => {
    const { filterMonth } = useApplicationContext();
    const defaultMonth = new Date().getMonth();
    const [open, setOpen] = React.useState(false);
    const transparent = true

    const handleChange = (item) => {
        setFilterMonth(item)
        setOpen(false);
    }

    return (
<Card extra="rounded-xl py-6 px-2 text-center w-[400px]">
  <Dropdown
      button={
        <button
          onClick={() => setOpen(!open)}
          open={open}
          className={`flex items-center text-xl hover:cursor-pointer ${
            !transparent
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
            <p className=" text-sm pr-4">  Selecciona un mes </p>
       <BsCalendar className="h-6 w-6" />
       <p className="pl-5 hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium">
        {filterMonth ? filterMonth.name : optionMonths.find(item => item.number === defaultMonth).name }</p>
        </button>
      }
      animation={open ? "origin-top-right transition-all duration-300 ease-in-out" : ""}
      classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
      children={
        <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          {
            optionMonths.map(item =>(
                <p onClick ={()=>handleChange(item)} key={item.name} className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium">
                <span>
                  <BsCalendar />
                </span>
               {item.name}
              </p>
            ))
          }

        </div>
      }
    />

        </Card>
    )
}
export default FilterByMonth;