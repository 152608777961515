import { client } from "utils/axios"
import * as yup from "yup";

export const deletePrenda = async (prendaId) => {
  await client.delete(`prendas/${prendaId}`);
};
export const deletePrendaInsumo = async (prendaInsumoId) => {
  await client.delete(`/prendaInsumo/${prendaInsumoId}`);
};
export async function getAllPrendas() {
  const { data } = await client.get("/prendas");
  return data;
}
export async function getAllPrendasById(id) {
  const { data } = await client.get("/prendas/"+ id);
  return data;
}

export async function getAllByProductId(id) {
  const { data } = await client.get(`/prendas/producto/${id}`);
  return data;
}
export async function getProductTallas(id) {
  const { data } = await client.get(`/productoTalla/product/${id}`);
  return data;
}
export async function getAllConsumptionByPrenda(id) {
  const { data } = await client.get(`/clothConsumption/prenda/${id}`);
  const response = data.length > 0 ? data[0] : null;
  return response;
}
export async function getAllConsumption() {
  const { data } = await client.get(`/clothConsumption`);

  return data;
}
export async function getAllConsumptionByPrendaV2(id) {
  const { data } = await client.get(`/clothConsumption/prenda/${id}`);
 
  return data;
}
export const deleteConsumption= async (id) => {
  await client.delete(`/clothConsumption/${id}`);
};
export async function getAllPrendaInsumo() {
  const { data } = await client.get(`/prendaInsumo`);
  return data;
}
export async function getAllPrendaInsumoById(id) {
  const { data } = await client.get(`/prendaInsumo/filter/${id}`);
  return data;
}

export const validationSchemaPrenda = yup.object({
  nombre: yup
    .string("Ingresa nombre del Prenda")
    .required("Campo Requerido"),
  descripcion: yup
    .string("Ingresa Descripción")
    .required("Campo Requerido"),
  sku: yup
    .string("Ingresa SKU")
    .required("Campo Requerido"),
});
export const createPrenda = async (prenda, pI) => {
  try {
    if (pI) {
      const res = await client.post("prendas", prenda);
      for (let i = 0; i < pI.length; i++) {
        let prendaInsumo = {
          cantidad: pI[i].cantidad,
          unidad: pI[i].unidad,
          insumo: { id: pI[i].insumo.id },
          prendaId: res.data.id
        }
        await createPrendaInsumo(prendaInsumo);
      }
      return;
    }
    await client.post("prendas", prenda);
  } catch (error) {
  }

};
export const modifyPrenda = async (prenda) => {
  try {
    await client.put("prendas", prenda);
  } catch (error) {
    console.log(error)
  }

};
export const createPrendaInsumo = async (prendaInsumo) => {
  try {
    await client.post("prendaInsumo", prendaInsumo);

  } catch (error) {
  }
}
export const modifyPrendaInsumo = async (prendaInsumo) => {
  try {
    await client.put("prendaInsumo", prendaInsumo);

  } catch (error) {
  }
}
export const createClothConsumption = async (clothConsumption) => {
  try {
    await client.post("clothConsumption", clothConsumption);
    return true;

  } catch (error) {
    return false;
  }
}

export const modifyClothConsumption = async (clothConsumption) => {
  try {
    await client.put("clothConsumption", clothConsumption);
    return true;

  } catch (error) {
    return false;
  }
}