import { Dialog, DialogContent, Button, DialogActions } from "@mui/material";
import { useSelector } from "react-redux";
import OrderSummaryPDF from "./OrderSummaryPDF";

const OrderSummaryModalPDF = ({ open, handleClose }) => {
  const { defaultDataPedido } = useSelector(({ pedido }) => pedido);
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent>
        <OrderSummaryPDF defaultDataDetail={defaultDataPedido} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={"secondary"} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OrderSummaryModalPDF;
