import ProductTable from "components-base/productos/components/ProductTable";
import TallasTable from "components-base/tallas/components/TallasTable";
import InsumoTable from "components-base/insumos/components/InsumoTable";
import TelasTable from "components-base/telas/components/TelaTable";





export const tabsElement = [
    {
      label: "Productos",
      component: <ProductTable />
    },
    {
        label: "Tallas",
        component: <TallasTable />
      },
      {
        label: "Insumos",
        component: <InsumoTable />
      },
      {
        label: "Telas",
        component: <TelasTable />
      },

  ];