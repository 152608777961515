import { combineReducers } from "redux";
import usuarios from "./modules/Usuarios/index";
import message from "./modules/Message";
import producto from "./modules/Productos";
import talla from "./modules/Tallas";
import insumo from "./modules/Insumos";
import pedido from "./modules/Pedidos";
import ruta from "./modules/Rutas";
import operador from "./modules/Operadores";
import maquilero from "./modules/Maquilero";
import supervisor from "./modules/Supervisor";
import unidad from "./modules/Unidades";
import asignaciones from "./modules/Asignaciones";
import salidas from "./modules/Salidas";
import telas from "./modules/Telas";
import suppliers from "./modules/Suppliers";
import prenda from "./modules/Prendas";
import session from "./modules/Session";
import cut from "./modules/CutOrder";
import embroidery from "./modules/EmbroideryOrder";
import category from "./modules/Categories";
import categoryType from "./modules/CategoriesType";
import department from "./modules/Department";
import position from "./modules/Position";
import incidentType from "./modules/IncidentType";
import systemRole from "./modules/SystemRole";
import worker from "./modules/Workers";
import user from "./modules/Users";
import specifications from "./modules/SpecificationSheet";
import stamping from "./modules/StampingOrder";
import driverlogs from "./modules/DriverLogs";
import machinary from "./modules/Machinary";
import furniture from "./modules/Furniture";
import inputs from "./modules/Inputs";
import outputs from "./modules/Outputs";
import pickups from "./modules/Pickups";
import stackRolls from "./modules/StackTelas";

export default combineReducers({
  pickups,
  driverlogs,
  stamping,
  specifications,
  user,
  worker,
  systemRole,
  incidentType,
  position,
  department,
  categoryType,
  category,
  cut,
  embroidery,
  session,
  salidas,
  telas,
  suppliers,
  asignaciones,
  usuarios,
  message,
  producto,
  talla,
  insumo,
  pedido,
  ruta,
  operador,
  maquilero,
  supervisor,
  unidad,
  prenda,
  machinary,
  furniture,
  inputs,
  outputs,
  stackRolls,
});
