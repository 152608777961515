import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteTalla } from "../functions";
const TallaDelete = ({ openDelete, setOpenDelete }) => {
    const dispatch = useDispatch();
    const { tallaIdSelected } = useSelector(({ talla }) => talla);
    const deleteFnc = async () => {
        await deleteTalla(tallaIdSelected)(dispatch);
        setOpenDelete(false);
    }
    return (
        <>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
            >
                <DialogTitle>
                    <Typography variant="title">{"Eliminar"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle">
                        {"Continuar con la Eliminación de la Talla"}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDelete(false) }color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={deleteFnc} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default TallaDelete;