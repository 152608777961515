import { createContext, useState, useContext } from 'react';
import { lightTheme } from "./themes";

const ThemeContext = createContext();

export function ThemeContextProvider({ children }) {
  

  const [theme, setTheme] = useState(lightTheme);

  return (
    <ThemeContext.Provider value={
      {
        theme, setTheme
      }
    }>
      {children}
    </ThemeContext.Provider>
  );
};

export function useThemeContext() {
  return useContext(ThemeContext)
}