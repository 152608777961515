import {
  Grid,
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { boxSize } from "utils/apis";
import { useEffect } from "react";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useSelector } from "react-redux";
import TallaCantidad from "./TallasCantidad";
import { getAllMaquilero } from "components-base/maquileros/functions";
import { getAllPedidos } from "components-base/pedidos/functions";
import { convertMonth, getAllSalidas, getDataDetalle } from "../functions";
import { getAllOperadores } from "components-base/operadores/functions";
import { getAllSupervisor } from "components-base/supervisor/functions";

const SalidaFormFields = ({
  internal,
  setInternal,
  formik,
  setFieldValue,
  mode,
  setFoliado,
  foliado,
  setTonos,
  tonos,
}) => {
  const { defaultData } = useSelector(({ salidas }) => salidas);
  const [supervisores, setSupervisores] = useState([]);
  const [operadores, setOperadores] = useState([]);
  const [maquileros, setMaquileros] = useState([]);
  const [dataAsigned, setDataAsigned] = useState(null);
  const [tallasByProduct, setTallasByProduct] = useState(null);
  const [editData, setEditData] = useState(null);
  const [pedidos, setPedidos] = useState(null);
  const [productoAsigned, setProductoAsigned] = useState(null);
  const [prendas, setPrendas] = useState([]);
  const [pedidoSelected, setPedidoSelected] = useState(null);
  const [productosByPedido, setProductosByPedido] = useState([]);
  const [prendasSelected, setPrendasSelected] = useState(null);
  const [rutaName, setRutaName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let maquileros = await getAllMaquilero();
      setMaquileros(maquileros);
      let operadores = await getAllOperadores();
      setOperadores(operadores);
      let supervisores = await getAllSupervisor();
      setSupervisores(supervisores);
      let pedidos = await getAllPedidos();
      let data = convertMonth(pedidos);
      let sortByMonth = data.sort(function (a, b) {
        return a.monthNumber - b.monthNumber;
      });
      setPedidos(sortByMonth);
      let salidas = await getAllSalidas();
      if (mode === "POST") {
        let maxFolio =
          salidas.length > 0
            ? salidas.sort((a, b) => {
                return b.folio - a.folio;
              })[0].folio
            : 0;
        setFieldValue("folio", maxFolio + 1);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (mode === "PUT") {
      // setMaquileros(
      //   defaultData.maquilero.ruta.supervisores.map((sup) => sup.supervisor)
      // );
      // setOperadores(
      //   defaultData.maquilero.ruta.operadores.map((op) => op.chofer)
      // );
      setRutaName(defaultData.maquilero.ruta.nombre);
      setTallasByProduct(defaultData.details.data);
      setFieldValue("pedido", defaultData?.pedido);
      setFieldValue("prendas", defaultData?.details?.prendas);
      setPrendasSelected(defaultData?.details?.prendas);
      setFieldValue("chofer", defaultData.chofer);
      setFieldValue("supervisor", defaultData.supervisor);
      loadDefaultData(defaultData?.pedido);
      setInternal(defaultData.internal);
      setTonos(defaultData.tonos);
      setFoliado(defaultData.foliado);
      setPedidoSelected(defaultData?.pedido);
    }
  }, [mode, defaultData]);

  const loadDefaultData = (pedido) => {
    pedido.detallePedido.forEach((element) => {
      setProductosByPedido((p) => [...p, element.producto]);
      setPrendas((p) => [...p, ...element.producto.prenda]);
    });
  };
  const handleChangeDate = (value, name) => {
    setFieldValue(name, moment(value).format("YYYY-MM-DD"));
  };
  const handleChangeMaquilero = (e, value) => {
    setRutaName(value.ruta.nombre);
    // setOperadores(value.ruta.operadores.map((op) => op.chofer));
    // setSupervisores(value.ruta.supervisores.map((op) => op.supervisor));
    setFieldValue("maquilero", value || "");
  };
  const onChangePedidos = async (e, value) => {
    setProductosByPedido([]);
    setPrendas([]);
    setProductoAsigned(null);
    setTallasByProduct(null);
    if (!value) {
      setFieldValue("pedido", null);
      setPedidoSelected(null);
      setProductoAsigned(null);
      setTallasByProduct(null);
      return;
    }

    setFieldValue("pedido", value);
    value.detallePedido.forEach((element) => {
      setProductosByPedido((p) => [...p, element.producto]);
    });
    let d_a = await getDataDetalle(value.id);
    setDataAsigned(d_a);
    setPedidoSelected(value);
  };

  const onChangeProducto = (e, value) => {
    if (!value || value.length === 0) {
      setTallasByProduct(null);
      setPrendas([]);
      return;
    }

    setTallasByProduct(null);
    if (!value || value.lenght === 0) {
      setProductoAsigned(null);
      return;
    }
    setProductoAsigned(value);
    value.forEach((item) => {
      setPrendas((p) => [...p, ...item.prenda]);
    });
    for (let i = 0; i < value.length; i++) {
      const val = value[i];
      const pedido = pedidoSelected.detallePedido.filter(
        (item) => item.producto.id === val.id
      );
      let tallas = pedido[0].details.tallas;
      for (let j = 0; j < tallas.length; j++) {
        let talla = tallas[j];
        talla.producto = val;
      }
      let obj = pedido[0].details.tallas;
      // for (let k = 0; k < obj.length; k++) {
      //   let o = obj[k];
      //   for (let h = 0; h < dataAsigned.length; h++) {
      //     const i = dataAsigned[h];
      //     if (o.talla.id === i.talla.id) {
      //       o.cantidad = Number(o.cantidad) - Number(i.cantidadAsignada);
      //       o.producto = {
      //         id: o.producto.id,
      //         nombre: o.producto.nombre,
      //         prenda: { length: o.producto.prenda.length },
      //       };
      //     }
      //   }
      // }
      setTallasByProduct(obj);
    }
  };
  const onChangePrenda = (e, options) => {
    setPrendasSelected(options);
    let _tallasByProduct = [...tallasByProduct];
    if (options.length === 0) {
      _tallasByProduct.forEach(item => {
        item.cantidadAsignada = 0;
        delete item.prendas;
      })
      setTallasByProduct(_tallasByProduct);
      setFieldValue("details", { data: _tallasByProduct, prendas: options.map(p => { return { id: p.id, productoId: p.productoId, nombre: p.nombre } }) });
      return;
    }
    let oldPrendas = prendasSelected;
    if (oldPrendas && oldPrendas.length > 0) {
      oldPrendas.forEach(op => {
        let p = options.find(item => Number(item.id) === Number(op.id));
        if (!p) {
          _tallasByProduct.forEach(item => {
            if (item.prendas) {
              let newPrendas = [];
              let oldP = item.prendas.find(p => Number(p.id) === Number(op.id));
              if (oldP) {
                item.cantidadAsignada = item.cantidadAsignada - oldP.cantidadAsignada;
                newPrendas = item.prendas.filter(i => Number(i.id) !== Number(oldP.id));
                item.prendas = newPrendas;
              }
            }
            setTallasByProduct(_tallasByProduct);
            setFieldValue("details", { data: _tallasByProduct, prendas: options.map(p => { return { id: p.id, productoId: p.productoId, nombre: p.nombre } }) })
          })
        }
      })
    }
  };
  const onChangeInternal = (e) => {
    setFieldValue("internal", e.target.checked);
    setInternal(e.target.checked);
  };
  const onChangeFoliado = (e) => {
    setFieldValue("foliado", e.target.checked);
    setFoliado(e.target.checked);
  };
  const onChangeTonos = (e) => {
    setFieldValue("tonos", e.target.checked);
    setTonos(e.target.checked);
  };
 if(!pedidos)return null;
  return (
    <Box sx={{ height: boxSize }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={1.5}>
              <TextField
                fullWidth
                id="folio"
                name="folio"
                label="Folio"
                value={formik.values.folio}
                onChange={formik.handleChange}
                error={formik.touched.folio && Boolean(formik.errors.folio)}
                helperText={formik.touched.folio && formik.errors.folio}
                color="primary"
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                name={"pedido"}
                groupBy={(option) => option.monthName}
                getOptionLabel={(pedido) =>
                  `${pedido.codigo} (${pedido.monthName}/${pedido.year})`
                }
                isOptionEqualToValue={(option, value) =>
                  Number(option.id) === Number(value.id)
                }
                onChange={onChangePedidos}
                disablePortal
                id="combo-box-pedidos"
                options={pedidos || []}
                renderInput={(params) => (
                  <TextField {...params} label="Pedido" />
                )}
                fullWidth
                defaultValue={mode === "PUT" ?  pedidos && pedidos.find(item => item.id === defaultData.pedido.id) : pedidos[0]}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                fullWidth
                id="number"
                name="number"
                disabled
                label="Codigo de pedido"
                InputLabelProps={{
                  shrink: true,
                }}
                value={pedidoSelected?.number}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
                color="primary"
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                name={"productos"}
                multiple
                limitTags={10}
                getOptionLabel={(option) => `${option.nombre}`}
                onChange={onChangeProducto}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                id="combo-box-productos"
                options={productosByPedido || []}
                renderInput={(params) => (
                  <TextField {...params} label="Producto(s)" />
                )}
                defaultValue={
                  mode === "PUT" ? [defaultData?.details.data[0].producto] : []
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                name={"prendas"}
                multiple
                limitTags={5}
                getOptionLabel={(option) => `${option.nombre}`}
                onChange={onChangePrenda}
                isOptionEqualToValue={(option, value) =>
                  Number(option.id) === Number(value.id)
                }
                id="combo-box-prendas"
                options={prendas || []}
                renderInput={(params) => (
                  <TextField {...params} label="Prenda(s)" />
                )}
                fullWidth
                defaultValue={
                  mode === "PUT" ? defaultData?.details?.prendas : []
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={3}>
            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="es-mx"
              >
                <DatePicker
                  name={"fechaSalida"}
                  label={"Fecha de Salida"}
                  value={moment(formik.values.fechaSalida) || moment()}
                  closeOnSelect
                  sx={{ width: "100%" }}
                  slotProps={{ textField: { variant: "outlined" } }}
                  showDaysOutsideCurrentMonth
                  disableHighlightToday
                  onChange={(value) => handleChangeDate(value, "fechaSalida")}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="es-mx"
              >
                <DatePicker
                  name={"fechaRetorno"}
                  label={"Fecha de Retorno"}
                  value={moment(formik.values.fechaRetorno) || moment()}
                  closeOnSelect
                  sx={{ width: "100%" }}
                  slotProps={{ textField: { variant: "outlined" } }}
                  showDaysOutsideCurrentMonth
                  disableHighlightToday
                  onChange={(value) => handleChangeDate(value, "fechaRetorno")}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                name="maquilero"
                options={maquileros || []}
                isOptionEqualToValue={(option, value) =>
                  Number(option.id) === Number(value.id)
                }
                id="maquilero"
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Maquilero" />
                )}
                getOptionLabel={(maquilero) =>
                  `${maquilero.persona.nombre} ${maquilero.persona.apellidoPaterno} ${maquilero.persona.apellidoMaterno}`
                }
                onChange={handleChangeMaquilero}
                defaultValue={defaultData.maquilero}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="ruta"
                name="ruta"
                label="Ruta"
                value={rutaName}
                helperText={formik.touched.ruta && formik.errors.ruta}
                color="primary"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={!supervisores}
                name="supervisor"
                options={supervisores || []}
                isOptionEqualToValue={(option, value) =>
                  Number(option.id) === Number(value.id)
                }
                id="supervisor"
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Supervisor" />
                )}
                getOptionLabel={(supervisor) =>
                  `${supervisor.persona.nombre} ${supervisor.persona.apellidoPaterno} ${supervisor.persona.apellidoMaterno}`
                }
                onChange={(e, value) =>
                  setFieldValue("supervisor", value || "")
                }
                defaultValue={defaultData.supervisor}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={!operadores}
                name="chofer"
                options={operadores || []}
                isOptionEqualToValue={(option, value) =>
                  Number(option.id) === Number(value.id)
                }
                id="chofer"
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Operador" />
                )}
                getOptionLabel={(operador) =>
                  `${operador.persona.nombre} ${operador.persona.apellidoPaterno} ${operador.persona.apellidoMaterno}`
                }
                onChange={(e, value) => setFieldValue("chofer", value || "")}
                defaultValue={defaultData.chofer}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="internal"
                    name="internal"
                    checked={internal}
                    onChange={onChangeInternal}
                    error={
                      formik.touched.internal && Boolean(formik.errors.internal)
                    }
                    helperText={
                      formik.touched.internal && formik.errors.internal
                    }
                    color="primary"
                  />
                }
                label="Incluir insumos internos"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="foliado"
                    name="foliado"
                    checked={foliado}
                    onChange={onChangeFoliado}
                    error={
                      formik.touched.foliado && Boolean(formik.errors.foliado)
                    }
                    helperText={formik.touched.foliado && formik.errors.foliado}
                    color="primary"
                  />
                }
                label="Foliado"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="tonos"
                    name="tonos"
                    checked={tonos}
                    onChange={onChangeTonos}
                    error={formik.touched.tonos && Boolean(formik.errors.tonos)}
                    helperText={formik.touched.tonos && formik.errors.tonos}
                    color="primary"
                  />
                }
                label="Tonos"
              />

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="comments"
                  name="comments"
                  label="Observaciones"
                  multiline
                  rows={5}
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TallaCantidad
              mode={mode}
              prendas={prendasSelected}
              setFieldValue={setFieldValue}
              tallasByProduct={tallasByProduct}
              setTallasByProduct={setTallasByProduct}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SalidaFormFields;
