import React from 'react';
import '../loader.css'; // You can create this CSS file for styling

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>

      </div>
      <p>Cargando datos del mes</p>
    </div>
  );
}

export default Loader;
