import { Box, Grid, Button, Typography, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { useState } from "react";
import storage from "utils/Firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { Add } from "@mui/icons-material";

const WorkerImage = ({ url, setFieldValue }) => {
    const [image, setImage] = useState(null);
    const [newUrl, setNewUrl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleUpload = () => {
        if (image) {
            const storageRef = ref(storage, `/worker_images/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                },
                (err) => console.log(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        setNewUrl(url);
                        setFieldValue("photo", url || "")
                    });
                }
            );
        }
        setOpen(false);//
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={"lg"}
            >
                <DialogTitle>
                    <Typography variant="subtitle">{"Cargar foto"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <input type="file" onChange={handleImageChange} />
                        <Button onClick={handleUpload} color="primary" variant="contained">
                            {"Cargar foto"}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Box sx={{ padding: 2 }} style={{ height: 400 }} >
                <Grid container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "flex-start", padding: 0 }}
                    flexDirection={"row"}
                >
                    <Grid item xs={12}>
                        <img style={{ height: 300 }} src={newUrl || url} alt="Sin foto del trabajador" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="title">{"Foto"}</Typography>
                        <IconButton onClick={() => setOpen(true)} color="primary" variant="contained">
                            <Add />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
export default WorkerImage;