import Card from "components/card";
import TallaMenu from "./TallaMenu";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../columns";
import TallaDrawer from "./TallaDrawer";
import React, { useState, useEffect } from "react";
import { getAllTallas } from "../functions";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import TallaDelete from "./TallaDelete";

const TallasTable = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [mode, setMode] = useState("POST");
  const [tallaData, setTallaData] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      let tallas = await getAllTallas();
      setTallaData(tallas);
    };
    fetchData();
    let col = columns(setOpen, setMode, setOpenDelete, dispatch);
    setDataColumns(col);
  }, [open, openDelete, dispatch]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ height: "750px", width: "100%" }}>
      <TallaDelete openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <TallaDrawer open={open} handleClose={handleClose} mode={mode} />
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-end">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            <TallaMenu setOpen={setOpen} setMode={setMode} />
          </div>
        </div>

        {/* <div className="relative flex items-center justify-end">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            <button
              onClick={() => {
                setOpen(true);
                setMode("POST");
              }}
              className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              Agregar Nueva Talla
            </button>
          </div>
        </div> */}

        {tallaData ? (
          <DataGrid
            className="dark:text-white mt-8 h-max w-full"
            rows={tallaData || []}
            columns={dataColumns || []}
            autoPageSize
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            checkboxSelection
            disableSelectionOnClick
            getRowHeight={() => "auto"}
          />
        ) : (
          <div className="flex justify-center pt-56">
            <CircularProgress color="primary" />
            <Typography>Cargando datos...</Typography>
          </div>
        )}
      </Card>
    </Box>
  );
};
export default TallasTable;
