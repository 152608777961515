import { Box } from "@mui/material";
import * as React from "react";
import TabComponent from "components-base/tab/Tab";
import { tabsElement } from "./tabs";

const InventoryManagement = () => {
  return (
    <Box>
      <TabComponent tabsElement={tabsElement} />
    </Box>
  );
};

export default InventoryManagement;
