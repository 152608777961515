import { Box } from "@mui/material";
import * as React from "react";

import Pedidos from "../../pedidos"
import TabComponent from "components-base/tab/Tab";

import DriverLogs from "components-base/driverlogs/DriverLogs";
import DriverLocation from "components-base/driverlogs/components/DriversLocations";

const PedidosManagement = () => {

  const tabsElement = [
    {
      label: "Pedidos",
      component: <Pedidos />
    },
    {
      label: "Seguimiento de Choferes",
      component: <DriverLogs />
    },
    // {
    //   label: "Ubicacion de choferes",
    //   component: <DriverLocation />
    // },
  ];

  return (
    <Box>
      <TabComponent tabsElement={tabsElement} />
    </Box>
  );
};

export default PedidosManagement;