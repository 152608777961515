
import { Autocomplete, Grid, TextField } from "@mui/material";


const AddConsumption = ({ details, setDetails,setNotes, notes }) => {

    const handleUnidadChange = (e, value, item) => {
        item.unit= value;
        setDetails(details.filter(t => t.id !== item.id))
        setDetails((talla) => [...talla, item])
        setDetails(details.sort((a, b) => { return a.id - b.id }))
    }
    const changeCantidad = (e, item) => {
        item.quantity = Number(e.target.value);
        setDetails(details.filter(t => t.id !== item.id))
        setDetails((talla) => [...talla, item])
        setDetails(details.sort((a, b) => { return a.id - b.id }))
    }
    const changeNotes = (e) =>{
        setNotes(e.target.value)

    }

    return (
        <>
            {details && (

                <>
                    <div style={{ textAlign: "center" }}>
                        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                            {"Especifique la cantidad por talla"}
                        </h4>
                    </div>
                    {details.length > 0 && details.map(item => (
                        <div className="grid grid-cols-4 gap-4 px-2 p-1">
                            <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <p className="text-sm text-gray-600">Talla:</p>
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    {item.nombre}
                                </p>
                            </div>

                            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <TextField
                                    label={"Cantidad requerida"}
                                    onChange={(e) => changeCantidad(e, item)}
                                    defaultValue={item.quantity}
                                    type="number"
                                />
                            </div>
                            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <Autocomplete
                                    getOptionLabel={(unidad) => `${unidad.name}`}
                                    onChange={(e, value) => handleUnidadChange(e, value, item)}
                                    disablePortal
                                    id="combo-box-insumos"
                                    options={[{ name: "Centimetros", factor: 100 }, { name: "Metros", factor: 1 }, { name: "Milimetros", factor: 1000 }, { name: "Pulgadas", factor: 2.54 }]}
                                    maxWidth
                                    renderInput={(params) => <TextField {...params} label="Unidad" />}
                                    defaultValue={item.unit}
                                />
                            </div>
                        </div>
                    ))}
                                <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <TextField
                                multiline
                                rows={3}
                                    fullWidth
                                    label={"Notas"}
                                    onChange={changeNotes}
                                    defaultValue={notes}
                                />
                            </div>
                </>

            )}
        </>
    )
}
export default AddConsumption