
import Card from "components/card";
import InsumoMenu from "./InsumoMenu";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../columns";
import InsumoDrawer from "./InsumoDrawer";
import React, { useState, useEffect } from "react";
import {  getAllInsumos } from "../functions";
import { Box,CircularProgress,Typography } from "@mui/material";
import { useDispatch } from "react-redux";
 import InsumoDelete from "./InsumoDelete";

const InsumosTable = () =>{
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [mode, setMode] = useState("POST");
    const [insumoData, setInsumoData] = useState(null)
    const [dataColumns, setDataColumns] = useState(null);
    const dispatch = useDispatch()
    
  useEffect(() => {
    const fetchData = async () => {
      let insumos = await getAllInsumos();
      setInsumoData(insumos)
    }
    fetchData();
    let col = columns(setOpen,setMode,setOpenDelete,dispatch,refresh,setRefresh);
     setDataColumns(col);
    
  }, [open,openDelete,dispatch,refresh]);

  const handleClose = () => {
    setOpen(false);
  }
    return (
        <Box sx={{ height: "750px", width: "100%" }}>
          <InsumoDelete openDelete={openDelete} setOpenDelete={setOpenDelete}/>
          <InsumoDrawer open={open} handleClose={handleClose} mode={mode} />
          <Card extra={"w-full h-full p-4"}>
            <div className="relative flex items-center justify-end">
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                <InsumoMenu setOpen={setOpen} setMode={setMode} />
              </div>
            </div>
            {insumoData ? (              
            <DataGrid
                className="dark:text-white mt-8 h-max w-full"
                rows={insumoData || []}
                columns={dataColumns || []}
                autoPageSize
                rowsPerPageOptions={[10,30,50,100]}
                checkboxSelection
                disableSelectionOnClick
                getRowHeight={() => 'auto'}
              />): (
                <div className="flex justify-center pt-56">
                <CircularProgress color="primary"/>
                 <Typography>Cargando datos...</Typography>
        
           </div>

              )}

          </Card>
        </Box>
      );
}
export default InsumosTable;