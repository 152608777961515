import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCutOrder,
  dataTransform,
  getAllClothsByProduct,
  modifyCutOrder,
  transformSizes,
} from "./functions";
import moment from "moment";
import SizesTable from "./sizesTable";
import TablePlaceholder from "./tablePlaceholder";
import CutOrderForm from "./CutOrderForm";
import { showMessage } from "store/modules/Message";

const OrderCut = ({ mode, open, handleClose, defaultDataPedido }) => {
  const { defaultDataCut } = useSelector(({ cut }) => cut);
  const { userName } = useSelector(({ session }) => session);
  const [data, setData] = useState(null);
  const [cloths, setCloths] = useState(null);
  const [sizes, setSizes] = useState(null);
  const [image, setImage] = useState("");
  const [cutter, setCutter] = useState(null);
  const [model, setModel] = useState(null);
  const [cloth, setCloth] = useState(null);
  const [verifier, setVerifier] = useState(0);
  const [defects, setDefects] = useState(null);
  const [cutTime, setCutTime] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(
    defaultDataPedido?.fechaEntrega
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(defaultDataPedido).length > 0) {
      setSizes(null);
      setImage("");
      const fetchData = async () => {
        const resp = await dataTransform(defaultDataPedido);
        setData(resp);
      };
      fetchData();
    }
  }, [defaultDataPedido]);

  useEffect(() => {
    if (defaultDataCut && mode === "PUT") {
      setImage(defaultDataCut.product.imagen);
      setSizes(defaultDataCut.sizes);
      setDefects(defaultDataCut.defects);
      setCutTime(defaultDataCut.cutTime);
      setDeliveryDate(defaultDataCut.deliveryDate);
      setCutter(defaultDataCut.cutter);
      setVerifier(defaultDataCut.verifier);
      setModel(defaultDataCut.product);
      setCloth(defaultDataCut.cloths);
      const getCloths = async () => {
        const _cloths = await getAllClothsByProduct(
          defaultDataCut.product.prenda
        );
        setCloths(_cloths);
      };
      getCloths();
    } else {
      setImage(null);
      setSizes(null);
      setDefects(null);
      setCutTime(null);
      setDeliveryDate(
        moment(defaultDataPedido?.fechaEntrega).format("YYYY-MM-DD")
      );
      setCutter({ id: 1, name: "Daniel Salcedo Lira" });
      setVerifier(0);
      setModel(null);
      setCloth(null);
    }
  }, [mode, defaultDataCut]);

  const handleChangeProduct = async (e, option) => {
    if (!option) return;
    const percentage = data.percentage;
    const _sizes = transformSizes(option.tallas, percentage);
    setSizes(_sizes);
    const _cloths = await getAllClothsByProduct(option.prenda);
    setCloths(_cloths);
    setImage(option.imagen);
    setModel(option);
  };
  const handleChangeDate = (value) => {
    setDeliveryDate(moment(value).format("YYYY-MM-DD"));
  };

  const handleSave = async () => {
    let obj = {
      cutter,
      verifier,
      defects,
      deliveryDate,
      cutTime,
      cloths: cloth,
      product: model,
      sizes,
      createdByUser: userName,
      createdDate: moment().format("YYYY-MM-DD"),
      pedidoId: defaultDataPedido.id,
    };
    switch (mode) {
      case "POST":
        await createCutOrder(obj);
        dispatch(
          showMessage({
            variant: "success",
            message: "La orden se creo satisfactoriamente",
          })
        );
        break;
      case "PUT":
        obj.id = defaultDataCut.id;
        obj.modifiedByUser = userName;
        obj.modifiedDate = moment().format("YYYY-MM-DD");
        await modifyCutOrder(obj);
        dispatch(
          showMessage({
            variant: "success",
            message: "La orden se modifico satisfactoriamente",
          })
        );
        break;
      default:
        break;
    }

    handleClose(false);
  };
  if (!data) return null;

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <div>
        <DialogTitle>
          <Typography variant="h4">{"Orden de Corte"}</Typography>
        </DialogTitle>
        <DialogContent>
          <CutOrderForm
            mode={mode}
            handleChangeDate={handleChangeDate}
            handleChangeProduct={handleChangeProduct}
            setCloth={setCloth}
            setCutter={setCutter}
            setVerifier={setVerifier}
            setCutTime={setCutTime}
            image={image}
            data={data}
            cloths={cloths}
            setDefects={setDefects}
            defaultDataCut={defaultDataCut}
            deliveryDate={defaultDataPedido?.fechaEntrega}
          />
          {sizes ? (
            <SizesTable sizes={sizes} setSizes={setSizes} />
          ) : (
            <TablePlaceholder style={{ paddingTop: 5 }} />
          )}
        </DialogContent>
      </div>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => (handleClose(), setSizes(null))}
        >
          Cancelar
        </Button>
        <Button
          disabled={!model || !cloth}
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OrderCut;
